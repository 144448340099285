import clsx from "clsx";
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  checkPermissions,
  dateFormat,
  defaultPermission,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isModalValidation,
  retrieveFilter,
  saveFilter,
  staticSearch
} from "../../../_eaFruitsDms/helpers/CommonHelpers";
import FormatMessage from "../../../_eaFruitsDms/helpers/components/FormateMessage";
import DatePickerCustom from "../../../_eaFruitsDms/helpers/datePicker";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../_eaFruitsDms/layout/components/button/eaButton";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import MobileNumberInput from "../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput";
import EaModal from "../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../TenantVariables";
import TableTeleSales from "./components/tableTeleSales";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce";
import ResetButton from "../../../_eaFruitsDms/layout/components/button/resetButton";
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import { filters } from "../../../_eaFruitsDms/utils/FilterConstants";

const TeleSales = () => {
  const intl = useIntl();

  const API_BASE_URL = tenantConfiguration.apiBaseUrl;

  const headerTitle = {
    title: "Tele-Sales Users"
  };

  // const currentDate = moment().format(GLOBAL_DATE_FORMAT)
  const currentDate = "Select";
  const currentDateObj = moment().toDate();

  const initialState = {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    gender: "",
    date_of_birth: "Select",
    is_active: false
  };

  const initialValidations = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    gender: "",
    dob: ""
  };

  let teleSalesFiltered: any = retrieveFilter(filters.sa_tele_sales);

  const initialFiltersState = {
    selected: teleSalesFiltered?.selected || '',
    search: teleSalesFiltered?.search || '',
    currentOffSet: teleSalesFiltered?.currentOffSet || '',
    buttonDisable: true
  }

  const [teleSaleUser, setTeleSaleUser] = useState<any>(initialState)
  const [teleSaleUsers, setTeleSaleUsers] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [searchByName, setSearchByName] = useState<string>(teleSalesFiltered?.search || '')
  const [selected, setSelected] = useState<number>(teleSalesFiltered?.selected || 0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffSet, setCurrentOffSet] = useState<any>(teleSalesFiltered?.currentOffSet || '0');
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [messageHandler, setMessageHandler] = useState<string>('')
  const [messageTitle, setMessageTitle] = useState<string>('')
  const [validationFor, setValidationFor] = useState<any>(initialValidations)
  const [submitButtonLabel, setSubmitButtonLabel] = useState('Submit')
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(false)
  const [isCancelBtnDisabled, setIsCancelBtnDisabled] = useState<boolean>(false)
  const [emailValidationMessage, setEmailValidationMessage] = useState<string>('')
  const [isNotUniquePhone, setIsNotUniquePhone] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [userId, setUserId] = useState<number>(0)
  const [dob, setDob] = useState<string>(currentDate)
  const [Name, setName] = useState(true)
  const [Email, setEmail] = useState(true)
  const [orderColumnKey, setOrderColumnKey] = useState('')
  const initialOrdering = '-created_at'
  const [ordering, setOrdering] = useState(initialOrdering)
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(teleSalesFiltered?.buttonDisable || false)

  const debounceSearch = useDebounce(searchByName, 500);

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    const is_deleted = localStorage.getItem("is_deleted");

    fetchTeleSalesUsers().then(() => {
      if (is_deleted) {
        setMessageHandler("successDelete");
        setIsAlertSuccess(true);

        setTimeout(() => {
          setIsAlertFailed(false);
          setIsAlertSuccess(false);
          localStorage.removeItem("is_deleted");
        }, 1500);
      }
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffSet
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_tele_sales, filterStates)
    if (searchByName == '') {
      setButtonDisable(false)
    }

  }, [limit, currentOffSet, debounceSearch])


  const fetchPermission = async () => {
    let res = await checkPermissions("telesales")
    setPermission(res)
  }

  const fetchTeleSalesUsers = async () => {
    let teleSalesUserListAPI = `${API_BASE_URL}auth/users/Telesales?ordering=${ordering}&limit=${limit}&offset=${currentOffSet}`;

    if (debounceSearch != '') {
      teleSalesUserListAPI += `&search=${debounceSearch}`
    }

    setDisplayLoader(true);

    const usersResponse = await getRequest(teleSalesUserListAPI, true);

    setDisplayLoader(false);
    setCount(usersResponse.count);
    setNext(usersResponse.next);
    setPrev(usersResponse.previous);
    setTeleSaleUsers(usersResponse.results);
  };
  let defaultOptions: any = [
    {
      value: "Male",
      label: "Male"
    },
    {
      value: "Female",
      label: "Female"
    }
  ];
  const fetchGender = async (search: any) => {
    let options: any = [];
    if (search) {
      options = staticSearch(defaultOptions, search);
    } else {
      options = defaultOptions;
    }
    return {
      options: options,
      hasMore: false
    };
  };

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name;
    let inputValue =
      inputName === "is_active" ? e.target.checked : e.target ? e.target.value : e.value;
    setIsNotUniquePhone(false);
    if (inputName === "phone_no") {
      if (inputValue.length > 9) {
        setValidationFor({ ...validationFor, phone_no: "is-invalid" });
        return false;
      }

      inputValue = inputValue.replace(/\D/g, "");
    }
    if (inputName === "email") {
      setEmailValidationMessage("");
    }

    setValidationFor({ ...validationFor, [inputName]: "" });

    if (inputValue === "") {
      setValidationFor({ ...validationFor, [inputName]: "is-invalid" });
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false);
    }

    setTeleSaleUser({ ...teleSaleUser, [inputName]: inputValue });
  };

  const checkEmail = (e: any) => {
    setEmailValidationMessage("");
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      setEmailValidationMessage("Please enter correct email format.");
      setValidationFor({ ...validationFor, email: "is-invalid" });
    }
  };

  const handleFilterChange = (e: any) => {
    setButtonDisable(true)
    setCurrentOffSet(0)
    setSelected(0)
    setSearchByName(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev);
      const newOffSet = queryParams.get("offset");
      setCurrentOffSet(newOffSet);
      setSelected(selected - 1);
    }
  };

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next);
      const newOffSet = queryParams.get("offset");
      setCurrentOffSet(newOffSet);
      setSelected(selected + 1);
    }
  };

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1);
      let newOffSet = index * 10;
      setCurrentOffSet(newOffSet);
    }
  };

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == "success") {
      setIsAlertSuccess(false);
    }
    if (val == "failed") {
      setIsAlertFailed(false);
    }
  };

  const isFormValid = () => {
    let isValid = true;
    const validationOccurFor = { ...validationFor };

    if (teleSaleUser.first_name.trim() === "") {
      validationOccurFor.first_name = "is-invalid";
      isValid = false;
    }

    if (teleSaleUser.last_name.trim() === "") {
      validationOccurFor.last_name = "is-invalid";
      isValid = false;
    }

    if (
      teleSaleUser.email.trim() === "" ||
      emailValidationMessage === "Please enter correct email format."
    ) {
      validationOccurFor.email = "is-invalid";
      isValid = false;
    } else {
      setEmailValidationMessage("");
      validationOccurFor.email = "";
    }

    if (teleSaleUser.phone_no.trim() === "") {
      validationOccurFor.phone_no = "is-invalid";
      isValid = false;
      setIsNotUniquePhone(false);
    }

    if (teleSaleUser.date_of_birth === "Select") {
      validationOccurFor.dob = "is-invalid";
      isValid = false;
    }

    if (teleSaleUser.gender === "") {
      validationOccurFor.gender = "is-invalid";
      isValid = false;
    }

    if (!isValid) {
      setIsSubmitBtnDisabled(false);
    }

    setValidationFor(validationOccurFor);
    return isValid;
  };

  const clearFunction = () => {
    setIsSubmitBtnDisabled(false);
    setInitialSettings({
      startDate: moment(),
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      cancelClass: "btn-secondary",
      singleDatePicker: true,
      maxDate: moment(Date.now()),
      locale: {
        format: GLOBAL_DATE_FORMAT
      },
      drops: "up",
      showDropdowns: true
    });
    setDob(currentDate);
    setTeleSaleUser(initialState);
    setValidationFor(initialValidations);
    setEmailValidationMessage("");
    setIsNotUniquePhone(false);
  };

  const clearDeleteModal = () => {
    setUsername("");
    setUserId(0);
  };

  const getTeleSaleUserDetails = async (userId: number) => {
    setModalLoader(true)
    const DETAIL_API = `${API_BASE_URL}auth/users/Telesales/${userId}`
    const response = await getRequest(DETAIL_API, true)

    if (response) {
      setTeleSaleUser(response)
      let newDate = dateFormat(response.date_of_birth)
      setInitialSettings((preState: any) => ({ ...preState, startDate: newDate.displayDate }))
      setDob(newDate.displayDate)
      setModalLoader(false)
    }
  };

  const submitTeleSaleUserData = async () => {
    let navigateField = {
      datePickerClose: teleSaleUser.first_name,
      last_name: teleSaleUser.last_name,
      email: teleSaleUser.email,
      phone_no: teleSaleUser.phone_no,
      gender: teleSaleUser.gender,
      date_of_birth: teleSaleUser.date_of_birth
    };

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0]);

    if (isFormValid()) {
      setSubmitButtonLabel("Please Wait..");
      setIsCancelBtnDisabled(true);
      let res: any = null;

      if (teleSaleUser.id === 0) {
        const CREATE_TELE_SALE_USER_API = `${API_BASE_URL}auth/users/telesales/create`;

        res = await postRequest(CREATE_TELE_SALE_USER_API, teleSaleUser, true);
      } else {
        const UPDATE_TELE_SALE_USER_API = `${API_BASE_URL}auth/users/Telesales/update/${teleSaleUser.id}/`;

        delete teleSaleUser.id;

        res = await putRequest(UPDATE_TELE_SALE_USER_API, teleSaleUser, true);
      }

      setSubmitButtonLabel("Submit");
      setIsCancelBtnDisabled(false);

      if (res.data.error) {
        if (res.data.error.field === "email") {
          setEmailValidationMessage(
            "Your added email already exist in this system! Please try again with unique name."
          );
        }

        if (res.data.error.field === "phone_no") {
          setIsNotUniquePhone(true);
        }
      } else {
        if (teleSaleUser.id != 0) {
          setMessageHandler("successEdit");
        } else {
          setMessageHandler("successAdd");
        }
        setIsAlertSuccess(true);
        setTeleSaleUser(initialState);
        $("#tele_sales_user").trigger("click");
        await fetchTeleSalesUsers();

        setTimeout(() => {
          setIsAlertFailed(false);
          setIsAlertSuccess(false);
        }, 1500);
      }
    }
  };

  const removeDetail = (user: any) => {
    setUsername(user.first_name + " " + user.last_name);
    setUserId(user.id);
  };

  const deleteUser = async () => {
    const DELETE_USER_API = `${API_BASE_URL}auth/users/Telesales/delete/${userId}/`;

    await deleteRequest(DELETE_USER_API, true);

    setMessageHandler("successDelete");
    setIsAlertSuccess(true);

    clearDeleteModal();

    $("#delete_user").trigger("click");

    await fetchTeleSalesUsers();

    setTimeout(() => {
      setIsAlertFailed(false);
      setIsAlertSuccess(false);
    }, 1500);
  };

  const onApply = () => {
    setValidationFor({ ...validationFor, dob: "" });
    $(`#datePickerClose`).blur();
  };

  const onCancel = () => {
    setValidationFor({ ...validationFor, dob: "" });
    $(`#datePickerClose`).blur();
  };

  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    alwaysShowCalendars: true,
    autoUpdateInput: false,
    cancelClass: "btn-secondary",
    singleDatePicker: true,
    maxDate: moment(Date.now()),
    locale: {
      format: GLOBAL_DATE_FORMAT
    },
    drops: "up",
    showDropdowns: true
  });
  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let newDate = dateFormat(start._d);
          setTeleSaleUser({ ...teleSaleUser, date_of_birth: newDate.apiDate });
          setDob(newDate.displayDate);
          setInitialSettings({
            startDate: start._d,
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            cancelClass: "btn-secondary",
            singleDatePicker: true,
            locale: {
              format: GLOBAL_DATE_FORMAT
            },
            drops: "up",
            showDropdowns: true,
            maxDate: moment(Date.now())
          });
          onApply();
        }}
        initialSettings={initialSettings}
        dateValidation={validationFor.dob}
        dateValue={dob}
      />
    );
  };

  const handleOrdering = (key: any) => {
    setOrderColumnKey(key);
    if (key === "Name") {
      setName(!Name);
      setEmail(true);
    }

    if (key === "Email") {
      setEmail(!Email);
      setName(true);
    }
  };

  useEffect(() => {
    if (orderColumnKey === "Email") {
      if (Email) {
        setOrdering("email");
      } else {
        setOrdering("-email");
      }
    }

    if (orderColumnKey === "Name") {
      if (Name) {
        setOrdering("first_name");
      } else {
        setOrdering("-first_name");
      }
    }
  }, [Email, Name, orderColumnKey]);

  useEffect(() => {
    fetchTeleSalesUsers();
  }, [ordering]);

  useEffect(() =>{
    localStorage.removeItem('teleSalesName')
  },[])

  const handleChangeReset = () =>{
    setSearchByName('')
    setCurrentOffSet(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title="Tele Sales" />
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: "Tele-Sales" })}</PageTitle>
      {/*Alert Success*/}
      <EaAlertSuccess
        onClick={() => isAlertSHow("success")}
        show={isAlertSuccess}
        message={
          messageHandler === "successAdd"
            ? FormatMessage("TELE_SALE.ADD", messageTitle)
            : messageHandler === "successEdit"
              ? FormatMessage("TELE_SALE.UPDATE", messageTitle)
              : messageHandler === "successDelete" && FormatMessage("TELE_SALE.DELETE", messageTitle)
        }
      />
      {/*Alert Error*/}
      <EaAlertDanger onClick={() => isAlertSHow("failed")} show={isAlertFailed} message="failed" />
      <div className="card">
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} value={searchByName} disabled={displayLoader} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <div className="me-3">
                {permission.write && (
                  <EaButton
                    isModal={true}
                    className="btn-sm btn-primary my-1"
                    dataTarget="#ea_modal_create_telesales_user"
                    btnName="Add Tele-Sales User"
                  />
                )}
              </div>
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {displayLoader && <IsLoader />}
            <TableTeleSales
              teleSaleUsers={teleSaleUsers}
              fetchDetail={getTeleSaleUserDetails}
              removeDetail={removeDetail}
              handleOrdering={handleOrdering}
              Name={Name}
              Email={Email}
              showWrite={permission.write}
              showDelete={permission.delete}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div></div>
      {/* <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${teleSaleUsers.length == 0 ? 0 : selected * limit + 1} to ${teleSaleUsers.length == 0
              ? 0
              : teleSaleUsers.length < 10
                ? count
                : (selected + 1) * 10
              } of ${teleSaleUsers.length == 0 ? 0 : count} entries`}
          </div>
          <div className="col">
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={count}
              selected={selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div> */}
      <CustomPaginate
        data={teleSaleUsers}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffSet}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.sa_tele_sales}
        filterStates={filterStates}
        isDisabled={displayLoader}
      />
      <EaModal
        closeAuto={"tele_sales_user"}
        id="ea_modal_create_telesales_user"
        onSubmit={submitTeleSaleUserData}
        modalTitle={
          teleSaleUser && teleSaleUser.id > 0 ? "Edit Tele-Sales User" : "Add Tele-Sales User"
        }
        actionBtnName={submitButtonLabel}
        btnDisabled={isSubmitBtnDisabled}
        cancel={clearFunction}
        cancelBtnDisabled={isCancelBtnDisabled}
        loader={modalLoader}
      >
        {/* <Form> */}
        <div className="row">
          <div className="mb-4 col-sm-6">
            <label className="form-label">
              First Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="datePickerClose"
              className={clsx("form-control", validationFor.first_name && validationFor.first_name)}
              placeholder="Type here..."
              onChange={handleChange}
              name="first_name"
              value={teleSaleUser && teleSaleUser.first_name}
            />
          </div>
          <div className="mb-4 col-sm-6">
            <label className="form-label">
              Last Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={clsx("form-control", validationFor.last_name && validationFor.last_name)}
              placeholder="Type here..."
              name="last_name"
              id="last_name"
              onChange={handleChange}
              value={teleSaleUser && teleSaleUser.last_name}
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="form-label">
            Email<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="email"
            disabled={teleSaleUser.id !== 0}
            className={clsx("form-control", validationFor.email && validationFor.email)}
            placeholder="Type here..."
            name="email"
            onChange={handleChange}
            onBlur={checkEmail}
            value={teleSaleUser && teleSaleUser.email}
          />
          {emailValidationMessage ? (
            <p className="text-danger">{emailValidationMessage}</p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-4" id="phone_no">
          <MobileNumberInput
            validation={validationFor.phone_no && validationFor.phone_no}
            onChange={handleChange}
            numberValue={teleSaleUser && teleSaleUser.phone_no}
            name="phone_no"
          />
          {isNotUniquePhone ? (
            <p className="text-danger">
              Your added phone number already exist in this system! Please try again with unique
              name.
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="row" id="gender">
          <div className="col-lg-6 fv-row mb-4">
            <label className="form-label">
              Gender<span className="text-danger">*</span>
            </label>
            <AsyncPaginate

              loadOptions={fetchGender}
              isSearchable
              placeholder="Select"
              className={clsx(
                "react-select-container mb-4",
                validationFor.gender && validationFor.gender
              )}
              classNamePrefix="react-select"
              name="gender"
              onChange={(e: any) => handleChange(e, "gender")}
              value={
                (teleSaleUser && teleSaleUser.gender === "") ||
                defaultOptions.find((gender: any) => gender.value === teleSaleUser.gender)
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#0c78a4"
                }
              })}
            />
          </div>
          <div className="col-lg-6 fv-row mb-4">
            <label className="form-label">
              Date of Birth<span className="text-danger">*</span>
            </label>
            <CustomDatePicker />
          </div>
        </div>
        {teleSaleUser && teleSaleUser.id > 0 && (
          <div className="mb-3">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <label className="form-check-label me-3">Access Status</label>
              <input
                className="form-check-input"
                name="is_active"
                onChange={handleChange}
                checked={teleSaleUser.is_active}
                type="checkbox"
                value=""
              />
            </div>
          </div>
        )}
      </EaModal>

      {/* delete User */}
      <EaModal
        modalTitle="Delete Tele-Sale User"
        children={
          <p className="mb-0">
            Are you sure you want to delete <span className="fw-bold">{username}?</span>
          </p>
        }
        cancel={clearDeleteModal}
        closeAuto="delete_user"
        id={"deleteUser"}
        actionBtnName="Yes, Proceed"
        onSubmit={deleteUser}
      />
    </>
  );
};

export default TeleSales;
