import React from 'react'
import Select from 'react-select'

interface dropdownModal {
  list: any
  placeholder: any
  onChange: any
  value: any
  className?: any
  isMulti?: any
  isDisable?: any
}

const EaDropDown = (props: dropdownModal) => {
  const {list, placeholder, onChange, value, className, isMulti, isDisable} = props

  return (
    <Select
      options={list}
      isSearchable
      isMulti={isMulti ? true : false}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={
        className
          ? `react-select-container ${className}`
          : `react-select-container my-1 me-3 w-160px`
      }
      classNamePrefix='react-select'
      isDisabled={isDisable}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#0c78a4',
        },
      })}
    />
  )
}

export default EaDropDown
