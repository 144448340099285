import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { apiBaseURL, checkPermissions, defaultPermission, isModalValidation, KTSVG, retrieveFilter, saveFilter } from "../../../../_eaFruitsDms/helpers"
import ShowingEntries from "../../../../_eaFruitsDms/helpers/components/ShowingEntries"
import { getRequest, postRequest, putRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import tenantConfiguration from "../../../../TenantVariables"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

const AddressReason = () => {
  const masterHeaderTitle = {
    title: 'Masters',
  }
  let reasonFiltered : any = retrieveFilter(filters.reason_master_address);

  const initialPageState = {
    search: reasonFiltered?.search || '',
    limit: tenantConfiguration.limit,
    offset: '',
    list: [],
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: reasonFiltered?.currentOffSet || '',
    selected:reasonFiltered?.selected || 0,
  }

  const initialFiltersState = {
      selected: reasonFiltered?.selected || '',
      search: reasonFiltered?.search || '',
      currentOffSet: reasonFiltered?.currentOffSet || '',
      buttonDisable: true
    }
  
  const initialValue = { option: '', type: 'Address_Rejection' }
  const [pageState, setPageState] = useState(initialPageState)
  const [validation, setValidation] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [id, setId] = useState('')
  const [disableBtn, setDisable] = useState(false)
  const [isLoading, setIsLoading] = useState('Submit')
  const [requestHandler, setRequest] = useState('Add')
  const [name, setName] = useState('')
  const [addressReason, setAddressReason] = useState(initialValue)
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(reasonFiltered?.buttonDisable || false) 

  const debounceSearch = useDebounce(pageState.search, 500);

  const getAddressReason = async () => {
    // Develop BY: Kashyap
    // Description: get List Details
    setPageState({
      ...pageState,
      isLoading: true,
    })
    const url =
      apiBaseURL() +
      'master/options-master/?' +
      `ordering=-updated_date&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${debounceSearch}&type=Address_Rejection`
    const response = await getRequest(url, true)
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
      isLoading: response ? false : true,
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = pageState.selected
    updatedState['currentOffSet'] = pageState.currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)

    setFilterStates(updatedState)
    saveFilter(filters.reason_master_address , filterStates)
    if(pageState.search == ''){
        setButtonDisable(false)
    }
  }

  const cancleFunction = () => {
    setAddressReason(initialValue)
    setIsLoading('Submit')
    setDisable(false)
    setValidation(false)
    setErrorMessage('')
    setModalLoader(false)
  }

  const modalHandler = (event: any, key: any) => {
    // Develop BY: Kashyap
    // Description: handling modal value when user add details
    key === 'input' && setAddressReason({ ...addressReason, [event.target.name]: event.target.value })
    if (key === 'edit') {
      setModalLoader(true)
      setRequest('Edit')
      setId(event.id)
      setAddressReason({ ...addressReason, option: event.option })
      setModalLoader(false)
    }
  }

  const onSubmit = async () => {
    setValidation(true)
    if (isModalValidation(addressReason).length === 0) {
      setName(addressReason.option)
      setDisable(true)
      setIsLoading('Please Wait...')
      let url = apiBaseURL() + 'master/options-master/'
      let editUrl = apiBaseURL() + 'master/options-master/' + id + '/'

      // Develop BY: Kashyap
      // Description: make the requestHandler for post and patch request with same code.
      const response =
        requestHandler === 'Add'
          ? await postRequest(url, addressReason, true)
          : await putRequest(editUrl, addressReason, true)
      let successArray = [201, 200]
      let failArray = [400, 500]
      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail()

      if (response.data.error) {
        if (response.data.error.field === 'non_field_errors') {
          setErrorMessage(
            'Your added Address reason already exists in this system! Please try again with a unique reason.'
          )
        }
      }
    }
  }

  const responseSuccess = () => {
    getAddressReason()
    setShowAlert(true)
    setIsLoading('Submit')
    setDisable(false)
  }

  const responseFail = () => {
    setIsLoading('Submit')
    setDisable(false)
  }

  useEffect(() => {
    getAddressReason()
    fetchPermission()
  }, [pageState.currentOffset, debounceSearch])
  
  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_rejection_options")
    setPermission(res)
   }

   const searchList = (e: any) => {
    setButtonDisable(true)
    let updatedPageState: any = { ...pageState }
    updatedPageState['selected'] = 0
    updatedPageState['currentOffset'] = 0
    updatedPageState['search'] =e.target.value
    setPageState(updatedPageState)
    setFilterStates({ ...filterStates, ['search']: e.target.value})
  }

  const handleChangeReset = () =>{
    let updatePageState :  any = {...pageState}
    updatePageState['search'] = ''
    updatePageState['currentOffset'] = 0
    updatePageState['selected'] = 0
    setPageState(updatePageState)

    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['selected'] = ''
    updatedState['currentOffSet'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Address Reason - ' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>{'Address Reason'}</PageTitle>
      <EaAlertSuccess
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={
          requestHandler === 'Add'
            ? 'ADDRESS.REASON.ADD'
            : requestHandler === 'Edit' && 'ADDRESS.REASON.EDIT'
        }
        name={name}
      />

      <div className={`card`}>
        {pageState.isLoading && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            {/* <CustomSearchBar newSearch={true} setResponse={setPageState} /> */}
            <CustomSearchBar onChange={searchList} value={pageState.search} disabled={pageState.isLoading}/>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
             {permission.write && 
              <EaButton
              onClick={() => setRequest('Add')}
              isModal={true}
              dataTarget='#ea_modal_create'
              btnName='Add Address Reason'
              className="btn-primary me-3"
            />
             }
             <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <ListTable
            list={pageState.list}
            showWrite = {permission.write}
            getPropsEdit={(item: any) => modalHandler(item, 'edit')}
          />
        </div>
      </div>
      {/* <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            <ShowingEntries response={pageState} />
          </div>
          <div className='col'>
            <CustomPagination newProp={true} pageState={pageState} setPageState={setPageState} />
          </div>
        </div>
      </div> */}
       <CustomPaginate
          data={pageState.list}
          selected={pageState.selected}
          pageState={pageState}
          setPageState={setPageState}
          totalRecords={pageState.count}
          limit={pageState.limit}
          isDisable={pageState.isLoading}
          saveFilterName={filters.reason_master_address}
          filterStates={filterStates}
        />
      {/* add group */}
      <EaModal
        actionBtnName={isLoading}
        btnDisabled={disableBtn}
        cancel={cancleFunction}
        onSubmit={onSubmit}
        id='ea_modal_create'
        modalTitle={requestHandler === 'Add' ? 'Add Address Reason' : 'Edit Address Reason'}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Address Reason<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx(
              'form-control',
              validation && addressReason.option === '' ? 'is-invalid' : ''
            )}
            name='option'
            onChange={(e) => modalHandler(e, 'input')}
            value={addressReason.option}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>{errorMessage && <ErrorHandler errorMessage={errorMessage} />}</div>
      </EaModal>
    </>
  )
}

export default AddressReason

const ListTable = (props: any) => {
  return (
    <div className='table-responsive'>
      <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
        <thead>
          <tr className=' text-muted fw-500'>
            <th className='min-w-120px py-2'>Option</th>
            <th className='min-w-80px action-th py-2'>{props.showWrite && "Actions"}</th>
          </tr>
        </thead>
        <tbody>
          {props.list.length !== 0 &&
            props.list.map((item: any) => (
              <tr>
                <td>{item.option}</td>
                <td className='text-end'>
                 {props.showWrite && 
                   <a
                   onClick={() => props.getPropsEdit(item)}
                   href='#ea_modal_create'
                   data-bs-toggle='modal'
                   data-bs-target='#ea_modal_create'
                   className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                 >
                   <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                 </a>
                 }
                </td>
              </tr>
            ))}
          <tr>
            <td>Other</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
