import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  isAdmin,
  isEmpty,
  isWarehouseManager,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../_eaFruitsDms/helpers'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {AsyncPaginate} from 'react-select-async-paginate'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import IssueStatus from './components/issueStatus'
import moment from 'moment/moment'
import {ChatInner} from '../../../_eaFruitsDms/partials'
import {issueStatus} from './credit-collection-report-constants'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {decryptData} from '../../../_eaFruitsDms/helpers/storageHelper'

const CreditCollectionReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const headerTitle = {
    title: 'Credit Collection Report',
    subTitle: 'Credit Collection Report',
  }

  const userType = decryptData('user_type') || ''
  let currentWarehouseId = !['sa', 'ts'].includes(userType.toLowerCase())
    ? localStorage.getItem('w_id')
    : ''

  const [creditCollectionCount, setCreditCollectionCount] = useState<any>({
    open_credit_request_count: 0,
    open_credit_request_amount: 0,
    overdue_credit_request_count: 0,
    overdue_credit_request_amount: 0,
  })
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [creditCollection, setCreditCollection] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [customers, setCustomers] = useState<any>([])
  const [customerReset, setCustomerReset] = useState<any>(new Date())
  const [srReset, setSrReset] = useState<any>(moment())
  const [customerOffSet, setCustomerOffSet] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [salesRepsOffSet, setSalesRepsOffSet] = useState<any>('')
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
  const [warehouseId, setWarehouseId] = useState<any>({label: 'All Warehouses', value: ''})
  const [customer, setCustomer] = useState<any>({
    label: 'All Customers',
    value: '',
  })
  const [sr, setSr] = useState<any>({
    label: 'All Sales Reps',
    value: '',
  })
  const [status, setStatus] = useState<any>({
    label: 'All Status',
    value: '',
  })
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchCreditCollection().then()
  }, [currentOffset, customer, sr, debounceSearch, status, warehouseId, apiDate])

  useEffect(() => {
    localStorage.setItem('selected-wid', '')
  }, [])

  const fetchCreditCollection = async () => {
    setDisplayLoader(true)
    let customerId = customer.value

    // if (
    //   (isAdmin() || isWarehouseManager()) &&
    //   location.pathname === '/customers/singlecustomer/credit-issue'
    // ) {
    //   customerId = localStorage.getItem('cId')
    // }

    let filteredWarehouse = warehouseId.value

    // if (isEmpty(filteredWarehouse)) {
    //   if (
    //     ['/warehouse/DC/singlewarehousename/credit-issues'].includes(location.pathname) ||
    //     checkUserType(['am'])
    //   ) {
    //     filteredWarehouse = currentWarehouseId
    //   }
    // }

    const statusValue = status.value === 'open' ? 'open,overdue' : status.value

    const creditResponse = await getRequest(
      `${apiBaseURL()}reports/credit-collection?search=${debounceSearch}&warehouse_id=${
        !isEmpty(filteredWarehouse) ? filteredWarehouse : ''
      }&customer_id=${customerId}&sr_id=${
        sr.value
      }&issue_status=${statusValue}&limit=${limit}&offset=${currentOffset}&ordering=-id&date_duration_after=${
        apiDate.startDate
      }&date_duration_before=${apiDate.endDate}`,
      true
    )

    if (!isEmpty(creditResponse.results)) {
      setCreditCollection(creditResponse.results)
      setCount(creditResponse.count)

      if (!isEmpty(creditResponse.meta)) {
        setCreditCollectionCount({
          open_credit_request_count: creditResponse.meta.open_credit_request_count,
          open_credit_request_amount: creditResponse.meta.open_credit_request_amount,
          overdue_credit_request_count: creditResponse.meta.overdue_credit_request_count,
          overdue_credit_request_amount: creditResponse.meta.overdue_credit_request_amount,
        })
      }
    }

    setDisplayLoader(false)
  }

  const fetchCustomers = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
      setCustomerOffSet('')
    }

    let wId = ['sa', 'wm', 'ts'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id') || ''

    if (!isEmpty(warehouseId.value)) {
      wId = warehouseId.value
    }

    if (!isEmpty(warehouseId.value)) {
      wId = warehouseId.value
    }

    let url = `&warehouse_id=${isEmpty(wId) ? '' : wId}`

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&on_boarding_status=Approved&search=${search}&limit=${limit}&offset=${customerOffSet}` +
        url,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (customerResponse.next !== null) {
      const queryParams = new URLSearchParams(customerResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerOffSet(newOffset)
      hasMore = true
    }

    if (customers.length === 0) {
      options.push({
        label: 'All Customers',
        value: '',
      })
    }

    if (customerResponse.results.length > 0) {
      customerResponse.results.map((option: any) => {
        options.push({
          label: option.business_name + ', ' + option.street,
          value: option.id,
        })
        optionData.push(option)
      })
    }

    if (customers.length > 0) {
      setCustomers(customers.concat(optionData))
    } else {
      setCustomers(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSalesReps = async (search?: any) => {
    let waId = ['sa', 'wm', 'ts'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id') || ''

    if (!isEmpty(warehouseId.value)) {
      waId = warehouseId.value
    }

    const wId = isEmpty(waId) ? '' : waId

    return await fetchAsyncDropdownNew(
      salesRepsOffSet,
      setSalesRepsOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/team/list?ordering=first_name&warehouse__id=${wId}&type=sr`,
      search,
      setSearchSelect,
      'first_name',
      'last_name',
      true,
      'All Sales Reps'
    )
  }

  const fetchIssueStatuses = (search?: string) => {
    return fetchStaticAsyncDropdown(issueStatus, search)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'warehouse':
        localStorage.setItem('selected-wid', inputValue?.value ?? '')
        setWarehouseId(e)
        setCustomerOffSet('')
        setCustomers([])
        setSalesRepsOffSet('')
        setSrReset(moment())
        setSalesRepsOption([])
        setSr({
          label: 'All Sales Reps',
          value: '',
        })
        setCustomer({
          label: 'All Customers',
          value: '',
        })
        break
      case 'customer':
        setCustomer(inputValue)
        break
      case 'sales':
        setSr(inputValue)
        break
      case 'status':
        setStatus(inputValue)
        break
      default:
        setSearchByName(inputValue)
        break
    }
  }

  const goToDetailPage = async (creditIssueId: number) => {
    navigate('/reports/credit-collection-report/credit-issues-detail', {
      state: {
        credit_issue: 'credit_issue',
        reports: 'report',
        creditIssueId: creditIssueId,
        creditCollection: 'creditCollection',
      },
    })
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Credit Collection Report' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Credit Collection Report',
        })}
      </PageTitle>
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={`${
              numberFormat(creditCollectionCount.open_credit_request_count).displayFormat
            } Credit Collection`}
            path='/warehouse/DC'
            description=''
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={`${
              numberFormatWithCode(creditCollectionCount.open_credit_request_amount).displayFormat
            }`}
            description='Collected Total Credit Amount'
            path='/products'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='dark'
            title={`${
              numberFormat(creditCollectionCount.overdue_credit_request_count).displayFormat
            } Overdue Credit Collection`}
            description=''
            path='/customers'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={`${
              numberFormatWithCode(creditCollectionCount.overdue_credit_request_amount)
                .displayFormat
            }`}
            description='Collected Overdue Credit Amount'
            path='/reports/sales-report'
          />
        </div>
      </div>
      <div className={`card ${className}`}>
        {displayLoader && <IsLoader />}
        {/* {displayLoader && <IsLoader />} */}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <CustomDatePickerComponent
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate({
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  })
                  localStorage.setItem('credit-start-date', callBakeDate.startDate)
                  localStorage.setItem('credit-end-date', callBakeDate.endDate)

                  setCurrentOffset(0)
                  setSelected(0)
                }}
                value={apiDate}
                startDate={apiDate.startDate}
                endDate={apiDate.endDate}
              />
              {!checkUserType(['am']) && (
                <WarehouseFilter
                  warehouse={warehouseId}
                  handleFilterChange={(e: any) => {
                    handleFilterChange(e, 'warehouse')
                    setCustomerReset(new Date())
                    localStorage.setItem('credit-selected-wid', e.value)
                  }}
                  isDisabled={displayLoader}
                />
              )}
              <AsyncPaginate
                loadOptions={fetchCustomers}
                key={customerReset}
                isDisabled={displayLoader}
                isSearchable
                className='react-select-container w-150px me-3'
                classNamePrefix='react-select'
                menuPosition='fixed'
                name='customer'
                value={customer}
                onChange={(e: any) => handleFilterChange(e, 'customer')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchSalesReps}
                isSearchable
                key={srReset}
                isDisabled={displayLoader}
                placeholder='All Sales Reps'
                className='react-select-container my-1 me-3 w-140px'
                classNamePrefix='react-select'
                name='sales'
                onChange={(e: any) => handleFilterChange(e, 'sales')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchIssueStatuses}
                isSearchable
                isDisabled={displayLoader}
                placeholder='All Status'
                className='react-select-container my-1 me-3 w-140px'
                classNamePrefix='react-select'
                name='sales'
                onChange={(e: any) => handleFilterChange(e, 'status')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {creditCollection.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px w-100px'>Order No</th>
                    <th className='w-120px min-w-120px'>Delivery Date</th>
                    <th className='min-w-160px'>Customer Name</th>
                    <th className='w-150px min-w-150px'>SR Name</th>
                    <th className='min-w-120px'>Order Amount</th>
                    <th className='min-w-150px'>Credit Amount</th>
                    <th className='min-w-150px'>Credit Collection</th>
                    <th className='min-w-180px'>Prev. Collected Amount</th>
                    <th className='min-w-150px'>Due Amount</th>
                    <th className='min-w-150px'>Due Date</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {creditCollection.map((creditCollection: any) => {
                    return (
                      <tr
                        onClick={() => goToDetailPage(creditCollection.credit_request_id)}
                        className='cursor-pointer'
                      >
                        <td>
                          {isEmpty(creditCollection.order_no)
                            ? '-'
                            : `#${creditCollection.order_no}`}
                        </td>
                        <td>
                          {isEmpty(creditCollection.delivery_date)
                            ? '-'
                            : dateFormat(creditCollection.delivery_date).displayDate}
                        </td>
                        <td>
                          {isEmpty(creditCollection.customer_name)
                            ? '-'
                            : creditCollection.customer_name}
                        </td>
                        <td>
                          {isEmpty(creditCollection.sr_name) ? '-' : creditCollection.sr_name}
                        </td>
                        <td>
                          {isEmpty(creditCollection.total_amount)
                            ? '-'
                            : numberFormatWithCode(creditCollection.total_amount).displayFormat}
                        </td>
                        <td>
                          {isEmpty(creditCollection.credit_amount)
                            ? '-'
                            : numberFormatWithCode(creditCollection.credit_amount).displayFormat}
                        </td>
                        <td>
                          {isEmpty(creditCollection.amount) ? (
                            '-'
                          ) : (
                            <>
                              {numberFormatWithCode(creditCollection.amount).displayFormat}
                              <div>
                                {isEmpty(creditCollection.payment_date)
                                  ? ''
                                  : `on #${dateFormat(creditCollection.payment_date).displayDate}`}
                              </div>
                            </>
                          )}
                        </td>
                        <td>
                          {isEmpty(creditCollection.previous_collected_amount)
                            ? '-'
                            : numberFormatWithCode(creditCollection.previous_collected_amount)
                                .displayFormat}
                        </td>
                        <td>
                          {isEmpty(creditCollection.due_amount)
                            ? '-'
                            : numberFormatWithCode(creditCollection.due_amount).displayFormat}
                        </td>
                        <td>
                          {isEmpty(creditCollection.due_date)
                            ? '-'
                            : dateFormat(creditCollection.due_date).displayDate}
                        </td>
                        <td>
                          <IssueStatus
                            status={creditCollection.issue_status}
                            dueDate={creditCollection.due_date}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={creditCollection}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default CreditCollectionReport
