import React, {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {apiBaseURL, retrieveFilter, saveFilter, shortName} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../../../_eaFruitsDms/layout/components/pagination/pagination'
import tenantConfiguration from '../../../../../../TenantVariables'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'
import CustomPaginate from '../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ResetButton from '../../../../../../_eaFruitsDms/layout/components/button/resetButton'
import { filters } from '../../../../../../_eaFruitsDms/utils/FilterConstants'

const Team: React.FC = () => {
  const warehouseId = localStorage.getItem('wId')
  let teamsFiltered : any = retrieveFilter(filters.sa_warehouse_dc_team);

  const initialState = {
      roleId : teamsFiltered?.roleId || {label : 'All Roles' , value : ''},
      selected: teamsFiltered?.selected || '',
      search: teamsFiltered?.search || '',
      currentOffSet: teamsFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [teamUsers, setTeamUsers] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  const [limit, setLimit] = useState<number>(10)
  const [searchByName, setSearchByName] = useState<string>(teamsFiltered?.search || '')
  const [selected, setSelected] = useState<number>(teamsFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>(teamsFiltered?.currentOffSet || '0')
  const [roleOffset, setRoleOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [roleId, setRoleId] = useState<any>(teamsFiltered?.roleId || {
    label: 'All Roles',
    value: ''
});
  const [searchSelect, setSearchSelect] = useState('')
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(teamsFiltered?.buttonDisable || false) 

  useEffect(() => {
    fetchTeamUsers().then()
  }, [currentOffset, roleId, searchByName])

  useEffect(() => {
    setRoleOffset('')
  }, [searchSelect])

  const fetchTeamUsers = async () => {
    let FETCH_API = `${apiBaseURL()}auth/users/team/list?warehouse__id=${warehouseId}&limit=${limit}&offset=${currentOffset}`

    if (roleId.value != 0) {
      FETCH_API += `&role__id=${roleId.value}`
    }

    if (searchByName !== '') {
      FETCH_API += `&search=${searchByName}`
    }

    setTimeout(async () => {
      setDisplayLoader(true)
      const users = await getRequest(FETCH_API, true)
      setTeamUsers(users.results)

      setCount(users.count)
      setNext(users.next)
      setPrev(users.previous)
      setDisplayLoader(false)
    }, timer)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_warehouse_dc_team , filterStates)
    if(roleId.value == '' && searchByName == ''){
        setButtonDisable(false)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * limit
      setCurrentOffset(newOffset)
    }
  }

  const fetchRoles = async (key?: any, search?: any) => {
    let options: any = []
    if (search != '') {
      setSearchSelect(search)
    }

    const ROLE_API = `${apiBaseURL()}auth/application/role/list/?ordering=name&limit=${limit}&offset=${roleOffset}&user_type__in=wm,oo,lo,ss,sr,sg,ls,am&search=${search}`

    const roleResponse = await getRequest(ROLE_API, true)

        let hasMore: boolean = false
        if(roleResponse.results){
            if (roleResponse.next !== null) {
                const queryParams = new URLSearchParams(roleResponse.next);
                let newOffset: any = queryParams.get('offset');
                setRoleOffset(newOffset)
                hasMore = true
            }

            if (roles.length == 0) {
                options.push({
                    label: "All Role",
                    value: 0
                })
            }

            if (roleResponse.results.length > 0) {
                roleResponse.results.map((option: any) => {
                    options.push({
                        label: option.name,
                        value: option.id
                    })
                })
            }

            if (roles.length > 0) {
                setRoles(roles.concat(options))
            } else {
                setRoles(options)
            }
        }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleRoleChange = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({ ...filterStates, ['roleId']: e })
    setRoleId(e)
    setTimer(null)
  }

  const handleFilterChange = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setTimer(1000)
    setSearchByName(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearchByName('')
    setRoleId({label : 'All Roles' , value : ''})
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['roleId'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Team' />
      <div className='card'>
        {displayLoader && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div>
            <input
              type='text'
              className='form-control form-search'
              placeholder='Search'
              onChange={handleFilterChange}
              value={searchByName}
              disabled={displayLoader}
            />
          </div>
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={(search) => fetchRoles('', search)}
                isSearchable
                className='react-select-container me-3 w-160px'
                classNamePrefix='react-select'
                name='role'
                placeholder='All Roles'
                value={roleId}
                isDisabled={displayLoader}
                onChange={handleRoleChange}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {teamUsers.length == 0 ? (
            <NoRecords />
          ) : (
            <>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-150px'>Name</th>
                      <th className='min-w-140px'>Email</th>
                      <th className='min-w-120px'>Mobile Number</th>
                      <th className='min-w-120px'>Role</th>
                      <th className='min-w-120px'>Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {teamUsers.map((user: any) => {
                      return (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <span className='rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10'>
                                {shortName(user.first_name + ' ' + user.last_name)}
                              </span>
                              <div className='media-body'>
                                <p className='m-0 xs-font'>
                                  {user.first_name + ' ' + user.last_name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>{user.email}</td>
                          <td>
                            {user.phone_no && tenantConfiguration.baseCountryCode} {user.phone_no}
                          </td>
                          <td className='text-dark'>{user.role ? user.role.name : '-'}</td>
                          <td>
                            {user.is_active ? (
                              <span className='badge badge-light-success text-dark'>Active</span>
                            ) : user.is_deleted ? (
                              <span className='badge badge-danger text-dark'>Inactive</span>
                            ) : (
                              <span className='badge badge-secondary text-dark'>Inactive</span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </>
          )}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
          data={teamUsers}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.sa_warehouse_dc_team}
          filterStates={filterStates}
          isDisabled={displayLoader}
        />
    </>
  )
}

export default Team
