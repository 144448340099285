import clsx from 'clsx'
import $ from 'jquery'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkPermissions,
  dateFormat,
  defaultPermission,
  fetchAsyncDropdown,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  isModalValidation,
  KTSVG,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../_eaFruitsDms/helpers/components/CapitalLetter'
import DatePickerCustom from '../../../_eaFruitsDms/helpers/datePicker'
import {getRequest, patchRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import MobileNumberInput from '../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {validateEmail} from '../../../_eaFruitsDms/validations/eaValidation'
import {TeamMemberTable} from './components/tableTeamMemberRedesign'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../TenantVariables'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import {Modal} from 'react-bootstrap'

const TeamMembersRedesign = () => {
  const intl = useIntl()
  const masterHeaderTitle = {title: 'Team members'}
  const initialRoleModal = {label: 'Select', value: ''}
  const emailValidationMessage = 'Wrong email format'
  let teamFiltered: any = retrieveFilter(filters.wm_team_members)

  const initialPageState = {
    search: teamFiltered?.search || '',
    limit: tenantConfiguration.limit,
    offset: '',
    list: [],
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: teamFiltered?.currentOffSet || '',
    selected: teamFiltered?.selected || 0,
    warehouseId: teamFiltered?.warehouseId.value || '',
    roleId: teamFiltered?.roleId.value || '',
  }
  const initialGender = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'},
  ]
  const warehouseManagerUserType = [
    {value: 'am', label: 'Account Manager'},
    {value: 'lo', label: 'Logistics Officer'},
    {value: 'mo', label: 'Marketing Officer'},
    {value: 'oo', label: 'Operations Officer'},
    {value: 'ss', label: 'Sales Supervisor'},
    {value: 'sr', label: 'Sales Representatives'},
    {value: 'sg', label: 'Security Gatekeeper'},
    {value: 'wm', label: 'Branch Manager'},
  ]
  const initialTeamMember = {
    first_name: '',
    last_name: '',
    gender: '',
    email: '',
    warehouse: '',
    type: '',
    date_of_birth: '',
    is_active: false,
    phone_no: '',
    role: '',
    supervisor: '',
    // user_ward_brand: '',
    sales_teams: '',
    sales_team: '',
    is_active_pay_later_today: false,
    tra_device_id: null,
    tra_device_phone: null,
    tra_device_serial_number: null,
    tra_device_name: null,
    tra_device_password: null,
  }
  const initialWardBrand: any = {
    ward: '',
    brand: [],
  }
  const initialSalesTeamWards: any = {
    salesTeam: '',
    wards: [],
  }
  const initialBrands: any = {brands: []}

  const initialState = {
    roleId: teamFiltered?.roleId || '',
    warehouseId: teamFiltered?.warehouseId || '',
    search: teamFiltered?.search || '',
    selected: teamFiltered?.selected || 0,
    currentOffSet: teamFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  // listing state
  const [pageState, setPageState] = useState(initialPageState)

  // team state
  const [teamMember, setTeamMember] = useState(initialTeamMember)

  // warehouse filter state
  const [warehouse, setWarehouse] = useState(
    teamFiltered?.warehouseId || {label: 'All Warehouse', value: ''}
  )
  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')

  // supervisor modal filter state
  const [supervisorOffset, setSupervisorOffset] = useState('')
  const [supervisorList, setSupervisorList] = useState([])
  const [supervisorSearch, setSupervisorSearch] = useState('')

  // role filter state
  const [role, setRole] = useState(teamFiltered?.roleId || {label: 'All Roles', value: ''})
  const [roleOffset, setRoleOffset] = useState('')
  const [roleList, setRoleList] = useState([])
  const [roleSearch, setRoleSearch] = useState('')

  // ward filter state
  const [wardsSalesTeamOffset, setWardsSalesTeamOffset] = useState('')
  const [ward, setWard] = useState<boolean>(true)
  const [wardsSalesTeamSearch, setWardsSalesTeamSearch] = useState('')
  const [wardAPIDone, setWardAPIDone] = useState<boolean>(false)

  // sales team filter state
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [salesTeam, setSalesTeam] = useState<any>('')

  // modal state
  const [requestHandler, setRequestHandler] = useState('Add')
  const [isModalLoading, setIsModalLoading] = useState('Submit')
  const [date, setDate] = useState('Select')
  //   - modal dropdown value state
  const [supervisor, setSupervisor] = useState<any>('')
  const [userType, setUserType] = useState<any>('')
  const [rolesReset, setRolesReset] = useState<any>(moment())
  const [roleModal, setRoleModal] = useState(initialRoleModal)
  const [warehouseModal, setWarehouseModal] = useState<any>('')
  const [gender, setGender] = useState<any>('')
  const [id, setId] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState<any>('')

  // validation state
  const [isValidation, setIsValidation] = useState(false)
  const [isCorrectEmail, setIsCorrectEmail] = useState('')
  const [isWard, setIsWard] = useState('')

  // alert state
  const [showAlert, setShowAlert] = useState(false)
  const [alertName, setAlertName] = useState('')

  // status state
  const [checked, setChecked] = useState<any>(false)

  // allow pay later today state
  const [allowPayLaterToday, setAllowPayLaterToday] = useState<any>(true)

  // tra state
  const [showPassword, setShowPassword] = useState(false)
  const [serialNumberValid, setSerialNumberValid] = useState('')
  const [showSrConfirm, setShowSrConfirm] = useState(false)
  const [isTraValid, setIsTraValid] = useState(false)

  // ward-brand state
  const [wardBrand, setWardBrand] = useState([initialWardBrand])
  const [remove, setRemove] = useState(false)
  const [add, setAdd] = useState(false)
  const [brands, setBrands] = useState<any>([initialBrands])
  const [permission, setPermission] = useState(defaultPermission)
  const [supervisorReset, setSupervisorReset] = useState<any>(moment())

  // sales-team-brand state
  const [salesTeamWards, setSalesTeamWards] = useState([initialSalesTeamWards])
  const [salesTeamOffset, setSalesTeamOffset] = useState('')
  const [salesTeamList, setSalesTeamList] = useState([])
  const [salesTeamSearch, setSalesTeamSearch] = useState('')
  const [salesTeamReset, setSalesTeamReset] = useState<any>(moment())
  const [salesTeamWardsReset, setSalesTeamWardsReset] = useState<any>(moment())
  const [salesTeamWardsUniqError, setSalesTeamWardsUniqError] = useState<any>([])
  const [resetSalesTeam, setResetSalesTeam] = useState<any>(moment())

  // date state
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    alwaysShowCalendars: true,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    maxDate: moment(),
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    parentEl: '.modal',
    drops: 'up',
    autoUpdateInput: false,
    showDropdowns: true,
  })

  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(teamFiltered?.buttonDisable || false)
  const [search, setSearch] = useState<any>(teamFiltered?.search || '')
  const debounceSearch = useDebounce(pageState.search, 500)

  // team-list function
  const getTeamMemberList = async () => {
    setPageState({
      ...pageState,
      isLoading: true,
    })

    let warehouseUrl = ''
    if (pageState.warehouseId == '') {
      warehouseUrl = `&warehouse_id=${getW_Id}`
    } else {
      warehouseUrl =
        `&warehouse_id=${pageState.warehouseId}` + `&warehouse__id=${pageState.warehouseId}`
    }

    const url = `${apiBaseURL()}auth/users/team/list?&limit=${pageState.limit}&offset=${
      pageState.currentOffset
    }&search=${debounceSearch}&role__id=${pageState.roleId}${warehouseUrl}`
    const response = await getRequest(url, true)
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
      isLoading: response ? false : true,
    })
  }

  ////////////////// for TRA  //////////////////////////
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  ////////////////// all asyncDropdown start //////////////////////////
  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${getW_Id}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      true,
      'All Warehouse'
    )
  }

  const fetchWarehouseModal = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${getW_Id}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  const fetchRoles = (search: any) => {
    return fetchAsyncDropdown(
      roleOffset,
      setRoleOffset,
      roleList,
      setRoleList,
      `auth/application/role/list/?ordering=name&user_type__in=wm,oo,lo,ss,sr,sg,ls,am`,
      search,
      setRoleSearch,
      false,
      true,
      'All Roles'
    )
  }

  const fetchRolesModal = (search?: any) => {
    let userId = userType?.value
    return fetchAsyncDropdown(
      roleOffset,
      setRoleOffset,
      roleList,
      setRoleList,
      `auth/application/role/list/?ordering=name&user_type__in=${userId}`,
      search,
      setRoleSearch,
      false,
      false
    )
  }

  const fetchSupervisor = (search?: any) => {
    let warehouseId = warehouseModal?.value
    let roleName = ''

    if (teamMember.type.toLowerCase().includes('sr')) {
      roleName = 'Sales Supervisor'
    }

    if (roleModal.label.includes('Logistics Officer')) {
      roleName = 'Logistics Supervisor'
    }

    return fetchAsyncDropdown(
      supervisorOffset,
      setSupervisorOffset,
      supervisorList,
      setSupervisorList,
      `auth/users/team/list?role__name=${roleName}&warehouse_id=${warehouseId}&warehouse__id=${warehouseId}&ordering=name`,
      search,
      setSupervisorSearch,
      'first_name',
      false
    )
  }

  // const fetchingWards = async (search?: any, selectedWarehouse?: any) => {
  //   let warehouseId = warehouseModal?.value
  //   let selectedWardsBrands = [...wardBrand]
  //   let selectedWards: any = []

  //   if (add) {
  //     selectedWardsBrands.length !== 1 &&
  //       selectedWardsBrands.forEach((item) => {
  //         if (item.ward.value) {
  //           selectedWards.push(item.ward.value)
  //         }
  //       })
  //   }

  //   // for search in AsyncDropdown
  //   if (search != '') {
  //     setWardsSalesTeamSearch(search)
  //   }

  //   let limit = 10 // default limit
  //   let options: any = []

  //   if (selectedWarehouse) {
  //     warehouseId = selectedWarehouse
  //   }

  //   let concatUrl = `${apiBaseURL()}master/wards/list/?warehouse_id=${warehouseId}&ordering=name&search=${search}&limit=${limit}&offset=${wardsSalesTeamOffset}`
  //   setWardAPIDone(true)
  //   const response = await getRequest(concatUrl, true)

  //   let hasMore: boolean = false

  //   if (response.next !== null) {
  //     const queryParams = new URLSearchParams(response.next)
  //     let newOffset: any = queryParams.get('offset')
  //     setWardsSalesTeamOffset(newOffset)
  //     hasMore = true
  //   }

  //   if (response.results.length > 0) {
  //     response.results.map((option: any) => {
  //       if (selectedWards.length !== 0) {
  //         if (!selectedWards.includes(option.id)) {
  //           options.push({
  //             label: option.name,
  //             value: option.id,
  //           })
  //         }
  //       } else {
  //         options.push({
  //           label: option.name,
  //           value: option.id,
  //         })
  //       }
  //     })
  //   }

  //   let wardLength = options.length

  //   if (remove) {
  //     wardLength += 1
  //   }

  //   if (wardLength == 1 || wardLength == 0) {
  //     setWard(false)
  //   } else {
  //     setWard(true)
  //   }

  //   return {
  //     options: options,
  //     hasMore: hasMore,
  //   }
  // }

  const fetchSalesTeamOfWards = async (search?: any) => {
    let selectedSalesTeam = [...salesTeamWards]
    let selectedSalesTeamWards: any = []

    if (add) {
      selectedSalesTeam.length !== 1 &&
        selectedSalesTeam.forEach((item) => {
          if (item.salesTeam.value) {
            selectedSalesTeamWards.push(item.salesTeam.value)
          }
        })
    }

    // for search in AsyncDropdown
    if (search != '') {
      setWardsSalesTeamSearch(search)
    }

    let limit = tenantConfiguration.limit
    let options: any = []

    let concatUrl = `${apiBaseURL()}master/sales-team?ordering=name&search=${search}&limit=${limit}&offset=${wardsSalesTeamOffset}`

    setWardAPIDone(true)

    const response = await getRequest(concatUrl, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWardsSalesTeamOffset(newOffset)
      hasMore = true
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (selectedSalesTeamWards.length !== 0) {
          if (!selectedSalesTeamWards.includes(option.id)) {
            options.push({
              label: option.name,
              value: option.id,
            })
          }
        } else {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    let wardLength = options.length

    if (remove) {
      wardLength += 1
    }

    if (wardLength == 1 || wardLength == 0) {
      setWard(false)
    } else {
      setWard(true)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSalesTeam = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `master/sales-team?ordering=name&supervisor_id=${
        isEmpty(supervisor) ? '' : supervisor?.value
      }`,
      search,
      setDropDownSearch,
      'name',
      false
    )
  }

  const fetchSalesTeamWards = async (search: any, salesTeam: any) => {
    let warehouseId = warehouseModal?.value
    let salesTeamId = salesTeam?.value ?? ''
    // `master/wards/list/?warehouse_id=${warehouseId}&sales_team_id=${salesTeamId}`,

    return fetchAsyncDropdown(
      salesTeamOffset,
      setSalesTeamOffset,
      salesTeamList,
      setSalesTeamList,
      `master/product/wards/view/test?warehouse_id=${warehouseId}&sales_team_id=${salesTeamId}`,
      search,
      setSalesTeamSearch,
      'name',
      false
    )
  }

  ////////////////// all asyncDropdown end //////////////////////////

  // handle the edit modal
  const getProps = async (item: any) => {
    let id = item.id

    setAdd(true)
    setWard(false)
    setRequestHandler('Edit')
    setId(id)
    setModalLoader(true)
    const response = await getRequest(`${apiBaseURL()}auth/users/${id}/`, true)

    if (response.id) {
      setTeamMember({
        ...teamMember,
        first_name: response.first_name,
        last_name: response.last_name,
        gender: response.gender,
        email: response.email,
        warehouse: response.warehouse ? response.warehouse.id : '',
        type: response.type.toLowerCase(),
        date_of_birth: response.date_of_birth,
        is_active: response.is_active,
        phone_no: response.phone_no,
        role: response.role ? response.role.id : '',
        supervisor: response.supervisor ? response.supervisor.id : '',
        // user_ward_brand: response.user_ward_brand.length !== 0 ? response.user_ward_brand : '',
        sales_teams: response.sales_teams.length !== 0 ? response.sales_teams : '',
        sales_team: response.sales_team ? response.sales_team.id : '',
        is_active_pay_later_today: isEmpty(response.is_active_pay_later_today)
          ? false
          : response.is_active_pay_later_today,
        tra_device_id: isEmpty(response.tra_device_id) ? null : response.tra_device_id,
        tra_device_name: isEmpty(response.tra_device_name) ? null : response.tra_device_name,
        tra_device_password: isEmpty(response.tra_device_password)
          ? null
          : response.tra_device_password,
        tra_device_phone: isEmpty(response.tra_device_phone) ? null : response.tra_device_phone,
        tra_device_serial_number: isEmpty(response.tra_device_serial_number)
          ? null
          : response.tra_device_serial_number,
      })
      !isEmpty(response.is_active_pay_later_today) &&
        setAllowPayLaterToday(response.is_active_pay_later_today)
      setSupervisorReset(moment())
      setModalLoader(false)
      let findGender = initialGender.find((item) => item.value == response.gender)
      setGender(findGender)
      response &&
        response.sales_team &&
        setSalesTeam({label: response.sales_team.name, value: response.sales_team.id})
      let findUserType = warehouseManagerUserType.find(
        (item) => item.value == response.type.toLowerCase()
      )
      setUserType(findUserType)
      setRolesReset(moment())

      let selectedRole = response.role && {label: response.role.name, value: response.role.id}
      if (response.role) {
        setRoleModal(selectedRole)
        setSupervisorReset(moment())
      }

      let selectedDate = dateFormat(response.date_of_birth)
      setDate(selectedDate.displayDate)

      setChecked(response.is_active)

      let selectedSupervisor = response.supervisor && {
        label: response.supervisor.first_name + ' ' + response.supervisor.last_name,
        value: response.supervisor.id,
      }
      response.supervisor && setSupervisor(selectedSupervisor)

      let selectedWarehouse = response.warehouse && {
        label: response.warehouse.name,
        value: response.warehouse.id,
      }

      if (response.warehouse) {
        setWarehouseModal(selectedWarehouse)
        setSupervisorReset(moment())
      }

      if (!wardAPIDone) {
        // fetchingWards('', selectedWarehouse.value).then()
      }

      if (response.sales_teams.length > 0) {
        let salesTeamWards = response.sales_teams.map((item: any) => ({
          salesTeam: {label: item.sales_team_name, value: item.sales_team_id},
          wards:
            item.wards && item.wards.length > 0
              ? item.wards.map((i: any) => ({label: i.ward_name, value: i.ward_id}))
              : [],
        }))

        setSalesTeamWards(salesTeamWards)
      }

      if (response.user_ward_brand.length !== 0) {
        let selectedWardsBrands: any = []
        let concateArray: any = []

        response.user_ward_brand.map(async (item: any, index: any) => {
          let selectedBrands: any = []
          let mergeBrands: any = []

          item.brand.length !== 0 &&
            item.brand.map((brand: any) => {
              selectedBrands.push({label: brand.name, value: brand.id})
              mergeBrands.push({label: brand.name, value: brand.id})
            })

          selectedWardsBrands.push({
            ward: {label: item.ward.name, value: item.ward.id},
            brand: selectedBrands,
          })

          let url = `${apiBaseURL()}master/product/brand/view?ward_id=${
            item.ward.id
          }&ordering=name&limit=1000`

          const res = await getRequest(url, true)

          res.results.length !== 0 &&
            res.results.map((brand: any, index: any) => {
              mergeBrands.push({label: brand.name, value: brand.id})
            })

          concateArray.push({brands: mergeBrands})
        })

        setWardBrand(selectedWardsBrands)
        setTimeout(() => {
          setBrands(concateArray)
        }, 500)
      }
    }
  }

  ////////////////// submit/cancel modal start //////////////////////////
  const submitTeamMemberModal = async () => {
    let navigateField = {
      first_name: teamMember.first_name,
      last_name: teamMember.last_name,
      email: teamMember.email,
      phone_no: teamMember.phone_no,
      userType: userType,
      roleModal: roleModal,
      warehouseModal: warehouseModal,
      supervisor: supervisor,
      gender: gender,
      sales_team: salesTeam,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    setIsValidation(true)

    let selectedSalesTeamWards = [...salesTeamWards]
    let wardsSalesTeamPayload: any = []

    if (selectedSalesTeamWards[0]?.salesTeam !== '') {
      selectedSalesTeamWards.map((item: any) => {
        let selectedWardsIds: any = []

        if (item.wards) {
          item.wards.map((i: any) => {
            selectedWardsIds.push(i.value)
          })
        }

        wardsSalesTeamPayload.push({
          sales_team_id: item.salesTeam !== '' && item.salesTeam.value,
          wards: selectedWardsIds,
        })
      })
    }

    teamMember.sales_teams = wardsSalesTeamPayload

    // object loop for validation
    let modalProperty: any = {...teamMember}
    let requiredModalProperty: any = {}
    for (const property in modalProperty) {
      if (property == 'type') {
        if (modalProperty[property] == '') {
          delete modalProperty['warehouse']
        }
        if (modalProperty[property] == 'wm') {
          delete modalProperty['warehouse']
        }
        if (modalProperty[property] !== 'sr' && !roleModal.label.includes('Logistics Officer')) {
          delete modalProperty['supervisor']
        }
        if (modalProperty[property] !== 'sr') {
          delete modalProperty['sales_team']
        }
      }

      if (property == 'role') {
        if (modalProperty['type'] !== 'sr' && !roleModal.label.includes('Logistics Officer')) {
          delete modalProperty['supervisor']
        }
      }

      if (property == 'email') {
        if (!validateEmail(modalProperty['email'])) {
          modalProperty['email'] = ''
        }
      }

      if (property == 'sales_teams') {
        if (teamMember.type.includes('ss')) {
          let wardsSalesTeamsArray = modalProperty[property]
          let isValid: any = []
          if (wardsSalesTeamsArray.length === 0) {
            modalProperty['sales_teams'] = ''
          }

          wardsSalesTeamsArray.length !== 0 &&
            wardsSalesTeamsArray.map((item: any, index: any) => {
              if (item.salesTeam === '') {
                isValid.push(index)
              }
              if (item.wards.length === 0) {
                isValid.push(index)
              }
            })

          if (isValid.length !== 0) {
            modalProperty['sales_teams'] = ''
          }
        } else {
          delete modalProperty['sales_teams']
        }
      }

      if (modalProperty['type'] === 'sr') {
        if (
          !isEmpty(modalProperty['tra_device_serial_number']) ||
          !isEmpty(modalProperty['tra_device_password']) ||
          !isEmpty(modalProperty['tra_device_name']) ||
          !isEmpty(modalProperty['tra_device_id']) ||
          !isEmpty(modalProperty['tra_device_phone'])
        ) {
          setIsTraValid(true)
          if (property === 'tra_device_serial_number') {
            if (
              typeof modalProperty['tra_device_serial_number'] === 'string' &&
              modalProperty['tra_device_serial_number'].length < 9
            ) {
              setSerialNumberValid('Serial number should be minimum 8 characters long')
              modalProperty['tra_device_serial_number'] = ''
              return
            }
          }
        } else {
          delete modalProperty['tra_device_name']
          delete modalProperty['tra_device_password']
          delete modalProperty['tra_device_serial_number']
          delete modalProperty['tra_device_id']
          delete modalProperty['tra_device_phone']
        }
      } else {
        setIsTraValid(false)
        delete modalProperty['tra_device_id']
        delete modalProperty['tra_device_phone']
        delete modalProperty['tra_device_name']
        delete modalProperty['tra_device_password']
        delete modalProperty['tra_device_serial_number']
      }

      requiredModalProperty = modalProperty
    }

    if (isModalValidation(requiredModalProperty).length === 0) {
      setIsModalLoading('Please Wait...')

      setAlertName(teamMember.first_name + ' ' + teamMember.last_name)

      let postUrl = `${apiBaseURL()}auth/users/team/create/test`
      let patchUrl = `${apiBaseURL()}auth/users/update/${id}/test`

      teamMember.type = teamMember.type.toLowerCase()
      teamMember.is_active_pay_later_today = teamMember.type.toLowerCase().includes('sr')
        ? allowPayLaterToday
        : false

      let updatedTeamMember: any = {...teamMember}

      if (teamMember.type.toLocaleLowerCase() === 'sr') {
        if (showSrConfirm) {
          updatedTeamMember.tra_warning = false
        } else {
          updatedTeamMember.tra_warning = true
        }

        if (
          isEmpty(updatedTeamMember['tra_device_serial_number']) ||
          isEmpty(updatedTeamMember['tra_device_password']) ||
          isEmpty(updatedTeamMember['tra_device_name']) ||
          isEmpty(updatedTeamMember['tra_device_id']) ||
          isEmpty(updatedTeamMember['tra_device_phone'])
        ) {
          updatedTeamMember['tra_device_serial_number'] = null
          updatedTeamMember['tra_device_password'] = null
          updatedTeamMember['tra_device_name'] = null
          updatedTeamMember['tra_device_id'] = null
          updatedTeamMember['tra_device_phone'] = null
        }
      }

      const response =
        requestHandler === 'Add'
          ? await postRequest(postUrl, updatedTeamMember, true)
          : await patchRequest(patchUrl, updatedTeamMember, true)

      let successArray = [201, 200]
      let failArray = [400, 500]

      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail(response)
    }
  }

  const responseSuccess = () => {
    getTeamMemberList()
    setShowAlert(true)
    setIsModalLoading('Submit')
    setShowSrConfirm(false)
  }

  const responseFail = (response: any) => {
    setIsModalLoading('Submit')
    if (response && response.data && response.data.error) {
      if (response.data.error.field === 'email') {
        setErrorMessage(
          'Your added email address already exists in this system! Please try again with a unique email address.'
        )
      }

      if (response.data.error.field === 'tra_serial_number_password_exist') {
        setShowSrConfirm(true)
      }

      if (response.data.error.field === 'tra_device_validate') {
        setErrorMessage(response.data.error.error_message)
      }

      if (response.data.error.field === 'combination_already_exists') {
        setSalesTeamWardsUniqError(response?.data?.error?.data ?? [])
        // setErrorMessage(response.data.error.error_message)
      }
    }
  }

  const cancleFunction = () => {
    setIsTraValid(false)
    setSerialNumberValid('')
    setShowPassword(false)
    setIsValidation(false)
    setRoleOffset('')
    setRoleModal(initialRoleModal)
    setSalesTeamWards([initialSalesTeamWards])
    setSalesTeamWardsUniqError([])
    setSupervisorReset(moment())
    setWarehouseOffset('')
    setWarehouseModal('')
    setSupervisorReset(moment())
    setSupervisorOffset('')
    setWardsSalesTeamOffset('')
    setSupervisor('')
    setGender('')
    setDate('Select')
    setTeamMember(initialTeamMember)
    setSupervisorReset(moment())
    setUserType('')
    setRolesReset(moment())
    setChecked('')
    setIsCorrectEmail('')
    setChecked(true)
    setIsWard('')
    setWardBrand([initialWardBrand])
    setBrands([initialBrands])
    setRemove(false)
    setAdd(false)
    setWard(true)
    setErrorMessage('')
    setModalLoader(false)
    setSalesTeam('')
    setAllowPayLaterToday(true)
  }
  ////////////////// submit/cancel modal end //////////////////////////

  ////////////////// all onchange handler start //////////////////////////
  const userTypeOnChangeHandler = (e: any) => {
    setRoleModal(initialRoleModal)
    setSupervisorReset(moment())
    setWarehouseModal('')
    setSupervisorReset(moment())
    setSupervisor('')
    setRoleOffset('')
    setWarehouseOffset('')
    setSupervisorOffset('')
    setAllowPayLaterToday(true)
  }

  const handleState = (state: any, key: string) => {
    setSupervisorReset(moment())
    if (key === 'text') {
      setSupervisorReset(moment())
      setTeamMember({
        ...teamMember,
        [state.target.name]:
          state.target.name === 'email'
            ? state.target.value
            : capitalizeFirstLetter(state.target.value),
      })
    }

    if (key === 'type') {
      setTeamMember({
        ...teamMember,
        type: state.value,
        warehouse: getW_Id,
        supervisor: '',
        role: '',
        tra_device_id: null,
        tra_device_name: null,
        tra_device_password: null,
        tra_device_phone: null,
        tra_device_serial_number: null,
      })
    }

    if (key === 'warehouse') {
      setTeamMember({
        ...teamMember,
        warehouse: state.value,
        supervisor: '',
        sales_teams: '',
      })
      setWardBrand([initialWardBrand])
      setBrands([initialBrands])
      setRemove(false)
      setAdd(false)
      setWard(true)
    }
    if (key === 'role') {
      setTeamMember({
        ...teamMember,
        role: state.value,
        supervisor: '',
      })
    }

    if (key === 'supervisor') {
      setTeamMember({
        ...teamMember,
        supervisor: state.value,
      })

      setResetSalesTeam(moment())
      setOffset(0)
      setList([])
      setDropDownSearch('')
    }

    if (key === 'sales_team') {
      setTeamMember({
        ...teamMember,
        sales_team: state.value,
      })
    }

    if (key === 'gender') {
      setTeamMember({
        ...teamMember,
        gender: state.value,
      })
    }

    if (key === 'date') {
      setTeamMember({
        ...teamMember,
        date_of_birth: state,
      })
    }

    if (key === 'status') {
      setTeamMember({
        ...teamMember,
        is_active: state ? true : false,
      })
    }
  }

  const handleBrands = (e: any, removeEvent: any, index: any) => {
    let totalWardBrand = [...wardBrand]

    totalWardBrand[index].brand = e

    setWardBrand(totalWardBrand)
  }

  const onWardChange = async (event: any, index: any) => {
    let totalWardBrand = [...wardBrand]
    setTimeout(() => {
      setBrands([initialBrands])
    }, 500)
    let totalBrand = [...brands]

    totalWardBrand[index].ward = event
    totalWardBrand[index].brand = null

    let url = `${apiBaseURL()}master/product/brand/view?ward_id=${
      event.value
    }&ordering=name&limit=1000`

    const response = await getRequest(url, true)

    if (response.results.length !== 0) {
      let options: any = []

      response.results.map((item: any) => {
        options.push({
          label: item.name,
          value: item.id,
        })
      })

      if (totalWardBrand[index]) {
        totalWardBrand[index].brand = options
      }
    }

    setBrands(totalBrand)
    setWardBrand(totalWardBrand)
    setIsWard('')
  }

  const addWardBrandRows = (e: any) => {
    e.preventDefault()
    setRemove(false)
    setAdd(true)
    setSalesTeamReset(moment())
    let isWardAddOrNot: any = []

    wardBrand.forEach((item, index) => {
      if (item.ward === '') {
        isWardAddOrNot.push(index)
      }
    })

    if (isWardAddOrNot.length === 0) {
      setWardBrand([...wardBrand, initialWardBrand])
      setWardsSalesTeamOffset('')
      setIsWard('')
      setBrands([...brands, initialBrands])
    } else {
      setIsWard('Please select the ward & brand(s) above first!')
    }
  }

  const addSalesTeamWardsRow = (e: any) => {
    e.preventDefault()

    setRemove(false)
    setAdd(true)
    setSalesTeamReset(moment())

    let isSalesTeamAddOrNot: any = []

    salesTeamWards.forEach((item, index) => {
      if (item.salesTeam === '' || item.wards.length === 0) {
        isSalesTeamAddOrNot.push(index)
      }
    })

    if (isSalesTeamAddOrNot.length === 0) {
      setSalesTeamWards([...salesTeamWards, initialSalesTeamWards])
      setWardsSalesTeamOffset('')
      setIsWard('')
      // setBrands([...brands, initialBrands])
    } else {
      setIsWard('Please select the sales team & ward(s) above first!')
    }
  }

  const removeWardBrandRows = (e: any, index: any) => {
    e.preventDefault()
    setRemove(true)
    setSalesTeamReset(moment())
    let totalWardBrand = [...wardBrand]
    let filterWardBrand: any = []
    if (totalWardBrand.length !== 0) {
      totalWardBrand.forEach((item, wardBrandIndex) => {
        if (wardBrandIndex !== index) {
          filterWardBrand.push(item)
        }
      })
    }

    setWardBrand(filterWardBrand)
  }

  const removeSalesTeamWards = (e: any, index: any) => {
    e.preventDefault()

    setRemove(true)
    setSalesTeamReset(moment())

    let totalSalesTeamWards = [...salesTeamWards]
    let filterSalesTeamWards: any = []

    if (totalSalesTeamWards.length !== 0) {
      totalSalesTeamWards.forEach((item, salesTeamWardsIndex) => {
        if (salesTeamWardsIndex !== index) {
          filterSalesTeamWards.push(item)
        }
      })
    }

    setSalesTeamWards(filterSalesTeamWards)
  }

  ////////////////// all onchange handler end //////////////////////////

  ////////// useEffect section start //////////

  useEffect(() => {
    fetchPermission()
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions('team_member')
    setPermission(res)
  }

  useEffect(() => {
    getTeamMemberList()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = pageState.selected
    updatedState['currentOffSet'] = pageState.currentOffset
    updatedState['buttonDisable'] = buttonDisable
    updatedState['search'] = pageState.search
    setFilterStates(updatedState)

    saveFilter(filters.wm_team_members, filterStates)

    if (pageState.warehouseId == '' && pageState.roleId == '' && debounceSearch == '') {
      setButtonDisable(false)
    }
  }, [pageState.currentOffset, debounceSearch, pageState.warehouseId, pageState.roleId])

  useEffect(() => {
    setWarehouseOffset('')
  }, [warehouseSearch])

  useEffect(() => {
    setSupervisorOffset('')
  }, [supervisorSearch])

  useEffect(() => {
    setRoleOffset('')
  }, [roleSearch])

  useEffect(() => {
    setWardsSalesTeamOffset('')
  }, [wardsSalesTeamSearch])
  ////////// useEffect section end //////////

  // handle uncontrol component
  const CustomDatePicker = () => {
    return (
      <>
        <DatePickerCustom
          onCallback={(start: any, end: any, label: any) => {
            let formatDate = dateFormat(start._d)
            setDate(formatDate.displayDate)
            setInitialSettings({...initialSettings, startDate: start._d})
            handleState(formatDate.apiDate, 'date')
          }}
          initialSettings={initialSettings}
          dateValidation={isValidation && teamMember.date_of_birth === '' ? 'is-invalid' : ''}
          dateValue={date}
        />
      </>
    )
  }

  const handleChangeReset = () => {
    let updatePageState: any = {...pageState}
    updatePageState['warehouseId'] = ''
    updatePageState['roleId'] = ''
    updatePageState['search'] = ''
    setPageState(updatePageState)

    setWarehouse({label: 'All Warehouse', value: ''})
    setRole({label: 'All Roles', value: ''})

    let updatedState: any = {...filterStates}
    updatedState['roleId'] = ''
    updatedState['warehouseId'] = ''
    updatedState['search'] = ''
    updatedState['selected'] = ''
    updatedState['currentOffSet'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  const findSalesTeamWithId = (id: any) => {
    const error = salesTeamWardsUniqError

    if (error?.sales_team_id === id) {
      return (
        <p className='text-danger'>
          Sales team should be paired with unique wards to avoid duplicate combinations.{' '}
          {error?.wards.map((item: any, index: any) => {
            return (
              <>
                <b>
                  {index ? ',' : ''} {item.ward_name}{' '}
                </b>
              </>
            )
          })}{' '}
          are already assigned to <b>{error?.sales_team_name}</b>
        </p>
      )
    }

    return ''
  }

  return (
    <>
      <EATitle title='Team Members - ' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({id: 'Team members'})}
      </PageTitle>

      <EaAlertSuccess
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={
          requestHandler === 'Add'
            ? 'TEAM.MEMBER.ADD'
            : requestHandler === 'Edit' && 'TEAM.MEMBER.UPDATE'
        }
        name={alertName}
      />

      <>
        <div className='card'>
          {pageState.isLoading && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            {/* <CustomSearchBar newSearch={true} setResponse={setPageState} /> */}
            <CustomSearchBar
              onChange={(e: any) => {
                setButtonDisable(true)
                let updatedPageState: any = {...pageState}
                updatedPageState['selected'] = 0
                updatedPageState['currentOffset'] = 0
                updatedPageState['search'] = e.target.value
                setPageState(updatedPageState)
                setFilterStates({...filterStates, ['search']: e.target.value})
              }}
              value={pageState.search}
              isDisable={pageState.isLoading}
            />
            {/* onChange={(e : any) => { console.log('search', e); */}

            {/* NOTE : warehouse and role filter */}

            <div className='ms-auto'>
              {/* <div > */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  onChange={(e: any) => {
                    setButtonDisable(true)
                    setWarehouse(e)
                    setSalesTeamReset(moment())
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    updatedPageState['warehouseId'] = e.value
                    setPageState(updatedPageState)
                    // setPageState({ ...pageState, warehouseId: e.value })
                    setFilterStates({...filterStates, ['warehouseId']: e})
                  }}
                  isSearchable
                  placeholder='All Warehouse'
                  isDisabled={pageState.isLoading}
                  value={warehouse}
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchRoles}
                  onChange={(e: any) => {
                    setButtonDisable(true)
                    setRole(e)
                    // setPageState({ ...pageState, roleId: e.value })
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    updatedPageState['roleId'] = e.value
                    setPageState(updatedPageState)

                    setFilterStates({...filterStates, ['roleId']: e})
                  }}
                  isSearchable
                  placeholder='All Roles'
                  isDisabled={pageState.isLoading}
                  value={role}
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal__user'
                    btnName='Add Team Member'
                    isDisable={pageState.isLoading}
                    onClick={() => {
                      setRequestHandler('Add')
                    }}
                    className='btn btn-primary btn-sm font-13 my-1 me-3'
                  />
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                {/* </div> */}
              </form>
            </div>
          </div>
          <div className='card-body py-4'>
            {pageState.list.length === 0 ? (
              <NoRecords />
            ) : (
              <TeamMemberTable
                list={pageState.list}
                getProps={getProps}
                showDelete={permission.delete}
                showWrite={permission.write}
              />
            )}
          </div>
        </div>
        <CustomPaginate
          data={pageState.list}
          selected={pageState.selected}
          pageState={pageState}
          setPageState={setPageState}
          totalRecords={pageState.count}
          limit={pageState.limit}
          isDisable={pageState.isLoading}
          saveFilterName={filters.wm_team_members}
          filterStates={filterStates}
        />
      </>

      {/* =========================================== MODAL ===================================================== */}

      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={isModalLoading !== 'Submit' ? true : false}
        cancel={cancleFunction}
        onSubmit={submitTeamMemberModal}
        id='ea_modal__user'
        modalTitle={`${requestHandler} Team Member`}
        loader={modalLoader}
      >
        <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
          <>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    First Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className={clsx(
                      'form-control',
                      isValidation && teamMember.first_name === '' ? 'is-invalid' : ''
                    )}
                    onChange={(e) => {
                      handleState(e, 'text')
                    }}
                    value={teamMember.first_name}
                    name='first_name'
                    placeholder='Type here...'
                    id='first_name'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    Last Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className={clsx(
                      'form-control',
                      isValidation && teamMember.last_name === '' ? 'is-invalid' : ''
                    )}
                    onChange={(e) => {
                      handleState(e, 'text')
                    }}
                    value={teamMember.last_name}
                    name='last_name'
                    id='last_name'
                    placeholder='Type here...'
                  />
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label'>
                Email<span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                disabled={requestHandler !== 'Add'}
                className={clsx(
                  'form-control',
                  isValidation && teamMember.email === '' ? 'is-invalid' : ''
                )}
                onChange={(e) => {
                  handleState(e, 'text')
                }}
                value={teamMember.email}
                name='email'
                id='email'
                placeholder='Type here...'
                onBlur={() => {
                  if (teamMember.email !== '') {
                    if (!validateEmail(teamMember.email)) {
                      setIsCorrectEmail(emailValidationMessage)
                    } else {
                      setIsCorrectEmail('')
                    }
                  }
                }}
              />
              <div className='mt-4'>
                <ErrorHandler errorMessage={isCorrectEmail} />
              </div>
            </div>
            <div className='mb-4' id='phone_no'>
              <MobileNumberInput
                onChange={(e: any) => {
                  const re = /^[0-9\b]+$/
                  if (e.target.value === '' || re.test(e.target.value)) {
                    handleState(e, 'text')
                  }
                }}
                validation={isValidation && teamMember.phone_no === '' ? 'is-invalid' : ''}
                name='phone_no'
                numberValue={teamMember.phone_no}
              />
            </div>
            <div className='mb-4'>
              <label className='form-label' id='userType'>
                User Type<span className='text-danger'>*</span>
              </label>
              <Select
                options={warehouseManagerUserType}
                isSearchable
                placeholder='Select'
                className={clsx(
                  'react-select-container mb-4',
                  isValidation && teamMember.type === '' ? 'is-invalid' : ''
                )}
                onChange={(e) => {
                  setUserType(e)
                  setRolesReset(moment())
                  userTypeOnChangeHandler(e)
                  handleState(e, 'type')
                }}
                value={userType}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div className='mb-4'>
              <label className='form-label' id='roleModal'>
                Role<span className='text-danger'>*</span>
              </label>
              <AsyncPaginate
                key={rolesReset}
                loadOptions={fetchRolesModal}
                onChange={(e: any) => {
                  setRoleModal(e)
                  setSupervisorReset(moment())
                  handleState(e, 'role')
                  setSupervisor('')
                }}
                isSearchable
                isDisabled={userType === ''}
                placeholder='Select'
                value={roleModal}
                className={`react-select-container  ${
                  isValidation && teamMember.role === '' ? 'is-invalid' : ''
                }`}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>

            {
              // hide and show warehouse based on usertype
              teamMember.type !== '' && !teamMember.type.includes('wm') && (
                <div className='mb-4' id='warehouseModal'>
                  <label className='form-label'>Warehouse</label>
                  <AsyncPaginate
                    loadOptions={fetchWarehouseModal}
                    onChange={(e: any) => {
                      setWarehouseModal(e)
                      setSupervisorReset(moment())
                      handleState(e, 'warehouse')
                      setSupervisor('')
                    }}
                    isSearchable
                    placeholder='Select'
                    value={warehouseModal}
                    className={`react-select-container ${
                      isValidation && teamMember.warehouse === '' ? 'is-invalid' : ''
                    }`}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
              )
            }

            {
              // show hide supervisor based on Logistics Officer and usertype
              (teamMember.type.toLowerCase().includes('sr') ||
                roleModal.label.includes('Logistics Officer')) && (
                <div className='mb-4'>
                  <label className='form-label' id='supervisor'>
                    Supervisor<span className='text-danger'>*</span>
                  </label>
                  <AsyncPaginate
                    key={supervisorReset}
                    loadOptions={fetchSupervisor}
                    onChange={(e: any) => {
                      setSupervisor(e)
                      handleState(e, 'supervisor')
                    }}
                    isSearchable
                    isDisabled={warehouseModal === ''}
                    placeholder='Select'
                    value={supervisor}
                    className={`react-select-container  ${
                      isValidation && teamMember.supervisor === '' ? 'is-invalid' : ''
                    }`}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
              )
            }

            {teamMember.type.toLowerCase().includes('sr') && (
              <div className='mb-4'>
                <label className='form-label' id='supervisor'>
                  Sales Team<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchSalesTeam}
                  key={resetSalesTeam}
                  isSearchable
                  placeholder='All Sales Team'
                  className={`react-select-container  ${
                    isValidation && teamMember.sales_team === '' ? 'is-invalid' : ''
                  }`}
                  classNamePrefix='react-select'
                  name='sales_team'
                  onChange={(e) => {
                    setSalesTeam(e)
                    handleState(e, 'sales_team')
                  }}
                  isDisabled={supervisor === ''}
                  value={salesTeam}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            )}

            {teamMember.type.toLowerCase().includes('sr') && (
              <div className='mb-4'>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input widget-13-check'
                    name='allowPayLaterToday'
                    checked={allowPayLaterToday}
                    onChange={(e: any) => setAllowPayLaterToday(!allowPayLaterToday)}
                    type='checkbox'
                    value=''
                    id='allowPayLaterToday'
                  />
                  <label className='form-check-label' htmlFor='allowPayLaterToday'>
                    Enable Pay Later Today
                  </label>
                </div>
              </div>
            )}

            <div className='row'>
              <div className='col-lg-6 fv-row mb-4'>
                <label className='form-label' id='gender'>
                  Gender<span className='text-danger'>*</span>
                </label>
                <Select
                  options={initialGender}
                  isSearchable
                  placeholder='Select'
                  className={clsx(
                    'react-select-container',
                    isValidation && teamMember.gender === '' ? 'is-invalid' : ''
                  )}
                  onChange={(e) => {
                    setGender(e)
                    handleState(e, 'gender')
                  }}
                  value={gender}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='col-lg-6 fv-row mb-4'>
                <label className='form-label'>
                  Date of Birth<span className='text-danger'>*</span>
                </label>
                <CustomDatePicker />
              </div>
            </div>
            {requestHandler !== 'Add' && (
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <label className='form-check-label me-3 ms-0'>Access Status</label>
                <input
                  checked={checked}
                  id='accessStatus'
                  onChange={(e) => {
                    let checked = $('#accessStatus').is(':checked')
                    handleState(checked, 'status')
                    if (checked == true) {
                      setChecked(true)
                    } else {
                      setChecked(false)
                    }
                  }}
                  value={checked}
                  className='form-check-input h-30px w-50px'
                  type='checkbox'
                  name='layout-builder[layout][header][fixed][desktop]'
                  defaultChecked={true}
                />
              </div>
            )}
          </>
          {warehouseModal &&
            teamMember.type.includes('ss') &&
            teamMember.warehouse !== '' &&
            teamMember.role !== '' && (
              <>
                {/* {wardBrand.map((item, index) => {
                  return (
                    <div className='mt-2'>
                      <div className='mb-4'>
                        <label className='form-label'>
                          Ward<span className='text-danger'>*</span>
                        </label>
                        <AsyncPaginate
                          key={salesTeamReset}
                          loadOptions={(e: any) => fetchingWards(e)}
                          isSearchable
                          isOptionDisabled={(option: any) => option.isDisable}
                          className={`react-select-container ${
                            isValidation && wardBrand[index].ward === '' ? 'is-invalid' : ''
                          }`}
                          classNamePrefix='react-select'
                          placeholder='Select'
                          menuPosition='fixed'
                          onChange={(e) => {
                            setSalesTeamReset(moment())
                            onWardChange(e, index)
                          }}
                          value={wardBrand[index].ward}
                          name='ward'
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#0c78a4',
                            },
                          })}
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='form-label'>
                          Brand<span className='text-danger'>*</span>
                        </label>
                        <Select
                          options={brands[index] ? brands[index].brands : []}
                          isSearchable
                          isMulti
                          className={`react-select-container ${
                            isValidation &&
                            wardBrand[index] &&
                            wardBrand[index].brand !== null &&
                            wardBrand[index].brand.length === 0
                              ? 'is-invalid'
                              : ''
                          }`}
                          classNamePrefix='react-select'
                          placeholder='Select'
                          value={wardBrand[index].brand}
                          onChange={(e, removeEvent) => {
                            handleBrands(e, removeEvent, index)
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#0c78a4',
                            },
                          })}
                        />
                      </div>
                      {wardBrand.length !== 1 && (
                        <>
                          <button
                            className='text-danger btn btn-link p-0'
                            onClick={(e: any) => removeWardBrandRows(e, index)}
                          >
                            Remove
                          </button>
                          <hr className='bg-light my-3' />
                        </>
                      )}
                    </div>
                  )
                })} */}

                {salesTeamWards.map((item: any, index: number) => {
                  return (
                    <div className='mt-2'>
                      <div className='mb-4'>
                        <label className='form-label'>
                          Sales Team<span className='text-danger'>*</span>
                        </label>
                        <AsyncPaginate
                          key={salesTeamReset}
                          loadOptions={(e: any) => fetchSalesTeamOfWards(e)}
                          isSearchable
                          onBlur={() => {
                            setSalesTeamReset(moment())
                            setWardsSalesTeamOffset('')
                            setWardsSalesTeamSearch('')
                          }}
                          isOptionDisabled={(option: any) => option.isDisable}
                          className={`react-select-container ${
                            isValidation && salesTeamWards[index].salesTeam === ''
                              ? 'is-invalid'
                              : ''
                          }`}
                          classNamePrefix='react-select'
                          placeholder='Select'
                          menuPosition='fixed'
                          onChange={(e) => {
                            let totalSalesTeamWards = [...salesTeamWards]

                            totalSalesTeamWards[index].salesTeam = e
                            totalSalesTeamWards[index].wards = []

                            setSalesTeamWards(totalSalesTeamWards)
                          }}
                          value={salesTeamWards[index].salesTeam}
                          name='salesTeam'
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#0c78a4',
                            },
                          })}
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='form-label'>
                          Wards<span className='text-danger'>*</span>
                        </label>
                        <AsyncPaginate
                          key={salesTeamWardsReset}
                          loadOptions={(e) =>
                            fetchSalesTeamWards(e, salesTeamWards[index].salesTeam)
                          }
                          isSearchable
                          isMulti
                          isDisabled={isEmpty(salesTeamWards[index].salesTeam)}
                          onBlur={() => {
                            setSalesTeamWardsReset(moment())
                            setSalesTeamOffset('')
                            setSalesTeamList([])
                            setSalesTeamSearch('')
                          }}
                          className={`react-select-container ${
                            isValidation &&
                            salesTeamWards[index] &&
                            salesTeamWards[index].wards !== null &&
                            salesTeamWards[index].wards.length === 0
                              ? 'is-invalid'
                              : ''
                          }`}
                          classNamePrefix='react-select'
                          placeholder='Select'
                          menuPosition='fixed'
                          onChange={(e) => {
                            let totalSalesTeamWards = [...salesTeamWards]

                            totalSalesTeamWards[index].wards = e

                            setSalesTeamWards(totalSalesTeamWards)
                          }}
                          value={salesTeamWards[index].wards}
                          name='wards'
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#0c78a4',
                            },
                          })}
                        />
                      </div>

                      {findSalesTeamWithId(
                        isEmpty(salesTeamWards[index].salesTeam)
                          ? ''
                          : salesTeamWards[index].salesTeam?.value ?? ''
                      )}

                      {salesTeamWards.length !== 1 && (
                        <>
                          <button
                            className='text-danger btn btn-link p-0'
                            onClick={(e: any) => {
                              removeSalesTeamWards(e, index)
                            }}
                          >
                            Remove
                          </button>
                          <hr className='bg-light my-3' />
                        </>
                      )}
                    </div>
                  )
                })}

                <div className='my-3'>
                  <ErrorHandler errorMessage={isWard} />
                </div>

                <button className='text-primary btn btn-link p-0' onClick={addSalesTeamWardsRow}>
                  Add More Sales Team
                </button>
                <hr className='bg-light my-3' />
              </>
            )}

          {teamMember.type.toLocaleLowerCase().includes('sr') && (
            <>
              <div className='mt-5 mb-3 border-bottom'>
                <h6 className='text-bold'>TRA Configuration</h6>
              </div>
              <div className='mb-4'>
                <label className='form-label'>Device Id</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control',
                    isTraValid &&
                      (teamMember.tra_device_id === '' || teamMember.tra_device_id === null)
                      ? 'is-invalid'
                      : ''
                  )}
                  onChange={(e) => {
                    handleState(e, 'text')
                  }}
                  value={teamMember.tra_device_id || ''}
                  name='tra_device_id'
                  placeholder='Type here...'
                  id='tra_device_id'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <label className='form-label'>Device Name</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control',
                    isTraValid &&
                      (teamMember.tra_device_name === '' || teamMember.tra_device_name === null)
                      ? 'is-invalid'
                      : ''
                  )}
                  onChange={(e) => {
                    handleState(e, 'text')
                  }}
                  value={teamMember.tra_device_name || ''}
                  name='tra_device_name'
                  placeholder='Type here...'
                  id='tra_device_name'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <label className='form-label'>Serial Number</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control',
                    isTraValid &&
                      (teamMember.tra_device_serial_number === '' ||
                        teamMember.tra_device_serial_number === null)
                      ? 'is-invalid'
                      : ''
                  )}
                  onChange={(e) => {
                    handleState(e, 'text')
                    setSerialNumberValid('')
                  }}
                  value={teamMember.tra_device_serial_number || ''}
                  name='tra_device_serial_number'
                  placeholder='Type here...'
                  id='tra_device_serial_number'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <ErrorHandler errorMessage={serialNumberValid} />
              </div>
              <div className='mb-4'>
                <label className='form-label'>Password</label>
                <div className='d-flex position-relative'>
                  <input
                    placeholder='Password'
                    type={'text'}
                    autoComplete='off'
                    className={clsx(
                      'form-control',
                      // psw-input
                      isTraValid &&
                        (teamMember.tra_device_password === '' ||
                          teamMember.tra_device_password === null)
                        ? 'is-invalid'
                        : ''
                    )}
                    name='tra_device_password'
                    id='tra_device_password'
                    onChange={(e) => {
                      handleState(e, 'text')
                    }}
                    value={teamMember.tra_device_password || ''}
                  />
                  {/* <a
                    onClick={handleTogglePassword}
                    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer'
                  >
                    {showPassword ? (
                      <>
                        <KTSVG
                          path='/media/icons/ic_eye_off.svg'
                          className='ic ms-n4 position-absolute psw-toggle'
                        />
                      </>
                    ) : (
                      <>
                        <KTSVG
                          path='/media/icons/ic_eye_on.svg'
                          className='ic ms-n4 position-absolute psw-toggle'
                        />
                      </>
                    )}
                  </a> */}
                </div>
              </div>
              <div className='mb-4'>
                <MobileNumberInput
                  label='Connected Phone'
                  isOptional={true}
                  validation={
                    isTraValid &&
                    (teamMember.tra_device_phone === '' || teamMember.tra_device_phone === null)
                      ? 'is-invalid'
                      : ''
                  }
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/
                    if (e.target.value === '' || re.test(e.target.value)) {
                      if (e.target.value !== '0') {
                        handleState(e, 'text')
                      }
                    }
                  }}
                  numberValue={teamMember.tra_device_phone || ''}
                  name='tra_device_phone'
                />
              </div>
            </>
          )}

          <div className='mt-3'>
            <ErrorHandler errorMessage={errorMessage} />
          </div>
        </form>
      </EaModal>

      <>
        <Modal
          show={showSrConfirm}
          onHide={() => {
            setShowSrConfirm(false)
          }}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>TRA Configuration</h2>
            </Modal.Title>
            <div
              className='btn p-1 mh-24 btn-active-light-primary ms-2'
              onClick={() => {
                setShowSrConfirm(false)
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='p-5'>
              <p className='text-bold'>
                Serial number and password already assigned to other SR. are you sure you want to
                continue?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={() => {
                  setShowSrConfirm(false)
                }}
                disabled={isModalLoading !== 'Submit'}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={submitTeamMemberModal}
                disabled={isModalLoading !== 'Submit'}
              >
                {isModalLoading === 'Submit' ? 'Continue' : isModalLoading}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default TeamMembersRedesign
