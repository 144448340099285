import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import {Modal} from 'react-bootstrap'
import {getRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  dateTimeFormat,
  isEmpty,
  KTSVG,
  measurementLabel,
} from '../../../_eaFruitsDms/helpers'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import clsx from 'clsx'
import {rejectionValidation} from '../customers/WMcustomers/CustomerConst'
import {useNavigate} from 'react-router-dom'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import moment from 'moment'

type Props = {
  className: string
}
const UnsoldProductStockIn: React.FC<Props> = ({ className }) => {
  const intl = useIntl();
  const navigate = useNavigate()
  const headerTitle = {
    title: "Stock-In",
    subTitle: "Unsold Product Stock-in"
  };
  const initialModalTitle = {
    received_qty: 0,
    product_id: 0,
    product_name: "-"
  }
  let initialRejectReason = {
    reason: '',
    reason_label: "",
    reason_note: ""
  }

  const initialSgNotes = {
    reason: null,
    display_reason: "",
    processed_by: null,
    rejected_at: null
  }

  const [show, setShow] = useState(false);
  const [selectedGatePass, setSelectedGatePass] = useState<any>("");
  const [srOffset, setSrOffset] = useState("");
  const [searchSelect, setSearchSelect] = useState<string>("");
  const [unSoldProducts, setUnSoldProducts] = useState<any>(null);
  const [unSoldProduct, setUnSoldProduct] = useState<any>(null);
  const [unSoldProductModalData, setUnSoldProductModalData] = useState<any>([]);
  const [modalData, setModalData] = useState<any>([]);
  const [unSoldProductModalTitle, setUnSoldProductModalTitle] = useState<any>(initialModalTitle);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [modalButtonDisabled, setModalButtonDisabled] = useState<boolean>(true)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const [showReasonModal, setShowReasonModal] = useState<boolean>(false)
  const [lessQuantityReasonValidation, setLessQuantityReasonValidation] = useState<any>(rejectionValidation)
  const [unSoldLessProductReasonOffSet, setUnSoldLessProductionReasonOffSet] = useState<any>("")
  const [unSoldLessProductReasonOption, setUnSoldLessProductionReasonOption] = useState<any>([])
  const [unSoldLessProductReason, setUnSoldLessProductionReason] = useState<any>(initialRejectReason)
  const [needFetch, setNeedFetch] = useState<any>(false)
  const [sgNotes, setSgNotes] = useState<any>(initialSgNotes)
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [messageHandler, setMessageHandler] = useState('')
  const [gatePassId, setGatePassId] = useState<number>(0);
  const [srReset, setSrReset] = useState<any>(moment());

  useEffect(() => {
    setSrOffset("");
  }, [searchSelect]);

  useEffect(() => {
    setButtonDisabled(true)

    if (unSoldProduct && unSoldProducts && unSoldProducts.products && unSoldProduct.product_inventory && (unSoldProduct.product_inventory.length === unSoldProducts.products.length)) {
      setButtonDisabled(false)
    }
  }, [unSoldProduct]);

  useEffect(() => {
    if (selectedGatePass !== "") {
      fetchUnSoldProducts().then();
    }
  }, [selectedGatePass]);

  const fetchUnSoldProducts = async () => {
    setIsLoading(true);
    const response = await getRequest(`${apiBaseURL()}configuration/gatepass/unsold-products/${selectedGatePass.value}`, true);
    let unsoldProductData: any = { ...unSoldProducts };
    setUnSoldProductModalData([]);
    setUnSoldProductModalTitle(initialModalTitle);

    setUnSoldProduct({
      gatepass_id: selectedGatePass.value
    })

    setSgNotes({
      reason: response.reason,
      display_reason: response.display_reason,
      processed_by: response.processd_by,
      rejected_at: response.rejected_at
    })

    if (response) {
      unsoldProductData = {
        id: response.id,
        gatepass_number: response.gatepass_number,
        date_time: response.date_time,
        reason_note: response.reason_note,
        display_reason: response.display_reason
      };

      if (response.products.length > 0) {
        response.products.map((product: any) => {
          const obj = {
            id: product.id,
            product_id: product.product_sku.id,
            product_name: product.product_sku.name,
            measurement_unit: product.product_sku.measurement_unit,
            material: product.product_sku.material,
            fix_size: product.product_sku.fix_size,
            size: product.product_sku.size,
            quantity_label: measurementLabel(
              product.product_sku.fix_size
                ? product.product_sku.material
                : product.product_sku.approx_weight > 0
                  ? product.product_sku.material
                  : product.product_sku.measurement_unit
            ),
            multiple_qty: (product.product_sku.measurement_unit.toLowerCase() === "gram") ? (product.product_sku.size / 1000) : product.product_sku.size,
            received_qty: "",
            current_qty: product.current_qty,
            reason_note: ""
          };
          if (unsoldProductData["products"]) {
            return unsoldProductData["products"].push(obj);
          }
          return unsoldProductData["products"] = [obj];
        });
      }
    }
    setUnSoldProducts(unsoldProductData);

    setIsLoading(false);
  };

  const handleClose = () => {
    const updatedProduct: any = {...unSoldProduct}

    updatedProduct && updatedProduct.product_inventory && updatedProduct.product_inventory.length > 0 && updatedProduct.product_inventory.map((inventory: any, index: number) => {
      if (inventory.inventory.length > 0) {
        inventory.inventory.map((item: any, itemIndex: number) => {
          if (modalData.length > 0) {
            const inventoryIndex = modalData.findIndex((modal: any) => modal.inventory_id === item.inventory_id)
            let quantity = 0

            if (inventoryIndex > -1) {
              quantity = modalData[inventoryIndex].quantity
              updatedProduct.product_inventory[index].inventory[itemIndex].units = isEmpty(quantity) ? 0 : quantity
            }
          }
          else {
            updatedProduct.product_inventory[index].inventory.map((inventoryItem: any, i: number) => {
              return updatedProduct.product_inventory[index].inventory[i].units = 0
            })
          }
          return updatedProduct
        })
      }
    })

    setUnSoldProduct(updatedProduct)
    setUnSoldProductModalData([]);
    setTotalQty(0)
    setUnSoldProductModalTitle(initialModalTitle);
    setModalButtonDisabled(true)
    setShow(false)
  };

  const generateModalData = async (currentIndex: number, product: any) => {
    setUnSoldLessProductionReason(initialRejectReason)
    let modalData = [...unSoldProductModalData];
    let totalQuantity = 0

    if (unSoldProductModalData.length === 0) {
      setIsLoading(true);
      const response = await getRequest(`${apiBaseURL()}configuration/oo-gatepass/${selectedGatePass.value}/product-batch/${product.product_id}`, true);

      if (response.length > 0) {
        setUnSoldProductModalTitle({
          received_qty: product.current_qty,
          product_name: product.product_name,
          product_id: product.product_id,
          quantity_label: product.quantity_label,
          multiple_qty: product.multiple_qty,
        });

        const updatedUnsoldProducts: any = {...unSoldProduct}
        const updatedModal: any = []
        response.map((product: any) => {
          let quantity: any = ''

          updatedUnsoldProducts.product_inventory && updatedUnsoldProducts.product_inventory.map((unSoldProduct: any) => {
            const productIndex = unSoldProduct.inventory.findIndex((unSoldProductObj: any) => unSoldProductObj.inventory_id == product.id)

            if (unSoldProduct.reason_note !== "") {
              setUnSoldLessProductionReason({
                reason: null,
                reason_note: unSoldProduct.reason_note
              })
            }

            if (![null, ""].includes(unSoldProduct.reason)) {
              setUnSoldLessProductionReason({
                reason: unSoldProduct.reason,
                reason_note: ""
              })
            }

            if (productIndex > -1) {
              quantity = unSoldProduct.inventory[productIndex].units

              const modalIndex = updatedModal.findIndex((modal: any) => modal.inventory_id == unSoldProduct.inventory[productIndex].inventory_id)

              if (modalIndex > -1) {
                updatedModal[modalIndex]['quantity'] = quantity
              } else {
                updatedModal.push({
                  inventory_id: unSoldProduct.inventory[productIndex].inventory_id,
                  quantity: quantity
                })
              }
            }

            return quantity
          })

          if (quantity !== "") {
            totalQuantity += quantity
          }

          return modalData.push({
            id: product.id,
            batch_number: product.batch_number,
            product_id: product.product_sku_id.id,
            product_name: product.product_sku_id.name,
            measurement_unit: product.product_sku_id.measurement_unit,
            material: product.product_sku_id.material,
            fix_size: product.product_sku_id.fix_size,
            received_qty: quantity,
            inward_quantity: quantity * unSoldProductModalTitle.multiple_qty,
            size: product.product_sku_id.size,
            expiry_date: product.expiry_date,
            qty: product.qty,
            validation: ""
          });
        });

        setModalData(updatedModal)
      }
    }

    setIsLoading(false);
    setUnSoldProductModalData(modalData);
    setTotalQty(totalQuantity)
    setShow(true);
  };

  const clearState = () => {
    setSelectedGatePass("")
    setSrOffset("")
    setUnSoldProductModalData([])
    setUnSoldLessProductionReasonOffSet('')
    setUnSoldLessProductionReasonOption([])
    setUnSoldProducts(null)
    setUnSoldProduct(null)
    setTotalQty(0)
    setTotalQty(0)
    setSgNotes(initialSgNotes)
    setSrReset(moment())
  }

  const fetchSR = async (search?: any) => {
    if (search !== "") {
      setSearchSelect(search);
    }

    let options: any = [];
    let hasMore: boolean = false;

    const response: any = await getRequest(`${apiBaseURL()}configuration/oo-gatepass?limit=10&offset=${srOffset}&search=${search}`, true);
    setNeedFetch(false)
    if(response.results){
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next);
        let newOffset: any = queryParams.get("offset");
        setSrOffset(newOffset);
        hasMore = true;
        setNeedFetch(true)
      }
  
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.sr_user.first_name + " " + option.sr_user.last_name + " Gatepass - #" + option.gatepass_number,
            value: option.id,
            gate_pass_id: option.gatepass_number
          });
        });
      }
    }

    return {
      options: options,
      hasMore: hasMore
    };
  };

  const handleSrChange = async (e: any) => {
    setSelectedGatePass(e);
  };

  const handleQtyChange = (e: any, index: number, unSoldProductObj: any) => {
    const inputValue: any = (e.target.value !== "") ? parseInt(e.target.value) : 0

    const updatedModalData = [...unSoldProductModalData]
    updatedModalData[index].received_qty = inputValue > 0 ? inputValue : ''
    updatedModalData[index].inward_quantity = (unSoldProductModalTitle.multiple_qty * (inputValue > 0 ? inputValue : 0))

    let total = 0
    let isValid: boolean = true

    updatedModalData.map((modalData: any, modalIndex: number) => {
      total += ((modalData.received_qty !== '') ? modalData.received_qty : 0)

      if (modalIndex === index) {
        if (total > unSoldProductModalTitle.received_qty || inputValue > modalData.qty || inputValue === 0) {
          isValid = false
          if (inputValue > 0) {
            updatedModalData[index].validation = 'is-invalid'
          }
        } else {
          isValid = true
          updatedModalData[index].validation = ''
        }
      }
      return total
    })

    setModalButtonDisabled(true)
    if (isValid) {
      setModalButtonDisabled(false)
    }

    let updatedUnsoldProduct: any = {...unSoldProduct}
    if (!updatedUnsoldProduct.product_inventory || updatedUnsoldProduct.product_inventory.length === 0) {
      updatedUnsoldProduct.product_inventory = [{
        product: unSoldProductModalTitle.product_id,
        inventory: [{
          inventory_id: unSoldProductObj.id,
          units: inputValue
        }]
      }]
    } else {
      const findIndex = updatedUnsoldProduct.product_inventory.findIndex((product: any) => product.product == unSoldProductModalTitle.product_id)

      if (findIndex > -1) {
        const inventoryIndex = updatedUnsoldProduct.product_inventory[findIndex] && updatedUnsoldProduct.product_inventory[findIndex].inventory ? updatedUnsoldProduct.product_inventory[findIndex].inventory.findIndex((inventory: any) => inventory.inventory_id == unSoldProductObj.id) : -1

        if (inventoryIndex > -1) {
          updatedUnsoldProduct.product_inventory[findIndex].inventory[inventoryIndex].units = inputValue
        } else {
          if (updatedUnsoldProduct.product_inventory[index] && updatedUnsoldProduct.product_inventory[index].inventory) {

            updatedUnsoldProduct.product_inventory[findIndex].inventory.push({
              inventory_id: unSoldProductObj.id,
              units: inputValue
            })
          } else {
            if (updatedUnsoldProduct.product_inventory[findIndex].inventory.length > 0) {
              updatedUnsoldProduct.product_inventory[findIndex].inventory[index] = {
                inventory_id: unSoldProductObj.id,
                units: inputValue
              }
            } else {
              updatedUnsoldProduct.product_inventory[findIndex].inventory.push({
                inventory_id: unSoldProductObj.id,
                units: inputValue
              })
            }
          }
        }
      } else {
        updatedUnsoldProduct.product_inventory.push({
          product: unSoldProductModalTitle.product_id,
          inventory: [
            {
              inventory_id : unSoldProductObj.id,
              units : inputValue
            }
          ]
        })
      }
    }

    setUnSoldProduct(updatedUnsoldProduct)

    setUnSoldProductModalData(updatedModalData)
    setTotalQty(total)
  }

  const updateUnSoldModalData = () => {
    const unsoldProducts: any = {...unSoldProducts}

    if (unsoldProducts.products.length > 0) {
      const findIndex = unsoldProducts.products.findIndex((product: any) => product.product_id === unSoldProductModalTitle.product_id)

      if (findIndex > -1) {
        unsoldProducts.products[findIndex].received_qty = totalQty

        if (unsoldProducts.products[findIndex].current_qty === unsoldProducts.products[findIndex].received_qty) {
          unsoldProducts.products[findIndex].reason_note = ""
          const unsoldProduct: any = {...unSoldProduct}

          const productIndex = unsoldProduct.product_inventory.findIndex((product: any) => product.product == unSoldProductModalTitle.product_id)

          if (productIndex > -1) {
            unsoldProduct.product_inventory[productIndex]['reason'] = null
            unsoldProduct.product_inventory[productIndex]['reason_note'] = ""
          }
        }

        let updatedProductData: any = {...unSoldProduct}

        if (updatedProductData.product_inventory) {
          updatedProductData.product_inventory = [{
            product: unSoldProductModalTitle.product_id,
          }]
        }
      }
    }

    setUnSoldProductModalData([])
    setTotalQty(0)
    setUnSoldProducts(unsoldProducts)
    setShowReasonModal(false)
    setShow(false)
    setLessQuantityReasonValidation(rejectionValidation)
    setUnSoldLessProductionReason(initialRejectReason)
  }

  const submitModalData = () => {
    setUnSoldLessProductionReason(initialRejectReason)
    if (totalQty < unSoldProductModalTitle.received_qty) {
      setShowReasonModal(true)
    } else {
      updateUnSoldModalData()
    }
  }

  const confirmReason = () => {
    let isValid: boolean = true

    if (unSoldLessProductReason.reason === "") {
      isValid = false
      setLessQuantityReasonValidation({
        option: 'is-invalid'
      })
    } else {
      if (unSoldLessProductReason.reason === null && unSoldLessProductReason.reason_note.trim() === "") {
        isValid = false
        setLessQuantityReasonValidation({
          option: '',
          comments: 'is-invalid'
        })
      }
    }

    isValid && updateUnSoldModalData()
  }

  const fetchReasons = async () => {
    if (searchSelect !== "") {
      setUnSoldLessProductionReasonOffSet("")
    }
    let unSoldLessProductReasonResponse = await getRequest(
      `${apiBaseURL()}master/stockin_reason?unsold_product=true&search=${searchSelect}&limit=10&offset=${unSoldLessProductReasonOffSet}`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if(unSoldLessProductReasonResponse.results){
      if (unSoldLessProductReasonResponse.next !== null) {
        const queryParams = new URLSearchParams(unSoldLessProductReasonResponse.next)
        let newOffset: any = queryParams.get("offset")
        setUnSoldLessProductionReasonOffSet(newOffset)
        hasMore = true
      }
  
      if (unSoldLessProductReasonResponse.results.length > 0) {
        unSoldLessProductReasonResponse.results.map((option: any) => {
          optionData.push(option)
          options.push({
            label: option.stockin_reason,
            value: option.id,
          })
        })
      }
  
      if (unSoldLessProductReasonOption.length > 0) {
        setUnSoldLessProductionReasonOption(unSoldLessProductReasonOption.concat(options))
      } else {
        setUnSoldLessProductionReasonOption(options)
      }
  
      const otherOptionExist = options.some((obj: any) => Object.is(obj.label, "Other"))
  
      if (!otherOptionExist) {
        options.push({
          label: "Other",
          value: null
        })
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleRejectReason = (e: any, fromDropDown?: boolean) => {
    let reason_id: any = ''
    let reason_note: string = ''
    let label: string = ''
    if (fromDropDown) {
      setLessQuantityReasonValidation({ ...lessQuantityReasonValidation, ["option"]: "" })
      setUnSoldLessProductionReason({
        reason: e.value || null,
        reason_label: e.label || null,
        reason_note: ""
      })
      reason_id = e.value
      reason_note = ""
      label = e.label
    } else {
      setLessQuantityReasonValidation({ ...lessQuantityReasonValidation, ["comments"]: "" })
      setUnSoldLessProductionReason({
        reason: null,
        reason_label: null,
        reason_note: e.target.value
      })
      reason_id = null
      label= ""
      reason_note = e.target.value
    }
    let updatedUnsoldProduct: any = {...unSoldProduct}
    let updatedUnsoldProducts: any = {...unSoldProducts}

    const findIndex = updatedUnsoldProduct.product_inventory.findIndex((product: any) => product.product == unSoldProductModalTitle.product_id)

    if (findIndex > -1) {
      updatedUnsoldProduct.product_inventory[findIndex]['reason'] = reason_id
      updatedUnsoldProduct.product_inventory[findIndex]['reason_note'] = reason_note
    }

    const productIndex = updatedUnsoldProducts.products.findIndex((product: any) => product.product_id === unSoldProductModalTitle.product_id)

    if (productIndex > -1) {
      updatedUnsoldProducts.products[productIndex].reason_note = label !== "" ? label : reason_note
    }

    setUnSoldProducts(updatedUnsoldProducts)
    setUnSoldProduct(updatedUnsoldProduct)
  }

  const hideRejectModel = () => {
    setShowReasonModal(false)
    setLessQuantityReasonValidation(rejectionValidation)
    setUnSoldLessProductionReasonOffSet("")
    setUnSoldLessProductionReason(initialRejectReason)
  }

  const unsoldProductStockIn = async () => {
    setIsLoading(true)
    setGatePassId(selectedGatePass.gate_pass_id)

    const res = await postRequest(`${apiBaseURL()}configuration/process-gatepass-stock-in`, unSoldProduct, true)

    if (res.status === 201) {
      setMessageHandler("success")
      setIsAlertSuccess(true)
      clearState()
      setNeedFetch(true)
    } else {
      setMessageHandler("error")
      setIsAlertFailed(true)
    }

    setIsLoading(false)

    setTimeout(() => {
      setIsAlertSuccess(false)
      setIsAlertFailed(false)
    }, 2500)
  }
  const hideAlert = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }

    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: "Unsold Product Stock-in" })}</PageTitle>
      <EaAlertSuccess
        onClick={() => hideAlert('success')}
        show={isAlertSuccess && messageHandler !== ""}
        message={
          messageHandler === 'success' && FormatMessage('UNSOLD_STOCK_IN', gatePassId)
        }
      />
      <EaAlertDanger
        onClick={() => hideAlert('failed')}
        show={isAlertFailed && messageHandler !== ""}
        message={FormatMessage('ERROR')}
      />
      <div className={`card ${className}`}>
        {
          isLoading && <IsLoader />
        }
        {/* begin::Header */}
        <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7">
          {/* begin::Title */}
          <h3 className="card-title align-items-start flex-column my-0">
            <span className="card-label fw-bold font-17">Unsold Product Stock-in</span>
          </h3>
          {/* end::Title */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body pt-0 pb-3">
          <div className="row mb-4">
            <div className="col-md-4">
              <label className="form-label">Sales Representative</label>
              <AsyncPaginate
                key={srReset}

                loadOptions={fetchSR}
                isSearchable
                className="react-select-container my-1 me-3 w-300px"
                classNamePrefix="react-select"
                onChange={handleSrChange}
                value={selectedGatePass}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4"
                  }
                })}
              />
            </div>
          </div>
          {
            sgNotes.reason != null && <div
              className="alert alert-dismissible border-danger font-14 d-flex flex-sm-row py-3 px-5 align-items-center mb-5"
              style={{ "backgroundColor": "rgba(208,31,69,0.1)" }}
            >
              <div className="text-dark pe-2">
                <p className="text-danger mb-1">Issue Reported by {sgNotes.processed_by != null ? `${sgNotes.processed_by.first_name} ${sgNotes.processed_by.last_name} (${sgNotes.processed_by.type.toUpperCase()})` : "-"} on {sgNotes.rejected_at ? dateTimeFormat(sgNotes.rejected_at) : '-'}</p>
                <span className="fw-600">#{sgNotes.reason.reason_name}#</span> - {checkEmpty(sgNotes.display_reason, '')}.
              </div>
            </div>
          }
          {/* begin::Table container */}
          <div className="table-responsive">
            {
              unSoldProducts == null ? <NoRecords />
                :
                <table
                  className="table table-row-gray-100 table-row-bordered align-middle gy-3 mt-2 gx-10">
                  <thead>
                  <tr className="text-muted fw-500 bg-light2 border-bottom">
                    <th className="min-w-200px">Product SKU</th>
                    <th className="min-w-190px w-190px">Unsold Qty</th>
                    <th className="min-w-150px w-150px">Received Qty</th>
                    <th className="min-w-200px">Reason</th>
                    <th className="min-w-120px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    !unSoldProducts || !unSoldProducts.products || unSoldProducts.products.length === 0 ? <div className="w-100 ml-100"><NoRecords/></div> : unSoldProducts.products.map((unSoldProduct: any, index: number) => {
                      const reasonNote = checkEmpty(unSoldProduct.reason_note, '-')
                      return <tr>
                        <td>
                          {unSoldProduct.product_name}
                        </td>
                        <td>
                          {unSoldProduct.current_qty} {unSoldProduct.quantity_label}
                        </td>
                        <td>
                          {unSoldProduct.received_qty > 0 ? unSoldProduct.received_qty : 0} {unSoldProduct.quantity_label}
                        </td>
                        <td>
                          {reasonNote}
                        </td>
                        <td>
                          {
                            unSoldProduct.received_qty > 0 || reasonNote !== '-'
                              ?
                              <button className="btn btn-secondary h-28px py-1 font-13"
                                      onClick={() => generateModalData(index, unSoldProduct)}>
                                Update
                              </button>
                              :
                              <button className="btn btn-primary h-28px py-1 font-13"
                                      onClick={() => generateModalData(index, unSoldProduct)}>
                                Process
                              </button>
                          }
                        </td>
                      </tr>;
                    })
                  }
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={4} className="text-center">
                      <button className="btn btn-secondary me-3 h-28px py-1 font-13" onClick={clearState}>
                        Cancel
                      </button>
                      <button className="btn btn-primary h-28px py-1 font-13" onClick={unsoldProductStockIn} disabled={buttonDisabled}>
                        Submit
                      </button>
                    </td>
                  </tr>
                  </tfoot>
                </table>
            }
          </div>
          {/* end::Table container */}
          {/* <div className='pt-4 border-top text-center'>
                        <button type="button" className="btn btn-secondary me-3">Cancel</button>
                        <button className="btn btn-primary">Submit</button>
                    </div> */}
        </div>
        {/* begin::Body */}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        dialogClassName="modal-lg max-w-700"
      >
        <Modal.Header closeButton>
          <Modal.Title><b>{`Select Batch Wise Unsold ${unSoldProductModalTitle.received_qty} Qty. for ${unSoldProductModalTitle.product_name}`}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="table-responsive">
              {
                unSoldProductModalData.length === 0 ?
                  <NoRecords />
                  :
                  <table
                    className="table table-row-bordered table-row-gray-100 align-baseline gy-2 gs-3 mb-0">
                    {/* begin::Table head */}
                    <thead>
                    <tr className="text-muted fw-500 bg-light2">
                      <th className="min-w-120px py-3">Batch Number</th>
                      <th className="min-w-130px py-3">Expiry Date</th>
                      <th className="min-w-100px py-3">Loaded-Out Qty.</th>
                      <th className="min-w-100px py-3">Received Qty.</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {
                      unSoldProductModalData.map((modalData: any, index: number) => {
                        return <tr>
                          <td>{modalData.batch_number}</td>
                          <td>{dateFormat(modalData.expiry_date).displayDate}</td>
                          <td>{modalData.qty} {unSoldProductModalTitle.quantity_label}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className={clsx('input-group me-1', modalData.validation && 'is-invalid-grp')}>
                                <input
                                  type="text"
                                  placeholder="0"
                                  name="quantity"
                                  value={modalData.received_qty}
                                  onChange={(e) => handleQtyChange(e, index, modalData)}
                                  className={clsx('form-control w-120px min-w-70px', modalData.validation)}
                                />
                                <span className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px">{unSoldProductModalTitle.quantity_label}</span>
                              </div>
                              <span
                                className="min-w-70px text-muted ms-1">{modalData.inward_quantity ? modalData.inward_quantity : 0} Kgs.</span>
                            </div>
                          </td>
                        </tr>;
                      })
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                      <td className="text-end" colSpan={4}>
                        <div className={clsx((unSoldProductModalTitle.received_qty !== totalQty) ? 'text-danger' : 'text-success')}>
                          Total Received Qty.:- {totalQty}
                        </div>
                      </td>
                    </tr>
                    </tfoot>
                    {/* end::Table body */}
                  </table>
              }
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-secondary me-3" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={submitModalData} disabled={modalButtonDisabled}>
              Process
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showReasonModal} centered
             onHide={() => setShowReasonModal(false)}
             backdrop="static"
      >
        <Modal.Header>
          <Modal.Title><h2 className='modal-title fw-bold'>Receiving Less Quantity than Unsold!</h2></Modal.Title>
          <div onClick={() => setShowReasonModal(false)} className='btn p-1 mh-24 btn-active-light-primary ms-2' data-bs-dismiss='modal' >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>You are doing stock-in of {unSoldProductModalTitle.product_name} with {totalQty} quantity, which is less than the unsold quantity of {unSoldProductModalTitle.received_qty}! Please select the appropriate reason for this!</p>
            <div>
              <label className="form-label">Select Reason</label>
              <AsyncPaginate
                loadOptions={fetchReasons}
                isSearchable
                placeholder="Select"
                className={clsx("react-select-container w-100", lessQuantityReasonValidation.option)}
                classNamePrefix="react-select"
                onChange={(e: any) => handleRejectReason(e, true)}
                value={unSoldLessProductReasonOption.find((x: any) => x.value === unSoldLessProductReason.reason) || ""}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4",
                  },
                })}
              />
            </div>
            {
              unSoldLessProductReason.reason === null && <div className="mt-3">
                <label className="form-label">Reason</label>
                <textarea
                  className={clsx("form-control", lessQuantityReasonValidation.comments)}
                  rows={4}
                  name="comments"
                  value={unSoldLessProductReason.reason_note || ""}
                  onChange={handleRejectReason}
                ></textarea>
              </div>
            }
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-secondary me-3" onClick={hideRejectModel}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={confirmReason}>
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnsoldProductStockIn;
