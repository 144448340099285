import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  retrieveFilter,
  saveFilter,
  toAbsoluteUrl,
} from "../../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { deleteRequest, getRequest, postRequest, putRequest, } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import Imagecropper from "./components/imageCropper"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import tenantConfiguration from "../../../../../TenantVariables"
import { filters } from "../../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}
const BlogAuthors: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const initialRow: any = {
    name: '',
    bio_description: '',
    profile_picture: '',
  }
  const initialCustomerValidation: any = {
    name: '',
    bio_description: '',
    profile_picture: '',
  }

  let authorFiltered : any = retrieveFilter(filters.cms_author);

  const initialFiltersState = {
      selected: authorFiltered?.selected || '',
      search: authorFiltered?.search || '',
      currentOffSet: authorFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [authorList, setAuthorList] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState<number>(authorFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(authorFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(authorFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [DeleteId, setDeleteId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [authors, setAuthors] = useState<any>(initialRow)
  const [authorsValidation, setAuthorsValidation] = useState<any>(initialCustomerValidation)
  const [errorMessage, setErrorMessage] = useState('')
  const [imageSize, setImageSize] = useState('')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [img, setImg] = useState<any>('')
  const [imageRequire, setImageRequire] = useState('')
  const [imageRequireMessage, setImageRequireMessage] = useState('')
  const [isLoading, setIsLoading] = useState('Submit')
  const [disableBtn, setDisable] = useState(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(authorFiltered?.buttonDisable || false) 

  const debounceSearch = useDebounce(search, 500);

  const cmsAuthor: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Blog',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => blogsAuthorList().then(), timer)
  }, [currentOffset, debounceSearch, count, authorList.length])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
  }

  const blogsAuthorList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}blog/author/list?&limit=${limit}&ordering=-updated_date&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setAuthorList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.cms_author , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}


  const singledata = async (item: any) => {
    setModalLoader(true)
    setId(item.id)
    const API = `${apiBaseURL()}blog/author/get/${item.id}/`
    const response = await getRequest(API, true)
    const formDetail = response
    // const formDetail = item
    setCropData(
      formDetail.profile_picture && formDetail.profile_picture
        ? formDetail.profile_picture
        : defaultSrc
    )

    let updatedData: any = { ...authors }
    updatedData['name'] = formDetail.name
    updatedData['bio_description'] = formDetail.bio_description
    updatedData['profile_picture'] = formDetail.profile_picture
    setAuthors(updatedData)
    setModalLoader(false)
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.Jfif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setAuthors({ ...authors, ['profile_picture']: e.target.files[0] })
      }
      let img: any = ''
      if (e.target.files[0]) {
        setImageRequire('')
        setImageRequireMessage('')
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageRequireMessage('')
            setImageValidation(false)

            // authorsValidation.profile_picture = 'is-invalid'
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }
  const getImage = (val: any) => {
    // setAuthorsValidation({...authorsValidation, ['profile_picture']: ''})

    setAuthors({ ...authors, ['profile_picture']: val })
  }
  const deleteItem = async (item: any) => {
    setDeleteId(item.id)

    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    await deleteRequest(`${apiBaseURL()}blog/author/delete/${DeleteId}/`, true).then((res) => {
      if (res.status === 204) {
        notify.success = true
      }
      if (res.statusText === 'Bad Request') {
        notify.failed = true
        notify.errorField = res.data.error.field
      }
    })
    if (notify.success) {
      const API = `${apiBaseURL()}blog/author/list?limit=${limit}&ordering=-updated_date&search=${search}&offset=${currentOffset}`
      const response = await getRequest(API, true)
      setAuthorList(response.results)
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 2000)
      setIsAlertSuccess(true)
    }
    if (notify.failed) {
      // setIsAlertFailed(true)
    }
  }

  const clearFunctionAdd = () => {
    setAuthors(initialRow)
    setAuthorsValidation('')
    setErrorMessage('')
    setCropData(defaultSrc)
    setImg('')
    setImageRequire('')
    setImageRequireMessage('')
    setimgSizeInvalid('')
    setIsLoading('Submit')
    setDisable(false)
    setModalLoader(false)
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    setAuthorsValidation({ ...authorsValidation, [inputName]: '' })

    switch (inputName) {
      case 'name':
        setAuthors({ ...authors, ['name']: inputValue })
        break
      case 'bio_description':
        setAuthors({ ...authors, ['bio_description']: inputValue })
        break
    }
  }

  const validateForm = () => {
    let navigateField = {
      profile_picture: authors.profile_picture,
      name: authors.name,
      bio_description: authors.bio_description,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    let validations = { ...authorsValidation }
    let isValid = true

    if (authors.name.trim() == '') {
      validations.name = 'is-invalid'
      isValid = false
    }

    if (authors.bio_description.trim() == '') {
      validations.bio_description = 'is-invalid'
      isValid = false
    }

    if (authors.profile_picture === '' || imgSizeInvalid == 'text-danger') {
      setImageRequire('file-icon-inValid')
      if (imgSizeInvalid !== 'text-danger') {
        setImageRequireMessage('file-icon-inValid')
      }

      validations.profile_picture = 'is-invalid'
      isValid = false
    }

    setAuthorsValidation(validations)
    return isValid
  }

  const apiCall = async () => {
    let customerData = new FormData()
    const authorsInfo = { ...authors }

    Object.entries(authorsInfo).map((object: any) => {
      if (object[0] == 'profile_picture') {
        if (object[1] != null && typeof object[1] != 'string') {
          return customerData.append(object[0], object[1])
        }
      } else {
        return customerData.append(object[0], object[1])
      }
    })
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    if (requestHandler === 'add') {
      setDisable(true)
      setIsLoading('Please Wait...')
      postRequest(`${apiBaseURL()}blog/author/create`, customerData, true).then((response: any) => {
        if (response.status === 201) {
          notify.success = true
        }
        if (response.statusText === 'Bad Request') {
          notify.failed = true
          notify.errorField = response.data.error.field
        }
        if (notify.success) {
          setIsLoading('Submit')
          setDisable(false)
          blogsAuthorList()
          $('#closeEaModalBlogsCategory').trigger('click')
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 2000)
          setIsAlertSuccess(true)
        }
        if (notify.failed) {
          if (notify.errorField == 'name') {
            setErrorMessage(
              'Your added data is already exist in this system! Please try again with unique data.'
            )
          }
          // setIsAlertFailed(true)
        }
      })
    } else {
      console.log('put', authors.bio_description)
      setDisable(true)
      setIsLoading('Please Wait...')
      putRequest(`${apiBaseURL()}blog/author/update/${id}/`, customerData, true).then(
        (response: any) => {
          if (response.status === 200) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            setIsLoading('Submit')
            setDisable(false)
            blogsAuthorList()
            $('#closeEaModalBlogsCategory').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'name') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    }
  }

  const submitFunction = () => {
    const isValid = validateForm()
    if (isValid) {
      apiCall()
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Author - ' />
      <div>
        <PageTitle breadcrumbs={cmsAuthor}>{intl.formatMessage({ id: 'Authors' })}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_BLOG_AUTHORS.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('CMS_BLOG_AUTHORS.UPDATE')
                : messageHandler === 'successDelete' && FormatMessage('CMS_BLOG_AUTHORS.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch}  value={search} disabled={listLoadder} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_authorss'
                    btnName='Add Author'
                    className="btn-primary me-3"
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {authorList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Author Name</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {authorList.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  {/* <div className='symbol symbol-50px me-2'> */}
                                  <div className='symbol symbol-28px me-2'>
                                    <span className='symbol-label'>
                                      {item.profile_thumbnail === null ? (
                                        <img
                                          alt='group image'
                                          className='h-35px rounded-1'
                                          // className='h-50px rounded-1'
                                          src={defaultSrc}
                                        />
                                      ) : (
                                        <img
                                          src={item.profile_thumbnail}
                                          className='h-40 rounded-1'
                                          alt=''
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span>{item.name}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    // onClick={singledata(item)}
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_create_authorss'
                                    onClick={(event) => {
                                      singledata(item)
                                      setRequest('edit')
                                      setMessageHandler('successEdit')
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                                {/* <a
                                  href='javascript:void(0)'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                  onClick={(event) => {
                                    deleteItem(item)
                                    setMessageHandler('successDelete')
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='ic mr-0'
                                  />
                                </a> */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
          <CustomPaginate
              data={authorList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.cms_author}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      </div>

      {/* <AddStockInReason getId={id} requestData={requestHandler} data={data} /> */}
      <EaModal
        id='ea_modal_create_authorss'
        modalTitle={requestHandler === 'add' ? 'Add Author' : 'Update Author'}
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        actionBtnName={isLoading}
        closeAuto='closeEaModalBlogsCategory'
        btnDisabled={disableBtn}
        loader={modalLoader}
      >
        <div id='profile_picture'>
          <Imagecropper
            title='Author Image'
            getImage={getImage}
            onImageChange={onImageChange}
            imageRequire={imageRequire}
            imageRequireMessage={imageRequireMessage}
            imageSize={imgSizeInvalid}
            cropData={cropData}
            setCrop={setCropData}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Author Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='name'
            className={clsx('form-control', authorsValidation.name)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'name')}
            name='name'
            value={authors && authors.name}
          />
        </div>
        <div>
          <label className='form-label'>
            Small Description<span className="text-danger">*</span>
          </label>
          <textarea
            id='bio_description'
            className={clsx('form-control', authorsValidation.bio_description)}
            rows={4}
            placeholder='Type here…'
            name='bio_description'
            onChange={(e) => handleChange(e, 'bio_description')}
            value={authors && authors.bio_description}
          ></textarea>
        </div>
        <div className='mb-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default BlogAuthors
