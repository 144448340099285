import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { apiBaseURL, checkPermissions, defaultPermission, KTSVG, retrieveFilter, saveFilter } from "../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { deleteRequest, getRequest, postRequest, putRequest, } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { Helmet } from "react-helmet"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import tenantConfiguration from "../../../../TenantVariables"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}
const StockinReason: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Less Stock-in Reasons',
  }
  const initialRow: any = {
    stockin_reason: '',
    warehouse_transfer: true,
    unsold_product: true,
    returned_product: true,
  }
  const initialCustomerValidation: any = {
    stockin_reason: '',
  }

  let categoryFiltered : any = retrieveFilter(filters.reason_master_stockin);

  const initialFiltersState = {
      selected: categoryFiltered?.selected || '',
      search: categoryFiltered?.search || '',
      currentOffSet: categoryFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [stockInReasonList, setStockInReasonList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState<number>(categoryFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(categoryFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(categoryFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [DeleteId, setDeleteId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [stockInReason, setStockInReason] = useState<any>(initialRow)
  const [stockInReasonValidation, setStockInReasonValidation] =
    useState<any>(initialCustomerValidation)
  const [isButtonLoadder, setIsButtonLoader] = useState<any>('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(categoryFiltered?.buttonDisable || false) 
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchPermission()
    setTimeout(() => lessStockInList().then(), timer)
  }, [currentOffset, debounceSearch])

  
  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_rejection_options")
    setPermission(res)
   }

  const lessStockInList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}master/stockin_reason?limit=${limit}&ordering=-id&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setStockInReasonList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.reason_master_stockin , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}
  // Develop BY: Kriti
  // Description: get single item function which is called edit button's on onClick event

  const singledata = async (item: any) => {
    setId(item.id)
    setModalLoader(true)

    const API = `${apiBaseURL()}master/stockin_reason/${item.id
      }/?limit=${limit}&ordering=-id&search=${search}&offset=${currentOffset}`
    const response = await getRequest(API, true)

    const formDetail = response
    let updatedData: any = { ...stockInReason }
    updatedData['stockin_reason'] = formDetail.stockin_reason
    updatedData['warehouse_transfer'] = formDetail.warehouse_transfer
    updatedData['unsold_product'] = formDetail.unsold_product
    updatedData['returned_product'] = formDetail.returned_product
    setStockInReason(updatedData)
    setModalLoader(false)
  }

  const deleteItem = async (item: any) => {
    setDeleteId(item.id)

    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    await deleteRequest(`${apiBaseURL()}master/stockin_reason/${DeleteId}/`, true).then((res) => {
      if (res.status === 204) {
        notify.success = true
      }
      if (res.statusText === 'Bad Request') {
        notify.failed = true
        notify.errorField = res.data.error.field
      }
    })
    if (notify.success) {
      const API = `${apiBaseURL()}master/stockin_reason?limit=${limit}&ordering=name&search=${search}&offset=${currentOffset}`
      const response = await getRequest(API, true)
      setStockInReasonList(response.results)
      setIsAlertSuccess(true)
    }
    if (notify.failed) {
      // setIsAlertFailed(true)
    }
  }

  const clearFunctionAdd = () => {
    setStockInReason(initialRow)
    setStockInReasonValidation('')
    setErrorMessage('')
    setModalLoader(false)
  }

  // Develop BY: Kriti
  // Description: OnChange Handler

  const handleChange = (e: any) => {
    const inputName = e.target ? e.target.name : 'name'
    let inputValue = e.target ? e.target.value : e.value

    setStockInReasonValidation({ ...stockInReasonValidation, [inputName]: '' })

    switch (inputName) {
      case 'stockin_reason':
        setStockInReason({ ...stockInReason, ['stockin_reason']: inputValue })
        break
      default:
        setStockInReason({ ...stockInReason, [inputName]: e.target.checked })
    }
  }

  // Develop BY: Kriti
  // Description: To validate

  const validateForm = () => {
    let validations = { ...stockInReasonValidation }
    let isValid = true

    if (stockInReason.stockin_reason === '') {
      validations.stockin_reason = 'is-invalid'
      isValid = false
    }
    setStockInReasonValidation(validations)
    return isValid
  }

  // Develop BY: Kriti
  // Description: Add and Update Stock-in-reason function

  const apiCall = async () => {
    const stockInInfo = { ...stockInReason }

    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    if (requestHandler === 'add') {
      postRequest(`${apiBaseURL()}master/stockin_reason/`, stockInInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 201) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            lessStockInList()
            $('#closeEaModalStockInReason').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'stockin_reason') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    } else {
      putRequest(`${apiBaseURL()}master/stockin_reason/${id}/`, stockInInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 200) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            lessStockInList()
            $('#closeEaModalStockInReason').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'stockin_reason') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    }
  }

  // Develop BY: Kriti
  // Description: API Call function which is called on Eamodel's submit event

  const submitFunction = () => {
    const isValid = validateForm()
    if (isValid) {
      // setIsButtonLoader('Please Wait...')
      apiCall()
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Less Stock-in Reasons' />
      <div>
        <PageTitle breadcrumbs={[masterHeaderTitle]}>
          {intl.formatMessage({ id: 'Less Stock-in Reasons' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          // message={FormatMessage('success')}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('LESS_STOCKIN_REASON.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('LESS_STOCKIN_REASON.UPDATE')
                : messageHandler === 'successDelete' && FormatMessage('LESS_STOCKIN_REASON.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} value={search} disabled={listLoadder}/>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_stockinreasons'
                    btnName='Add Less Stock-in Reasons'
                    className="btn-primary me-3"
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-100px py-2'>Less Stock-in Reasons</th>
                        <th className='min-w-100px py-2 text-center'>
                          Warehouse Transfer Stock-in
                        </th>
                        <th className='min-w-100px py-2 text-center'>Unsold Product Stock-in</th>
                        <th className='min-w-100px py-2 text-center'>Returned Product Stock-in</th>
                        <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {stockInReasonList.map((item: any) => {
                        return (
                          <tr>
                            <td>{item.stockin_reason}</td>
                            <td className='text-center'>
                              {item.warehouse_transfer == false ? (
                                // '-'
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='svg-icon-muted ic'
                                />
                              ) : (
                                <span>
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='svg-icon-muted ic'
                                  />
                                </span>
                              )}
                            </td>
                            <td className='text-center'>
                              {item.unsold_product == false ? (
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='svg-icon-muted ic'
                                />
                              ) : (
                                <span>
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='svg-icon-muted ic'
                                  />
                                </span>
                              )}
                            </td>
                            <td className='text-center'>
                              {item.returned_product == false ? (
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='svg-icon-muted ic'
                                />
                              ) : (
                                <span>
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='svg-icon-muted ic'
                                  />
                                </span>
                              )}
                            </td>
                            <td className='text-end'>
                              {permission.write && (
                                <a
                                  href='javascript:void(0)'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                  data-bs-toggle='modal'
                                  data-bs-target='#ea_modal_create_stockinreasons'
                                  onClick={(event) => {
                                    singledata(item)
                                    setRequest('edit')
                                    setMessageHandler('successEdit')
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>
                              )}
                              {/* {permission.delete && (
                                <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={(event) => {
                                  deleteItem(item)
                                  setMessageHandler('successDelete')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </a>
                              )} */}
                            </td>
                          </tr>
                        )
                      })}

                      {(currentOffset == '0' || currentOffset == null) && (
                        <tr>
                          <td>Other</td>
                          <td className='text-center'>
                            <span>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr012.svg'
                                className='svg-icon-muted ic'
                              />
                            </span>
                          </td>
                          <td className='text-center'>
                            <span>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr012.svg'
                                className='svg-icon-muted ic'
                              />
                            </span>
                          </td>
                          <td className='text-center'>
                            <span>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr012.svg'
                                className='svg-icon-muted ic'
                              />
                            </span>
                          </td>
                          <td className='text-end'></td>
                        </tr>
                      )}
                    </tbody>
                    {/* end::Table body */}
                  </table>
            </div>
          </div>
          {/* begin::Body */}
        </div>
        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${stockInReasonList.length == 0 ? 0 : selected * 10 + 1} to ${stockInReasonList.length == 0
                ? 0
                : stockInReasonList.length < 10
                  ? count
                  : (selected + 1) * 10
                } of ${stockInReasonList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={stockInReasonList.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div> */}
        <CustomPaginate
              data={stockInReasonList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.reason_master_stockin}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      </div>

      {/* <AddStockInReason getId={id} requestData={requestHandler} data={data} /> */}
      <EaModal
        id='ea_modal_create_stockinreasons'
        modalTitle={
          requestHandler === 'add' ? 'Add Less Stock-in Reasons' : 'Update Less Stock-in Reasons'
        }
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        btnDisabled={isButtonLoadder === 'Submit' ? false : true}
        closeAuto='closeEaModalStockInReason'
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Less Stock-in Reasons<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', stockInReasonValidation.stockin_reason)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e)}
            name='stockin_reason'
            value={stockInReason && stockInReason.stockin_reason}
          />
        </div>
        <div className='mb-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
        <div>
          <label className='form-label'>Show reasons in</label>
          <div className='form-check form-check-sm form-check-custom form-check-solid mt-1 mb-4 me-4'>
            <input
              className='form-check-input widget-13-check me-2'
              type='checkbox'
              id='warehousestockin'
              onChange={(e) => handleChange(e)}
              checked={stockInReason && stockInReason.warehouse_transfer}
              name='warehouse_transfer'
            />
            <label htmlFor='warehousestockin'>Warehouse Transfer Stock-in</label>
          </div>

          <div className='form-check form-check-sm form-check-custom form-check-solid mb-4 me-4'>
            <input
              className='form-check-input widget-13-check me-2'
              type='checkbox'
              id='unsoldstockin'
              onChange={(e) => handleChange(e)}
              checked={stockInReason && stockInReason.unsold_product}
              name='unsold_product'
            />
            <label htmlFor='unsoldstockin'>Unsold Product Stock-in</label>
          </div>

          <div className='form-check form-check-sm form-check-custom form-check-solid me-4'>
            <input
              className='form-check-input widget-13-check me-2'
              type='checkbox'
              id='returnstockin'
              onChange={(e) => handleChange(e)}
              checked={stockInReason && stockInReason.returned_product}
              name='returned_product'
            />
            <label htmlFor='returnstockin'>Returned Product Stock-in</label>
          </div>
        </div>
      </EaModal>
    </>
  )
}

export default StockinReason
