import moment from 'moment'
import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import DatePicker from 'react-date-picker'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Props } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import Select from 'react-select'
import DatePickerCustom from '../../../_eaFruitsDms/helpers/datePicker'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageLink, PageTitle } from '../../../_eaFruitsDms/layout/core'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import { EaStatisticsWidget } from '../../../_eaFruitsDms/partials/widgets'
import CustomPagination from '../../../_eaFruitsDms/layout/components/pagination/pagination'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
const creditnotetypesoptions = [
    { value: 'all', label: 'All Open' },
    { value: 'overdue', label: 'Overdue' },
]

const fetchCreditNotesTypes = async (key?: any, search?: any) => {

    let options: any = [{ value: 'select', label: 'All Credit Note Types' }, { value: 'Option1', label: 'Expired SKUs' }, { value: 'Option2', label: 'Damaged SKUs' }, { value: 'Option3', label: 'Missing SKUs' }, { value: 'Option4', label: 'Returned SKUs' }]
    let hasMore: boolean = false

    return {
        options: options,
        hasMore: hasMore,
    }
}


const customersoption = [
    { value: 'allcustomers', label: 'All Customers' },
    { value: 'customer1', label: 'Customer1' },
    { value: 'customer2', label: 'Customer2' },
    { value: 'customer3', label: 'Customer3' },
]
const sroptions = [
    { value: 'allsalesresp', label: 'All Sales Reps' },
    { value: 'sr1', label: 'Dusya Sigachyova' },
    { value: 'sr2', label: 'Thomas Kukabango' },
    { value: 'sr3', label: 'Neeshaan El Pasha' },
]

const CreditIssues: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const headerTitle = {
        title: 'Credit Notes',
        subTitle: 'Credit Notes',
    }

    return (
        <>
            <EATitle title='Credit Notes' />
            <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Credit Notes' })}</PageTitle>
            <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='success'
                        title='38 Credit Notes'
                        path='/'
                        description='Total Issued Credit Notes Count'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='info'
                        title='TZS 234,000.00'
                        description='Total Issued Credit Notes Amount'
                        path='/'
                    />
                </div>
            </div>
            <div className={`card ${className}`}>
                {/* {displayLoader && <IsLoader />} */}
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <DateRangePicker
                                initialSettings={
                                    {
                                        startDate: '01/01/2020',
                                        endDate: '01/12/2020',
                                        alwaysShowCalendars: true,
                                        cancelClass: 'btn-secondary',
                                        drops: "auto",
                                        locale: {
                                            format: 'DD/MM/YYYY'
                                        },
                                        ranges: {
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        },
                                    }}
                            >
                                <input type="text" className="form-control calendar my-1 me-3 w-auto" />
                            </DateRangePicker>
                            <Select
                                options={sroptions}
                                isSearchable
                                placeholder="All Sales Reps"
                                className='react-select-container my-1 me-3 w-140px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <AsyncPaginate
                                loadOptions={fetchCreditNotesTypes}
                                isSearchable
                                placeholder="All Credit Note Types"
                                className='react-select-container my-1 w-180px me-3'
                                classNamePrefix='react-select'
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <EaButton isModal={true} dataTarget="#ea_modal_create_credit_note" btnName="Create Credit Note" />
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-0 pb-3'>
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2 mt-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='text-muted fw-500'>
                                    <th className='min-w-100px w-130px'>Credit Note ID</th>
                                    <th className='min-w-120px'>Date Created</th>
                                    <th className='min-w-160px'>Credit Note Type</th>
                                    <th className='min-w-160px'>Customer Name</th>
                                    <th className='min-w-150px'>Issue Amount</th>
                                    <th className='w-150px min-w-150px'>SR Name</th>
                                    <th className='w-120px min-w-180px'>Settled In Invoice No.</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                <tr onClick={() => navigate('credit-notes-detail')} className="cursor-pointer">
                                    <td>
                                        #66304
                                    </td>
                                    <td>
                                        30 May 2022
                                    </td>
                                    <td>
                                        Returned SKUs
                                    </td>
                                    <td>
                                        Ekene Obasey
                                    </td>
                                    <td>
                                        TZS 285
                                    </td>
                                    <td>
                                        Dusya Sigachyova
                                    </td>
                                    <td>
                                        28 Aug 2022
                                    </td>
                                </tr>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                </div>
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>

            <EaModal id='ea_modal_create_credit_note' modalTitle='Create Credit Note'>
                <div className='mb-4'>
                    <label className='form-label'>Credit Note Amount</label>
                    <div
                        className='input-group'>
                        <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-50px'>
                            TZS
                        </span>
                        <input
                            type='text'
                            className='form-control'
                            name='price'
                            placeholder='Type here...'
                            defaultValue={1000}
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <label className='form-label'>Credit Note Type</label>
                    <AsyncPaginate
                        loadOptions={fetchCreditNotesTypes}
                        isSearchable
                        className='react-select-container w-100'
                        classNamePrefix='react-select'
                        menuPosition='fixed'
                        name='reason'
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                            },
                        })}
                    />
                </div>
                <div>
                    <label className='form-label'>Comments</label>
                    <textarea
                        className='form-control'
                        rows={4}
                        placeholder='Type here…'
                    ></textarea>
                </div>
            </EaModal>
        </>
    )
}

export default CreditIssues
