import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  KTSVG,
  retrieveFilter,
  saveFilter
} from "../../../../_eaFruitsDms/helpers";
import { categoryStyle } from "../../../../_eaFruitsDms/helpers/components/categoryStyle";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce";
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton";
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}
type modalType = {
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}

const ManualStockChangeReport: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Manual Stock Change Report',
  }
  const currentWarehouseId: any = localStorage.getItem('w_id')
  let manualStockFiltered : any = retrieveFilter(filters.oo_maual_stock_change_report);
  let monthStart = dateFormat(moment().startOf('month'))
  let monthEnd = dateFormat(moment().endOf('month'))

  const initialState = {
      selectedBrand : manualStockFiltered?.selectedBrand || {label : 'All Brands' , value : ''},
      selectedCategory: manualStockFiltered?.selectedCategory || {label : 'All Categories' , value : ''},
      selectedSku: manualStockFiltered?.selectedSku || {label : 'All SKU' , value : ''},
      selectedReason: manualStockFiltered?.selectedReason || {label : 'All Reason' , value : ''},
      startDate: manualStockFiltered?.startDate || monthStart,
      endDate: manualStockFiltered?.endDate || monthEnd,
      selected: manualStockFiltered?.selected || '',
      search: manualStockFiltered?.search || '',
      currentOffSet: manualStockFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [searchSelect, setSearchSelect] = useState('')
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [productSkus, setProductSkus] = useState<any>([])
  const [limit, setLimit] = useState<number>(10)
  const [reasonOffset, setReasonOffset] = useState<any>('')
  const [reasonOption, setReasonOption] = useState<any>([])
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [selectedBrand, setSelectedBrand] = useState<any>(manualStockFiltered?.selectedBrand || {
    label: 'All Brands',
    value: ''
  }); 
  const [selectedCategory, setSelectedCategory] = useState<any>(manualStockFiltered?.selectedCategory || {
    label: 'All Categories',
    value: ''
  }); 
  const [selectedSku, setSelectedSku] = useState<any>(manualStockFiltered?.selectedSku || {
    label: 'All SKU',
    value: ''
  }); 
  const [selectedReason, setSelectedReason] = useState<any>(manualStockFiltered?.selectedReason || {
    label: 'All Reason',
    value: ''
  }); 
  const [searchByName, setSearchByName] = useState<string>(manualStockFiltered?.search || '')
  const [product, setProduct] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(manualStockFiltered?.buttonDisable || false) 
  const initialDateFilter = {
    startDate: manualStockFiltered?.startDate || monthStart,
    endDate: manualStockFiltered?.endDate || monthEnd,
  }
  const [stockDate, setStockDate] = useState<any>(initialDateFilter)

  const debounceSearch = useDebounce(searchByName, 500); 

  useEffect(() => {
    setTimeout(() => fetchExpiredProductList().then(), timeoutSeconds)
  }, [
    currentOffset,
    stockDate,
    selectedBrand,
    selectedCategory,
    selectedReason,
    selectedSku,
    debounceSearch,
  ])

  const fetchExpiredProductList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}inventory/update_stock_list/?warehouse_id__id=${currentWarehouseId}&ordering=-change_date&search=${debounceSearch}&offset=${currentOffset}&product_id__brand_id__id=${selectedBrand.value}&category_id=${selectedCategory.value}&reason=${selectedReason.value}&product_id=${selectedSku.value}&start_date=${stockDate.startDate.apiDate}&end_date=${stockDate.endDate.apiDate}`
    const response = await getRequest(API, true)
    setProduct(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.oo_maual_stock_change_report , filterStates)
    if(selectedBrand.value == '' && selectedCategory.value == '' && selectedReason.value =='' &&  searchByName == ''  && stockDate.startDate.apiDate == monthStart.apiDate && stockDate.endDate.apiDate == monthEnd.apiDate){
        setButtonDisable(false)
    }
  }

  const CustomDateRangePicker = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            const callBakeDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBakeDate.startDate
            updatedState['endDate'] = callBakeDate.endDate
            setFilterStates(updatedState)
            setStockDate(callBakeDate)
          }}
          initialSettings={{
            startDate: moment(stockDate.startDate.apiDate).toDate(),
            endDate: moment(stockDate.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={stockDate}
            disabled={listLoadder}
          />
        </DateRangePicker>
      </>
    )
  }

  const fetchBrands = async (search?: any) => {
    return await fetchAsyncDropdown(
      brandOffset,
      setBrandOffset,
      brandOption,
      setBrandOption,
      `master/product/brand?ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All Brands'
    )
  }
  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if(categoriesResponse.results){
      if (categoriesResponse.next !== null) {
        const queryParams = new URLSearchParams(categoriesResponse.next)
        let newOffset: any = queryParams.get('offset')
        setCategoryOffset(newOffset)
        hasMore = true
      }
  
      if (categoryOption.length == 0) {
        options.push({
          label: 'All Categories',
          value: '',
        })
      }
  
      if (categoriesResponse.results.length > 0) {
        categoriesResponse.results.map((option: any) => {
          options.push({
            label: option.name,
            value: option.id,
            color: '#6d6e6f',
            bg: '#f7f7f7',
            transform: 'uppercase',
            boxSizing: 'borderBox',
            weight: '500',
            cursor: 'default',
            size: '.75rem !important',
          })
          if (option.child_category.length > 0) {
            option.child_category.map((child: any) => {
              options.push({
                label: child.name,
                value: child.id,
              })
            })
          }
        })
      }
  
      if (categoryOption.length > 0) {
        setCategoryOption(categoryOption.concat(options))
      } else {
        setCategoryOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchProductsku = async (search?: any) => {
    return await fetchAsyncDropdown(
      productOffSet,
      setProductOffSet,
      productSkus,
      setProductSkus,
      `inventory/product-SKUs/?warehouse_id=${currentWarehouseId}&ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All SKU'
    )
  }
  const featchReason = async (search?: any) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonOption,
      setReasonOption,
      `master/options-master/?type=Manual_Stock_Update`,
      search,
      setSearchSelect,
      'option',
      true,
      'All Reason'
    )
    response.options.push({
      label: 'Other',
      value: 'other',
    })
    return response
  }
  const handleFilterOptionChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    switch (inputName) {
      case 'brand':
        setTimeoutSeconds(0)
        setSelectedBrand(e)
        setFilterStates({ ...filterStates, ['selectedBrand']: e })
        break
      case 'category':
        setTimeoutSeconds(0)
        setSelectedCategory(e)
        setFilterStates({ ...filterStates, ['selectedCategory']: e })
        break
      case 'productSku':
        setTimeoutSeconds(0)
        setSelectedSku(e)
        setFilterStates({ ...filterStates, ['selectedSku']: e })
        break
      case 'reason':
        setTimeoutSeconds(0)
        setSelectedReason(e)
        setFilterStates({ ...filterStates, ['selectedReason']: e })
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearchByName(inputValue)
        setFilterStates({ ...filterStates, ['search']: inputValue})
        break
    }
  }
  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }
  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeReset = () =>{
    setSearchByName('')
    setSelectedBrand({label : 'All Brands' , value : ''})
    setSelectedCategory({label : 'All Categories' , value : ''})
    setSelectedSku({label : 'All SKU' , value : ''})
    setSelectedReason({label : 'All Reason' , value : ''})
    setCurrentOffset(0)
    setSelected(0)

    let updatedDateState: any = {...stockDate}
    updatedDateState['startDate'] = monthStart
    updatedDateState['endDate'] = monthEnd
    setStockDate(updatedDateState)

    let updatedState: any = { ...filterStates }
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    updatedState['selectedBrand'] = ''
    updatedState['selectedCategory'] = ''
    updatedState['selectedSku'] = ''
    updatedState['selectedReason'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Manual Stock Change Report -' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({ id: 'Manual Stock Change Report' })}
      </PageTitle>
      <div className='card'>
      {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={(e: any) => handleFilterOptionChange(e, 'search')} value={searchByName}/>
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0 d-flex me-3'>
                <CustomDateRangePicker />
              </div>
              <AsyncPaginate
                    
                loadOptions={fetchBrands}
                isSearchable
                placeholder='All Brands'
                className={clsx('react-select-container my-1 me-3 w-120px', '')}
                classNamePrefix='react-select'
                name='brand'
                value={selectedBrand}
                isDisabled={listLoadder}
                onChange={(e: any) => handleFilterOptionChange(e, 'brand')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                    
                loadOptions={fetchCategory}
                isSearchable
                placeholder='All Categories'
                className={clsx('react-select-container my-1 me-3 w-135px', '')}
                classNamePrefix='react-select'
                styles={categoryStyle}
                isDisabled={listLoadder}
                name='category'
                onChange={(e: any) => handleFilterOptionChange(e, 'category')}
                value={selectedCategory}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                    
                loadOptions={fetchProductsku}
                isSearchable
                placeholder='All SKU'
                className={clsx('react-select-container my-1 me-3 w-100px', '')}
                classNamePrefix='react-select'
                name='productSku'
                value={selectedSku}
                isDisabled={listLoadder}
                onChange={(e: any) => handleFilterOptionChange(e, 'productSku')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                    
                loadOptions={featchReason}
                isSearchable
                placeholder='All Reason'
                className={clsx('react-select-container my-1 me-3 w-120px', '')}
                classNamePrefix='react-select'
                name='reason'
                value={selectedReason}
                isDisabled={listLoadder}
                onChange={(e: any) => handleFilterOptionChange(e, 'reason')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <button
                type='button'
                className='btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center me-3'
              >
                <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
                Export
              </button>
              <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
          {product.length == 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-110px py-2'>Batch Number</th>
                          <th className='min-w-110px py-2'>Product SKU</th>
                          <th className='min-w-100px py-2'>Brand</th>
                          <th className='min-w-120px py-2'>Category</th>
                          <th className='min-w-100px py-2'>Size</th>
                          <th className='min-w-100px py-2'>Change</th>
                          <th className='min-w-150px py-2'>Reason</th>
                          <th className='min-w-100px py-2'>Change By</th>
                          <th className='min-w-100px py-2'>Change Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.map((item: any) => {
                          let approxWeight = 0

                          if (item.product_id && item.product_id.variants.approx_weight > 0) {
                            approxWeight = item.product_id.variants.approx_weight / 1000
                          }

                          return (
                            <tr>
                              <td>{item.batch_number}</td>
                              <td>{item.product_id && item.product_id.name}</td>
                              <td>{item.product_id && item.product_id.brand_id}</td>
                              <div>
                                <div className='text-muted font-13 mb-1 font-medium'>
                                  {item.product_id &&
                                    item.product_id.category_id.parent_category.name}
                                </div>
                                {item.product_id && item.product_id.category_id.name}
                              </div>
                              <td>
                                {' '}
                                {approxWeight > 0
                                  ? approxWeight + ' Kgs'
                                  : (item.product_id.measurement_unit.toLowerCase() === 'gram'
                                    ? item.product_id.size / 1000
                                    : item.product_id.size) + ' Kgs'}
                              </td>

                              <td>
                                {item.qty_difference && item.qty_difference <= 0
                                  ? item.qty_difference
                                  : '+' + item.qty_difference}
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <span>
                                    {item.reason && item.reason.option
                                      ? item.reason.option
                                      : item.reason_note}
                                  </span>
                                  {/* {item.reason_note ? (
                                    <>
                                      {' '}
                                      <OverlayTrigger
                                        delay={{hide: 300, show: 300}}
                                        overlay={(props) => (
                                          <Tooltip {...props} className='tooltip-dark long-tooltip'>
                                            {item.reason_note}
                                          </Tooltip>
                                        )}
                                        placement='top'
                                      >
                                        <a className='ms-2 text-hover-primary cursor-pointer'>
                                          <KTSVG
                                            path='/media/icons/duotune/communication/com003.svg'
                                            className='ic text-dark mr-0'
                                          />
                                        </a>
                                      </OverlayTrigger>
                                    </>
                                  ) : (
                                    ''
                                  )} */}
                                </div>
                              </td>
                              <td>
                                {item.action_by.first_name} {item.action_by.last_name}
                              </td>
                              <td>
                                {item.change_date ? dateFormat(item.change_date).displayDate : '-'}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {/* <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${product.length == 0 ? 0 : selected * 10 + 1} to ${product.length == 0 ? 0 : product.length < 10 ? count : (selected + 1) * 10
              } of ${product.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              count={product.length == 0 ? 0 : count}
              selected={selected}
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
            />
          </div>
        </div>
      </div> */}
      <CustomPaginate
        data={product}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.oo_maual_stock_change_report}
        filterStates={filterStates}
        isDisabled={listLoadder}
      />
    </>
  )
}

export default ManualStockChangeReport
