import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {dailyStockInOutReport} from '../../../../_eaFruitsDms/apiFunctions/reports/dailyStockInReport'
import {
  apiBaseURL,
  dateFormat,
  GLOBAL_DATE_FORMAT,
  KTSVG,
  retrieveFilter,
  saveFilter,
  staticSearch,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {blobRequest, getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getroleId, getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {stockInOutType} from '../../customers/WMcustomers/CustomerConst'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'

type Props = {
  className: string
}

const DailyStockInReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Daily Stock-In Report',
  }

  let customersFiltered: any = retrieveFilter(filters.oo_stockin_report)
  let toDayDate = dateFormat(moment())

  const initialState = {
    getIndusialOOs: customersFiltered?.getIndusialOOs || {label: 'From All OOs', value: ''},
    stockType: customersFiltered?.stockType || '',
    startDate: customersFiltered?.startDate || toDayDate,
    selected: customersFiltered?.selected || '',
    search: customersFiltered?.search || '',
    currentOffSet: customersFiltered?.currentOffSet || '',
    buttonDisable: true,
  }
  const [getDailyStockInList, setDailyStockInList] = useState<any>([])
  const [count, setCount] = useState<any>('')
  const [next, setNext] = useState<any>('')
  const [prev, setPrev] = useState<any>('')
  const [selected, setSelected] = useState<number>(customersFiltered?.selected || 0)
  const [currentOffset, setCurrentOffset] = useState<any>(customersFiltered?.currentOffSet || '0')
  const [getLength, setLength] = useState<any>('1')
  const [timer, setTimer] = useState<any>(null)
  const [getIndusialOOs, setIndusialOOs] = useState<any>(
    customersFiltered?.getIndusialOOs || {
      label: 'From All OOs',
      value: '',
    }
  )
  const [listLoadder, setListLoader] = useState<any>(true)
  const [stockType, setStockType] = useState(customersFiltered?.stockType || '')
  const [stockOutOffset, setStockInOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [operationOfficerOption, setOperatioOfficerOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [search, setSearch] = useState(customersFiltered?.search || '')
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    customersFiltered?.buttonDisable || false
  )
  const debounceSearch = useDebounce(search, 500)
  const [startDate, setStartDate] = useState<any>(customersFiltered?.startDate || toDayDate)

  useEffect(() => {
    setStockInOffset('')
  }, [searchSelect])

  useEffect(() => {
    setListLoader(true)
    dailyStockInOutReport(
      debounceSearch,
      '',
      stockType,
      'In',
      '',
      startDate.apiDate,
      '',
      getIndusialOOs.value,
      '',
      getW_Id,
      '',
      limit,
      currentOffset
    ).then((res) => {
      setDailyStockInList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
    // getDropDownValues()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.oo_stockin_report, filterStates)
    if (
      getIndusialOOs.value == '' &&
      stockType == '' &&
      search == '' &&
      startDate.apiDate == toDayDate.apiDate
    ) {
      setButtonDisable(false)
    }
  }, [getIndusialOOs, stockType, debounceSearch, startDate])

  const handlechangeStockType = (e: any) => {
    setStockType(e.value)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['stockType']: e.value})
  }

  const handlechangeOperationOfficer = (e: any) => {
    setIndusialOOs(e)
    const actionBy = e.value
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['getIndusialOOs']: e})
  }

  // Develop BY: Kriti
  // Description: fetch operational officer drop-down's options which is called on AsyncPaginate

  const fetchOperationOfficer = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const STOCK_OUT_API = `${apiBaseURL()}auth/users/team/list?role__id=${getroleId}&warehouse__id=${getW_Id}&search=${search}&limit=${limit}&offset=${stockOutOffset}`
    const response = await getRequest(STOCK_OUT_API, true)
    let hasMore: boolean = false

    if (response.results) {
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setStockInOffset(newOffset)
        hasMore = true
      }
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.first_name + ' ' + option.last_name,
            value: option.id,
          })
        })
      }
      if (operationOfficerOption.length > 0) {
        setOperatioOfficerOption(operationOfficerOption.concat(options))
      } else {
        setOperatioOfficerOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  // Develop BY: Kriti
  // Description: fetch stock in type drop-down's options which is called on AsyncPaginate
  const defaultOptions: any = [
    {
      value: '',
      label: 'All Stock-In Types',
    },
    {
      value: 'FreshProduct',
      label: 'Fresh Product',
    },
    {
      value: 'WarehouseTransfer',
      label: 'Warehouse Transfer',
    },
    {
      value: 'Unsold',
      label: 'Unsold',
    },
    {
      value: 'ReturnProduct',
      label: 'Return Product',
    },
  ]

  const fetchStockInType = async (search: any) => {
    let options: any

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }

    return {
      options: options,
      hasMore: false,
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const searchList = (e: any) => {
    setSearch(e.target.value)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['search']: e.target.value})
  }

  const downloadBarcode = async (stockId: any) => {
    setListLoader(true)
    await blobRequest(`${apiBaseURL()}inventory/barcode-pdf-download/?id=${stockId}`, true)
    setListLoader(false)
  }

  const handleChangeReset = () => {
    setSearch('')
    setIndusialOOs({label: 'From All OOs', value: ''})
    setStockType('')
    setCurrentOffset(0)
    setSelected(0)
    setStartDate(toDayDate)

    let updatedState: any = {...filterStates}
    updatedState['getIndusialOOs'] = ''
    updatedState['stockType'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['startDate'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            let startDate = dateFormat(start._d)
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = startDate
            setFilterStates(updatedState)
            setStartDate(startDate)
          }}
          initialSettings={{
            startDate: moment(startDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            singleDatePicker: true,
            maxDate: moment(Date.now()),
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-140px'
            defaultValue={startDate}
            disabled={listLoadder}
          />
        </DateRangePicker>
      </>
    )
  }
  return (
    <>
      <EATitle title='Daily Stock-In Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Daily Stock-In Report'})}
      </PageTitle>
      <div className='card'>
        {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='col-12 col-md-auto mt-0'>
            <input
              type='text'
              placeholder='Search'
              className='form-control form-search'
              onChange={searchList}
              value={search}
              disabled={listLoadder}
            />
          </div>
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <CustomDatePickerComponent />
              <AsyncPaginate
                loadOptions={fetchStockInType}
                isSearchable
                className='react-select-container my-1 me-3 w-170px'
                classNamePrefix='react-select'
                placeholder='All Stock-In Types'
                onChange={handlechangeStockType}
                isDisabled={listLoadder}
                value={defaultOptions.find((option: any) => option.value == stockType)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchOperationOfficer}
                isSearchable
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                placeholder='From All OOs'
                value={getIndusialOOs}
                onChange={handlechangeOperationOfficer}
                isDisabled={listLoadder}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div
            className='table-responsive expandable'
            style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
            id='expandTable'
          >
            {getDailyStockInList.length == 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                <thead>
                  <tr className=' text-muted fw-500'>
                    <th className='min-w-100px py-2'>ID</th>
                    <th className='min-w-100px py-2'>Stock-In Date & Time</th>
                    <th className='min-w-100px py-2'>Type</th>
                    <th className='min-w-100px py-2'>OO Name</th>
                    {/* <th className='w-140px py-2 text-end'>Download Barcodes</th> */}
                  </tr>
                </thead>
                <tbody>
                  {getDailyStockInList.length > 0 &&
                    getDailyStockInList.map((data: any) => {
                      const item: any = data
                      return (
                        <>
                          <tr
                            data-bs-toggle='collapse'
                            data-bs-target={`.row-collapse${item.id}`}
                            aria-controls={`rowCollapse${item.id}`}
                            className='cursor-pointer'
                          >
                            <td>
                              {' '}
                              {item.stock_id.includes('#') ? item.stock_id : '#' + item.stock_id}
                            </td>
                            <td>
                              {/* <span>
                                    {' '}
                                    {item.length !== 0
                                      ? moment(item.stock_date, 'YYYY-MM-DD-h:m:s').format(
                                        'DD MMM YYYY  LT'
                                      )
                                      : '-'}
                                  </span> */}
                              {item.length !== 0 ? (
                                <>
                                  {dateFormat(item.stock_date).displayDate}{' '}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.stock_date, 'LT').displayDate}
                                  </p>
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>{stockInOutType(item.stock_type)}</td>
                            <td>
                              {item.action_by.first_name} {item.action_by.last_name}
                            </td>
                            {/* action_by */}
                            <td className='text-center'></td>
                          </tr>
                          <tr className='expandable-row'>
                            <td className='reset-expansion-style' colSpan={9}>
                              <div
                                className={`row-expansion-style collapse row-collapse${item.id} collapsible`}
                                id={`rowCollapse${item.id}`}
                                data-bs-parent='#expandTable'
                              >
                                <div>
                                  <table className='table table-bordered gs-4 mb-0'>
                                    <thead>
                                      <tr className='text-muted fw-500 bg-light2'>
                                        <th className='w-25 py-2'>Product SKU</th>
                                        <th className='w-140px py-2'>Batch Number</th>
                                        <th className='min-w-120px w-120px py-2'>Qty.</th>
                                        <th className='min-w-120px py-2'>Expiry Date</th>
                                        {/* <th className='min-w-120px py-2'>Added Date</th> */}
                                        <th className='w-140px py-2 text-end'></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.stock_entries &&
                                        item.stock_entries.map((data: any) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className='d-flex'>
                                                  {data.product_id && data.product_id.name}
                                                  {
                                                    //TODO: Kriti you need to optimized this
                                                    (data.note ||
                                                      (data.reason &&
                                                        data.reason.stockin_reason)) && (
                                                      <OverlayTrigger
                                                        delay={{hide: 300, show: 300}}
                                                        overlay={(props) => (
                                                          <Tooltip
                                                            {...props}
                                                            className='tooltip-dark long-tooltip'
                                                          >
                                                            {data.note
                                                              ? data.note
                                                              : data.reason.stockin_reason}
                                                          </Tooltip>
                                                        )}
                                                        placement='top'
                                                      >
                                                        <a className='ms-2 text-hover-primary cursor-pointer'>
                                                          <KTSVG
                                                            path='/media/icons/duotune/communication/com003.svg'
                                                            className='ic text-dark mr-0'
                                                          />
                                                        </a>
                                                      </OverlayTrigger>
                                                    )
                                                  }
                                                </div>
                                              </td>
                                              <td>
                                                {data.batch_number.includes('#')
                                                  ? data.batch_number
                                                  : '#' + data.batch_number}
                                              </td>
                                              <td>{data.qty}</td>
                                              <td>
                                                {item.length !== 0
                                                  ? moment(data.expiry_date).format('DD MMM YYYY')
                                                  : '-'}
                                              </td>
                                              {/* <td className='text-center pe-0'>
                                                {!['', null, 'null'].includes(data.barcode) ? (
                                                  <img
                                                    src={toAbsoluteUrl(
                                                      '/media/icons/duotune/ecommerce/ecm010.svg'
                                                    )}
                                                    style={{cursor: 'pointer'}}
                                                    className='ms-2'
                                                    onClick={() => downloadBarcode(data.id)}
                                                  />
                                                ) : (
                                                  '-'
                                                )}
                                              </td> */}
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {/* end first row expand */}
                        </>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {/* <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getDailyStockInList.length == 0 ? 0 : selected * 10 + 1} to ${
              getDailyStockInList.length == 0
                ? 0
                : getDailyStockInList.length < 10
                ? count
                : (selected + 1) * 10
            } of ${getDailyStockInList.length == 0 ? 0 : count} entries`}
          </div>{' '}
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getDailyStockInList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div> */}
      <CustomPaginate
        data={getDailyStockInList}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.oo_stockin_report}
        filterStates={filterStates}
        isDisabled={listLoadder}
      />
    </>
  )
}

export default DailyStockInReport
