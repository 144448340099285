import {useEffect, useState} from 'react'
import {checkPermissions, checkPermissionsWithLoading, defaultPermission} from '../CommonHelpers'

const usePermission = (permissionName: string, checkWithLoading?: boolean, isLoading?: boolean) => {
  const [permission, setPermission] = useState(defaultPermission)

  useEffect(() => {
    !checkWithLoading && fetchPermission()
  }, [checkWithLoading])

  useEffect(() => {
    checkWithLoading && fetchPermissionWithLoading()
  }, [checkWithLoading, isLoading])

  const fetchPermission = async () => {
    let res = await checkPermissions(permissionName)
    setPermission(res)
  }

  const fetchPermissionWithLoading = async () => {
    if (!isLoading) {
      let res = await checkPermissionsWithLoading(permissionName)
      setPermission(res)
    }
  }

  return permission
}

export default usePermission
