import clsx from "clsx";
import $ from "jquery";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  dateFormat,
  eaCloseModal,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isModalValidation,
  toAbsoluteUrl
} from "../../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage";
import DatePickerCustom from "../../../../../_eaFruitsDms/helpers/datePicker";
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomSunEditor from "../../../../../_eaFruitsDms/layout/components/sunEditor/CustomSunEditor";
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core";
import Imagecropper from "./components/imagecropper";

const cmsPost: Array<PageLink> = [
  {
    title: 'CMS',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Blog',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Posts',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

const AddPost = () => {
  const navigate = useNavigate()
  const postsId = localStorage.getItem('postId')

  const intl = useIntl()
  const adminHeaderTitle = {
    title: 'CMS',
  }

  const initialPosts = {
    id: postsId,
    category: '',
    author: '',
    title: '',
    short_desc: '',
    description: '',
    cover_image: '',
    // is_published: '',
    is_published: 'true',
    published_date: '',
  }
  const initialPostsValidation = {
    id: postsId,
    category: '',
    author: '',
    title: '',
    short_desc: '',
    description: '',
    cover_image: '',
    is_published: '',
    published_date: '',
  }
  const initialDateSetting: any = {
    startDate: moment(),
    maxDate: moment(),
    alwaysShowCalendars: true,
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    drops: 'auto',
    showDropdowns: true,
  }
  const [posts, setPosts] = useState<any>(initialPosts)
  const [descriptionValidation, setDescriptionValidation] = useState('')
  const [description, setDescription] = useState('')
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [postsLogoImg, setPostsLogoImg] = useState('')
  const [img, setImg] = useState<any>('')
  const [imageSize, setImageSize] = useState('')
  const [imageRequire, setImageRequire] = useState('')
  const defaultSrc = toAbsoluteUrl('/media/icons/featured_image.png')
  const [cropData, setCropData] = useState(defaultSrc)
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [limit, setLimit] = useState<number>(10)
  const [blogCategoryOffSet, setBlogCategoryOffSet] = useState<any>('')
  const [blogCategory, setBlogCategory] = useState<any>([])
  const [authorOffSet, setAuthorOffSet] = useState<any>('')
  const [author, setAuthor] = useState<any>([])
  const [postsValidation, setPostsValidation] = useState<any>(initialPostsValidation)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  // const [isButtonLoadder, setIsButtonLoader] = useState<any>('Submit')
  const [postDetailInitial, setpostDetailInitial] = useState<any>(initialPosts)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [imageRequireMessage, setImageRequireMessage] = useState('')
  const [publishedKey, setPublishedKey] = useState<any>('')
  const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
  const [saveAsDraft, setSaveAsDraft] = useState<any>('Save as Draft')
  const [saveDraft, setSaveDraft] = useState<any>('Save Draft')
  const [isLoading, setIsLoading] = useState('Switch to Draft')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [isResonseLoading, setIsResponseLoading] = useState<any>('')
  const [dateShow, setDateShow] = useState('Select')
  const [initialSettings, setInitialSettings] = useState<any>(initialDateSetting)
  const [accessSts, setAccessSts] = useState<any>(true)
  const [value, onChange] = useState(new Date())

  useEffect(() => {
    fetchPostsDetail().then()
  }, [])

  useEffect(() => {
    setPosts(postDetailInitial)
  }, [postDetailInitial])

  useEffect(() => {
    let UpdatePost: any = { ...posts }
    UpdatePost.published_date = moment(value).format('YYYY-MM-DD')
    setPosts(UpdatePost)
  }, [value])

  const fetchPostsDetail = async () => {
    setPosts(initialPosts)
    if (postsId != null) {
      setIsResponseLoading(true)
      let postsResponse = await getRequest(`${apiBaseURL()}blog/post/get/${postsId}/`, true)
      if (postsResponse) {
        let postsDetail: any = { ...posts }

        // setPublishedKey(postsResponse.is_published)
        setAccessSts(postsResponse.is_published)

        Object.entries(postsResponse).map((object: any) => {
          if (object[0] in postsDetail) {
            if (object[0] === 'author') {
              return (postsDetail[object[0]] = object[1].id)
            }
            if (postsResponse.published_date) {
              const dob = dateFormat(postsResponse.published_date)
              setDateShow(dob.displayDate)
              setInitialSettings({ ...initialDateSetting, startDate: dob.displayDate })
            }
            if (object[0] === 'category') {
              let id: any = []
              object[1].forEach((item: any, index: any) => {
                id.push(item.id)
              })
              return (postsDetail[object[0]] = id)
            }
            if (object[0] !== 'category' && object[0] !== 'author') {
              return (postsDetail[object[0]] = object[1])
            }
          }
        })

        if (postsResponse.cover_image != null) {
          setCropData(postsResponse.cover_image)
        } else {
          setCropData(defaultSrc)
        }

        let category: any = []
        postsResponse.category.length !== 0 &&
          postsResponse.category.map((item: any) => {
            category.push({
              label: item.title,
              value: item.id,
            })
          })
        setBlogCategory(category)

        setAuthor([
          {
            label: postsResponse.author.name,
            value: postsResponse.author.id,
          },
        ])
        setPosts(postsDetail)
        // setpostDetailInitial(postsDetail)
        setIsResponseLoading(false)
      }
    }
  }
  const fetchBlogCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const creditRangeResponse = await getRequest(
      `${apiBaseURL()}blog/categories/list?ordering=title&search=${search}&limit=${limit}&offset=${blogCategoryOffSet}`,
      true
    )

    let hasMore: boolean = false
    if(creditRangeResponse.results){
      if (creditRangeResponse.next !== null) {
        const queryParams = new URLSearchParams(creditRangeResponse.next)
        let newOffset: any = queryParams.get('offset')
        setBlogCategoryOffSet(newOffset)
        hasMore = true
      }
  
      if (creditRangeResponse.results.length > 0) {
        creditRangeResponse.results.map((option: any) => {
          options.push({
            label: option.title,
            value: option.id,
          })
        })
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchAuthor = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const postsTagResponse = await getRequest(
      `${apiBaseURL()}blog/author/list?search=${search}&ordering=name&limit=${limit}&offset=${authorOffSet}`,
      true
    )

    let hasMore: boolean = false
    if(postsTagResponse.results){
      if (postsTagResponse.next !== null) {
        const queryParams = new URLSearchParams(postsTagResponse.next)
        let newOffset: any = queryParams.get('offset')
        setAuthorOffSet(newOffset)
        hasMore = true
      }
  
      if (postsTagResponse.results.length > 0) {
        postsTagResponse.results.map((option: any) => {
          options.push({
            label: option.name,
            value: option.id,
          })
        })
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let date = start._d
          let newDate = dateFormat(start._d)
          setPosts({ ...posts, published_date: newDate.apiDate })
          setDateShow(newDate.displayDate)
          setInitialSettings((pre: any) => ({ ...pre, startDate: start._d }))
        }}
        initialSettings={initialSettings}
        dateValidation={'w-100'}
        dateValue={dateShow}
      />
    )
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.Jfif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setPosts({ ...posts, ['cover_image']: e.target.files[0] })
      }
      let img: any = ''
      if (e.target.files[0]) {
        setImageRequire('')
        setImageRequireMessage('')
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageValidation(false)
            setImageRequireMessage('')
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }
  const getImage = (val: any) => {
    setPosts({ ...posts, ['cover_image']: val })
  }
  const deleteItem = async () => {
    setDeleteId(postsId)
  }

  const deletAPI = async () => {
    await deleteRequest(`${apiBaseURL()}blog/post/delete/${DeleteId}/`, true).then((res) => {
      handleResponse(res, 'Delete')
    })
  }

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setPostsValidation({ ...postsValidation, [inputName]: '' })
    let updatedPosts: any = { ...posts }

    switch (inputName) {
      case 'category':
        setBlogCategory(e)
        updatedPosts['category'] = e
        break
      case 'author':
        setAuthor(e)
        updatedPosts[inputName] = inputValue
        break
      case 'description':
        if (e === '') {
          setPostsValidation({ ...postsValidation, [inputName]: 'is-invalid' })
        }
        updatedPosts[inputName] = e
        break
      default:
        updatedPosts[inputName] = inputValue
    }
    setPosts(updatedPosts)
  }

  const handleDescription = (e: any) => {
    setDescriptionValidation('')
    setDescription(e)
  }

  const validateForm = () => {
    const validation = { ...posts }
    let isValid = true

    let navigateField = {
      title: posts.title,
      short_desc: posts.short_desc,
      cover_image: posts.cover_image,
      description: posts.description,
      category: posts.category,
      author: posts.author,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (posts.category === '') {
      validation.category = 'is-invalid'
      isValid = false
    }
    if (posts.author === '') {
      validation.author = 'is-invalid'
      isValid = false
    }
    if (posts.title === '') {
      validation.title = 'is-invalid'
      isValid = false
    }
    if (posts.short_desc === '') {
      validation.short_desc = 'is-invalid'
      isValid = false
    }
    if (description === '' || description === '<p><br></p>') {
      // validation.description = 'is-invalid'
      setDescriptionValidation('is-invalid')
      isValid = false
    }
    if (posts.cover_image === '' || imgSizeInvalid == 'text-danger') {
      setImageRequire('file-icon-inValid')
      if (imgSizeInvalid != 'text-danger') {
        setImageRequireMessage('file-icon-inValid')
      }
      validation.cover_image = 'is-invalid'
      isValid = false
    }
    setPostsValidation(validation)
    return isValid
  }
  const addPost = (type: any) => {
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      setSaveAsDraft('Please Wait...')
      setSaveDraft('Please Wait...')
      setIsLoading('Please Wait...')
      let postsData = new FormData()
      const postsInfo: any = { ...posts }

      // if (type === 'is_published') {
      //   postsInfo.is_published = true
      // } else {
      //   postsInfo.is_published = false
      // }
      postsInfo.description = description

      Object.entries(postsInfo).map((object: any) => {
        if (object[0] === 'cover_image') {
          if (object[1] == null || typeof object[1] == 'string') {
            return postsData.delete(object[0])
          }
        }
        if (object[0] === 'category') {
          for (var i = 0; i < blogCategory.length; i++) {
            postsData.append(`category`, blogCategory[i].value)
          }
        } else {
          return postsData.append(object[0], object[1])
        }
      })

      if (postsId != null) {
        patchRequest(`${apiBaseURL()}blog/post/update/${postsId}/`, postsData, true).then((res) => {
          handleResponse(res, 'Edit')
        })
      } else {
        postRequest(`${apiBaseURL()}blog/post/create`, postsData, true).then((res) => {
          handleResponse(res, 'Add')
        })
      }
    }
  }

  const handleResponse = (res: any, type: string) => {
    eaCloseModal()
    setIsButtonLoader('Submit')
    setSaveAsDraft('Save as Draft')
    setSaveDraft('Save Draft')
    setIsLoading('Switch to Draft')
    if (res.status === 201 || res.status === 200 || res.status === 204) {
      setIsAlertSuccess(true)
      const postDetailInitials: any = { ...posts }
      setPosts(initialPosts)
      localStorage.setItem('type', type)
      localStorage.removeItem('postId')
      navigate('/cms/blogs/posts')
    } else {
      setIsAlertFailed(true)
    }

    setTimeout(() => {
      setIsAlertFailed(false)
      setIsAlertSuccess(false)
    }, 1500)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  const draftHandle = () => {
    addPost('is_published_false')
  }
  return (
    <>
      <div>
        <EATitle title='Posts - ' />
        {/* {postsId == null ? (
          <>
            <PageTitle breadcrumbs={cmsPost}>{intl.formatMessage({id: 'Add Posts'})}</PageTitle>
          </>
        ) : (
          ''
        )}
        {postsId != null ? (
          <>
            <PageTitle breadcrumbs={cmsPost}>
              {intl.formatMessage({id: publishedKey === true ? 'Edit Posts' : 'Draft Posts'})}
            </PageTitle>
          </>
        ) : (
          ''
        )} */}
        <PageTitle breadcrumbs={cmsPost} backNavigate='/cms/blogs/posts'>
          {intl.formatMessage({ id: postsId != null ? 'Edit Posts' : 'Add Posts' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('success')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        {isResonseLoading !== '' && isResonseLoading == true && <IsLoader />}
        {/* <div className='card'> */}
        <form className='card' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
          {/* {displayLoader && <IsLoader />} */}
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              {/* {postsId != null ? (
                <>
                  <span className='card-label fw-bold font-17'>
                    {publishedKey === true ? 'Edit Posts' : 'Draft Posts'}
                  </span>
                </>
              ) : (
                ''
              )}
              {postsId == null && <span className='card-label fw-bold font-17'>Add Posts</span>} */}
              {postsId == null ? (
                <span className='card-label fw-bold font-17'>Add Posts</span>
              ) : (
                <span className='card-label fw-bold font-17'>Edit Posts</span>
              )}
            </h3>
            {/* end::Title */}
          </div>
          <div className='card-body pt-4'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-4'>
                  <label className='form-label'>
                    Blog Title<span className="text-danger">*</span>
                  </label>
                  <input
                    type='text'
                    id='title'
                    className={clsx('form-control', postsValidation.title)}
                    name='title'
                    value={posts.title}
                    placeholder='Type here...'
                    onChange={(e: any) => handleChange(e, 'title')}
                  />
                </div>
                <div className='mb-4'>
                  <label className='form-label'>
                    Short Description<span className="text-danger">*</span>
                  </label>
                  <textarea
                    className={clsx('form-control', postsValidation.short_desc)}
                    rows={3}
                    id='short_desc'
                    placeholder='Type here…'
                    value={posts.short_desc}
                    name='short_desc'
                    onChange={(e: any) => handleChange(e, 'short_desc')}
                  ></textarea>
                </div>
                <div className='md-4'>
                  <label className='form-label' id='published_date'>
                    Publish Date
                  </label>
                  <CustomDatePicker />
                </div>
              </div>
              <div className='col-md-6' id='cover_image'>
                {/* <div className='mb-4'>COVER IMAGE AREA</div> */}
                <Imagecropper
                  title='Cover Image'
                  getImage={getImage}
                  onImageChange={onImageChange}
                  imageRequire={imageRequire}
                  imageRequireMessage={imageRequireMessage}
                  imageSize={imgSizeInvalid}
                  cropData={cropData}
                  setCrop={setCropData}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mb-4'>
                <label className='form-label' id='description'>
                  Full Description<span className="text-danger">*</span>
                </label>
                <CustomSunEditor
                  data={posts.description}
                  handleChange={handleDescription}
                  fieldName='description'
                  validationTriggered={descriptionValidation}
                  message='Description Field is required'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label' id='category'>
                  Blog Category<span className="text-danger">*</span>
                </label>
                <AsyncPaginate
                    
                  loadOptions={fetchBlogCategory}
                  isSearchable
                  placeholder='Select'
                  isMulti
                  className={clsx('react-select-container', postsValidation.category)}
                  classNamePrefix='react-select'
                  name='category'
                  onChange={(e: any) => handleChange(e, 'category')}
                  value={blogCategory}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Select Author<span className="text-danger">*</span>
                </label>
                <AsyncPaginate
                    
                  loadOptions={fetchAuthor}
                  isSearchable
                  placeholder='Select'
                  className={clsx(
                    'react-select-container my-1 me-3 w-100%',
                    postsValidation.author
                  )}
                  classNamePrefix='react-select'
                  name='author'
                  onChange={(e: any) => handleChange(e, 'author')}
                  value={author}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            <div className='mt-4'></div>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <label className='form-check-label me-3'>Publish Post</label>
              <input
                checked={posts.is_published}
                id='accessStatus'
                onChange={(e) => {
                  let checkedEdit = $('#accessStatus').is(':checked')
                  if (checkedEdit == true) {
                    setAccessSts(true)
                    setPosts({ ...posts, ['is_published']: true })
                  } else {
                    setAccessSts(false)
                    setPosts({ ...posts, ['is_published']: false })
                  }
                }}
                value={posts.is_published}
                className='form-check-input h-30px w-50px'
                type='checkbox'
                name='layout-builder[layout][header][fixed][desktop]'
                defaultChecked={true}
              />
            </div>
          </div>
          <div className='card-footer py-2'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => navigate('/cms/blogs/posts')}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              // disabled={isButtonLoader !== 'Publish'}
              disabled={isButtonLoader !== 'Submit'}
              onClick={() => {
                addPost('is_published')
              }}
            >
              {isButtonLoader}
            </button>
            {/* hide draft options based on changes , kindly don't remove my commented code.  = by kriti */}
            {/* {postsId == null ? (
              <>
                <button
                  className='btn btn-secondary ms-auto'
                  name='is_published'
                  disabled={saveAsDraft !== 'Save as Draft'}
                  onClick={() => {
                    addPost('is_published_false')
                  }}
                >
                  {saveAsDraft}
                </button>
              </>
            ) : (
              ''
            )}
            {publishedKey === false ? (
              <>
                {' '}
                <button
                  className='btn btn-secondary ms-auto'
                  name='is_published'
                  disabled={saveDraft !== 'Save Draft'}
                  onClick={() => {
                    addPost('is_published_false')
                  }}
                >
                  {saveDraft}
                </button>
              </>
            ) : (
              ''
            )}

            {publishedKey === true ? (
              <>
                {' '}
                <EaButton
                  className='btn btn-secondary ms-auto'
                  isModal={true}
                  dataTarget='#ea_modal_draft'
                  btnName='Switch to Draft'
                ></EaButton>
              </>
            ) : (
              ''
            )} */}

            {/* {postsId != null ? (
              <>
                {' '}
                <button
                  className='btn btn-secondary ms-2'
                  data-bs-target='#ea_modal_delete_posts'
                  data-bs-toggle='modal'
                  name='is_published'
                  onClick={() => {
                    deleteItem()
                    setMessageHandler('successDelete')
                  }}
                >
                  Remove
                </button>
              </>
            ) : (
              ''
            )} */}
          </div>
        </form>
      </div>
      {/* </div> */}
      <EaModal
        modalTitle='Switching to Draft'
        onSubmit={draftHandle}
        actionBtnName={isLoading}
        children={
          <>
            <div className='mb-4'>
              <label className='form-label'>
                Switching to draft will unpublished the post from the blog. You can update and
                publish it later. Are you sure you want to continue?
              </label>
            </div>
          </>
        }
        id={'ea_modal_draft'}
      // actionBtnName='Switch to Draft'
      />
      <EaModal
        modalTitle='Delete Posts'
        closeAuto='closeDeleteEaModalBrand'
        children={<p className='mb-0'>Are you sure you want to delete this post?</p>}
        id={'ea_modal_delete_posts'}
        actionBtnName='Yes, Proceed'
        onSubmit={deletAPI}
      />
    </>
  )
}

export default AddPost
