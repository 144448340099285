import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {dailyStockInOutReport} from '../../../../_eaFruitsDms/apiFunctions/reports/dailyStockInReport'
import {apiBaseURL} from '../../../../_eaFruitsDms/helpers'
import {
  dateFormat,
  retrieveFilter,
  saveFilter,
  staticSearch,
} from '../../../../_eaFruitsDms/helpers/CommonHelpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getroleId, getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {stockInOutType} from '../../customers/WMcustomers/CustomerConst'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'

type Props = {
  className: string
}
const DailyStockOutReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Daily Stock-Out Report',
  }

  let customersFiltered: any = retrieveFilter(filters.oo_stockout_report)
  const toDayDate = dateFormat(moment())

  const initialState = {
    getIndusialOOs: customersFiltered?.getIndusialOOs || {label: 'From All OOs', value: ''},
    stockType: customersFiltered?.stockType || '',
    selected: customersFiltered?.selected || '',
    startDate: customersFiltered?.startDate || toDayDate,
    search: customersFiltered?.search || '',
    currentOffSet: customersFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const [getDailyStockInList, setDailyStockInList] = useState<any>([])
  const [count, setCount] = useState<any>('')
  const [next, setNext] = useState<any>('')
  const [prev, setPrev] = useState<any>('')
  const [selected, setSelected] = useState<number>(customersFiltered?.selected || 0)
  const [currentOffset, setCurrentOffset] = useState<any>(customersFiltered?.currentOffSet || '0')
  const [getLength, setLength] = useState<any>('1')
  const [getOOsList, setOOsList] = useState<any>([])
  const [timer, setTimer] = useState<any>(null)
  const [getIndusialOOs, setIndusialOOs] = useState<any>(
    customersFiltered?.getIndusialOOs || {
      label: 'From All OOs',
      value: '',
    }
  )
  const [listLoadder, setListLoader] = useState<any>(true)
  const [stockType, setStockType] = useState(customersFiltered?.stockType || '')
  const [stockOutOffset, setStockOutOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [OperationOfficerOption, setOperationOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [search, setSearch] = useState(customersFiltered?.search || '')

  const debounceSearch = useDebounce(search, 500)

  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    customersFiltered?.buttonDisable || false
  )
  const [startDate, setStartDate] = useState<any>(customersFiltered?.startDate || toDayDate)

  useEffect(() => {
    setStockOutOffset('')
  }, [searchSelect])

  useEffect(() => {
    setListLoader(true)
    dailyStockInOutReport(
      debounceSearch,
      '',
      stockType,
      'Out',
      '',
      startDate.apiDate,
      '',
      getIndusialOOs.value,
      getW_Id,
      '',
      '',
      limit,
      '0'
    ).then((res) => {
      setDailyStockInList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.oo_stockout_report, filterStates)
    if (
      getIndusialOOs.value == '' &&
      stockType == '' &&
      search == '' &&
      startDate.apiDate == toDayDate.apiDate
    ) {
      setButtonDisable(false)
    }

    // getDropDownValues()
  }, [getIndusialOOs, stockType, startDate, debounceSearch])

  const handlechangeStockType = (e: any) => {
    setStockType(e.value)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['stockType']: e.value})
  }

  const handlechangeOperationOfficer = (e: any) => {
    setIndusialOOs(e)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['getIndusialOOs']: e})
  }

  // Develop BY: Kriti
  // Description: fetch stock out type drop-down's options which is called on AsyncPaginate
  const defaultOptions: any = [
    {
      value: '',
      label: 'All Stock Out Types',
    },
    {
      value: 'LoadOutRequest',
      label: 'Load Out Request',
    },
    {
      value: 'WarehouseTransfer',
      label: 'Warehouse Transfer',
    },
  ]
  const fetchStockOutType = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: '',
        label: 'All Stock Out Types',
      },
      {
        value: 'LoadOutRequest',
        label: 'Load Out Request',
      },
      {
        value: 'WarehouseTransfer',
        label: 'Warehouse Transfer',
      },
    ]
    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  // Develop BY: Kriti
  // Description: fetch operational officer drop-down's options which is called on AsyncPaginate

  const fetchOperationOfficer = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const STOCK_OUT_API = `${apiBaseURL()}auth/users/team/list?role__id=${getroleId}&warehouse__id=${getW_Id}&limit=${limit}&search=${search}&offset=${stockOutOffset}`
    const Response = await getRequest(STOCK_OUT_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setStockOutOffset(newOffset)
      hasMore = true
    }

    if (OperationOfficerOption.length === 0) {
      options.push({
        label: 'From All OOs',
        value: '',
      })
    }

    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.first_name + ' ' + option.last_name,
          value: option.id,
        })
      })

      if (OperationOfficerOption.length > 0) {
        setOperationOption(OperationOfficerOption.concat(options))
      } else {
        setOperationOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      dailyStockInOutReport(
        '',
        '',
        '',
        'Out',
        '',
        '',
        '',
        '',
        getW_Id,
        '',
        '',
        limit,
        newOffset
      ).then((res) => {
        setDailyStockInList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      dailyStockInOutReport(
        '',
        '',
        '',
        'Out',
        '',
        '',
        '',
        '',
        getW_Id,
        '',
        '',
        limit,
        newOffset
      ).then((res) => {
        setDailyStockInList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      dailyStockInOutReport(
        '',
        '',
        '',
        'Out',
        '',
        '',
        '',
        '',
        getW_Id,
        '',
        '',
        limit,
        newOffset
      ).then((res) => {
        setDailyStockInList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }
  const searchList = (e: any) => {
    setSearch(e.target.value)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setFilterStates({...filterStates, ['search']: e.target.value})
  }

  const handleChangeReset = () => {
    setSearch('')
    setIndusialOOs({label: 'From All OOs', value: ''})
    setStockType('')
    setCurrentOffset(0)
    setSelected(0)
    setStartDate(toDayDate)
    let updatedState: any = {...filterStates}
    updatedState['getIndusialOOs'] = ''
    updatedState['stockType'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['startDate'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            let startDate = dateFormat(start._d)
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = startDate
            setFilterStates(updatedState)
            setStartDate(startDate)
          }}
          initialSettings={{
            startDate: moment(startDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            singleDatePicker: true,
            maxDate: moment(Date.now()),
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-140px'
            defaultValue={startDate}
            disabled={listLoadder}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Daily Stock-Out Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Daily Stock-Out Report'})}
      </PageTitle>
      <div className='card'>
        {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          {/* <CustomSearchBar /> */}
          <div className='col-12 col-md-auto mt-0'>
            <input
              type='text'
              placeholder='Search'
              className='form-control form-search'
              onChange={searchList}
              value={search}
              disabled={listLoadder}
            />
          </div>
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              {/* <DateRangePicker
								onCallback={(dateView: any) => {
									setFilterStates({ ...filterStates, ['startDate']: dateFormat(dateView._d)})
									setDate(dateFormat(dateView._d))
								}}
								initialSettings={{
									startDate: moment(startDate.apiDate).toDate(),
									alwaysShowCalendars: true,
									autoUpdateInput: true,
									maxDate: moment(Date.now()),
									cancelClass: "btn-secondary",
									singleDatePicker: true,
									drops: "auto",
									locale: {
										format: GLOBAL_DATE_FORMAT,
									},
								}}
							>
								<input type="text" className="form-control calendar my-1 me-3 w-140px" defaultValue={startDate}/>
							</DateRangePicker> */}
              <CustomDatePickerComponent />
              <AsyncPaginate
                loadOptions={fetchStockOutType}
                isSearchable
                className='react-select-container my-1 me-3 w-180px'
                classNamePrefix='react-select'
                placeholder='All Stock Out Types'
                onChange={handlechangeStockType}
                isDisabled={listLoadder}
                value={defaultOptions.find((option: any) => option.value == stockType)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchOperationOfficer}
                isSearchable
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                placeholder='From All OOs'
                // value={OperationOfficerOption.filter(
                // 	(option: any) => option.value === getIndusialOOs
                // )}
                value={getIndusialOOs}
                onChange={handlechangeOperationOfficer}
                isDisabled={listLoadder}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div
            className='table-responsive expandable'
            style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
            id='expandTable'
          >
            {getDailyStockInList.length == 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                <thead>
                  <tr className=' text-muted fw-500'>
                    <th className='min-w-100px py-2'>ID</th>
                    <th className='min-w-100px py-2'>Stock-Out Date & Time</th>
                    <th className='min-w-100px py-2'>Type</th>
                    <th>Request From</th>
                    <th className='min-w-100px py-2'>OO Name</th>
                  </tr>
                </thead>{' '}
                <tbody>
                  {getDailyStockInList.length > 0 &&
                    getDailyStockInList.map((item: any) => {
                      return (
                        <>
                          <tr
                            data-bs-toggle='collapse'
                            data-bs-target={`.row-collapse${item.id}`}
                            aria-controls={`rowCollapse${item.id}`}
                            className='cursor-pointer'
                          >
                            <td>
                              {' '}
                              {item.stock_id.includes('#') ? item.stock_id : '#' + item.stock_id}
                            </td>

                            <td>
                              {item.length !== 0 ? (
                                <>
                                  {dateFormat(item.stock_date).displayDate}{' '}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.stock_date, 'LT').displayDate}
                                  </p>
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {item.stock_type.length !== 0 ? (
                                <>{stockInOutType(item.stock_type)}</>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {item.stock_type === 'WarehouseTransfer'
                                ? item.receiver_warehouse_id && item.receiver_warehouse_id.name
                                : item.sales_representative
                                ? item.sales_representative.first_name +
                                  ' ' +
                                  item.sales_representative.last_name
                                : '-'}
                            </td>
                            <td>
                              {item.action_by.first_name} {item.action_by.last_name}
                            </td>
                          </tr>
                          <tr className='expandable-row'>
                            <td className='reset-expansion-style' colSpan={9}>
                              <div
                                className={`row-expansion-style collapse row-collapse${item.id} collapsible`}
                                id={`rowCollapse${item.id}`}
                                data-bs-parent='#expandTable'
                              >
                                <div>
                                  <table className='table table-bordered gs-4 mb-0'>
                                    <thead>
                                      <tr className='text-muted fw-500 bg-light2'>
                                        <th className='w-200px py-2'>Product SKU</th>
                                        <th className='w-120px py-2'>Batch Number</th>
                                        <th className='min-w-120px w-120px py-2'>Qty.</th>
                                        <th className='min-w-120px w-120px py-2'>Expiry Date</th>
                                        {/* <th className='min-w-120px py-2'>Added Date</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.stock_entries.map((data: any) => {
                                        return (
                                          <tr>
                                            <td>{data.product_id && data.product_id.name}</td>
                                            <td>
                                              {' '}
                                              {data.batch_number.includes('#')
                                                ? data.batch_number
                                                : '#' + data.batch_number}{' '}
                                            </td>
                                            <td>{data.qty}</td>
                                            <td>
                                              {' '}
                                              {item.length !== 0
                                                ? moment(data.expiry_date).format('DD MMM YYYY')
                                                : '-'}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {/* end first row expand */}

                          {/* end first row expand */}
                        </>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {/* <div className="table-info py-5">
				<div className="row g-0">
					<div className="col">
						{`Showing ${getDailyStockInList.length == 0 ? 0 : selected * 10 + 1} to ${getDailyStockInList.length == 0
							? 0
							: getDailyStockInList.length < 10
								? count
								: (selected + 1) * 10
							} of ${getDailyStockInList.length == 0 ? 0 : count} entries`}
					</div>
					{" "}
					<div className="col">
						<CustomPagination
							numberClick={numberClick}
							prev={prevPage}
							next={nextPage}
							count={getDailyStockInList.length == 0 ? 0 : count}
							selected={selected}
						/>
					</div>
				</div>
			</div> */}
      <CustomPaginate
        data={getDailyStockInList}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.oo_stockout_report}
        filterStates={filterStates}
        isDisabled={listLoadder}
      />
    </>
  )
}

export default DailyStockOutReport
