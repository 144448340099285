import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { apiBaseURL, checkPermissions, defaultPermission, KTSVG, retrieveFilter, saveFilter } from "../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest, postRequest, putRequest, } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import tenantConfiguration from "../../../../TenantVariables"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}
const RevokeAccessReason: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Less Stock-in Reasons',
  }
  const initialRow: any = {
    option: '',
    type: 'Customer_Suspension_Reasons',
  }
  const initialCustomerValidation: any = {
    option: '',
    type: '',
  }

  let reasonFiltered : any = retrieveFilter(filters.reason_master_revoke_access);

  const initialFiltersState = {
      selected: reasonFiltered?.selected || '',
      search: reasonFiltered?.search || '',
      currentOffSet: reasonFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [revokeReasonList, setRevokeReasonList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState<number>(reasonFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(reasonFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(reasonFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [DeleteId, setDeleteId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [revokeReason, setRevokeReason] = useState<any>(initialRow)
  const [revokeReasonValidation, setRevokeReasonValidation] =
    useState<any>(initialCustomerValidation)
  const [isButtonLoadder, setIsButtonLoader] = useState<any>('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(reasonFiltered?.buttonDisable || false) 

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchPermission()

    setTimeout(() => customerRevokeList().then(), timer)
  }, [currentOffset, debounceSearch])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_rejection_options")
    setPermission(res)
   }

  const customerRevokeList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}master/options-master/?type=Customer_Suspension_Reasons&offset=${currentOffset}&limit=${limit}&ordering=-id&search=${debounceSearch}`
    const response = await getRequest(API, true)
    setRevokeReasonList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.reason_master_revoke_access , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}
  // Develop BY: Kriti
  // Description: get single item function which is called edit button's on onClick event

  const singledata = async (item: any) => {
    setModalLoader(true)
    setId(item.id)
    const API = `${apiBaseURL()}master/options-master/${item.id
      }?limit=${limit}&ordering=-id&search=${search}&offset=${currentOffset}`

    const response = await getRequest(API, true)

    const formDetail = response
    let updatedData: any = { ...revokeReason }
    updatedData['option'] = formDetail.option
    setRevokeReason(updatedData)
    setModalLoader(false)
  }

  const clearFunctionAdd = () => {
    setRevokeReason(initialRow)
    setRevokeReasonValidation('')
    setErrorMessage('')
    setModalLoader(false)
  }

  // Develop BY: Kriti
  // Description: OnChange Handler

  const handleChange = (e: any) => {
    const inputName = e.target ? e.target.name : 'name'
    let inputValue = e.target ? e.target.value : e.value

    setRevokeReasonValidation({ ...revokeReasonValidation, [inputName]: '' })

    switch (inputName) {
      case 'option':
        setRevokeReason({ ...revokeReason, ['option']: inputValue })
        break
      default:
        setRevokeReason({ ...revokeReason, [inputName]: e.target.checked })
    }
  }

  // Develop BY: Kriti
  // Description: To validate user

  const validateForm = () => {
    let validations = { ...revokeReasonValidation }
    let isValid = true

    if (revokeReason.option.trim() == '') {
      validations.option = 'is-invalid'
      isValid = false
    }
    setRevokeReasonValidation(validations)
    return isValid
  }

  // Develop BY: Kriti
  // Description: Add and Update Revoke-access Reason function

  const apiCall = async () => {
    const RevokeInfo = { ...revokeReason }
    console.log('optiondata', RevokeInfo)

    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    if (requestHandler === 'add') {
      postRequest(`${apiBaseURL()}master/options-master/`, RevokeInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 201) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            customerRevokeList()
            $('#closeEaModalRevokeReason').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'non_field_errors') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    } else {
      putRequest(`${apiBaseURL()}master/options-master/${id}/`, RevokeInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 200) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            customerRevokeList()
            $('#closeEaModalRevokeReason').trigger('click')
            setTimeout(() => {
              setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
          }
          if (notify.failed) {
            if (notify.errorField == 'non_field_errors') {
              setErrorMessage(
                'Your added data is already exist in this system! Please try again with unique data.'
              )
            }
            // setIsAlertFailed(true)
          }
        }
      )
    }
  }

  // Develop BY: Kriti
  // Description: API Call function which is called on Eamodel's submit event

  const submitFunction = () => {
    const isValid = validateForm()
    if (isValid) {
      // setIsButtonLoader('Please Wait...')
      apiCall()
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Customer Revoke Access Reasons' />
      <div>
        <PageTitle breadcrumbs={[masterHeaderTitle]}>
          {intl.formatMessage({ id: 'Customer Revoke Access Reasons' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          // message={FormatMessage('success')}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('LESS_CUSTOMER_REVOKE_REASON.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('LESS_CUSTOMER_REVOKE_REASON.UPDATE')
                : messageHandler === 'successDelete' &&
                FormatMessage('LESS_CUSTOMER_REVOKE_REASON.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} value={search} disabled={listLoadder}/>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_revokereasons'
                    btnName='Add Revoke Access Reasons'
                    className="btn-primary me-3"
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-100px py-2'>Customer Revoke Access Reasons</th>
                        <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {revokeReasonList.map((item: any) => {
                        return (
                          <tr>
                            <td>{item.option}</td>

                            <td className='text-end'>
                              {permission.write && (
                                <a
                                  href='javascript:void(0)'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                  // onClick={singledata(item)}
                                  data-bs-toggle='modal'
                                  data-bs-target='#ea_modal_create_revokereasons'
                                  onClick={(event) => {
                                    singledata(item)
                                    setRequest('edit')
                                    setMessageHandler('successEdit')
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>
                              )}
                              {/* {permission.delete && (
                                <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={(event) => {
                                  deleteItem(item)
                                  setMessageHandler('successDelete')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </a>
                              )} */}
                            </td>
                          </tr>
                        )
                      })}

                      {(currentOffset == '0' || currentOffset == null) && (
                        <tr>
                          <td>Other</td>
                          <td className='text-end'></td>
                        </tr>
                      )}
                    </tbody>
                    {/* end::Table body */}
                  </table>
            </div>
          </div>
          {/* begin::Body */}
        </div>
        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${revokeReasonList.length == 0 ? 0 : selected * 10 + 1} to ${revokeReasonList.length == 0
                ? 0
                : revokeReasonList.length < 10
                  ? count
                  : (selected + 1) * 10
                } of ${revokeReasonList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={revokeReasonList.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div> */}
        <CustomPaginate
              data={revokeReasonList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.reason_master_revoke_access}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      </div>

      {/* <AddStockInReason getId={id} requestData={requestHandler} data={data} /> */}
      <EaModal
        id='ea_modal_create_revokereasons'
        modalTitle={
          requestHandler === 'add' ? 'Add Revoke Access Reasons' : 'Update Revoke Access Reasons'
        }
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        btnDisabled={isButtonLoadder === 'Submit' ? false : true}
        closeAuto='closeEaModalRevokeReason'
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Customer Revoke Access Reasons<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', revokeReasonValidation.option)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e)}
            name='option'
            value={revokeReason && revokeReason.option}
          />
        </div>
        <div className='mb-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default RevokeAccessReason
