import React, {useEffect, useState} from 'react'
import Slider from 'react-slick'
import {apiBaseURL, downloadFile, KTSVG} from '../../../../_eaFruitsDms/helpers'
import {deleteRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {decryptData} from '../../../../_eaFruitsDms/helpers/storageHelper'

const LightBoxCustom = ({addressTitle, imageData, addressType, fetchAddressDetails}: any) => {
  const userType: any = decryptData('user_type')
  const initialModelContent = {
    contentText: 'Are you sure, you want to delete this photo?',
    buttonText: 'Delete',
  }

  const [nav1, setNav1] = useState<any>(null)
  const [nav2, setNav2] = useState<any>(null)
  const [slider1, setSlider1] = useState<any>(null)
  const [slider2, setSlider2] = useState<any>(null)
  const [imageList, setImagesList] = useState<any>([])
  const [deleteModelContent, setDeleteModelContent] = useState(initialModelContent)

  useEffect(() => {
    if (imageData) {
      setImagesList(imageData)
    }
  }, [imageData])

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
    setNav2(slider2)
    localStorage.removeItem('location')
  }, [])

  const settingsMain = {
    dots: true,
  }

  const deleteImage = async () => {
    const imageIndex = localStorage.getItem('item_index')
    const customerId = localStorage.getItem('cId')
    if (imageIndex !== null && imageIndex !== undefined) {
      await deleteRequest(
        `${apiBaseURL()}customers/${customerId}/business-address-documents/${
          imageList[imageIndex].address_id
        }/delete/${imageList[imageIndex].id}`,
        true
      )
      if (imageList.length === 2) {
        setDeleteModelContent({
          ...deleteModelContent,
          contentText:
            "You are about to delete the last address verification photo. This will reject the customer's business address verification status and will need to upload new photos to verify once again. Are you sure you want to continue?",
          buttonText: 'Yes, Reject Verification Status',
        })
      } else {
        setDeleteModelContent(initialModelContent)
      }
      const updatedImageList: any = [...imageList]
      delete updatedImageList[imageIndex]
      setImagesList(updatedImageList.filter((el: any) => el !== undefined))
    }
  }

  return (
    <>
      <div
        key={addressType}
        className='modal fade'
        id={`exampleModalToggle_${addressType}`}
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-fullscreen gallery-modal'>
          <div className='modal-content'>
            <div className='modal-header border-0 py-2'>
              <h5 className='modal-title invisible' id='exampleModalToggleLabel'>
                Gallery
              </h5>
              <button
                type='button'
                className='btn-close btn-close-white'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={async () => {
                  await fetchAddressDetails()
                }}
              ></button>
            </div>
            <div className='modal-body'>
              <div className='col-md-7 mx-auto'>
                <div className='slider-wrapper'>
                  <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}
                    key={addressType}
                  >
                    {imageList.map((image: any, index: number) => (
                      <div className='slick-slide gallery-slide' key={image.id}>
                        <img className='slick-slide-image' src={image.src} alt='' />
                        <div className='slider-captions'>
                          <div className='d-sm-flex justify-content-center'>
                            <a
                              href='/customers/location'
                              rel='noopener noreferrer'
                              target='_blank'
                              className='btn btn-primary me-sm-2'
                              onClick={(e: any) => {
                                const location: any = {
                                  customerLocation: {
                                    latitude: parseFloat(image.customer_latitude),
                                    longitude: parseFloat(image.customer_longitude),
                                    info: image.customer_info,
                                  },
                                  photoLocation: {
                                    latitude: parseFloat(image.latitude),
                                    longitude: parseFloat(image.longitude),
                                    info: image.photo_info,
                                  },
                                }
                                localStorage.setItem('location', JSON.stringify(location))
                              }}
                            >
                              View Location On Map
                            </a>
                            <button
                              className='me-2 btn btn-primary'
                              onClick={() => downloadFile(image.src)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr091.svg'
                                className='me-0 ic h-24px w-24px download-icon text-white'
                              />
                            </button>
                            {userType !== 'SA' && !image.address_verify && (
                              <button
                                className='btn btn-danger'
                                data-bs-target={`#exampleModalToggle2_${addressType}`}
                                data-bs-toggle='modal'
                                onClick={() => {
                                  localStorage.setItem('item_index', index.toString())
                                  if (imageList.length === 1) {
                                    setDeleteModelContent({
                                      ...deleteModelContent,
                                      contentText:
                                        "You are about to delete the last address verification photo. This will reject the customer's business address verification status and will need to upload new photos to verify once again. Are you sure you want to continue?",
                                      buttonText: 'Yes, Reject Verification Status',
                                    })
                                  } else {
                                    setDeleteModelContent(initialModelContent)
                                  }
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='me-0 ic mr-0 h-24px w-24px text-white download-icon'
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  {/*<div className="thumbnail-slider-wrap">*/}
                  {/*    <Slider*/}
                  {/*        {...settingsThumbs}*/}
                  {/*        asNavFor={nav1}*/}
                  {/*        ref={slider => (setSlider2(slider))}*/}
                  {/*    >*/}
                  {/*        {imageData.map((image: any) =>*/}
                  {/*            <div className="slick-slide" key={image.id}>*/}
                  {/*                <img className="slick-slide-image" src={image.src} alt="" />*/}
                  {/*            </div>*/}
                  {/*        )}*/}
                  {/*        /!* {slidesData.map((slide) =>*/}

                  {/*            <div className="slick-slide" key={image.id}>*/}
                  {/*                <img className="slick-slide-image"
									 src={`https://picsum.photos/150/100?img=${image.id}`} />*/}
                  {/*            </div>*/}

                  {/*        )} *!/*/}
                  {/*    </Slider>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id={`exampleModalToggle2_${addressType}`}
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel2'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalToggleLabel2'>
                {addressType === 'store_other_photo' ? `Delete Photo` : `Delete ${addressTitle}`}?
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-target={`#exampleModalToggle_${addressType}`}
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>{deleteModelContent.contentText}</div>
            <div className='modal-footer'>
              <button
                className='btn btn-secondary'
                data-bs-target={`#exampleModalToggle_${addressType}`}
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                className='btn btn-danger'
                data-bs-target={
                  imageList.length > 1
                    ? `#exampleModalToggle_${addressType}`
                    : `#${addressType}_ea_modal_reject_reason`
                }
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                onClick={() => imageList.length > 1 && deleteImage()}
              >
                {deleteModelContent.buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LightBoxCustom
