import CryptoJS from 'crypto-js'

// NOTE: DO NOT CHANGE SECRET KEY
const SECRET_KEY = process?.env?.REACT_APP_STORAGE_SECRET_KEY || ''

export const encryptData = (name: string, data: any) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()

  localStorage.setItem(name, encrypted)
}

export const decryptData = (name: any) => {
  const encrypted: any = localStorage.getItem(name)

  if (encrypted) {
    try {
      const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8)

      return JSON.parse(decrypted)
    } catch (error) {
      localStorage.clear()
      window.location.reload()
      console.error('de-cost-err', error)
    }
  }

  return ''
}
