import {Chart, registerables} from 'chart.js'
import ReactDOM from 'react-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import {EafruitsI18nProvider} from './_eaFruitsDms/i18n/Eafruits18n'
import {AppRoutes} from './app/routing/appRoutes'
import tenantConfiguration from './TenantVariables'
import {getUserId} from './_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {getMyProfile} from './_eaFruitsDms/apiFunctions/master/master'
import {encryptData} from './_eaFruitsDms/helpers/storageHelper'

require(tenantConfiguration.cssBaseUrl + 'style.scss')
require(tenantConfiguration.cssBaseUrl + 'style.react.scss')

Chart.register(...registerables)
const queryClient = new QueryClient()

const handleLocalStorageUpdate = () => {
  getMyProfile(getUserId)
    .then((res: any) => {
      encryptData('user_type', res?.type)
    })
    .catch((err) => {})
}

// NOTE: DON'T TOUCH BELOW CODE
window.addEventListener('storage', (event) => {
  if (localStorage.getItem('eafruitsLogin') !== null) {
    if (event.key === 'user_type') {
      handleLocalStorageUpdate()
    }
  }
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <EafruitsI18nProvider>
      <AppRoutes />
    </EafruitsI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
