import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  fetchAsyncDropdownNew,
  isAdmin,
  isEmpty,
  isWarehouseManager,
  numberFormat,
  numberFormatWithCode,
  removeFilter,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {AsyncPaginate} from 'react-select-async-paginate'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../TenantVariables'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import moment from 'moment'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import {paymentStatus, paymentStatusOptions} from './invoiceConstant'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {decryptData} from '../../../_eaFruitsDms/helpers/storageHelper'

const SalesInvoices: React.FC<Props> = ({className}) => {
  const warehouseName: any = localStorage.getItem('warehouse_name')

  const intl = useIntl()
  const userType: any = decryptData('user_type') || ''
  const currentWarehouseId = !['sa', 'ts'].includes(userType.toLowerCase())
    ? localStorage.getItem('w_id')
    : ''
  const location = useLocation()
  const navigate = useNavigate()
  const headerTitle = {
    title:
      (isAdmin() && location.pathname === '/reports/sales-invoice-report') ||
      (checkUserType(['wm', 'oo', 'am']) && location.pathname === '/reports/sales-invoice-report')
        ? 'Sales Invoice Report'
        : 'Sales Invoices',
    subTitle:
      (isAdmin() && location.pathname === '/reports/sales-invoice-report') ||
      (checkUserType(['wm', 'oo', 'am']) && location.pathname === '/reports/sales-invoice-report')
        ? 'Sales Invoice Report'
        : 'Sales Invoices',
  }

  let salesInvoiceFiltered = retrieveFilter(filters.am_sales_invoice)
  let todayDate = dateFormat(moment())

  const initialState = {
    sr: salesInvoiceFiltered?.sr || {label: 'All Sales Reps', value: ''},
    brands: salesInvoiceFiltered?.brands || {label: 'All Brands', value: ''},
    customer: salesInvoiceFiltered?.customer || {label: 'All Customers', value: ''},
    warehouseId:
      salesInvoiceFiltered?.warehouseId ||
      (!['sa', 'ts', 'wm'].includes(userType.toLowerCase())
        ? {label: warehouseName, value: currentWarehouseId}
        : {label: 'All Warehouses', value: ''}),
    paymentMethod: salesInvoiceFiltered?.paymentMethod || '',
    startDate: salesInvoiceFiltered?.startDate || todayDate,
    endDate: salesInvoiceFiltered?.endDate || todayDate,
    search: salesInvoiceFiltered?.search || '',
    selected: salesInvoiceFiltered?.selected || '',
    currentOffset: salesInvoiceFiltered?.currentOffset || '',
    isHoreca: salesInvoiceFiltered?.isHoreca || false,
    buttonDisable: true,
  }

  const initialDateFilter = {
    startDate: salesInvoiceFiltered?.startDate || todayDate,
    endDate: salesInvoiceFiltered?.endDate || todayDate,
  }

  const [metaCounts, setMetaCounts] = useState({
    total_load_outs: 0,
    total_sales: 0,
    total_discount: 0,
    total_credits: 0,
    total_pay_later_today: 0,
  })
  const [searchSelect, setSearchSelect] = useState<string | undefined>('')
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [customer, setCustomer] = useState<any>(
    salesInvoiceFiltered?.customer || {label: 'All Customers', value: ''}
  )
  const [customerOffSet, setCustomerOffSet] = useState<any>('')
  const [sr, setSr] = useState<any>(
    salesInvoiceFiltered?.sr || {label: 'All Sales Reps', value: ''}
  )
  const [status, setStatus] = useState<any>(
    salesInvoiceFiltered?.paymentStatus || {
      label: 'All Payment Status',
      value: '',
    }
  )
  const [srOffSet, setSrOffSet] = useState<any>('')
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [brandsOffSet, setBrandsOffSet] = useState<any>('')
  const [brandsOption, setBrandsOption] = useState<any>([])
  const [search, setSearch] = useState<string>(salesInvoiceFiltered?.search || '')
  // const [warehouseId, setWarehouseId] = useState<any>(!['sa', 'ts', 'wm'].includes(userType.toLowerCase()) ? currentWarehouseId : "")
  const [warehouseId, setWarehouseId] = useState<any>(
    salesInvoiceFiltered?.warehouseId ||
      (!['sa', 'ts', 'wm'].includes(userType.toLowerCase())
        ? {label: warehouseName, value: currentWarehouseId}
        : {label: 'All Warehouses', value: ''})
  )
  const [brandsId, setBrandsId] = useState<any>(
    salesInvoiceFiltered?.brands || {label: 'All Brands', value: ''}
  )
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
  const [salesInvoices, setSalesInvoices] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(salesInvoiceFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [currentOffset, setCurrentOffset] = useState<number>(
    salesInvoiceFiltered?.currentOffset || '0'
  )
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    salesInvoiceFiltered?.buttonDisable || false
  )
  const [invoiceDate, setInvoiceDate] = useState<any>(initialDateFilter)
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [srReset, setSrReset] = useState<any>(new Date()) //TODO: somehow moment() not working here so new Date used for reset SR
  const [customers, setCustomers] = useState<any>([])
  const [isHoreca, setIsHoreca] = useState(salesInvoiceFiltered?.isHoreca || false)

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    localStorage.setItem('start-date', initialDateFilter.startDate.apiDate)
    localStorage.setItem('end-date', initialDateFilter.endDate.apiDate)
    localStorage.removeItem('from-report')
  }, [])

  useEffect(() => {
    setCustomerOffSet('')
    setWarehouseOffSet('')
  }, [searchSelect])

  useEffect(() => {
    fetchSalesInvoices().then()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffset'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)

    saveFilter(filters.am_sales_invoice, filterStates)

    localStorage.setItem('selected-wid', warehouseId?.value ?? '')

    if (
      // warehouseId.value === '' &&
      sr.value === '' &&
      brandsId.value === '' &&
      customer.value === '' &&
      status.value === '' &&
      debounceSearch === '' &&
      invoiceDate.endDate.apiDate === todayDate.apiDate &&
      invoiceDate.startDate.apiDate === todayDate.apiDate
    ) {
      setButtonDisable(false)
    }
  }, [
    customer,
    sr,
    currentOffset,
    status,
    invoiceDate,
    warehouseId,
    brandsId,
    isHoreca,
    debounceSearch,
  ])

  const fetchSalesInvoices = async () => {
    setDisplayLoader(true)

    let filterWarehouseId: any = warehouseId.value

    if (isAdmin() && location.pathname === '/warehouse/DC/singlewarehousename/sales') {
      filterWarehouseId = localStorage.getItem('wId')
    }

    const brand = location.pathname.includes('/reports/sales-invoice-report') ? brandsId.value : ''

    const response = await getRequest(
      `${apiBaseURL()}payment/sales-invoices?limit=${limit}&offset=${currentOffset}&search=${search}&is_horeca_customer=${
        location.pathname.includes('/reports/sales-invoice-report')
          ? isHoreca === true
            ? true
            : ''
          : ''
      }&customer_id=${customer.value}&sr_id=${sr.value}&order_payment_status=${
        status.value
      }&delivery_date_duration_after=${
        invoiceDate.startDate.apiDate
      }&delivery_date_duration_before=${invoiceDate.endDate.apiDate}&warehouse_id=${checkEmpty(
        filterWarehouseId,
        ''
      )}&brand_id=${brand}`,
      true
    )

    if (response.results) {
      if (!isEmpty(response.meta)) {
        setMetaCounts({
          total_load_outs: isEmpty(response.meta.total_load_outs)
            ? 0
            : response.meta.total_load_outs,
          total_sales: isEmpty(response.meta.total_sales) ? 0 : response.meta.total_sales,
          total_discount: isEmpty(response.meta.total_discount) ? 0 : response.meta.total_discount,
          total_credits: isEmpty(response.meta.total_credits) ? 0 : response.meta.total_credits,
          total_pay_later_today: isEmpty(response.meta.total_pay_later_today)
            ? 0
            : response.meta.total_pay_later_today,
        })
      }
      setSalesInvoices(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCustomers = async (search?: string) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let wId: any = localStorage.getItem('w_id') || ''
    // let wId: any = !checkUserType(['sa', 'wm']) ? localStorage.getItem('w_id') : ''

    if (typeof wId === 'object') {
      wId = wId.value
    }

    if (!isEmpty(warehouseId)) {
      wId = warehouseId
      if (typeof warehouseId === 'object') {
        wId = warehouseId.value
      }
    }

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&search=${searchSelect}&limit=${limit}&offset=${customerOffSet}&warehouse_id=${
        !isEmpty(wId) ? wId : ''
      }`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (customerResponse.results) {
      if (customers.length === 0) {
        options.push({
          label: 'All Customers',
          value: '',
        })
      }

      if (customerResponse.next !== null) {
        const queryParams = new URLSearchParams(customerResponse.next)
        let newOffset: any = queryParams.get('offset')
        setCustomerOffSet(newOffset)
        hasMore = true
      }

      if (customerResponse.results.length > 0) {
        customerResponse.results.map((option: any) => {
          options.push({
            label: option.business_name + ', ' + option.street,
            value: option.id,
          })
        })
      }
    }

    if (customers.length > 0) {
      setCustomers(customers.concat(options))
    } else {
      setCustomers(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSrs = async (search?: string) => {
    let wId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id') || ''

    if (!isEmpty(warehouseId)) {
      wId = typeof warehouseId === 'object' ? warehouseId.value : warehouseId
    }
    if (!isEmpty(warehouseId.value)) {
      wId = warehouseId.value
    }

    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/team/list?ordering=first_name&warehouse__id=${isEmpty(wId) ? '' : wId}&type=sr`,
      search,
      setSearchSelect,
      'first_name',
      'last_name',
      true,
      'All Sales Reps'
    )
  }

  const fetchBrands = async (search?: string) => {
    return await fetchAsyncDropdown(
      brandsOffSet,
      setBrandsOffSet,
      brandsOption,
      setBrandsOption,
      `master/product/brandSKU?ordering=name`,
      search,
      setSearchSelect,
      'name',
      true,
      'All Brands'
    )
  }

  const fetchPaymentStatus = () => {
    return {
      options: paymentStatusOptions,
      hasMore: false,
    }
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setSearchSelect('')
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    const currentMomentObj: any = moment()
    switch (inputName) {
      case 'warehouse':
        localStorage.setItem('selected-wid', inputValue?.value ?? '')
        setFilterStates({...filterStates, ['warehouseId']: e})
        setWarehouseId(e)
        setCustomerOffSet('')
        setCustomers([])
        setCustomerReset(currentMomentObj)
        setSrOffSet('')
        setSrReset(new Date())
        setSalesRepsOption([])
        break
      case 'customer':
        setCustomer(e)
        setFilterStates({...filterStates, ['customer']: e})
        break
      case 'sr':
        setSr(e)
        setFilterStates({...filterStates, ['sr']: e})
        break
      case 'status':
        setStatus(e)
        setFilterStates({...filterStates, ['status']: inputValue})
        break
      case 'brands':
        setBrandsId(e)
        setFilterStates({...filterStates, ['brands']: e})
        break
      case 'isHoreca':
        setFilterStates({...filterStates, ['isHoreca']: !isHoreca})
        break
    }
  }

  const handleChangeReset = () => {
    localStorage.setItem('selected-wid', '')
    localStorage.setItem('start-date', '')
    localStorage.setItem('end-date', '')
    removeFilter(filters.am_sales_invoice)
    setSearch('')
    setSr({label: 'All Sales Reps', value: ''})
    setCustomer({label: 'All Customers', value: ''})
    setWarehouseId({label: 'All Warehouses', value: ''})
    setBrandsId({label: 'All Brands', value: ''})
    setStatus({
      label: 'All Payment Status',
      value: '',
    })
    setCurrentOffset(0)
    setSelected(0)
    setIsHoreca(false)

    let updatedDateState: any = {...invoiceDate}
    updatedDateState['startDate'] = todayDate
    updatedDateState['endDate'] = todayDate
    setInvoiceDate(updatedDateState)

    let updatedState: any = {...filterStates}
    updatedState['sr'] = ''
    updatedState['customer'] = ''
    updatedState['warehouseId'] = ''
    updatedState['paymentMethod'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    updatedState['brands'] = ''
    updatedState['isHoreca'] = false
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setSearchSelect('')
            setCurrentOffset(0)
            setSelected(0)
            setButtonDisable(true)
            const callBakeDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            localStorage.setItem('start-date', callBakeDate.startDate.apiDate)
            localStorage.setItem('end-date', callBakeDate.endDate.apiDate)
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBakeDate.startDate
            updatedState['endDate'] = callBakeDate.endDate
            setFilterStates(updatedState)
            setInvoiceDate(callBakeDate)
          }}
          initialSettings={{
            startDate: moment(invoiceDate.startDate.apiDate).toDate(),
            endDate: moment(invoiceDate.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-1 w-190px'
            defaultValue={invoiceDate}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  const onNavigate = (invoice: any) => {
    saveFilter(filters.am_sales_invoice, filterStates)
    if (location.pathname === '/warehouse/DC/singlewarehousename/sales') {
      navigate('/sales-invoices/sales-invoice-details', {
        state: {invoiceId: invoice.id},
      })
    } else {
      localStorage.setItem('from-report', 'true')
      if (isWarehouseManager()) {
        navigate('sales-invoice-details', {state: {invoiceId: invoice.id}})
      } else {
        navigate('sales-invoice-details', {state: {invoiceId: invoice.id}})
      }
    }
  }

  return (
    <>
      <EATitle
        title={
          (isAdmin() && location.pathname === '/reports/sales-invoice-report') ||
          (checkUserType(['wm', 'oo', 'am']) &&
            location.pathname === '/reports/sales-invoice-report')
            ? 'Sales Invoice Report'
            : 'Sales Invoices'
        }
      />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id:
            (isAdmin() && location.pathname === '/reports/sales-invoice-report') ||
            (checkUserType(['wm', 'oo', 'am']) &&
              location.pathname === '/reports/sales-invoice-report')
              ? 'Sales Invoice Report'
              : 'Sales Invoices',
        })}
      </PageTitle>
      {/* <PageDescription>test</PageDescription> */}

      <div className={`card ${className}`}>
        {/* {displayLoader && <IsLoader />} */}
        {/* begin::Header */}
        <div className='card-header border-0 py-3 px-7 align-items-center'>
          <div className='my-2 mb-3'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)

                let updatedState: any = {...filterStates}
                updatedState['search'] = e.target.value
                setFilterStates(updatedState)
              }}
              isDisable={displayLoader}
            />
          </div>

          <div className=''>
            <div className='card-toolbar d-flex my-0'>
              {location.pathname.includes('/reports/sales-invoice-report') && (
                <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                  <input
                    className='form-check-input widget-13-check'
                    name='isHoreca'
                    checked={isHoreca}
                    onChange={(e: any) => {
                      setIsHoreca(!isHoreca)
                      handleFilterChange(e, 'isHoreca')
                    }}
                    type='checkbox'
                    disabled={false}
                    value=''
                    id='isHoreca'
                  />
                  <label className='form-check-label' htmlFor='isHoreca'>
                    HORECA Only
                  </label>
                </div>
              )}
              <div className=''>
                <CustomDatePickerComponent />
              </div>

              {checkUserType(['sa', 'wm']) &&
                ['/sales-invoices', '/reports/sales-invoice-report'].includes(
                  location.pathname
                ) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    isDisabled={displayLoader}
                    isSearchable
                    className='react-select-container my-1 me-1 w-180px'
                    classNamePrefix='react-select'
                    placeholder='All Warehouses'
                    // value={warehouses.find((option: any) => option.value === warehouseId)}
                    value={warehouseId}
                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}

              <AsyncPaginate
                key={customerReset}
                loadOptions={fetchCustomers}
                isDisabled={displayLoader}
                className='react-select-container my-1 me-1 w-200px'
                classNamePrefix='react-select'
                placeholder='All Customers'
                value={customer}
                onChange={(e: any) => handleFilterChange(e, 'customer')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                key={srReset}
                loadOptions={fetchSrs}
                isDisabled={displayLoader}
                className='react-select-container my-1 me-1 w-150px'
                classNamePrefix='react-select'
                placeholder='All Sales Reps'
                value={sr}
                onChange={(e: any) => handleFilterChange(e, 'sr')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              {location.pathname.includes('/reports/sales-invoice-report') && (
                <AsyncPaginate
                  loadOptions={fetchBrands}
                  isDisabled={displayLoader}
                  isSearchable
                  className='react-select-container my-1 me-1 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Brands'
                  value={brandsId}
                  onChange={(e: any) => handleFilterChange(e, 'brands')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              )}
              <AsyncPaginate
                loadOptions={fetchPaymentStatus}
                isDisabled={displayLoader}
                className='react-select-container my-1 me-3 w-175px'
                classNamePrefix='react-select'
                onChange={(e: any) => handleFilterChange(e, 'status')}
                placeholder='All Payment Status'
                value={status}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          {checkUserType(['sa', 'wm', 'oo', 'am']) &&
            location.pathname === '/reports/sales-invoice-report' && (
              <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='success'
                    title={numberFormat(metaCounts.total_load_outs).displayFormat}
                    //  path='/warehouse/DC'
                    description='Total Loadouts'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='info'
                    title={numberFormatWithCode(metaCounts.total_sales).displayFormat}
                    description='Total Sales'
                    //   path='/products'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='dark'
                    title={numberFormatWithCode(metaCounts.total_discount).displayFormat}
                    description='Total Discount'
                    //  path='/customers'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='warning'
                    title={numberFormatWithCode(metaCounts.total_credits).displayFormat}
                    description='Total Credits Sales'
                    //  path='/reports/sales-report'
                  />
                </div>

                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='danger'
                    title={numberFormatWithCode(metaCounts.total_pay_later_today).displayFormat}
                    description='Total Pay Later Today Sales'
                    //  path='/reports/sales-report'
                  />
                </div>
              </div>
            )}
          <div className='table-responsive'>
            {/* begin::Table */}
            {displayLoader ? (
              <FetchingRecords />
            ) : salesInvoices.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-90px'>Invoice No.</th>
                    <th className='min-w-100px w-100px'>Order No.</th>
                    <th className='min-w-120px'>Delivery Date & Time</th>
                    <th className='min-w-160px'>Customer Name</th>
                    <th className='w-150px min-w-150px'>SR Name</th>
                    <th className='w-170px'>EFD Receipt Number</th>
                    <th className='min-w-120px'>Payable Amt.</th>
                    <th className='min-w-120px'>Received Amt.</th>
                    <th className='min-w-120px'>Due Amt.</th>
                    <th className='min-w-100px'>Payment Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {salesInvoices.map((invoice: any) => {
                    return (
                      <tr className='cursor-pointer'>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {invoice.invoice_no ? '#' + invoice.invoice_no : '-'}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          #{invoice.order_no}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {dateFormat(invoice.delivery_date_time).displayDate}
                          <div>{dateFormat(invoice.delivery_date_time, 'LT').displayDate}</div>
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {isEmpty(invoice.customer_name)
                            ? ''
                            : `${invoice.customer_name} ${
                                !isEmpty(invoice.customer_street) &&
                                '(' + invoice.customer_street + ')'
                              }`}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {isEmpty(invoice.sr_name) ? '-' : invoice.sr_name}
                        </td>
                        <td
                          onClick={() => {
                            if (isEmpty(invoice.tra_verification_code)) {
                              onNavigate(invoice)
                            }
                          }}
                        >
                          {isEmpty(invoice.tra_verification_code) ? (
                            '-'
                          ) : (
                            <>
                              <div>
                                {' '}
                                {invoice.tra_verification_code}
                                {!isEmpty(invoice.tra_verification_url) && (
                                  <>
                                    <a
                                      href={invoice.tra_verification_url}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      <span className='ms-2 cursor-pointer'>
                                        <i className='fa fa-info-circle' aria-hidden='true'></i>
                                      </span>
                                    </a>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {numberFormatWithCode(invoice.total_payable).displayFormat}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {numberFormatWithCode(invoice.total_received_amount).displayFormat}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {numberFormatWithCode(invoice.total_outstanding_amount).displayFormat}
                        </td>
                        <td
                          onClick={() => {
                            onNavigate(invoice)
                          }}
                        >
                          {isEmpty(invoice.order_payment_status)
                            ? '-'
                            : paymentStatus[invoice.order_payment_status]}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
            {/* end::Table */}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={salesInvoices}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={displayLoader}
        saveFilterName={filters.am_sales_invoice}
        filterStates={filterStates}
      />
    </>
  )
}

export default SalesInvoices
