import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  GroupsCreate,
  GroupsDelete,
  GroupsList,
  GroupsSingle,
  GroupsUpdate,
} from '../../../../_eaFruitsDms/apiFunctions/master/master'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isEmpty,
  isModalValidation,
  KTSVG,
  numberFormat,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import formateMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ValidationHandler from '../../../../_eaFruitsDms/helpers/components/validationHandler'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import EaDropDown from '../../../../_eaFruitsDms/layout/components/dropdown/dropdown'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import Imagecropper from '../../../../_eaFruitsDms/layout/components/forms/Cropper'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import TableProduct from './components/tableProduct'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import Tooltip from 'react-bootstrap/Tooltip'
import {OverlayTrigger} from 'react-bootstrap'

type Props = {
  className: string
}

const ProductGroups: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Product Group',
  }
  const measurementUnits = [
    {
      value: 'Kilogram',
      label: 'Kilogram',
    },
    {
      value: 'Crate',
      label: 'Crate',
    },
    {
      value: 'Gram',
      label: 'Gram',
    },
    {
      value: 'Punnet',
      label: 'Punnet',
    },
  ]
  const [validationMeasutement, setValidationMeasurementUnit] = useState('')

  // all states
  const [id, setId] = useState('')
  const [getList, setGetList] = useState<any>([])
  const [groupName, setGroupName] = useState('')
  const [measurementUnit, setMeasurementUnit] = useState('')
  const [measurement, setMeasurement] = useState<any>('')
  const [groupImage, setGroupImage] = useState('')
  const [groupImageEdit, setGroupImageEdit] = useState('')
  const [groupNameEdit, setGroupNameEdit] = useState('')
  const [groupNameDelete, setGroupNameDelete] = useState('')
  const [img, setImg] = useState<any>('')
  const [imgEdit, setImgEdit] = useState<any>('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [validationName, setValidationName] = useState('')
  const [validationImage, setValidationImage] = useState('')
  const [validationNameEdit, setValidationNameEdit] = useState('')
  const [validationImageEdit, setValidationImageEdit] = useState('')
  const [imageSize, setImageSize] = useState('')
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [imageSizeEdit, setImageSizeEdit] = useState('')
  const [imageValidationEdit, setImageValidationEdit] = useState<boolean>(true)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [validationSelcetedvat, validationSetVat] = useState('')
  const [selcetedvat, setVat] = useState('')
  const [getLength, setLength] = useState('1')
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [cropDataEdit, setCropDataEdit] = useState(defaultSrc)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [shortDescription, setDescription] = useState('')
  const [shortDescriptionValidation, setDescriptionValidation] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [currentAllowedGrams, setCurrentAllowedGrams] = useState<any>('')
  const [allowedGrams, setAllowedGrams] = useState<any>('')
  const [allowedGramsValidation, setAllowedGramsValidation] = useState<string>('')

  useEffect(() => {
    fetchPermission()

    setListLoader(true)
    GroupsList('10', '0').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setPrev(res.prev)
      setListLoader(false)
    })
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions('masters_products')
    setPermission(res)
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setGroupImage(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setImageSize('text-danger')
            setImageValidation(false)
          } else {
            setImageValidation(true)
            setImageSize('')
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const getPropsDelete = (val: any) => {
    setId(val.id)
    setGroupNameDelete(val.name)
  }

  const onImageChangeEdit = (e: any) => {
    setImageSizeEdit('')
    if (e.target.files[0]) {
      const [file] = e.target.files
      let size = e.target.files[0].size / 1024 / 1024
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImgEdit(URL.createObjectURL(file))
        setGroupImageEdit(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setImageSizeEdit('text-danger')
            setImageValidationEdit(false)
          } else {
            setImageValidationEdit(true)
            setImageSizeEdit('')
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const addFunction = () => {
    // api validation.
    let isValid = true

    let navigateField = {
      groupName: groupName,
      shortDescription: shortDescription,
      measurementUnit: measurementUnit,
      parentCategory: parentCategory,
      category: category,
      allowedGrams: allowedGrams,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (groupName.trim() === '') {
      isValid = false
      setValidationName('is-invalid')
    }

    if (measurementUnit === '') {
      isValid = false
      setValidationMeasurementUnit('is-invalid')
    }

    //  if (selcetedvat === '') {
    //    isValid = false
    //    validationSetVat('is-invalid')
    //  }

    if (shortDescription === '') {
      isValid = false
      setDescriptionValidation('is-invalid')
    }

    if (parentCategory === '') {
      isValid = false
      setParValidation('is-invalid')
    }
    if (category === '') {
      isValid = false
      setCatValidation('is-invalid')
    }

    if (isSalesQuantityControl) {
      if (['', 0, '0'].includes(allowedGrams)) {
        isValid = false
        setAllowedGramsValidation('is-invalid')
      }
    }

    if (isValid) {
      setValidationName('')
      setValidationMeasurementUnit('')
      setDescriptionValidation('')
      validationSetVat('')
      setParValidation('')
      setCatValidation('')

      if (img.trim() !== '') {
        if (imageValidation) {
          setDisableBtn(true)
          setIsLoading('Please Wait..')
          setValidationImage('')
          groupCreateApi()
        }
      } else {
        setDisableBtn(true)
        setIsLoading('Please Wait..')
        groupCreateApi()
      }
    }
  }

  // add api function
  const groupCreateApi = () => {
    GroupsCreate(
      groupName,
      measurementUnit,
      groupImage,
      shortDescription,
      selcetedvat,
      category.value,
      isSalesQuantityControl,
      allowedGrams
    ).then((notify) => {
      setDisableBtn(false)
      setIsLoading('Submit')
      setValidationImage('')
      if (notify.success) {
        clearFunctionAdd()
        setListLoader(true)
        GroupsList('10', '0', search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setSelected(0)
          setNext(res.next)
          setPrev(res.prev)
          setListLoader(false)
        })
        $('#ea_close_create_product_group').trigger('click') // modal close
        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'name') {
        setErrorMessage(
          'Your added group name already exists in this system! Please try again with a unique group name.'
        )
      }
      if (notify.failed) {
        // setIsAlertFailed(true)
      }
    })
  }

  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setId(val.id)
    setModalLoader(true)
    GroupsSingle(val.id).then((res: any) => {
      setGroupNameEdit(res.name)
      setMeasurementUnit(res.measurement_unit)
      const unit = measurementUnits.find((option: any) => option.value === res.measurement_unit)

      if (unit) {
        setMeasurement(unit.label)
      }

      setVat(res.product_vat)
      setIsSalesQuantityControl(res.is_max_qty_limit_active)
      setAllowedGrams(res.sales_max_size_limit)

      res.short_description && setDescription(res.short_description)
      setImgEdit(res.group_image)
      if (res.group_image !== null) {
        setCropDataEdit(res.group_image)
      } else {
        setCropDataEdit(defaultSrc)
      }

      if (res.category) {
        getCategory(res.category.parent_category?.id)
        setParentCategory({
          label: res.category.parent_category?.name,
          value: res.category.parent_category?.id,
        })
        setCategory({label: res.category.name, value: res.category.id})
      }
      setModalLoader(false)
    })
  }

  const updateFunction = () => {
    let navigateField = {
      groupNameEdit: groupNameEdit,
      shortDescriptionEdit: shortDescription,
      measurementUnit: measurementUnit,
      parentCategory: parentCategory,
      category: category,
      allowedGrams: allowedGrams,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])
    let isValid: boolean = true
    if (isSalesQuantityControl) {
      if (['', 0, '0'].includes(allowedGrams)) {
        isValid = false
        setAllowedGramsValidation('is-invalid')
      }
    }

    if (isValid) {
      if (groupNameEdit !== '') {
        setValidationNameEdit('')
        if (shortDescription !== '') {
          setDescriptionValidation('')
          //   if (selcetedvat !== '') {
          //  validationSetVat('')
          if (parentCategory !== '') {
            if (category !== '') {
              if (imgEdit !== '') {
                if (imageValidationEdit) {
                  setValidationImageEdit('')
                  setDisableBtn(true)
                  setIsLoading('Please Wait..')
                  GroupsUpdate(
                    groupNameEdit,
                    measurementUnit,
                    groupImageEdit,
                    shortDescription,
                    selcetedvat,
                    category.value,
                    isSalesQuantityControl,
                    allowedGrams,
                    id
                  ).then((notify) => {
                    setDisableBtn(false)
                    setIsLoading('Submit')
                    if (notify.success) {
                      setIsAlertSuccess(true)
                      clearFunctionEdit()
                      setListLoader(true)
                      GroupsList('10', '0', search).then((res) => {
                        setGetList(res.data)
                        setCount(res.count)
                        setNext(res.next)
                        setSelected(0)
                        setListLoader(false)
                        if (res.data.length == 0) {
                          setLength('2')
                        } else {
                          setLength('1')
                        }
                        setPrev(res.prev)
                      })
                      $('#ea_update_create_product_group').trigger('click') // modal close
                      setTimeout(() => {
                        setIsAlertFailed(false)
                        setIsAlertSuccess(false)
                      }, 1500)
                    }
                    if (notify.failed) {
                      // setIsAlertFailed(true)
                    }

                    if (notify.errorField == 'name') {
                      setErrorMessage(
                        'Your added group name already exists in this system! Please try again with a unique group name.'
                      )
                    }
                  })
                }
              } else {
                setValidationImageEdit('file-icon-inValid')
              }
            } else {
              setCatValidation('is-invalid')
            }
          } else {
            setParValidation('is-invalid')
          }
          //   }
          //   else {
          //     validationSetVat('is-invalid')
          //   }
        } else {
          setDescriptionValidation('is-invalid')
        }
      } else {
        setValidationNameEdit('is-invalid')
      }
    }
  }

  const deleteFuntion = () => {
    GroupsDelete(id).then((notify) => {
      if (notify.success) {
        setListLoader(true)
        GroupsList('10', currentOffset, search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setListLoader(false)
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      $('#ea_close_delete_product_group').trigger('click') // modal close
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
    })
  }

  const clearFunctionAdd = () => {
    // clear add modal state
    setErrorMessage('')
    setDescription('')
    setDescriptionValidation('')
    setCropData(defaultSrc)
    setGroupImage('')
    setGroupName('')
    setImg('')
    setImageValidation(false)
    setValidationName('')
    setMeasurement('')
    setMeasurementUnit('')
    setValidationImage('')
    setIsSalesQuantityControl(false)
    setAllowedGrams('')
    setCurrentAllowedGrams('')
    setImageSize('')
    setValidationMeasurementUnit('')
    setVat('')
    validationSetVat('')
    setParentCategoryOffset('')
    setParValidation('')
    setParentCategory('')
    setCategory('')
    setCatValidation('')
    setCategoryList('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setDescription('')
    setErrorMessage('')
    setGroupNameEdit('')
    setIsSalesQuantityControl(false)
    setMeasurementUnit('')
    setAllowedGrams('')
    setMeasurement('')
    setCurrentAllowedGrams('')
    setDescriptionValidation('')
    setCropDataEdit(defaultSrc)
    setGroupImageEdit('')
    setImgEdit('')
    setImageValidationEdit(true)
    setValidationNameEdit('')
    setValidationImageEdit('')
    setImageSizeEdit('')
    setValidationMeasurementUnit('')
    validationSetVat('')
    setVat('')
    setParentCategoryOffset('')
    setParValidation('')
    setParentCategory('')
    setCategory('')
    setCatValidation('')
    setCategoryList('')
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      GroupsList('10', '0', e.target.value).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
      })
    }, 500)
    setTimer(newTimer)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      GroupsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      GroupsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      GroupsList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const getImage = (val: any) => {
    setGroupImage(val)
  }

  const getImageEdit = (val: any) => {
    setGroupImageEdit(val)
  }

  const [parentCategoryOffset, setParentCategoryOffset] = useState('')
  const [parentCategoryList, setParentCategoryList] = useState('')
  const [ParentCategorySearch, setParentCategorySearch] = useState('')
  const [parentCategory, setParentCategory] = useState<any>('')
  const [categorylist, setCategoryList] = useState<any>('')
  const [category, setCategory] = useState<any>('')
  const [isSalesQuantityControl, setIsSalesQuantityControl] = useState<boolean>(false)
  const [parValidation, setParValidation] = useState<any>('')
  const [catValidation, setCatValidation] = useState<any>('')

  const fetchParentCategory = async (search: any) => {
    // let url = `master/product/category?ordering=name`
    // const response = await fetchAsyncDropdown(parentCategoryOffset, setParentCategoryOffset, parentCategoryList, setParentCategoryList, url, search, setParentCategorySearch)
    if (search != '') {
      setParentCategorySearch(search)
    }

    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/category?ordering=name&search=${search}&limit=${10}&offset=${parentCategoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setParentCategoryOffset(newOffset)
      hasMore = true
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (option.parent_category == null) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    if (parentCategoryList.length > 0) {
      setParentCategoryList(parentCategoryList.concat(options))
    } else {
      setParentCategoryList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchParentCategoryAdd = async (search: any) => {
    // let url = `master/product/category?ordering=name`
    // const response = await fetchAsyncDropdown(parentCategoryOffset, setParentCategoryOffset, parentCategoryList, setParentCategoryList, url, search, setParentCategorySearch)
    if (search != '') {
      setParentCategorySearch(search)
    }

    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/category?ordering=name&search=${search}&limit=${10}&offset=${parentCategoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setParentCategoryOffset(newOffset)
      hasMore = true
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (option.parent_category == null) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    if (parentCategoryList.length > 0) {
      setParentCategoryList(parentCategoryList.concat(options))
    } else {
      setParentCategoryList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  useEffect(() => {
    setParentCategoryOffset('')
  }, [ParentCategorySearch])

  const getCategory = async (id: any) => {
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/category?orderning=name&parent_category__id=${id}&limit=1000`,
      true
    )

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (option.parent_category !== null) {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
      setCategoryList(options)
    }
  }

  return (
    <>
      <EATitle title='Product Groups' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({id: 'Product Group'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRODUCT.GROUP.ADD')
            : FormatMessage('PRODUCT.GROUP.UPDATE')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />
      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} id={'searcList_product_groups'} />
            {permission.write && (
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                <EaButton
                  isModal={true}
                  onClick={() => {
                    setMessageHandler('successAdd')
                  }}
                  dataTarget='#ea_modal_create_product_group'
                  btnName='Add Group'
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableProduct
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                getPropsDelete={getPropsDelete}
                tableList={getList}
                getPropsEdit={getProps}
              />
            )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${
              getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
            } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div>

      {/* add group */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionAdd}
        closeAuto={'ea_close_create_product_group'}
        onSubmit={addFunction}
        id='ea_modal_create_product_group'
        modalTitle='Add Product Group'
      >
        <div className='mb-3'>
          <label className='form-label'>
            Group Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='groupName'
            className={clsx('form-control', validationName)}
            value={groupName}
            onChange={(e) => {
              setGroupName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationName(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Short Description<span className='text-danger'>*</span>
          </label>
          <textarea
            id='shortDescription'
            className={`form-control ${shortDescriptionValidation}`}
            rows={2}
            placeholder='Type here…'
            onChange={(e) => {
              setDescription(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setDescriptionValidation(validation)
            }}
            value={shortDescription}
            name='description'
          ></textarea>
        </div>
        <div className='mb-4'>
          <label className='form-label'>VAT (in %)</label>
          <input
            type='text'
            className={clsx('form-control', validationSelcetedvat)}
            placeholder='0.00'
            value={selcetedvat}
            onChange={(e) => {
              const re = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setVat(e.target.value)
                // const validation = ValidationHandler(e.target.value, 'is-invalid')
                // validationSetVat(validation)
              }
            }}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label' id='measurementUnit'>
            Measurement Unit<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={() => {
              return {
                options: measurementUnits,
                hasMore: false,
              }
            }}
            isSearchable
            className={`react-select-container  ${validationMeasutement}`}
            classNamePrefix='react-select'
            placeholder='Select'
            name='brand'
            value={measurementUnits.find((option: any) => option.value === measurementUnit) || ''}
            onChange={(e: any) => {
              setMeasurementUnit(e.value)
              setMeasurement(e.label)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setValidationMeasurementUnit(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='parentCategory'>
            Parent Category<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchParentCategoryAdd}
            isSearchable
            className={`react-select-container ${parValidation}`}
            classNamePrefix='react-select'
            menuPosition='fixed'
            placeholder='Select'
            name='product'
            value={parentCategory}
            onChange={(e) => {
              setParentCategory(e)
              getCategory(e.value)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setParValidation(validation)
              setCategory('')
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='category'>
            Category<span className='text-danger'>*</span>
          </label>
          <EaDropDown
            className={`react-select-container ${catValidation}`}
            list={categorylist}
            placeholder='Select'
            onChange={(e: any) => {
              setCategory(e)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setCatValidation(validation)
            }}
            value={category}
          />
        </div>

        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <label className='form-check-label m-0'>
              <input
                className='form-check-input widget-13-check me-2'
                name='checkValue'
                checked={isSalesQuantityControl}
                type='checkbox'
                onChange={(e: any) => {
                  setIsSalesQuantityControl(e.target.checked)
                  setAllowedGramsValidation('')
                  setAllowedGrams(currentAllowedGrams)
                }}
                value=''
              />
              Sales Quantity Control
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => (
                  <Tooltip {...props} className='tooltip-dark'>
                    {formateMessage('SALES_QUANTITY_CONTROL_DESC')}
                  </Tooltip>
                )}
                placement='top'
              >
                <span className='cursor-pointer text-decoration-underline'>
                  <a href='#' className='ms-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='ic text-primary mr-0'
                    />
                  </a>
                </span>
              </OverlayTrigger>
            </label>
          </div>
        </div>
        {isSalesQuantityControl && (
          <>
            <div className='mb-3'>
              <label className='form-label'>
                Up to Grams<span className='text-danger'>*</span>
              </label>
              <div
                className='input-group'
                style={{
                  borderColor: allowedGramsValidation != '' ? 'Red' : '',
                }}
              >
                <input
                  type='text'
                  id='gram'
                  placeholder='0'
                  className={clsx('form-control', allowedGramsValidation)}
                  value={allowedGrams}
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/

                    if (e.target.value === '' || re.test(e.target.value)) {
                      setAllowedGrams(e.target.value)
                    }

                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setAllowedGramsValidation(validation)
                  }}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                  Gram
                </span>
              </div>
              <p className='text-muted mt-1'>
                {numberFormat(isEmpty(allowedGrams) ? 0 : allowedGrams / 1000).displayFormat} Kgs
              </p>
            </div>
          </>
        )}
        {/* <div className="mb-5">
               <label className="form-label">Group Name</label>
            </div> */}
        <Imagecropper
          title='Product Group Image'
          getImage={getImage}
          onImageChange={onImageChange}
          imageRequire={validationImage}
          imageSize={imageSize}
          cropData={cropData}
          setCrop={setCropData}
        />
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit group */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionEdit}
        closeAuto={'ea_update_create_product_group'}
        onSubmit={updateFunction}
        id='ea_modal_edit_product_group'
        modalTitle='Edit Product Group'
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Group Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='groupNameEdit'
            placeholder='Type here...'
            className={clsx('form-control', validationNameEdit)}
            value={groupNameEdit}
            onChange={(e) => {
              setGroupNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationNameEdit(validation)
            }}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>
            Short Description<span className='text-danger'>*</span>
          </label>
          <textarea
            className={`form-control ${shortDescriptionValidation}`}
            rows={2}
            id='shortDescriptionEdit'
            placeholder='Type here…'
            onChange={(e) => {
              setDescription(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setDescriptionValidation(validation)
            }}
            value={shortDescription}
            name='description'
          ></textarea>
        </div>
        <div className='mb-4'>
          <label className='form-label'>VAT (in %)</label>
          <input
            type='text'
            className={clsx('form-control', validationSelcetedvat)}
            placeholder='0.00'
            value={selcetedvat}
            onChange={(e) => {
              const re = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setVat(e.target.value)
                //  const validation = ValidationHandler(e.target.value, 'is-invalid')
                //  validationSetVat(validation)
              }
            }}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label' id='measurementUnit'>
            Measurement Unit<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={() => {
              return {
                options: measurementUnits,
                hasMore: false,
              }
            }}
            isSearchable
            className={`react-select-container  ${validationMeasutement}`}
            classNamePrefix='react-select'
            placeholder='Select'
            name='brand'
            value={measurementUnits.find((option: any) => option.value === measurementUnit)}
            onChange={(e: any) => {
              setMeasurementUnit(e.value)
              setMeasurement(e.label)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setValidationMeasurementUnit(validation)
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='parentCategory'>
            Parent Category<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchParentCategory}
            isSearchable
            className={`react-select-container ${parValidation}`}
            classNamePrefix='react-select'
            menuPosition='fixed'
            placeholder='Select'
            name='product'
            value={parentCategory}
            onChange={(e) => {
              setParentCategory(e)
              getCategory(e.value)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setParValidation(validation)
              setCategory('')
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label' id='category'>
            Category<span className='text-danger'>*</span>
          </label>
          <EaDropDown
            className={`react-select-container ${catValidation}`}
            list={categorylist}
            placeholder='Select'
            onChange={(e: any) => {
              setCategory(e)
              const validation = ValidationHandler(e.value, 'is-invalid')
              setCatValidation(validation)
            }}
            value={category}
          />
        </div>

        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <label className='form-check-label m-0'>
              <input
                className='form-check-input widget-13-check me-2'
                name='checkValue'
                checked={isSalesQuantityControl}
                type='checkbox'
                onChange={(e: any) => {
                  setIsSalesQuantityControl(e.target.checked)
                  setAllowedGramsValidation('')
                  setAllowedGrams(currentAllowedGrams)
                }}
                value=''
              />
              Sales Quantity Control
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => (
                  <Tooltip {...props} className='tooltip-dark'>
                    {formateMessage('SALES_QUANTITY_CONTROL_DESC')}
                  </Tooltip>
                )}
                placement='top'
              >
                <span className='cursor-pointer text-decoration-underline'>
                  <a href='#' className='ms-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='ic text-primary mr-0'
                    />
                  </a>
                </span>
              </OverlayTrigger>
            </label>
          </div>
        </div>
        {isSalesQuantityControl && (
          <>
            <div className='mb-3'>
              <label className='form-label'>
                Up to Grams<span className='text-danger'>*</span>
              </label>
              <div
                className='input-group'
                style={{
                  borderColor: allowedGramsValidation != '' ? 'Red' : '',
                }}
              >
                <input
                  type='text'
                  id='gram'
                  placeholder='0'
                  className={clsx('form-control', allowedGramsValidation)}
                  value={allowedGrams}
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/

                    if (e.target.value === '' || re.test(e.target.value)) {
                      setAllowedGrams(e.target.value)
                    }

                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setAllowedGramsValidation(validation)
                  }}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                  Gram
                </span>
              </div>
              <p className='text-muted mt-1'>
                {numberFormat(isEmpty(allowedGrams) ? 0 : allowedGrams / 1000).displayFormat} Kgs
              </p>
            </div>
          </>
        )}

        <Imagecropper
          title='Product Group Image'
          getImage={getImageEdit}
          onImageChange={onImageChangeEdit}
          imageRequire={validationImageEdit}
          imageSize={imageSizeEdit}
          cropData={cropDataEdit}
          setCrop={setCropDataEdit}
        />
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* delete group */}
      <EaModal
        modalTitle='Delete Product Group'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{groupNameDelete}?</span>
          </p>
        }
        onSubmit={deleteFuntion}
        id={'deleteProductGroup'}
        closeAuto='ea_close_delete_product_group'
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default ProductGroups
