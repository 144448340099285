import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  addCreditRejection,
  creditRejectionDelete,
  creditRejectionList,
  creditRejectionUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import { checkPermissions, defaultPermission, KTSVG, retrieveFilter, saveFilter } from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import tenantConfiguration from "../../../../TenantVariables"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}
const CreditRejection: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Credit Rejection Options',
  }

  let reasonFiltered : any = retrieveFilter(filters.reason_master_credit_rejection);

  const initialFiltersState = {
      selected: reasonFiltered?.selected || '',
      search: reasonFiltered?.search || '',
      currentOffSet: reasonFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState<number>(reasonFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(reasonFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(reasonFiltered?.search || '')
  const [creditRejection, setCreditRejection] = useState<any>('')
  const [creditRejectionEdit, setCreditRejectionEdit] = useState<any>('')
  const [Validation, setValidation] = useState<any>('')
  const [ValidationEdit, setValidationEdit] = useState<any>('')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(reasonFiltered?.buttonDisable || false) 
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)  
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    checkPermissions("masters_rejection_options").then((res: any) => {
      setPermission(res)
    })

    CategoriesList()
  }, [debounceSearch, currentOffset])

  const CategoriesList = () => {
      setListLoader(true)
    creditRejectionList(limit, currentOffset, debounceSearch, 'CreditRejection', 'name').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.reason_master_credit_rejection , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      creditRejectionList('10', newOffset, search, 'CreditRejection', 'name').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      creditRejectionList('10', newOffset, search, 'CreditRejection', 'name').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      creditRejectionList('10', newOffset, search, 'CreditRejection', 'name').then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  // Develop BY: Kriti
  // Description: Add Credit Rejection Options function which is called on Eamodel's submit event

  const AddFunction = () => {
    if (creditRejection === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
    }
    if (creditRejection !== '') {
      AddFunctionApi()
    }
  }
  const clearFunctionAdd = () => {
    setValidation('')
    setCreditRejection('')
    setErrorMessage('')
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addCreditRejection(creditRejection, 'CreditRejection').then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunctionAdd()
      setListLoader(true)
        creditRejectionList(limit, '0', '', 'CreditRejection', 'name').then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added Credit Rejection Option name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // Develop BY: Kriti
  // Description: Edit Credit Rejection Options function which is called on Eamodel's submit event

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setCreditRejectionEdit(item.option)
    setEditId(item.id)
    setModalLoader(false)
  }
  const EditFunction = () => {
    if (creditRejectionEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
    }
    if (creditRejectionEdit !== '') {
      EditFunctionApi()
    }
  }
  const clearFunctionEdit = () => {
    setValidationEdit('')
    setCreditRejectionEdit('')
    setErrorMessage('')

    $('#tableId')
      .find('input[type=number]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    creditRejectionUpdate(creditRejectionEdit, 'CreditRejection', EditId).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        creditRejectionList(limit, '0', search, 'CreditRejection', 'name').then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setListLoader(false)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added Credit Rejection Option name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const onDelete = (item: any) => {
    setDeleteId(item.id)

    // api call
    creditRejectionDelete(DeleteId)
    creditRejectionList('10', '0', search, 'CustomerRejection', 'name')
  }
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Credit Rejection Options' />
      <div>
        <PageTitle breadcrumbs={[masterHeaderTitle]}>
          {intl.formatMessage({ id: 'Credit Rejection Options' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your credit rejection has been added successfully.'
              : 'Your credit rejection has been edited successfully.'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                  value={search} 
                  disabled={listLoadder}
                />
              </div>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_addcreditrejection'
                    btnName='Add Credit Rejection Options'
                    className="btn-primary me-3"
                    onClick={() => {
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {/* <TableCreditRejection /> */}
              <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-100px py-2'>Credit Rejection Options</th>
                        <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {getList.map((item: any) => {
                        return (
                          <tr>
                            <td>{item.option}</td>
                            <td className='text-end'>
                              {permission.write && (
                                <a
                                  href='javascript:void(0)'
                                  data-bs-toggle='modal'
                                  data-bs-target='#ea_modal_edit_editcreditrejection'
                                  className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                  onClick={(event) => {
                                    onChangeAdd(item)
                                    setMessageHandler('successEdit')
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>
                              )}
                              {/* {permission.delete && (
                                <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={(event) => {
                                  onDelete(item)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </a>
                              )} */}
                            </td>
                          </tr>
                        )
                      })}
                      <tr>
                        <td>Other</td>
                      </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
            </div>
          </div>
          {/* begin::Body */}
        </div>
        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
                } of ${getList.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
                count={getList.length == 0 ? 0 : count}
                selected={selected}
              />
            </div>
          </div>
        </div> */}
         <CustomPaginate
              data={getList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.reason_master_credit_rejection}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      </div>

      {/* <AddCreditRejection /> */}
      <EaModal
        id='ea_modal_create_addcreditrejection'
        modalTitle='Add Credit Rejection Options'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunctionAdd}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Credit Rejection Options<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', Validation)}
            placeholder='Type here...'
            value={creditRejection}
            onChange={(e) => {
              setCreditRejection(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      <EaModal
        id='ea_modal_edit_editcreditrejection'
        modalTitle='Edit Credit Rejection Options'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Credit Rejection Options<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', ValidationEdit)}
            placeholder='Type here...'
            value={creditRejectionEdit}
            onChange={(e) => {
              setCreditRejectionEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationEdit(validation)
            }}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default CreditRejection
