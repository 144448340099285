import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchCustomerCategories,
  displayAddedBy,
  fetchAsyncDropdown,
  formatVerificationType,
  isEmpty,
  retrieveFilter,
  saveFilter,
  staticSearch,
  toAbsoluteUrl,
  verificationPending,
  checkUserType,
} from '../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest, patchRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {
  OrderingArrowDown,
  OrderingArrowUp,
} from '../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {fetchUserTypes, rejection, rejectionValidation, userTypes} from './CustomerConst'
import {categoryStyle} from '../../../../_eaFruitsDms/helpers/components/categoryStyle'
import tenantConfiguration from '../../../../TenantVariables'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import moment from 'moment/moment'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'

type Props = {
  className: string
}

const SuspendedCustomers: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const currentWarehouseId = localStorage.getItem('w_id')
  let customersFiltered: any = retrieveFilter(filters.ts_suspended_customers)

  const customerHeaderTitle = {
    title: 'Suspended Customers',
    subTitle: 'Customers',
  }

  const customerTypes: any = [
    {
      label: 'All Types',
      value: '',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
    {
      label: 'Wholesaler',
      value: 'Wholesaler',
    },
  ]
  const initialOrderKeys = {
    Id: true,
    Name: true,
    StreetName: true,
    Category: true,
    Type: true,
    CreditRange: true,
    Address: true,
  }

  const initialState = {
    warehouseId: customersFiltered?.warehouseId || {label: 'All Warehouses', value: ''},
    categoryId: customersFiltered?.categoryId || {label: 'All Categories', value: ''},
    customerType: customersFiltered?.customerType || '',
    userId: customersFiltered?.userId || {label: 'All Users', value: ''},
    userType: customersFiltered?.userType || '',
    selected: customersFiltered?.selected || '',
    search: customersFiltered?.search || '',
    currentOffSet: customersFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const [customers, setCustomers] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const [warehouses, setWarehouses] = useState<any>([])
  const [types, setTypes] = useState<any>([])
  const [categoryOffSet, setCategoryOffSet] = useState<number>(0)
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [typeOffSet, setTypeOffSet] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<any>(customersFiltered?.currentOffSet || '0')
  const [timer, setTimer] = useState<number>(0)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>(customersFiltered?.search || '')
  const [selected, setSelected] = useState<number>(customersFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  // const [warehouseId, setWarehouseId] = useState<any>("")
  const [warehouseId, setWarehouseId] = useState<any>(
    customersFiltered?.warehouseId || {
      label: 'All Warehouses',
      value: '',
    }
  )
  // const [categoryId, setCategoryId] = useState<any>("")
  const [categoryId, setCategoryId] = useState<any>(
    customersFiltered?.categoryId || {
      label: 'All Categories',
      value: '',
    }
  )
  const [customerType, setCustomerType] = useState<any>(customersFiltered?.customerType || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [messageHandler, setMessageHandler] = useState('')
  const [messageTitle, setMessageTitle] = useState<any>('')
  const [grantAccessReasonOffSet, setGrantAccessReasonOffSet] = useState<any>('')
  const [grantAccessReasonOption, setGrantAccessReasonOption] = useState<any>([])
  const [customerGrantAccess, setCustomerGrantAccess] = useState(rejection)
  const [customerGrantAccessValidation, setCustomerGrantAccessValidation] =
    useState<any>(rejectionValidation)
  const [ordering, setOrdering] = useState('')
  const [orderColumnKey, setOrderColumnKey] = useState('')
  const [orderKey, setOrderKey] = useState(initialOrderKeys)
  const [permission, setPermission] = useState(defaultPermission)
  const [userOffset, setUserOffset] = useState<any>('')
  const [userType, setUserType] = useState<any>(customersFiltered?.userType || '')
  // const [userId, setUserId] = useState<any>('')
  const [userId, setUserId] = useState<any>(
    customersFiltered?.userId || {
      label: 'All Users',
      value: '',
    }
  )
  const [userReset, setUserReset] = useState<any>(moment())
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    customersFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    localStorage.removeItem('cId')
    fetchPermission()

    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)
      setMessageTitle(localStorage.getItem('name'))
      setIsAlertSuccess(true)

      localStorage.removeItem('type')
      localStorage.removeItem('name')
    }
  }, [])

  useEffect(() => {
    setCategoryOffSet(0)
    setWarehouseOffSet(0)
    setTypeOffSet(0)
    setUserOffset(0)
  }, [searchSelect])

  useEffect(() => {
    fetchCustomers().then()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.ts_suspended_customers, filterStates)
    if (
      warehouseId.value == '' &&
      categoryId.value == '' &&
      searchByName == '' &&
      customerType == '' &&
      (userType == undefined || userType == '')
    ) {
      setButtonDisable(false)
    }
  }, [
    currentOffset,
    debounceSearch,
    warehouseId,
    categoryId,
    customerType,
    ordering,
    userType,
    userId,
  ])

  const fetchPermission = async () => {
    let res = await checkPermissions('suspended_customer')
    setPermission(res)
  }

  const fetchCustomers = async () => {
    setDisplayLoader(true)
    let fetchCustomersAPIUrl = `${apiBaseURL()}customers/suspended?ordering=${ordering}&limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&type=${customerType.toLowerCase()}&category_ids=${
      categoryId.value
    }&reg_by_type=${userType}&reg_by_id=${userId !== '' ? userId.value : ''}`

    if (warehouseId.value !== '') {
      fetchCustomersAPIUrl += `&warehouse_id=${warehouseId.value}`
    }

    const customersResponse = await getRequest(fetchCustomersAPIUrl, true)

    let customerList: any = []
    if (customersResponse.results.length > 0) {
      customersResponse.results.map((customer: any) => {
        const verificationStatus = verificationPending(customer)
        customer.pending = verificationStatus.pending
        customer.verification_type = verificationStatus.type

        let customerInfo: any = {...customer}
        customerList.push(customerInfo)
      })
    }

    setCustomers(customerList)
    setCount(customersResponse.count)
    setNext(customersResponse.next)
    setPrev(customersResponse.previous)
    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${
        isEmpty(currentWarehouseId) ? '' : currentWarehouseId
      }&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) => {
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/customer-category-parent/list/`,
      true
    )

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    const categoryKeys = Object.keys(categoriesResponse)

    categoryKeys.forEach((value) => {
      let optionId: string = ''

      options.push({
        label: value,
        value: optionId,
        color: '#6d6e6f',
        bg: '#f7f7f7',
        transform: 'uppercase',
        boxSizing: 'borderBox',
        weight: '500',
        cursor: 'default',
        size: '.75rem !important',
      })

      categoriesResponse[value].map((option: any) => {
        if (optionId === '') {
          optionId += option.id
        } else {
          optionId += ',' + option.id
        }
        options.push({
          label: option.category,
          value: option.id,
        })
        return options
      })

      let index = options.findIndex((x: any) => x.label === value)
      options[index].value = optionId
    })

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchCustomerTypes = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options,
      hasMore: false,
    }
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setButtonDisable(true)
    setTimer(0)
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'warehouse':
        // setWarehouseId(inputValue)
        setWarehouseId(e)
        setFilterStates({...filterStates, ['warehouseId']: e})
        setUserReset(moment())
        setUserOffset('')
        break
      case 'category':
        // setCategoryId(inputValue)
        setCategoryId(e)
        setFilterStates({...filterStates, ['categoryId']: e})
        break
      case 'type':
        setCustomerType(inputValue)
        setFilterStates({...filterStates, ['customerType']: inputValue})
        break
      case 'user-type':
        // setUserType(inputValue)
        setUserType(inputValue)
        setFilterStates({...filterStates, ['userType']: inputValue})
        setUserReset(moment())
        setUserOffset('')
        setUserId('')
        break
      case 'users':
        setUserId(e)
        setFilterStates({...filterStates, ['userId']: e})
        break
      default:
        setTimer(1000)
        setCurrentOffset(0)
        setSelected(0)
        setSearchByName(inputValue)
        setFilterStates({...filterStates, ['search']: inputValue})
    }
  }

  const goToOverview = (cId: any) => {
    localStorage.setItem('cId', cId)
    localStorage.setItem('customerPermission', 'suspendedCustomer')
    saveFilter(filters.ts_suspended_customers, filterStates)
    navigate('../customers/suspended-customers/overview')
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  const fetchGrantAccessReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const response = await fetchAsyncDropdown(
      grantAccessReasonOffSet,
      setGrantAccessReasonOffSet,
      grantAccessReasonOption,
      setGrantAccessReasonOption,
      `master/options-master/?type=CustomerGrantAccess`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const handleChangeReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setCustomerGrantAccessValidation({...customerGrantAccessValidation, [inputName]: ''})

    switch (inputName) {
      case 'option':
        setCustomerGrantAccess({...customerGrantAccess, ['option']: inputValue})
        break
      case 'comments':
        setCustomerGrantAccess({...customerGrantAccess, ['comments']: inputValue})
        break
    }
  }

  const clearFunction = () => {
    setCustomerGrantAccess(rejection)
    setCustomerGrantAccessValidation(rejectionValidation)
    setGrantAccessReasonOffSet('')
    setGrantAccessReasonOption([])
  }

  const grantCustomerAccess = async () => {
    const customerId = localStorage.getItem('cId')
    await patchRequest(
      `${apiBaseURL()}customers/${customerId}/suspend-or-grant`,
      {
        status: 'approved',
        reason_option: customerGrantAccess.option,
        reason_note: customerGrantAccess.comments,
      },
      true
    )

    $('#closeGrantAccessReasonModal').trigger('click')
    setTimeout(() => {
      clearFunction()
    }, 200)
    await fetchCustomers()
  }

  const handleOrdering = (key: any) => {
    setOrderColumnKey(key)
    if (key === 'Id') {
      setOrderKey({...initialOrderKeys, Id: !orderKey.Id})
    }
    if (key === 'Name') {
      setOrderKey({...initialOrderKeys, Name: !orderKey.Name})
    }
    if (key === 'StreetName') {
      setOrderKey({...initialOrderKeys, StreetName: !orderKey.StreetName})
    }
    if (key === 'Category') {
      setOrderKey({...initialOrderKeys, Category: !orderKey.Category})
    }
    if (key === 'Type') {
      setOrderKey({...initialOrderKeys, Type: !orderKey.Type})
    }
    if (key === 'CreditRange') {
      setOrderKey({...initialOrderKeys, CreditRange: !orderKey.CreditRange})
    }
    if (key === 'Address') {
      setOrderKey({...initialOrderKeys, Address: !orderKey.Address})
    }
  }

  useEffect(() => {
    if (orderColumnKey === 'Id') {
      if (orderKey.Id) {
        setOrdering('id')
      } else {
        setOrdering('-id')
      }
    }

    if (orderColumnKey === 'Name') {
      if (orderKey.Name) {
        setOrdering('business_name')
      } else {
        setOrdering('-business_name')
      }
    }

    if (orderColumnKey === 'StreetName') {
      if (orderKey.StreetName) {
        setOrdering('street')
      } else {
        setOrdering('-street')
      }
    }

    if (orderColumnKey === 'Category') {
      if (orderKey.Category) {
        setOrdering('category')
      } else {
        setOrdering('-category')
      }
    }

    if (orderColumnKey === 'CreditRange') {
      if (orderKey.CreditRange) {
        setOrdering('credit_range')
      } else {
        setOrdering('-credit_range')
      }
    }

    if (orderColumnKey === 'Type') {
      if (orderKey.Type) {
        setOrdering('type')
      } else {
        setOrdering('-type')
      }
    }

    if (orderColumnKey === 'Address') {
      if (orderKey.Address) {
        setOrdering('address')
      } else {
        setOrdering('-address')
      }
    }
  }, [orderColumnKey, orderKey])

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []

    if (search !== '') {
      setSearchSelect(search)
    }

    let hasMore: boolean = false

    if (userType !== '') {
      let wId = ''

      if (userType !== 'ts') {
        wId = warehouseId.value
      }

      const response: any = await getRequest(
        `${apiBaseURL()}auth/users/team/list?search=${searchSelect}&type=${userType}&limit=${limit}&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`,
        true
      )

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (options.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label:
                option.first_name + ' ' + option.last_name + ' (' + option.type.toUpperCase() + ')',
              value: option.id,
            })
          })
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleChangeReset = () => {
    setSearchByName('')
    setWarehouseId({label: 'All Warehouses', value: ''})
    setCategoryId({label: 'All Categories', value: ''})
    setUserId({label: 'All Users', value: ''})
    setCustomerType('')
    setUserType('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = {...filterStates}
    updatedState['warehouseId'] = ''
    updatedState['categoryId'] = ''
    updatedState['typeId'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <div>
        <EATitle title='Suspended Customer' />
        <PageTitle breadcrumbs={[customerHeaderTitle]}>
          {intl.formatMessage({id: 'Suspended Customers'})}
        </PageTitle>
        {!checkUserType(['sa']) && (
          <EaAlertSuccess
            onClick={() => isAlertSHow('success')}
            show={IsAlertSuccess}
            message={
              messageHandler === 'successAdd'
                ? FormatMessage('CUSTOMER.ADD', messageTitle)
                : FormatMessage('CUSTOMER.UPDATE', messageTitle)
            }
          />
        )}
        <div className='card'>
          {/* begin::Header */}
          {displayLoader && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={handleChange}
              value={searchByName}
              isDisable={displayLoader}
            />
            <div className='ms-md-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <AsyncPaginate
                  loadOptions={fetchWarehouses}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  placeholder='All Warehouses'
                  value={warehouseId}
                  // value={warehouses.find((option: any) => option.value === warehouseId)}
                  onChange={(e: any) => handleChange(e, 'warehouse')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategories}
                  isSearchable
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  name='categories'
                  value={categoryId}
                  // value={categories.filter((option: any) => option.value === categoryId)}
                  onChange={(e: any) => handleChange(e, 'category')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCustomerTypes}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Types'
                  value={customerTypes.find((option: any) => option.value == customerType)}
                  onChange={(e: any) => handleChange(e, 'type')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchUserTypes}
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='Added By ...'
                  value={userTypes.find((option: any) => option.value === userType)}
                  onChange={(e: any) => handleChange(e, 'user-type')}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {userType !== '' && (
                  <AsyncPaginate
                    key={userReset}
                    loadOptions={fetchUsers}
                    className='react-select-container my-1 me-3 w-200px'
                    classNamePrefix='react-select'
                    placeholder='All Users'
                    onChange={(e: any) => handleChange(e, 'users')}
                    isDisabled={displayLoader}
                    value={userId || ''}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </form>
              {/* </div> */}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {customers.length == 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-60px'>
                        ID
                        <span onClick={() => handleOrdering('Id')}>
                          {orderKey.Id ? <OrderingArrowUp /> : <OrderingArrowDown />}
                        </span>
                      </th>
                      <th className='min-w-150px'>
                        Name
                        <span onClick={() => handleOrdering('Name')}>
                          {orderKey.Name ? <OrderingArrowUp /> : <OrderingArrowDown />}
                        </span>
                      </th>
                      <th className='min-w-140px'>
                        Category
                        <span onClick={() => handleOrdering('Category')}>
                          {orderKey.Category ? <OrderingArrowUp /> : <OrderingArrowDown />}
                        </span>
                      </th>
                      <th className='min-w-120px'>
                        Type
                        <span onClick={() => handleOrdering('Type')}>
                          {orderKey.Type ? <OrderingArrowUp /> : <OrderingArrowDown />}
                        </span>
                      </th>
                      <th className='min-w-130px'>
                        Credit Range
                        <span onClick={() => handleOrdering('CreditRange')}>
                          {orderKey.CreditRange ? <OrderingArrowUp /> : <OrderingArrowDown />}
                        </span>
                      </th>
                      <th className='min-w-120px'>Credit Issue</th>
                      <th className='min-w-100px'>Added By</th>
                      <th className='min-w-100px'>Verification</th>
                      {checkUserType(['wm', 'ts']) && permission.write && (
                        <th className='min-w-200px action-th text-end'>Actions</th>
                      )}
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {customers.map((customer: any) => {
                      return (
                        <>
                          <tr>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              <div className='media-body'>
                                <p className='m-0 xs-font'>#{customer.id}</p>
                              </div>
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              <div className='d-flex align-items-center'>
                                <span
                                  className='rounded-circle pro-icon me-2'
                                  data-bs-toggle='tooltip'
                                  title={customer.business_name}
                                >
                                  {customer.logo === null ? (
                                    <img
                                      src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                      alt=''
                                      className='rounded-circle pro-icon'
                                    />
                                  ) : (
                                    <img
                                      src={customer.logo}
                                      alt=''
                                      className='rounded-circle pro-icon'
                                    />
                                  )}
                                </span>
                                <div className='media-body'>
                                  <p className='m-0 xs-font'>{customer.business_name}</p>
                                </div>
                              </div>
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              <div className='text-capitalize'>
                                <div className='text-muted font-13 mb-1 font-medium'>
                                  {customer.nature_of_business ? customer.nature_of_business : '-'}
                                </div>
                                {customer.category_name ? customer.category_name : '-'}
                              </div>
                            </td>
                            <td
                              className='cursor-pointer text-capitalize'
                              onClick={() => goToOverview(customer.id)}
                            >
                              {customer.type}
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              {customer && customer.credit_range ? customer.credit_range : '-'}
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              -
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              {displayAddedBy(customer.reg_by)}
                            </td>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              {/*<span*/}
                              {/*    className='btn py-0 ps-1 pe-0 btn-sm me-3'*/}
                              {/*>*/}
                              {/*    <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='ic mr-0' />*/}
                              {/*</span>*/}
                              <div className='d-flex align-items-center'>
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => (
                                    <Tooltip {...props} className='tooltip-dark'>
                                      <span>
                                        {formatVerificationType(customer.verification_type)}
                                      </span>
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <span className='cursor-pointer text-decoration-underline'>
                                    {customer.pending} Pending
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                            {/* <td className='text-end'>
                                                                <a href="#" onClick={() => {
                                                                    localStorage.setItem('cId', customer.id)
                                                                    navigate('manage-customer')
                                                                }
                                                                }>
                                                                    <span
                                                                        className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                                                    </span>
                                                                </a>
                                                            </td> */}
                            {checkUserType(['wm', 'ts']) && (
                              <td className='text-end'>
                                {permission.write && (
                                  <>
                                    <EaButton
                                      isModal={true}
                                      dataTarget='#ea_modal_grant_access'
                                      btnName='Grant Access'
                                      onClick={() => {
                                        localStorage.setItem('cId', customer.id)
                                      }}
                                    />
                                    <button
                                      className='btn btn-primary ms-2'
                                      onClick={() => goToOverview(customer.id)}
                                    >
                                      View
                                    </button>
                                  </>
                                )}
                              </td>
                            )}
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={customers}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.ts_suspended_customers}
          filterStates={filterStates}
          isDisabled={displayLoader}
        />
      </div>
      {/* grant access */}
      <EaModal
        modalTitle='Grant Customer Access'
        onSubmit={grantCustomerAccess}
        cancel={clearFunction}
        closeAuto='closeGrantAccessReasonModal'
        children={
          <>
            <p>Are you sure, you want to grant this customer access?</p>
            <div className='mb-4'>
              <label className='form-label'>Customer Grant Access Reason</label>
              <AsyncPaginate
                loadOptions={fetchGrantAccessReason}
                isSearchable
                className={clsx('react-select-container w-100', '')}
                classNamePrefix='react-select'
                onChange={(e: any) => handleChangeReason(e, 'option')}
                value={
                  customerGrantAccess.option != null &&
                  grantAccessReasonOption.find(
                    (option: any) => option.value === customerGrantAccess
                  )
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div>
              {customerGrantAccess.option == '' && (
                <>
                  <label className='form-label'>Comments</label>
                  <textarea
                    className='form-control'
                    rows={4}
                    placeholder='Type here…'
                    name='comments'
                    value={customerGrantAccess.comments}
                    onChange={(e: any) => handleChangeReason(e, 'comments')}
                  ></textarea>
                </>
              )}
            </div>
          </>
        }
        id={'ea_modal_grant_access'}
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default SuspendedCustomers
