import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  apiBaseURL,
  checkPermissions,
  cmsInquiresTitle,
  dateFormat,
  defaultPermission,
  KTSVG,
  retrieveFilter,
  saveFilter
} from "../../../../../_eaFruitsDms/helpers"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import { filters } from "../../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}

const Investors: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const masterHeaderTitle = {
    title: 'Categories',
    subTitle: 'CMS',
  }

  let investorsFiltered : any = retrieveFilter(filters.cms_investor);

  const initialFiltersState = {
      selected: investorsFiltered?.selected || '',
      search: investorsFiltered?.search || '',
      currentOffSet: investorsFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  // all states
  const [selected, setSelected] = useState<number>(investorsFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(investorsFiltered?.currentOffSet || '0');
  const [count, setCount] = useState('1')
  const [timer, setTimer] = useState<number>(0)
  const [search, setSearch] = useState<string>(investorsFiltered?.search || '')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [investorsList, setInvestorsList] = useState([])
  const [messageHandler, setMessageHandler] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(investorsFiltered?.buttonDisable || false) 

  useEffect(() => {
    checkPermissions("cms").then((res: any) => {
      setPermission(res)

    })
  }, [])

  useEffect(() => {
    localStorage.removeItem('inquiriesId')
    localStorage.removeItem('inquiriesType')
    setTimeout(() => fetchInvestors().then(), timer)
  }, [currentOffset, debounceSearch, count, investorsList.length])

  const fetchInvestors = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}cms/inquiries/?inquiry_type=Investor&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setInvestorsList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.cms_investor , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}


  const redirectPage = (item: any) => {
    localStorage.setItem('inquiriesId', item.id)
    localStorage.setItem('inquiriesType', item.inquiry_type)
    navigate('details')
  }

  return (
    <>
      <EATitle title='Investor & Partner - ' />
      <PageTitle breadcrumbs={cmsInquiresTitle}>
        {intl.formatMessage({ id: 'Investor & Partner' })}
      </PageTitle>
      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        {/* <div className='card-header border-0 px-7'> */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
          {/* <div className='card-toolbar row g-0 w-100'> */}
            <CustomSearchBar onChange={handleChangeSearch}  value={search} disabled={listLoadder} />
            <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
          </div>
        {/* </div> */}
        <div className='card-body py-3'>
          <div className='table-responsive'>
          {investorsList.length == 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <table className='table table-hover table-row-bordered align-middle gs-0 gy-2'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-120px py-2'>Name</th>
                          <th className='min-w-120px py-2'>Email</th>
                          <th className='min-w-120px py-2'>Mobile No</th>
                          <th className='min-w-120px py-2'>Inquiry Subject</th>
                          <th className='min-w-120px py-2'>Received Date</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {investorsList &&
                          investorsList.length > 0 &&
                          investorsList.map((item: any) => {
                            return (
                              <>
                                <tr className="cursor-pointer">
                                  <td onClick={() => redirectPage(item)}>
                                    {item.first_name + ' ' + item.last_name}
                                  </td>
                                  <td onClick={() => redirectPage(item)}>{item.email}</td>
                                  <td onClick={() => redirectPage(item)}>{item.mobile_number}</td>
                                  <td onClick={() => redirectPage(item)}>{item.inquiry_subject}</td>
                                  <td onClick={() => redirectPage(item)}>
                                    {dateFormat(item.received_on).displayDate}{' '}
                                    {dateFormat(item.received_on, 'LT').displayDate}
                                  </td>
                                  <td className=' text-end' onClick={() => redirectPage(item)}>
                                    <span className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                      <KTSVG path='/media/icons/duotune/arrows/arr007.svg' />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
          </div>
        </div>
      </div>
       <CustomPaginate
              data={investorsList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.cms_investor}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
    </>
  )
}

export default Investors
