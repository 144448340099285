import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  PackageCreate,
  PackageDelete,
  PackageList,
  PackageUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import {
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  retrieveFilter,
  saveFilter
} from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import TablePackageMaterial from "./components/tablePackageMaterial"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import tenantConfiguration from "../../../../TenantVariables"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}

const PackageMaterial: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Package Material',
  }

    let packingMaterialFiltered : any = retrieveFilter(filters.sa_master_product_packing);
  
    const initialFiltersState = {
        selected: packingMaterialFiltered?.selected || '',
        search: packingMaterialFiltered?.search || '',
        currentOffSet: packingMaterialFiltered?.currentOffSet || '',
        buttonDisable: true
      }

  // all states
  const [packageMaterial, setpackageMaterial] = useState('')
  const [packageMaterialEdit, setpackageMaterialEdit] = useState('')
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [validation, setValidation] = useState('')
  const [validationEdit, setValidationEdit] = useState('')
  const [packageNameDelete, setPackageNameDelete] = useState('')
  const [selected, setSelected] = useState<number>(packingMaterialFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(packingMaterialFiltered?.currentOffSet || '0');
  const [count, setCount] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(packingMaterialFiltered?.search || '')
  const [getLength, setLength] = useState('1')
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(packingMaterialFiltered?.buttonDisable || false) 
  const debounceSearch = useDebounce(search, 500);
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)

  useEffect(() => {
    fetchPermission()

    setListLoader(true)
    PackageList(limit, currentOffset, debounceSearch).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
    
    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_master_product_packing , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }, [debounceSearch, currentOffset])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_products")
    setPermission(res)
   }

  const addFunction = () => {
    let navigateField = {
      packageMaterial: packageMaterial,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // api validation front-end side.
    if (packageMaterial.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      setValidation('')
      PackageCreate(packageMaterial).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          setpackageMaterial('')
          setListLoader(true)
          setErrorMessage('')
          PackageList(limit, '0', search).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setListLoader(false)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setSelected(0)
            setNext(res.next)
            setPrev(res.prev)
          })
          $('#closeCreateEaModal').trigger('click')
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }

        if (notify.failed) {
          // setIsAlertFailed(true)
        }
        if (notify.errorField == 'name') {
          setErrorMessage(
            'Your added package material name already exists in this system! Please try again with a unique package material name.'
          )
        }
      })
    } else {
      setValidation('is-invalid')
    }
  }
  const updateFunction = () => {
    let navigateField = {
      packageMaterialEdit: packageMaterialEdit,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (packageMaterialEdit == '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
    }

    if (packageMaterialEdit !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      PackageUpdate(id, packageMaterialEdit).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        setValidationEdit('')
        if (notify.success) {
          setListLoader(true)
          setErrorMessage('')
          PackageList(limit, '0', search).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setSelected(0)
            setListLoader(false)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setNext(res.next)
            setPrev(res.prev)
          })
          setIsAlertSuccess(true)
          $('#closeEditEaModal').trigger('click') // modal close
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }
        if (notify.errorField == 'name') {
          setErrorMessage(
            'Your added package material name already exists in this system! Please try again with a unique package material name.'
          )
        }
      })
    }
  }
  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    setId(val.id)
    setpackageMaterialEdit(val.name)
    setModalLoader(false)
  }
  const getPropsDelete = (val: any) => {
    setId(val.id)
    setPackageNameDelete(val.name)
  }
  const deleteFuntion = () => {
    PackageDelete(id).then((notify) => {
      if (notify.success) {
        PackageList(limit, '0', search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setSelected(0)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setNext(res.next)
          setPrev(res.prev)
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
      $('#ea_close_delete_product_package').trigger('click') // modal close
    })
  }
  // success message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }
  
  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    
    setButtonDisable(false)
}
  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setListLoader(true)
      setSelected(selected - 1)
      PackageList(limit, newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }
  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setListLoader(true)
      setSelected(selected + 1)
      PackageList(limit, newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setListLoader(false)
        setPrev(res.prev)
      })
    }
  }
  const clearFunction = () => {
    // clear add modal state
    setValidation('')
    setErrorMessage('')
    setpackageMaterial('')
  }
  const clearFunctionEdit = () => {
    // clear edit modal state
    setValidationEdit('')
    setErrorMessage('')
    setpackageMaterialEdit('')
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setListLoader(true)
      setCurrentOffset(newOffset)
      PackageList(limit, newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  return (
    <>
      <EATitle title='Package Material' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Package Material' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRODUCT.MATERIAL.ADD')
            : FormatMessage('PRODUCT.MATERIAL.UPDATE')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />
      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} id='searcList_material' value={search} disabled={listLoadder}/>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
            {permission.write && (
                <EaButton
                  onClick={() => {
                    setMessageHandler('successAdd')
                  }}
                  isModal={true}
                  dataTarget='#ea_modal_create_package_material'
                  btnName='Add Package Material'
                  className="btn-primary me-3"
                />
            )}
            <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0  ? (
              <NoRecords />
            ) : (
              <TablePackageMaterial
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                getPropsDelete={getPropsDelete}
                getPropsEdit={getProps}
                tableList={getList}
              />
            )}
          </div>
        </div>
      </div>
          <CustomPaginate
              data={getList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.sa_master_product_packing}
              filterStates={filterStates}
              isDisabled={listLoadder}
          />
      {/* add package */}
      <EaModal
        onSubmit={addFunction}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunction}
        closeAuto={'closeCreateEaModal'}
        id='ea_modal_create_package_material'
        modalTitle='Add Package Material'
      >
        <div className=''>
          <label className='form-label'>
            Package Material Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='packageMaterial'
            className={clsx('form-control', validation)}
            placeholder='Type here...'
            value={packageMaterial}
            onChange={(e) => {
              setpackageMaterial(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit package */}
      <EaModal
        cancel={clearFunctionEdit}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={updateFunction}
        closeAuto={'closeEditEaModal'}
        id='ea_modal_edit_package_material'
        modalTitle='Edit Package Material'
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Package Material Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='packageMaterialEdit'
            className={clsx('form-control', validationEdit)}
            placeholder='Type here...'
            value={packageMaterialEdit}
            onChange={(e) => {
              setpackageMaterialEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationEdit(validation)
            }}
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* delete package */}
      <EaModal
        modalTitle='Delete Package Material'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{packageNameDelete}?</span>
          </p>
        }
        onSubmit={deleteFuntion}
        id={'deleteProductPackage'}
        closeAuto='ea_close_delete_product_package'
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}
export default PackageMaterial
