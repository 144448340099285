export const filters: any = {
  sa_all_customers: 'sa-all-customers',
  sa_tele_sales: 'sa-tele-sales',
  sa_product_sku: 'sa-product-sku',
  sa_survey_form: 'sa-survey-form',
  sa_brand: 'sa-brand',
  sa_survey_detail: 'sa-survey-detail',
  sa_team_report: 'sa-team-report',
  wm_customer_report: 'wm-customer-report',
  am_sales_invoice: 'am-sales-invoice',
  sa_user_account: 'sa-user-account',
  sa_master_regions: 'sa-master-regions',
  sa_master_ward: 'sa-master-ward',
  sa_master_streets: 'sa-master-streets',
  sa_master_district: 'sa-master-district',
  sa_master_product_category: 'sa-master-product-category',
  sa_master_product_group: 'sa-master-product-group',
  sa_master_product_packing: 'sa-master-product-packing',
  sa_master_product_variants: 'sa-master-product-variants',
  sa_master_customer_category: 'sa-master-customer-category',
  sa_master_customer_tags: 'sa-master-customer-tags',
  sa_master_vehicle_type: 'sa-master-vehicle-type',
  sa_master_vehicle_make: 'sa-master-vehicle-make',
  sa_master_vehicle_modal: 'sa-master-vehicle-modal',
  sa_master_vehicle_breakdown: 'sa-master-vehicle-breakdown',
  sa_master_payment_bank: 'sa-master-payment-bank',
  sa_master_payment_wallet: 'sa-master-payment-wallet',
  reason_master_address: 'reason-master-address',
  reason_master_customer_rejection: 'reason-master-customer-rejection',
  reason_master_credit_rejection: 'reason-master-credit-rejection',
  reason_master_credit_request_update: 'reason-master-credit-request-update',
  reason_master_credit_request_reject: 'reason-master-credit-request-reject',
  reason_master_manual_stock: 'reason-master-manual-stock',
  reason_master_discount_rejection_option: 'reason-master-discount-rejection-option',
  reason_master_stockin: 'reason-master-stockin',
  reason_master_revoke_access: 'reason-master-revoke-access',
  reason_master_grant_access: 'reason-master-grant-access',
  reason_master_buinsess_detail: 'reason-master-buinsess-detail',
  reason_master_loadoutrequest_reject: 'reason-master-loadoutrequest-reject',
  reason_master_loadoutrequest_partial_approval: 'reason-master-loadoutrequest-partial-approval',
  reason_master_gatepass_rejection: 'reason-master-gatepass-rejection',
  reason_master_case_settlement: 'reason-master-case-settlement',
  reason_master_discount_request_rejection: 'reason-master-discount-request-rejection',
  reason_master_discount_request_amount_update: 'reason-master-discount-request-amount-update',
  reason_master_loadoutrequest_cancel: 'reason-master-loadoutrequest-cancel',
  cms_category: 'cms-category',
  cms_author: 'cms-author',
  cms_posts: 'cms-posts',
  cms_farmer_story: 'cms-farmer-story',
  cms_vendor_story: 'cms-vendor-story',
  cms_employee_story: 'cms-employee-story',
  cms_testimonial: 'cms-testimonial',
  cms_contact_us: 'cms-contact-us',
  cms_investor: 'cms-investor',
  cms_products: 'cms-products',
  cms_current_opening: 'cms-current-opening',
  cms_job_application: 'cms-job-application',

  wm_team_members: 'wm-team-members',
  wm_all_customers: 'wm-all-customers',
  wm_manage_price: 'wm-manage-price',
  wm_discount_requesting: 'wm-discount-requesting',
  wm_promotional_discount: 'wm-promotional-discount',
  wm_discount_capping: 'wm-discount-capping',
  wm_low_stock: 'wm-low-stock',
  wm_bank: 'wm-bank',
  wm_wallet: 'wm-wallet',

  ts_all_customers: 'ts-all-customers',
  ts_promotional_discount: 'ts-promotional-discount',
  ts_survey: 'ts-survey',
  ts_survey_feedback: 'ts-survey-feedback',
  ts_current_stock: 'ts-current-stock',
  ts_verification_customer: 'ts-verification-customer',
  ts_suspended_customers: 'ts-suspended-customers',
  ts_orders: 'ts-orders',
  am_payment_issues: 'am-payment-issues',

  oo_dashboard: 'oo-dashboard',
  oo_product_sku: 'oo-product-sku',
  oo_current_stock: 'oo-current-stock',
  oo_loadout_request: 'oo-loadout-request',
  oo_stockin_report: 'oo-stockin-report',
  oo_stockout_report: 'oo-stockout-report',
  oo_lowstock_report: 'oo-lowstock-report',
  oo_expired_product_report: 'oo-expired-product-report',
  oo_maual_stock_change_report: 'oo-maual-stock-change-report',

  lo_vehicle_management: 'lo-vehicle-management',
  sa_warehouse_dc_team: 'sa-warehouse-dc-team',
  sa_warehouse_dc_stock: 'sa-warehouse-dc-stock',
  sa_warehouse_dc_customer: 'sa-warehouse-dc-customer',
  ts_support_request_newmessages: 'ts-support-request-newmessages',
  ts_support_request_my_assigned: 'ts-support-request-my-assigned',
  ts_support_request_my_closed: 'ts-support-request-my-closed',
  reason_master_support_request: 'reason-master-support-request',
  wm_credit_issue_report: 'wm-credit-issue-report',
  sa_customer_on_boarding_rejection_report: 'sa-customer-on-boarding-rejection-report',
  sa_telesales_salesorderfeedback: 'sa-telesales-salesorderfeedback',
  sa_telesales_supportrequest_message: 'sa-telesales-supportrequest-message',
  sa_pay_later_today_report: 'sa-pay-later-today-report',
  ts_orders_quantity_request: 'ts-orders-quantity-request',
  wm_credit_issue: 'wm-credit-issue',
  sa_order_feedback_report: 'sa-order-feedback-report',
  sa_product_wise_sales_report: 'sa-product-wise-sales-report',
  sa_issues_reported_by_sg_report: 'sa-issues-reported-by-sg-report',
  sa_gatepass_report: 'sa-gatepass-report',
  sa_load_out_requests_rejection_report: 'sa-load-out-requests-rejection-report',
  sa_order_discount_rejection_report: 'sa-order-discount-rejection-report',
  sa_credit_request_rejection_report: 'sa-credit-request-rejection-report',
  sa_gate_pass_rejection_rejection_report: 'sa-gate-pass-rejection-rejection-report',
}
