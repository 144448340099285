import { apiBaseURL, staticSearch } from "../../../../_eaFruitsDms/helpers";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";

export const adminHeaderTitle = [
    {
        title: 'Customers',
        path: '/customers',
    },
]

export const initialLatLong = {
    latitude: '',
    longitude: '',
}
export const premises: any = [
    {
        label: 'Stationary',
        value: 'stationary',
    },
    {
        label: 'Movable',
        value: 'movable',
    },
]
export const business: any = [
    {
        label: 'Formal',
        value: 'formal',
    },
    {
        label: 'Informal',
        value: 'informal',
    },
    {
        label: 'Corporate',
        value: 'corporate',
    },
]

export const businessSize: any = [
    {
        label: 'Small',
        value: 'small',
    },
    {
        label: 'Medium',
        value: 'medium',
    },
    {
        label: 'Large',
        value: 'large',
    },
    {
        label: 'Small Informal',
        value: 'small_informal',
    },
    {
        label: 'Medium Large Informal',
        value: 'medium_large_informal',
    },
]

export const familySize: any = [
    {
        label: 'Single No Children',
        value: 'single_on_children',
    },
    {
        label: 'Single With Children',
        value: 'single_with_children',
    },
    {
        label: 'Stable Relation or Marriage With Children',
        value: 'stable_relation_or_marriage_with_children',
    },
    {
        label: 'Stable Relation or Marriage With No Children',
        value: 'stable_relation_or_marriage_with_no_children',
    },
]

export const customerTypes: any = [
    {
        label: 'All Types',
        value: '',
    },
    {
        label: 'Retailer',
        value: 'retailer',
    },
    {
        label: 'Wholesaler',
        value: 'wholesaler',
    },
]

export const userTypes: any = [
    {
        label: 'Added By All',
        value: ''
    },
    {
        label: 'SR',
        value: 'sr'
    },
    {
        label: 'SS',
        value: 'ss'
    },
    {
        label: 'TS',
        value: 'ts'
    },
    {
        label: 'WM',
        value: 'wm'
    }
]

export const fetchUserTypes = async (search?: any) => {
    let options = userTypes

    if (search !== '') {
        options = staticSearch(options, search)
    }
    return {
        options: options,
        hasMore: false,
    }
}

export const rejectionValidation: any = {
    option: '',
    comments: '',
}

export const rejection = {
    option: null,
    comments: '',
}

export const initialCustomerLatLong = {
    latitude: '',
    longitude: ''
}

export const getCustomerDetails = async (customerId: any) => {
    return await getRequest(`${apiBaseURL()}customers/${customerId}/edit`, true)
}

export const stockInOutType = (type: string) => {
    switch (type) {
        case 'FreshProduct':
            return 'Fresh Product'
        case 'WarehouseTransfer':
            return 'Warehouse Transfer'
        case 'Unsold':
            return 'Unsold'
        case 'ReturnProduct':
            return 'Return Product'
        case 'LoadOutRequest':
            return 'Load Out Request'
        case 'WarehouseTransfer':
            return 'Warehouse Transfer'
    }
}

export const creditRequestStatus = [
    {
        label: "All Requests",
        value: ""
    },
    {
        label: "Approved",
        value: "approved"
    },
    {
        label: "Cancelled",
        value: "cancelled"
    },
    {
        label: "Pending",
        value: "pending"
    },
    {
        label: "Rejected",
        value: "rejected"
    }
]

export const supportRequestType = [
    {value: 'facing_issues_with_placing_order_from_the_mobile_app', label: 'facing issues with placing order from the mobile app'},
  ]

export const intialSupportRequest = {
    requestType: '',
    title: '',
    message: ''
}

export const intialSupportRequestValidation = {
    requestType: '',
    title: '',
    message: ''
}
export const quantityOpenRequest = [
    {
        label: "All Requests",
        value: ""
    },
    {
        label: "Approved",
        value: "approved"
    },
    {
        label: "Pending",
        value: "pending"
    },
    {
        label: "Rejected",
        value: "rejected"
    }
]
