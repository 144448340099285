import {encryptData} from '../../helpers/storageHelper'

export type loginModal = {
  id: ''
  first_name: ''
  last_name: ''
  profile_thumbnail: ''
  profile_img: ''
  base_url: ''
  email: ''
  token: ''
  user_type: ''
  permission: []
  warehouse: any
  role: ''
  device_id: 'string'
  device_type: 'string'
  supervisor: any
  device_token: ''
  role_name: ''
}

const getImageUrl = (loginUser: any) => {
  let imageUrl = null
  if (loginUser.profile_thumbnail) {
    if (loginUser.profile_thumbnail.includes('http')) {
      imageUrl = loginUser.profile_thumbnail
    } else {
      imageUrl = loginUser.base_url + loginUser.profile_thumbnail
    }
  }
  return imageUrl
}

export const getUserDetails = (loginUser: loginModal) => {
  localStorage.setItem('eafruitsLogin', 'loginToEaFruits')
  localStorage.setItem('roleId', loginUser.role)
  localStorage.setItem('roleName', loginUser.role_name)
  localStorage.setItem('w_id', loginUser.warehouse)
  localStorage.setItem(
    'userDetails',
    JSON.stringify({
      fullName: loginUser.first_name + ' ' + loginUser.last_name,
      image: getImageUrl(loginUser),
      type: loginUser.user_type,
    })
  )
  localStorage.setItem('email', loginUser.email)
  localStorage.setItem('user_id', loginUser.id)

  encryptData('user_type', loginUser.user_type)
}

export const getImpersonationUserDetails = (impersonationUser: any) => {
  localStorage.setItem('token', impersonationUser.token)
  localStorage.setItem('roleId', impersonationUser.role)
  localStorage.setItem('w_id', impersonationUser.warehouse)
  localStorage.setItem('roleName', impersonationUser.role_name)
  localStorage.setItem('warehouse_name', impersonationUser.warehouse_name)
  localStorage.setItem(
    'userDetails',
    JSON.stringify({
      fullName: impersonationUser.first_name + ' ' + impersonationUser.last_name,
      image: getImageUrl(impersonationUser),
      type: impersonationUser.user_type,
    })
  )
  localStorage.setItem('email', impersonationUser.email)
  localStorage.setItem('user_id', impersonationUser.id)

  encryptData('user_type', impersonationUser.user_type)
}
