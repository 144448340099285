//warehouse/DC/singlewarehousename/warehouse-customers TODO: For our reference do not remove this comment

import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  displayAddedBy,
  fetchCustomerCategories,
  KTSVG,
  retrieveFilter,
  saveFilter,
  staticSearch,
  toAbsoluteUrl,
  verificationPending,
} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import tenantConfiguration from '../../../../../../TenantVariables'
import {categoryStyle} from '../../../../../../_eaFruitsDms/helpers/components/categoryStyle'
import {fetchUserTypes, userTypes} from '../../../../customers/WMcustomers/CustomerConst'
import CustomPaginate from '../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import moment from 'moment/moment'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'
import { filters } from '../../../../../../_eaFruitsDms/utils/FilterConstants'
import ResetButton from '../../../../../../_eaFruitsDms/layout/components/button/resetButton'
import useDebounce from '../../../../../../_eaFruitsDms/helpers/components/useDebounce'

const Customers: React.FC = () => {
  let customersFiltered : any = retrieveFilter(filters.sa_warehouse_dc_customer);

  const initialState = {
      categoryId: customersFiltered?.categoryId || {label : 'All Categories' , value : ''},
      typeId: customersFiltered?.typeId || '',
      userId: customersFiltered?.userId || {label : 'All Users' , value : ''},
      userType: customersFiltered?.userType || '',
      selected: customersFiltered?.selected || '',
      search: customersFiltered?.search || '',
      currentOffSet: customersFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [customers, setCustomers] = useState<any>([])
  const [selected, setSelected] = useState<number>(customersFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>(customersFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<number>(0)
  const [categoryId, setCategoryId] = useState<any>(customersFiltered?.categoryId || {
    label: 'All Categories',
    value: ''
});
  const [typeId, setTypeId] = useState<any>(customersFiltered?.typeId || '')
  const [searchByName, setSearchByName] = useState<string>(customersFiltered?.search || '')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [categories, setCategories] = useState<any>([])
  const [userOffset, setUserOffset] = useState('')
  const [userType, setUserType] = useState<any>(customersFiltered?.userType || '')
  const [userId, setUserId] = useState<any>(customersFiltered?.userId || {
    label: 'All Users',
    value: ''
})
  const [userReset, setUserReset] = useState<any>(moment())
  const wareHouseId = localStorage.getItem('wId')
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(customersFiltered?.buttonDisable || false) 
  const debounceSearch = useDebounce(searchByName, 500);

  useEffect(() => {
    setTimeout(() => fetchCustomers().then(), timer)
  }, [currentOffset, debounceSearch, wareHouseId, categoryId, typeId, userType, userId])

  useEffect(() => {
    setUserOffset('')
  }, [searchSelect])

  const fetchCustomers = async () => {
    setDisplayLoader(true)

    const customersResponse = await getRequest(
      `${apiBaseURL()}customers?on_boarding_status__in=Approved,Pending,Reject&search=${debounceSearch}&limit=${limit}&offset=${currentOffset}&customer_type=${typeId}&customer_category__id__in=${categoryId.value}&warehouse_id=${wareHouseId}&category_ids=${categoryId.value}&reg_by_type=${userType}&reg_by_id=${
        userId !== '' ? userId.value : ''
      }`,
      true
    )
    let customerList: any = []
    if (customersResponse.results && customersResponse.results.length > 0) {
      customersResponse.results.map((customer: any) => {
        const verificationStatus = verificationPending(customer)
        customer.pending = verificationStatus.pending
        customer.type = verificationStatus.type

        let customerInfo: any = {...customer}
        customerList.push(customerInfo)
      })
    }
    setCustomers(customerList)
    setCount(customersResponse.count)
    setNext(customersResponse.next)
    setPrev(customersResponse.previous)
    setDisplayLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_warehouse_dc_customer , filterStates)
    if(categoryId.value == '' && searchByName == '' && typeId == '' && (userType == undefined || userType == '')){
        setButtonDisable(false)
    }
  }

  const customerTypes: any = [
    {
      value: '',
      label: 'All Types',
    },
    {
      label: 'Wholesaler',
      value: 'Wholesaler',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
  ]

  const fetchCustomerTypes = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []
    let hasMore: boolean = false

    if (userType !== '') {
      if (search !== '') {
        setSearchSelect(search)
      }

      const wId: any = !['ts'].includes(userType) ? wareHouseId : ''

      const response: any = await getRequest(
        `${apiBaseURL()}auth/users/team/list?search=${searchSelect}&type=${userType}&limit=${limit}&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`,
        true
      )

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (options.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label:
                option.first_name + ' ' + option.last_name + ' (' + option.type.toUpperCase() + ')',
              value: option.id,
            })
          })
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) =>{
    return await fetchCustomerCategories(
      setCategories,
      categories,
      search,
    )
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setTimer(0)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    switch (inputName) {
      case 'category':
        setCategoryId(e)
        setFilterStates({ ...filterStates, ['categoryId']: e })
        break
      case 'type':
        setTypeId(inputValue)
        setFilterStates({ ...filterStates, ['typeId']: inputValue })
        break
      case 'user-type':
        setUserType(inputValue)
        setFilterStates({ ...filterStates, ['userType']: inputValue })
        setUserOffset('')
        setUserId('')
        setSearchSelect('')
        break
      case 'users':
        setUserId(e)
        setUserReset(moment())
        setFilterStates({ ...filterStates, ['userId']: e })
        break
      default:
        setTimer(1000)
        setCurrentOffset(0)
        setSelected(0)
        setSearchByName(inputValue)
        setFilterStates({ ...filterStates, ['search']: inputValue })
    }
  }

  const handleChangeReset = () =>{
    setSearchByName('')
    setCategoryId({label : 'All Categories' , value : ''})
    setUserId({label : 'All Users' , value : ''})
    setTypeId('')
    setUserType('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['warehouseId'] = ''
    updatedState['categoryId'] = ''
    updatedState['typeId'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Customers' />
      <div className='card'>
        {/* begin::Header */}
        {displayLoader && <IsLoader />}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleChange} value={searchByName} isDisable={displayLoader}/>
          <div className='ms-md-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchCategories}
                isSearchable
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                placeholder='All Categories'
                styles={categoryStyle}
                name='categories'
                value={categoryId}
                // value={categories.filter((option: any) => option.value === categoryId)}
                onChange={(e: any) => handleChange(e, 'category')}
                isDisabled={displayLoader}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchCustomerTypes}
                isSearchable
                className='react-select-container my-1 me-3 w-120px'
                classNamePrefix='react-select'
                placeholder='All Types'
                value={customerTypes.find((option: any) => option.value === typeId)}
                onChange={(e: any) => handleChange(e, 'type')}
                isDisabled={displayLoader}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchUserTypes}
                className='react-select-container my-1 me-3 w-120px'
                classNamePrefix='react-select'
                placeholder='Added By ...'
                value={userTypes.find((option: any) => option.value === userType)}
                onChange={(e: any) => handleChange(e, 'user-type')}
                isDisabled={displayLoader}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              {userType !== '' && (
                <AsyncPaginate
                  key={userReset}
                  loadOptions={fetchUsers}
                  className='react-select-container my-1 me-3 w-200px'
                  classNamePrefix='react-select'
                  placeholder='All Users'
                  onChange={(e: any) => handleChange(e, 'users')}
                  value={userId || ''}
                  isDisabled={displayLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            {customers.length == 0 ? (
              <>
                <NoRecords />
              </>
            ) : (
              <>
                <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-60px'>ID</th>
                      <th className='min-w-160px'>Name</th>
                      <th className='min-w-110px'>Street Name</th>
                      <th className='min-w-130px'>Mobile Number</th>
                      <th className='min-w-120px'>Credit Range</th>
                      <th className='min-w-120px'>Added By</th>
                      <th className='min-w-100px'>Verification</th>
                      <th className='min-w-120px'>Total Sales</th>
                      <th className='min-w-120px'>Credit Days</th>
                      <th className='min-w-120px'>Credit Issue</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {customers.map((customer: any) => {
                      return (
                        <>
                          <tr>
                            <td>#{customer.id}</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <span
                                  className='rounded-circle pro-icon me-2'
                                  data-bs-toggle='tooltip'
                                  title={customer.business_name}
                                >
                                  {customer.logo_thumbnail === null ? (
                                    <img
                                      src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                      alt=''
                                      className='rounded-circle pro-icon'
                                    />
                                  ) : (
                                    <img
                                      src={customer.logo_thumbnail}
                                      alt=''
                                      className='rounded-circle pro-icon'
                                    />
                                  )}
                                </span>
                                <div className='media-body'>
                                  <p className='m-0 xs-font'>{customer.business_name}</p>
                                </div>
                              </div>
                            </td>
                            <td>{customer.street && customer.street}</td>
                            <td>
                              {customer.contact_no && tenantConfiguration.baseCountryCode}{' '}
                              {customer.contact_no}
                            </td>
                            <td>{customer.credit_range}</td>
                            <td>{displayAddedBy(customer.reg_by)}</td>
                            <td>
                              {customer.pending == 0 ? (
                                <>
                                  <span className='btn py-0 ps-1 pe-0 btn-sm me-3'>
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr012.svg'
                                      className='ic mr-0'
                                    />
                                  </span>
                                </>
                              ) : (
                                customer.type.length > 0 && (
                                  <div className='d-flex align-items-center'>
                                    <OverlayTrigger
                                      delay={{hide: 450, show: 300}}
                                      overlay={(props) => (
                                        <Tooltip {...props} className='tooltip-dark'>
                                          {customer.type.map((type: any, index: number) => {
                                            return (
                                              <>
                                                <span>
                                                  {' '}
                                                  {type}
                                                  {index + 1 < customer.type.length
                                                    ? index == 0 && customer.type.length > 2
                                                      ? ', '
                                                      : ' & '
                                                    : ''}{' '}
                                                </span>
                                              </>
                                            )
                                          })}
                                        </Tooltip>
                                      )}
                                      placement='top'
                                    >
                                      <span className='cursor-pointer text-decoration-underline'>
                                        {customer.pending} Pending
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                )
                              )}
                            </td>
                            <td>-</td>
                            <td>{customer.credit_cycle}</td>
                            <td>-</td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              </>
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CustomPaginate
        data={customers}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.sa_warehouse_dc_customer}
        filterStates={filterStates}
        isDisabled={displayLoader}
      />
    </>
  )
}

export default Customers
