import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { apiBaseURL, checkPermissions, defaultPermission, KTSVG, retrieveFilter, saveFilter, toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}

const Testimonials: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const masterHeaderTitle = {
    title: 'Categories',
    subTitle: 'CMS',
  }

  let teleSalesFiltered : any = retrieveFilter(filters.cms_testimonial);

  const initialFiltersState = {
      selected: teleSalesFiltered?.selected || '',
      search: teleSalesFiltered?.search || '',
      currentOffSet: teleSalesFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  // all states

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState<number>(teleSalesFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(teleSalesFiltered?.currentOffSet || '0');
  const [count, setCount] = useState('1')
  const [timer, setTimer] = useState<number>(0)
  const [search, setSearch] = useState<string>(teleSalesFiltered?.search || '')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [testimonialList, setTestimonialList] = useState([])
  const [messageHandler, setMessageHandler] = useState('')
  // const [showDelete, setShowDelete] = useState<any>(false)
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(teleSalesFiltered?.buttonDisable || false) 
  const cmsTestimonial: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    localStorage.removeItem('testimonialsId')
    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => fetchTestimonial().then(), timer)
  }, [currentOffset, debounceSearch, count, testimonialList.length])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
  }

  const fetchTestimonial = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}cms/testimonial/?&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setTestimonialList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.cms_testimonial , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  // const deleteItem = async (item: any) => {
  //   setDeleteId(item.id)
  // }

  // const deletAPI = async () => {
  //   const notify = {
  //     success: false,
  //     failed: false,
  //     errorField: '',
  //   }
  //   await deleteRequest(`${apiBaseURL()}cms/testimonial/${DeleteId}/`, true).then((res) => {
  //     if (res.status === 204) {
  //       notify.success = true
  //     }
  //     if (res.statusText === 'Bad Request') {
  //       notify.failed = true
  //       notify.errorField = res.data.error.field
  //     }
  //   })
  //   if (notify.success) {
  //     const API = `${apiBaseURL()}cms/testimonial/?limit=${limit}&ordering=name&search=${search}&offset=${currentOffset}`
  //     const response = await getRequest(API, true)
  //     setTestimonialList(response.results)
  //     $('#closeDeleteEaModal').trigger('click')
  //     setTimeout(() => {
  //       setIsAlertFailed(false)
  //       setIsAlertSuccess(false)
  //     }, 2000)
  //     setIsAlertSuccess(true)
  //   }
  //   if (notify.failed) {
  //     // setIsAlertFailed(true)
  //   }
  // }
  return (
    <>
      <EATitle title='Testimonials - ' />
      <PageTitle breadcrumbs={cmsTestimonial}>{intl.formatMessage({ id: 'Testimonials' })}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('CMS_TESTIMONIAL.ADD')
            : messageHandler === 'successEdit'
              ? FormatMessage('CMS_TESTIMONIAL.UPDATE')
              : messageHandler === 'successDelete' && FormatMessage('CMS_TESTIMONIAL.DELETE')
        }
      />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleChangeSearch}  value={search} disabled={listLoadder}/>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              {permission.write && (
                <button
                  className='btn btn-sm btn-primary me-3 my-1'
                  onClick={() => {
                    localStorage.removeItem('testimonialsId')
                    navigate('add-testimonials')
                  }}
                >
                  Add Testimonial
                </button>
              )}
            <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
            {/* } */}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
          {testimonialList.length == 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-120px py-2'>Name</th>
                          <th className='min-w-120px py-2'>Sub title</th>
                          <th className='min-w-120px py-2'>Testimonial From</th>
                          <th className='min-w-120px py-2'>Featured Testimonial</th>
                          <th className='min-w-80px action-th py-2'>
                            {/* {permission.write || permission.delete ? 'Actions' : ''} */}
                            {permission.write && 'Actions'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {testimonialList &&
                          testimonialList.length > 0 &&
                          testimonialList.map((item: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-28px me-2'>
                                        <span className='symbol-label'>
                                          {item.person_logo_thumbnail == null ? (
                                            <img
                                              alt='group image'
                                              className='h-35px rounded-1'
                                              src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                            />
                                          ) : (
                                            <img
                                              src={item.person_logo_thumbnail}
                                              className='h-40 rounded-1'
                                              alt=''
                                              style={{ width: '100%', height: '100%' }}
                                            />
                                          )}
                                        </span>
                                      </div>
                                      <div>
                                        <span>{item.person_name}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.Sub_title}</td>
                                  <td>{item.testimonial_from}</td>
                                  <td>
                                    {item.is_featured == true ? (
                                      <>
                                        <span className='badge badge-light-success my-2 text-dark'>
                                          Featured
                                        </span>
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td className='text-end'>
                                    {permission.write && (
                                      <a
                                        onClick={() => {
                                          localStorage.setItem('testimonialsId', item.id)
                                          navigate('add-testimonials')
                                        }}
                                        href='#'
                                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/art/art005.svg'
                                          className='ic mr-0'
                                        />
                                      </a>
                                    )}

                                    {/* {permission.delete && (
                                      <span
                                        data-bs-target='#ea_modal_delete_testimonial'
                                        data-bs-toggle='modal'
                                        onClick={(event) => {
                                          deleteItem(item)
                                          setMessageHandler('successDelete')
                                        }}
                                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/arrows/arr011.svg'
                                          className='ic mr-0'
                                        />
                                      </span>
                                    )} */}
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
          </div>
        </div>
      </div>
       <CustomPaginate
              data={testimonialList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.cms_testimonial}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      {/* <EaModal
        modalTitle='Delete Testimonials'
        closeAuto='closeDeleteEaModal'
        children={<p className='mb-0'>Are you sure you want to delete this Testimonial?</p>}
        id={'ea_modal_delete_testimonial'}
        actionBtnName='Yes, Proceed'
        onSubmit={deletAPI}
      /> */}
    </>
  )
}

export default Testimonials
