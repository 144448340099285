import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  CategoriesCreate,
  CategoriesDelete,
  CategoriesList,
  CategoriesSingle,
  CategoriesUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  retrieveFilter,
  saveFilter
} from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler"
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import { getToken } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage"
import TableCategory from "./components/tableCategory"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import { AsyncPaginate } from "react-select-async-paginate"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import tenantConfiguration from "../../../../TenantVariables"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}
const ProductCategoriesMaster: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Product Categories',
  }

    let categoryFiltered : any = retrieveFilter(filters.sa_master_product_category);

    const initialFiltersState = {
        selected: categoryFiltered?.selected || '',
        search: categoryFiltered?.search || '',
        currentOffSet: categoryFiltered?.currentOffSet || '',
        buttonDisable: true
      }

  // all states
  const [catName, setCatName] = useState('')
  const [parentcatName, setParentCatName] = useState('')
  const [catNameEdit, setCatNameEdit] = useState('')
  const [parentcatNameEdit, setParentCatNameEdit] = useState('')
  const [catNameDelete, setCatNameDelete] = useState('')
  const [id, setId] = useState('')
  const [getList, setGetList] = useState<any>([])
  const [getParentCategoryList, setParentCategory] = useState<any>([])
  const [getParentCategoryListModal, setParentCategoryModal] = useState<any>([])
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [validation, setValidation] = useState('')
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState<number>(categoryFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(categoryFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(categoryFiltered?.search || '')
  const [getLength, setLength] = useState('1')
  const [showWrite, setShowWrite] = useState<any>(false)
  const [showDelete, setShowDelete] = useState<any>(false)
  const [listLoadder, setListLoader] = useState<any>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [permission, setPermission] = useState(defaultPermission)
  const [ParentCategorySearch, setParentCategorySearch] = useState('')
  const [parentCategoryOffset, setParentCategoryOffset] = useState('')
  const [parentCategoryList, setParentCategoryList] = useState([])
  const [parValidation, setParValidation] = useState('')
  const [parValidationEdit, setParValidationEdit] = useState('')
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(categoryFiltered?.buttonDisable || false)

  const debounceSearch = useDebounce(search, 500);
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()

    setListLoader(true)
    CategoriesList(limit , currentOffset , debounceSearch).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })


    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_master_product_category , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }, [currentOffset, debounceSearch])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_products")
    setPermission(res)
   }

  //request method for api implementation :- last change by kriti
  const categoryApi = () => {
    getRequest(`${apiBaseURL()}master/product/category?ordering=name`, true).then((res) => {
      setParentCategory(res.results)
      setParentCategoryModal(res.results)
    })
  }
  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    categoryApi()
    setCatNameEdit(val.name)
    setId(val.id)
    CategoriesSingle(val.id).then((res: any) => {
      setCatNameEdit(res.name)
      setParentCatNameEdit(res.parent_category)
      setModalLoader(false)
    })
  }

  const fetchParentCategoryAdd = async (search: any) => {
    // let url = `master/product/category?ordering=name`
    // const response = await fetchAsyncDropdown(parentCategoryOffset, setParentCategoryOffset, parentCategoryList, setParentCategoryList, url, search, setParentCategorySearch)
    if (search != '') {
      setParentCategorySearch(search)
    }

    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/category?ordering=name&search=${search}&limit=${10}&offset=${parentCategoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if(response.results){
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setParentCategoryOffset(newOffset)
        hasMore = true
      }

      if (response.results.length > 0) {
        response.results.map((option: any) => {
          if (option.parent_category == null) {
            options.push({
              label: option.name,
              value: option.id,
            })
          }
        })
      }

      if (parentCategoryList.length > 0) {
        setParentCategoryList(parentCategoryList.concat(options))
      } else {
        setParentCategoryList(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const getPropsDelete = (val: any) => {
    setId(val.id)
    setCatNameDelete(val.name)
  }

  const updateFunction = () => {
    let navigateField = {
      catNameEdit: catNameEdit,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (catNameEdit.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')

      CategoriesUpdate(catNameEdit, parentcatNameEdit, id).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          clearFunctionEdit()
          setListLoader(true)
          CategoriesList(limit, '0', search).then((res) => {
            setGetList(res.data)
            setSelected(0)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setListLoader(false)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
          $('#ea_close_update_product_category').trigger('click') // modal close

          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }

        if (notify.errorField == 'name') {
          setErrorMessage(
            'Your added category name already exists in this system! Please try again with a unique category name.'
          )
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }
      })
    }
  }

  const addFunction = () => {
    let navigateField = {
      catName: catName,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // api validation front-end side
    if (catName.trim() !== '') {
      setDisableBtn(true)
      setIsLoading('Please Wait..')
      CategoriesCreate(catName, parentcatName).then((notify) => {
        setDisableBtn(false)
        setIsLoading('Submit')
        if (notify.success) {
          clearFunction()
          setListLoader(true)
          CategoriesList(limit, '0', search).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
            setListLoader(false)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
          })
          $('#ea_close_create_product_category').trigger('click') // modal close
          setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
          }, 1500)
          setIsAlertSuccess(true)
        }
        if (notify.failed) {
          // setIsAlertFailed(true)
        }

        if (notify.errorField == 'name') {
          setErrorMessage(
            'Your added category name already exists in this system! Please try again with a unique category name.'
          )
        }
      })
    } else {
      setValidation('is-invalid')
    }
  }

  const clearFunction = () => {
    // clear add modal state
    setParentCatName('')
    setCatName('')
    setValidation('')
    setErrorMessage('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setErrorMessage('')
    setCatNameEdit('')
  }

  const deleteFuntion = () => {
    CategoriesDelete(id).then((notify) => {
      if (notify.success) {
        setListLoader(true)
        CategoriesList(limit, currentOffset, search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setListLoader(false)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      $('#ea_close_delete_product_category').trigger('click') // modal close
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)
    })
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  // const searchList = (e: any) => {
  //   clearTimeout(timer)
  //   setSearch(e.target.value)
  //   const newTimer = setTimeout(() => {
  //     setListLoader(true)
  //     CategoriesList('10', '0', e.target.value).then((res) => {
  //       setGetList(res.data)
  //       setCount(res.count)
  //       setNext(res.next)
  //       setPrev(res.prev)
  //       setSelected(0)
  //       setListLoader(false)
  //       if (res.data.length == 0) {
  //         setLength('2')
  //       } else {
  //         setLength('1')
  //       }
  //     })
  //   }, 500)
  //   setTimer(newTimer)
  // }

  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      CategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      CategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      CategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  return (
    <>
      <EATitle title='Product-Categories' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Product Categories' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRODUCT.CATEGORIES.ADD')
            : FormatMessage('PRODUCT.CATEGORIES.UPDATE')
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={(e: any) => searchList(e)} id='searcList' value={search} disabled={listLoadder}/>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>

            {permission.write && (
                <EaButton
                  onClick={() => {
                    categoryApi()
                    setMessageHandler('successAdd')
                  }}
                  isModal={true}
                  btnName='Add Category'
                  className="btn-primary me-3"
                  dataTarget='#ea_modal_create_product_category'
                />
            )}
            <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <TableCategory
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getPropsDelete={getPropsDelete}
              />
            )}
          </div>
        </div>
      </div>
          <CustomPaginate
              data={getList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.sa_master_product_category}
              filterStates={filterStates}
              isDisabled={listLoadder}
          />
      {/* add categories */}
      <EaModal
        cancel={clearFunction}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={() => addFunction()}
        closeAuto={'ea_close_create_product_category'}
        id='ea_modal_create_product_category'
        modalTitle='Add Product Category'
      >
        <div className='mb-3'>
          <label className='form-label'>
            Category Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='catName'
            className={clsx('form-control', validation)}
            value={catName}
            onChange={(e) => {
              setCatName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div className=''>
          <label className='form-label'>Parent Category (If Available)</label>
          <AsyncPaginate
                    loadOptions={fetchParentCategoryAdd}
                    isSearchable
                    className={`react-select-container  ${parValidation}`}
                    classNamePrefix='react-select'
                    menuPosition='fixed'
                    placeholder='Select'
                    name='product'
                    value={parentCategoryList.filter((option: any) => option.value == parentcatName)}
                    onChange={(e : any) => {
                      setParentCatName(e.value)
                      // const validation = ValidationHandler(e.value, 'is-invalid')
                      // setParValidation(validation)
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0C78A4',
                      },
                    })}
                  />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* edit categories */}
      <EaModal
        cancel={clearFunctionEdit}
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={() => updateFunction()}
        closeAuto={'ea_close_update_product_category'}
        id='ea_modal_edit_product_category'
        modalTitle='Edit Product Category'
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Category Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            id='catNameEdit'
            value={catNameEdit}
            onChange={(e) => {
              setCatNameEdit(capitalizeFirstLetter(e.target.value))
            }}
            className={clsx('form-control', { 'is-invalid': catNameEdit.trim() == '' })}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Parent Category (If Available)</label>
            <AsyncPaginate
                    loadOptions={fetchParentCategoryAdd}
                    isSearchable
                    className={`react-select-container  ${parValidationEdit}`}
                    classNamePrefix='react-select'
                    menuPosition='fixed'
                    placeholder='Select'
                    name='product'
                    value={parentCategoryList.filter((option: any) => option.value == parentcatNameEdit)}
                    onChange={(e : any) => {
                      setParentCatNameEdit(e.value)
                      // const validation = ValidationHandler(e.value, 'is-invalid')
                      // setParValidationEdit(validation)
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0C78A4',
                      },
                    })}
                  />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* delete categories */}
      <EaModal
        modalTitle='Delete Product Category'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{catNameDelete}?</span>
          </p>
        }
        onSubmit={deleteFuntion}
        id={'deleteProductCategories'}
        closeAuto='ea_close_delete_product_category'
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default ProductCategoriesMaster
