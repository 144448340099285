import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { apiBaseURL, checkPermissions, defaultPermission, KTSVG, retrieveFilter, saveFilter, toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall"
import { EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}

const Products: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const masterHeaderTitle = {
    title: 'Categories',
    subTitle: 'CMS',
  }

  let productsFiltered : any = retrieveFilter(filters.cms_products);

  const initialFiltersState = {
      selected: productsFiltered?.selected || '',
      search: productsFiltered?.search || '',
      currentOffSet: productsFiltered?.currentOffSet || '',
      buttonDisable: true
    } 

  // all states

  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState<number>(productsFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(productsFiltered?.currentOffSet || '0');
  const [count, setCount] = useState('1')
  const [timer, setTimer] = useState<number>(0)
  const [search, setSearch] = useState<string>(productsFiltered?.search || '')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [products, setProducts] = useState([])
  const [messageHandler, setMessageHandler] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(productsFiltered?.buttonDisable || false) 

  const cmsProducts: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    localStorage.removeItem('ProductsId')
    if (localStorage.getItem('type')) {
      // setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => fetchProducts().then(), timer)
  }, [currentOffset, debounceSearch, count, products.length])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
  }

  const fetchProducts = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}cms/product/?limit=${limit}&search=${debounceSearch}&offset=${currentOffset}&ordering=-updated_date`
    const response = await getRequest(API, true)
    setProducts(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.cms_products , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}


  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  return (
    <>
      <EATitle title='Products for Website - ' />
      <PageTitle breadcrumbs={cmsProducts}>
        {intl.formatMessage({id: 'Products for Website'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={FormatMessage('CMS_PRODUCTS_GROUP.UPDATE')}
      />
      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        {/* <div className='card-header border-0 px-7'> */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
          {/* <div className='card-toolbar row g-0 w-100'> */}
            <CustomSearchBar onChange={handleChangeSearch}  value={search} disabled={listLoadder}/>
            <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
          </div>
        {/* </div> */}
        <div className='card-body py-3'>
          <div className='table-responsive'>
          {products.length == 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-120px py-2'>Product Group Name</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products &&
                          products.length > 0 &&
                          products.map((item: any) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-28px me-2'>
                                        <span className='symbol-label'>
                                          {item.cover_image == null ? (
                                            <img
                                              alt='group image'
                                              className='h-35px rounded-1'
                                              src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                            />
                                          ) : (
                                            <img
                                              src={item.cover_image}
                                              className='h-40 rounded-1'
                                              alt=''
                                              style={{width: '100%', height: '100%'}}
                                            />
                                          )}
                                        </span>
                                      </div>
                                      <div className='mb-4'>
                                        <span>{item.product_name}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='text-end'>
                                    {permission.write && (
                                      <a
                                        onClick={() => {
                                          localStorage.setItem('productsId', item.id)
                                          navigate('edit-products')
                                        }}
                                        // href='#'
                                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/art/art005.svg'
                                          className='ic mr-0'
                                        />
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
          </div>
        </div>
      </div>
       <CustomPaginate
              data={products}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.cms_products}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
    </>
  )
}

export default Products
