import React, {useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  checkPermissions,
  dateFormat,
  defaultPermission,
  fetchAsyncDropdown,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  IMPERSONATE_FAILED,
  IMPERSONATE_FOR_INACTIVE,
  isEmpty,
  isModalValidation,
  KTSVG,
  removeFilterImpersonate,
  toAbsoluteUrl,
} from '../../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest, patchRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../../_eaFruitsDms/layout/components/button/eaButton'
import {getImpersonationUserDetails} from '../../../../../_eaFruitsDms/localStorage/setItem/setLocalStorage'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import ErrorHandler from '../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {validateEmail} from '../../../../../_eaFruitsDms/validations/eaValidation'
import clsx from 'clsx'
import capitalizeFirstLetter from '../../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import {getW_Id} from '../../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import MobileNumberInput from '../../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'
import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import DatePickerCustom from '../../../../../_eaFruitsDms/helpers/datePicker'
import {Modal} from 'react-bootstrap'
import tenantConfiguration from '../../../../../TenantVariables'

const TeamMemberHeader = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()
  const teamId = localStorage.getItem('teamId')
  const id = teamId ? parseInt(teamId) : teamId
  const splashScreen = document.getElementById('splashScreenStyle')
  const isImpersonationLogin = localStorage.getItem('isImpersonationLogin')
  const masterHeaderTitle = {title: 'Team members'}
  const initialRoleModal = {label: 'Select', value: ''}
  const emailValidationMessage = 'Wrong email format'

  const initialTeamMember = {
    first_name: '',
    last_name: '',
    gender: '',
    email: '',
    warehouse: '',
    type: '',
    date_of_birth: '',
    is_active: false,
    phone_no: '',
    role: '',
    supervisor: '',
    // user_ward_brand: '',
    sales_teams: '',
    sales_team: '',
    is_active_pay_later_today: false,
    tra_device_id: null,
    tra_device_phone: null,
    tra_device_serial_number: null,
    tra_device_name: null,
    tra_device_password: null,
  }
  const initialGender = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'},
  ]
  const warehouseManagerUserType = [
    {value: 'am', label: 'Account Manager'},
    {value: 'lo', label: 'Logistics Officer'},
    {value: 'mo', label: 'Marketing Officer'},
    {value: 'oo', label: 'Operations Officer'},
    {value: 'ss', label: 'Sales Supervisor'},
    {value: 'sr', label: 'Sales Representatives'},
    {value: 'sg', label: 'Security Gatekeeper'},
    {value: 'wm', label: 'Branch Manager'},
  ]
  const initialWardBrand: any = {
    ward: '',
    brand: [],
  }
  const initialSalesTeamWards: any = {
    salesTeam: '',
    wards: [],
  }
  const initialBrands: any = {brands: []}
  // date state
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    alwaysShowCalendars: true,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    maxDate: moment(),
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    parentEl: '.modal',
    drops: 'up',
    autoUpdateInput: false,
    showDropdowns: true,
  })

  const [teamMember, setTeamMember] = useState(initialTeamMember)

  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')

  // supervisor modal filter state
  const [supervisorOffset, setSupervisorOffset] = useState('')
  const [supervisorList, setSupervisorList] = useState([])
  const [supervisorSearch, setSupervisorSearch] = useState('')

  // role filter state
  const [roleOffset, setRoleOffset] = useState('')
  const [roleList, setRoleList] = useState([])
  const [roleSearch, setRoleSearch] = useState('')

  // ward filter state
  const [wardsSalesTeamOffset, setWardsSalesTeamOffset] = useState('')
  const [ward, setWard] = useState<boolean>(true)
  const [wardsSalesTeamSearch, setWardsSalesTeamSearch] = useState('')
  const [wardAPIDone, setWardAPIDone] = useState<boolean>(false)

  // sales team filter state
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [salesTeam, setSalesTeam] = useState<any>('')

  // modal state
  const [isModalLoading, setIsModalLoading] = useState('Submit')
  const [date, setDate] = useState('Select')

  //   - modal dropdown value state
  const [supervisor, setSupervisor] = useState<any>('')
  const [userType, setUserType] = useState<any>('')
  const [roleModal, setRoleModal] = useState(initialRoleModal)
  const [warehouseModal, setWarehouseModal] = useState<any>('')
  const [gender, setGender] = useState<any>('')
  const [errorMessageEdit, setErrorMessageEdit] = useState<any>('')

  // validation state
  const [isValidation, setIsValidation] = useState(false)
  const [isCorrectEmail, setIsCorrectEmail] = useState('')
  const [isWard, setIsWard] = useState('')

  // alert state
  const [showAlert, setShowAlert] = useState(false)
  const [alertName, setAlertName] = useState('')

  // status state
  const [checked, setChecked] = useState<any>(false)

  // allow pay later today state
  const [allowPayLaterToday, setAllowPayLaterToday] = useState<any>(true)

  // tra state
  const [showPassword, setShowPassword] = useState(false)
  const [serialNumberValid, setSerialNumberValid] = useState('')
  const [showSrConfirm, setShowSrConfirm] = useState(false)
  const [isTraValid, setIsTraValid] = useState(false)

  // ward-brand state
  const [wardBrand, setWardBrand] = useState([initialWardBrand])
  const [remove, setRemove] = useState(false)
  const [add, setAdd] = useState(false)
  const [brands, setBrands] = useState<any>([initialBrands])
  const [wardReset, setWardReset] = useState<any>(moment())

  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [impersonate, setImpersonate] = useState(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  const [isButtonLoader, setIsButtonLoader] = useState<boolean>(false)

  // sales-team-brand state
  const [salesTeamWards, setSalesTeamWards] = useState([initialSalesTeamWards])
  const [salesTeamOffset, setSalesTeamOffset] = useState('')
  const [salesTeamList, setSalesTeamList] = useState([])
  const [salesTeamSearch, setSalesTeamSearch] = useState('')
  const [salesTeamReset, setSalesTeamReset] = useState<any>(moment())
  const [salesTeamWardsReset, setSalesTeamWardsReset] = useState<any>(moment())
  const [salesTeamWardsUniqError, setSalesTeamWardsUniqError] = useState<any>([])
  const [resetSalesTeam, setResetSalesTeam] = useState<any>(moment())

  const handleImpersonation = async () => {
    if (!props.isImpersonateAllow) {
      setErrorMessage(IMPERSONATE_FAILED)
      setIsAlertFailed(true)

      setTimeout(() => {
        setIsAlertFailed(false)
      }, 3000)
    } else {
      if (!props.isInActive) {
        setErrorMessage(IMPERSONATE_FOR_INACTIVE)
        setIsAlertFailed(true)

        setTimeout(() => {
          setIsAlertFailed(false)
        }, 3000)
      } else {
        // remove filter's data from localstorage
        removeFilterImpersonate()
        let body = {
          user_id_to_impersonate: id,
        }
        setIsButtonLoader(true)
        const response = await postRequest(`${apiBaseURL()}auth/impersonate`, body, true)
        setIsButtonLoader(false)

        if (response.status == 200) {
          if (response.data.error) {
            setIsAlertFailed(true)

            setTimeout(() => {
              setIsAlertFailed(false)
            }, 3000)
          } else {
            if (splashScreen) {
              splashScreen.style.setProperty('display', 'flex')
            }

            let impersonationUser = {
              role: response.data.role,
              warehouse: response.data.warehouse,
              warehouse_name: response.data.warehouse_name,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              profile_thumbnail: response.data.profile_thumbnail,
              base_url: response.data.base_url,
              user_type: response.data.type,
              id: response.data.request_to,
              token: response.data.token,
              email: response.data.email,
              role_name: response.data.role_name,
            }

            // NOTE : here set the details of login user
            localStorage.setItem('isImpersonationLogin', 'true')
            localStorage.removeItem('permissions')
            getImpersonationUserDetails(impersonationUser)

            // redirect to user panel
            navigate('/dashboard')
            window.location.reload()
          }
        } else {
          setErrorMessage('Something went wrong.! Please try again after sometime.!')

          setIsAlertFailed(true)

          setTimeout(() => {
            setIsAlertFailed(false)
          }, 3000)
        }
      }
    }
  }

  ////////////////// for TRA  //////////////////////////
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const isAlertSHow = (val: any) => {
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  useEffect(() => {
    fetchPermission()
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions('team_member')
    setPermission(res)

    let impersonate = await checkPermissions('team_member_impersonation')
    if (impersonate && impersonate.read) {
      setImpersonate(true)
    } else {
      setImpersonate(false)
    }
  }

  useEffect(() => {
    setWarehouseOffset('')
  }, [warehouseSearch])

  useEffect(() => {
    setSupervisorOffset('')
  }, [supervisorSearch])

  useEffect(() => {
    setRoleOffset('')
  }, [roleSearch])

  useEffect(() => {
    setWardsSalesTeamOffset('')
  }, [wardsSalesTeamSearch])

  // Edit team member
  const teamMemberDetail = async () => {
    setAdd(true)
    setWard(false)
    setModalLoader(true)
    const response = await getRequest(`${apiBaseURL()}auth/users/${id}/`, true)

    if (response.id) {
      setTeamMember({
        ...teamMember,
        first_name: response.first_name,
        last_name: response.last_name,
        gender: response.gender,
        email: response.email,
        warehouse: response.warehouse ? response.warehouse.id : '',
        type: response.type.toLowerCase(),
        date_of_birth: response.date_of_birth,
        is_active: response.is_active,
        phone_no: response.phone_no,
        role: response.role ? response.role.id : '',
        supervisor: response.supervisor ? response.supervisor.id : '',
        // user_ward_brand: response.user_ward_brand.length !== 0 ? response.user_ward_brand : '',
        sales_teams: response.sales_teams.length !== 0 ? response.sales_teams : '',
        sales_team: response.sales_team ? response.sales_team.id : '',
        is_active_pay_later_today: isEmpty(response.is_active_pay_later_today)
          ? false
          : response.is_active_pay_later_today,
        tra_device_id: isEmpty(response.tra_device_id) ? null : response.tra_device_id,
        tra_device_name: isEmpty(response.tra_device_name) ? null : response.tra_device_name,
        tra_device_password: isEmpty(response.tra_device_password)
          ? null
          : response.tra_device_password,
        tra_device_phone: isEmpty(response.tra_device_phone) ? null : response.tra_device_phone,
        tra_device_serial_number: isEmpty(response.tra_device_serial_number)
          ? null
          : response.tra_device_serial_number,
      })
      !isEmpty(response.is_active_pay_later_today) &&
        setAllowPayLaterToday(response.is_active_pay_later_today)

      setModalLoader(false)

      let findGender = initialGender.find((item) => item.value == response.gender)
      setGender(findGender)
      response &&
        response.sales_team &&
        setSalesTeam({label: response.sales_team.name, value: response.sales_team.id})
      let findUserType = warehouseManagerUserType.find(
        (item) => item.value == response.type.toLowerCase()
      )
      setUserType(findUserType)

      let selectedRole = response.role && {label: response.role.name, value: response.role.id}
      response.role && setRoleModal(selectedRole)

      let selectedDate = dateFormat(response.date_of_birth)
      setDate(selectedDate.displayDate)

      setChecked(response.is_active)

      let selectedSupervisor = response.supervisor && {
        label: response.supervisor.first_name + ' ' + response.supervisor.last_name,
        value: response.supervisor.id,
      }
      response.supervisor && setSupervisor(selectedSupervisor)

      let selectedWarehouse = response.warehouse && {
        label: response.warehouse.name,
        value: response.warehouse.id,
      }

      response.warehouse && setWarehouseModal(selectedWarehouse)

      if (!wardAPIDone) {
        // fetchingWards('', selectedWarehouse.value).then()
      }

      if (response.sales_teams.length > 0) {
        let salesTeamWards = response.sales_teams.map((item: any) => ({
          salesTeam: {label: item.sales_team_name, value: item.sales_team_id},
          wards:
            item.wards && item.wards.length > 0
              ? item.wards.map((i: any) => ({label: i.ward_name, value: i.ward_id}))
              : [],
        }))

        setSalesTeamWards(salesTeamWards)
      }

      if (response.user_ward_brand.length !== 0) {
        let selectedWardsBrands: any = []
        let concateArray: any = []

        response.user_ward_brand.map(async (item: any, index: any) => {
          let selectedBrands: any = []
          let mergeBrands: any = []

          item.brand.length !== 0 &&
            item.brand.map((brand: any) => {
              selectedBrands.push({label: brand.name, value: brand.id})
              mergeBrands.push({label: brand.name, value: brand.id})
            })

          selectedWardsBrands.push({
            ward: {label: item.ward.name, value: item.ward.id},
            brand: selectedBrands,
          })

          let url = `${apiBaseURL()}master/product/brand/view?ward_id=${
            item.ward.id
          }&ordering=name&limit=1000`

          const res = await getRequest(url, true)

          res.results.length !== 0 &&
            res.results.map((brand: any, index: any) => {
              mergeBrands.push({label: brand.name, value: brand.id})
            })

          concateArray.push({brands: mergeBrands})
        })

        setWardBrand(selectedWardsBrands)

        setTimeout(() => {
          setBrands(concateArray)
        }, 500)
      }
    }
  }

  // dropwdown functions
  const fetchWarehouseModal = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${getW_Id}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  const fetchRolesModal = useCallback(
    async (search?: any) => {
      let userId = userType?.value
      return fetchAsyncDropdown(
        roleOffset,
        setRoleOffset,
        roleList,
        setRoleList,
        `auth/application/role/list/?ordering=name&user_type__in=${userId}`,
        search,
        setRoleSearch,
        false,
        false
      )
    },
    [userType]
  )

  const fetchSupervisor = useCallback(
    async (search?: any) => {
      let warehouseId = warehouseModal?.value
      let roleName = ''

      if (teamMember.type.toLowerCase().includes('sr')) {
        roleName = 'Sales Supervisor'
      }

      if (roleModal.label.includes('Logistics Officer')) {
        roleName = 'Logistics Supervisor'
      }

      return fetchAsyncDropdown(
        supervisorOffset,
        setSupervisorOffset,
        supervisorList,
        setSupervisorList,
        `auth/users/team/list?role__name=${roleName}&warehouse_id=${warehouseId}&warehouse__id=${warehouseId}&ordering=name`,
        search,
        setSupervisorSearch,
        'first_name',
        false
      )
    },
    [warehouseModal, teamMember, roleModal]
  )

  const fetchSalesTeamOfWards = async (search?: any) => {
    let selectedSalesTeam = [...salesTeamWards]
    let selectedSalesTeamWards: any = []

    if (add) {
      selectedSalesTeam.length !== 1 &&
        selectedSalesTeam.forEach((item) => {
          if (item.salesTeam.value) {
            selectedSalesTeamWards.push(item.salesTeam.value)
          }
        })
    }

    // for search in AsyncDropdown
    if (search != '') {
      setWardsSalesTeamSearch(search)
    }

    let limit = tenantConfiguration.limit
    let options: any = []

    let concatUrl = `${apiBaseURL()}master/sales-team?ordering=name&search=${search}&limit=${limit}&offset=${wardsSalesTeamOffset}`

    setWardAPIDone(true)

    const response = await getRequest(concatUrl, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setWardsSalesTeamOffset(newOffset)
      hasMore = true
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        if (selectedSalesTeamWards.length !== 0) {
          if (!selectedSalesTeamWards.includes(option.id)) {
            options.push({
              label: option.name,
              value: option.id,
            })
          }
        } else {
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    let wardLength = options.length

    if (remove) {
      wardLength += 1
    }

    if (wardLength == 1 || wardLength == 0) {
      setWard(false)
    } else {
      setWard(true)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSalesTeamWards = async (search: any, salesTeam: any) => {
    let warehouseId = warehouseModal?.value
    let salesTeamId = salesTeam?.value ?? ''

    return fetchAsyncDropdown(
      salesTeamOffset,
      setSalesTeamOffset,
      salesTeamList,
      setSalesTeamList,
      // `master/wards/list/?warehouse_id=${warehouseId}&sales_team_id=${salesTeamId}`,
      `master/product/wards/view/test?warehouse_id=${warehouseId}&sales_team_id=${salesTeamId}`,
      search,
      setSalesTeamSearch,
      'name',
      false
    )
  }

  const fetchSalesTeam = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `master/sales-team?ordering=name&supervisor_id=${
        isEmpty(supervisor) ? '' : supervisor?.value
      }`,
      search,
      setDropDownSearch,
      'name',
      false
    )
  }

  // onChange handler functions
  const userTypeOnChangeHandler = (e: any) => {
    setRoleModal(initialRoleModal)
    setWarehouseModal('')
    setSupervisor('')
    setRoleOffset('')
    setWarehouseOffset('')
    setSupervisorOffset('')
  }

  const handleState = (state: any, key: string) => {
    if (key === 'text') {
      setTeamMember({
        ...teamMember,
        [state.target.name]:
          state.target.name === 'email'
            ? state.target.value
            : capitalizeFirstLetter(state.target.value),
      })
    }
    if (key === 'type') {
      setTeamMember({
        ...teamMember,
        type: state.value,
        warehouse: getW_Id,
        supervisor: '',
        role: '',
        tra_device_id: null,
        tra_device_name: null,
        tra_device_password: null,
        tra_device_phone: null,
        tra_device_serial_number: null,
      })
    }

    if (key === 'warehouse') {
      setTeamMember({
        ...teamMember,
        warehouse: state.value,
        supervisor: '',
        sales_teams: '',
      })
      setWardBrand([initialWardBrand])
      setBrands([initialBrands])
      setRemove(false)
      setAdd(false)
      setWard(true)
    }
    if (key === 'role') {
      setTeamMember({
        ...teamMember,
        role: state.value,
        supervisor: '',
      })
    }

    if (key === 'supervisor') {
      setTeamMember({
        ...teamMember,
        supervisor: state.value,
      })

      setResetSalesTeam(moment())
      setOffset(0)
      setList([])
      setDropDownSearch('')
    }

    if (key === 'sales_team') {
      setTeamMember({
        ...teamMember,
        sales_team: state.value,
      })
    }

    if (key === 'gender') {
      setTeamMember({
        ...teamMember,
        gender: state.value,
      })
    }

    if (key === 'date') {
      setTeamMember({
        ...teamMember,
        date_of_birth: state,
      })
    }

    if (key === 'status') {
      setTeamMember({
        ...teamMember,
        is_active: state ? true : false,
      })
    }
  }

  const handleBrands = (e: any, removeEvent: any, index: any) => {
    let totalWardBrand = [...wardBrand]

    totalWardBrand[index].brand = e

    setWardBrand(totalWardBrand)
  }

  const onWardChange = async (event: any, index: any) => {
    let totalWardBrand = [...wardBrand]
    setTimeout(() => {
      setBrands([initialBrands])
    }, 500)
    let totalBrand = [...brands]

    totalWardBrand[index].ward = event
    totalWardBrand[index].brand = null

    let url = `${apiBaseURL()}master/product/brand/view?ward_id=${
      event.value
    }&ordering=name&limit=1000`

    const response = await getRequest(url, true)

    if (response.results.length !== 0) {
      let options: any = []

      response.results.map((item: any) => {
        options.push({
          label: item.name,
          value: item.id,
        })
      })

      if (totalWardBrand[index]) {
        totalWardBrand[index].brand = options
      }
    }

    setBrands(totalBrand)
    setWardBrand(totalWardBrand)
    setIsWard('')
  }

  const addWardBrandRows = () => {
    setRemove(false)
    setAdd(true)
    setWardReset(moment())
    let isWardAddOrNot: any = []

    wardBrand.forEach((item, index) => {
      if (item.ward === '') {
        isWardAddOrNot.push(index)
      }
    })

    if (isWardAddOrNot.length === 0) {
      setWardBrand([...wardBrand, initialWardBrand])
      setWardsSalesTeamOffset('')
      setIsWard('')
      setBrands([...brands, initialBrands])
    } else {
      setIsWard('Please select the ward & brand(s) above first!')
    }
  }

  const removeWardBrandRows = (index: any) => {
    setRemove(true)
    setWardReset(moment())
    let totalWardBrand = [...wardBrand]
    let filterWardBrand: any = []
    if (totalWardBrand.length !== 0) {
      totalWardBrand.forEach((item, wardBrandIndex) => {
        if (wardBrandIndex !== index) {
          filterWardBrand.push(item)
        }
      })
    }

    setWardBrand(filterWardBrand)
  }

  // submit and clear functions
  const cancleFunction = () => {
    setIsTraValid(false)
    setSerialNumberValid('')
    setShowPassword(false)
    setIsValidation(false)
    setRoleOffset('')
    setRoleModal(initialRoleModal)
    setSalesTeamWards([initialSalesTeamWards])
    setWarehouseOffset('')
    setWarehouseModal('')
    setSupervisorOffset('')
    setWardsSalesTeamOffset('')
    setSupervisor('')
    setGender('')
    setDate('Select')
    setTeamMember(initialTeamMember)
    setUserType('')
    setChecked('')
    setIsCorrectEmail('')
    setChecked(true)
    setIsWard('')
    setWardBrand([initialWardBrand])
    setBrands([initialBrands])
    setRemove(false)
    setAdd(false)
    setWard(true)
    setErrorMessageEdit('')
    setModalLoader(false)
    setSalesTeam('')
  }

  const addSalesTeamWardsRow = (e: any) => {
    e.preventDefault()

    setRemove(false)
    setAdd(true)
    setSalesTeamReset(moment())

    let isSalesTeamAddOrNot: any = []

    salesTeamWards.forEach((item, index) => {
      if (item.salesTeam === '' || item.wards.length === 0) {
        isSalesTeamAddOrNot.push(index)
      }
    })

    if (isSalesTeamAddOrNot.length === 0) {
      setSalesTeamWards([...salesTeamWards, initialSalesTeamWards])
      setWardsSalesTeamOffset('')
      setIsWard('')
      // setBrands([...brands, initialBrands])
    } else {
      setIsWard('Please select the sales team & ward(s) above first!')
    }
  }

  const removeSalesTeamWards = (e: any, index: any) => {
    e.preventDefault()

    setRemove(true)
    setSalesTeamReset(moment())

    let totalSalesTeamWards = [...salesTeamWards]
    let filterSalesTeamWards: any = []

    if (totalSalesTeamWards.length !== 0) {
      totalSalesTeamWards.forEach((item, salesTeamWardsIndex) => {
        if (salesTeamWardsIndex !== index) {
          filterSalesTeamWards.push(item)
        }
      })
    }

    setSalesTeamWards(filterSalesTeamWards)
  }

  const submitTeamMemberModal = async () => {
    let navigateField = {
      first_name: teamMember.first_name,
      last_name: teamMember.last_name,
      email: teamMember.email,
      phone_no: teamMember.phone_no,
      userType: userType,
      roleModal: roleModal,
      warehouseModal: warehouseModal,
      supervisor: supervisor,
      gender: gender,
      sales_team: salesTeam,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    setIsValidation(true)

    let selectedSalesTeamWards = [...salesTeamWards]
    let wardsSalesTeamPayload: any = []

    if (selectedSalesTeamWards[0]?.salesTeam !== '') {
      selectedSalesTeamWards.map((item: any) => {
        let selectedWardsIds: any = []

        if (item.wards) {
          item.wards.map((i: any) => {
            selectedWardsIds.push(i.value)
          })
        }

        wardsSalesTeamPayload.push({
          sales_team_id: item.salesTeam !== '' && item.salesTeam.value,
          wards: selectedWardsIds,
        })
      })
    }

    teamMember.sales_teams = wardsSalesTeamPayload

    // object loop for validation
    let modalProperty: any = {...teamMember}
    let requiredModalProperty: any = {}
    for (const property in modalProperty) {
      if (property == 'type') {
        if (modalProperty[property] == '') {
          delete modalProperty['warehouse']
        }
        if (modalProperty[property] == 'wm') {
          delete modalProperty['warehouse']
        }
        if (modalProperty[property] !== 'sr' && !roleModal.label.includes('Logistics Officer')) {
          delete modalProperty['supervisor']
        }
        if (modalProperty[property] !== 'sr') {
          delete modalProperty['sales_team']
        }
      }

      if (property == 'role') {
        if (modalProperty['type'] !== 'SR' && !roleModal.label.includes('Logistics Officer')) {
          delete modalProperty['supervisor']
        }
      }

      if (property == 'email') {
        if (!validateEmail(modalProperty['email'])) {
          modalProperty['email'] = ''
        }
      }

      if (property == 'sales_teams') {
        if (teamMember.type.includes('ss')) {
          let wardsSalesTeamsArray = modalProperty[property]
          let isValid: any = []
          if (wardsSalesTeamsArray.length === 0) {
            modalProperty['sales_teams'] = ''
          }

          wardsSalesTeamsArray.length !== 0 &&
            wardsSalesTeamsArray.map((item: any, index: any) => {
              if (item.salesTeam === '') {
                isValid.push(index)
              }
              if (item.wards.length === 0) {
                isValid.push(index)
              }
            })

          if (isValid.length !== 0) {
            modalProperty['sales_teams'] = ''
          }
        } else {
          delete modalProperty['sales_teams']
        }
      }

      if (modalProperty['type'] === 'sr') {
        if (
          !isEmpty(modalProperty['tra_device_serial_number']) ||
          !isEmpty(modalProperty['tra_device_password']) ||
          !isEmpty(modalProperty['tra_device_name']) ||
          !isEmpty(modalProperty['tra_device_id']) ||
          !isEmpty(modalProperty['tra_device_phone'])
        ) {
          setIsTraValid(true)
          if (property === 'tra_device_serial_number') {
            if (
              typeof modalProperty['tra_device_serial_number'] === 'string' &&
              modalProperty['tra_device_serial_number'].length < 9
            ) {
              setSerialNumberValid('Serial number should be minimum 8 characters long')
              modalProperty['tra_device_serial_number'] = ''
              return
            }
          }
        } else {
          setIsTraValid(false)
          delete modalProperty['tra_device_name']
          delete modalProperty['tra_device_password']
          delete modalProperty['tra_device_serial_number']
          delete modalProperty['tra_device_id']
          delete modalProperty['tra_device_phone']
        }
      } else {
        delete modalProperty['tra_device_id']
        delete modalProperty['tra_device_phone']
        delete modalProperty['tra_device_name']
        delete modalProperty['tra_device_password']
        delete modalProperty['tra_device_serial_number']
      }

      requiredModalProperty = modalProperty
    }

    if (isModalValidation(requiredModalProperty).length === 0) {
      setIsModalLoading('Please Wait...')

      setAlertName(teamMember.first_name + ' ' + teamMember.last_name)

      let patchUrl = `${apiBaseURL()}auth/users/update/${id}/test`

      teamMember.type = teamMember.type.toLowerCase()
      teamMember.is_active_pay_later_today = teamMember.type.toLowerCase().includes('sr')
        ? allowPayLaterToday
        : false

      let updatedTeamMember: any = {...teamMember}

      if (teamMember.type.toLocaleLowerCase() === 'sr') {
        if (showSrConfirm) {
          updatedTeamMember.tra_warning = false
        } else {
          updatedTeamMember.tra_warning = true
        }

        if (
          isEmpty(updatedTeamMember['tra_device_serial_number']) ||
          isEmpty(updatedTeamMember['tra_device_password']) ||
          isEmpty(updatedTeamMember['tra_device_name']) ||
          isEmpty(updatedTeamMember['tra_device_id']) ||
          isEmpty(updatedTeamMember['tra_device_phone'])
        ) {
          updatedTeamMember['tra_device_serial_number'] = null
          updatedTeamMember['tra_device_password'] = null
          updatedTeamMember['tra_device_name'] = null
          updatedTeamMember['tra_device_id'] = null
          updatedTeamMember['tra_device_phone'] = null
        }
      }

      const response = await patchRequest(patchUrl, updatedTeamMember, true)
      let successArray = [201, 200]
      let failArray = [400, 500]

      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail(response)
    }
  }

  const responseSuccess = () => {
    // getTeamMemberList()
    setShowAlert(true)
    setIsModalLoading('Submit')
  }

  const responseFail = (response: any) => {
    setIsModalLoading('Submit')
    if (response && response.data && response.data.error) {
      if (response.data.error.field === 'email') {
        setErrorMessageEdit(
          'Your added email address already exists in this system! Please try again with a unique email address.'
        )
      }

      if (response.data.error.field === 'tra_serial_number_password_exist') {
        setShowSrConfirm(true)
      }

      if (response.data.error.field === 'tra_device_validate') {
        setErrorMessage(response.data.error.error_message)
      }

      if (response.data.error.field === 'combination_already_exists') {
        setSalesTeamWardsUniqError(response?.data?.error?.data ?? [])
        // setErrorMessage(response.data.error.error_message)
      }
    }
  }

  // custom date-picker function
  const CustomDatePicker = () => {
    return (
      <>
        <DatePickerCustom
          onCallback={(start: any, end: any, label: any) => {
            let formatDate = dateFormat(start._d)
            setDate(formatDate.displayDate)
            setInitialSettings({...initialSettings, startDate: start._d})
            handleState(formatDate.apiDate, 'date')
          }}
          initialSettings={initialSettings}
          dateValidation={isValidation && teamMember.date_of_birth === '' ? 'is-invalid' : ''}
          dateValue={date}
        />
      </>
    )
  }

  const findSalesTeamWithId = (id: any) => {
    const error = salesTeamWardsUniqError

    if (error?.sales_team_id === id) {
      return (
        <p className='text-danger'>
          Sales team should be paired with unique wards to avoid duplicate combinations.{' '}
          {error?.wards.map((item: any, index: any) => {
            return (
              <>
                <b>
                  {index ? ',' : ''} {item.ward_name}{' '}
                </b>
              </>
            )
          })}{' '}
          are already assigned to <b>{error?.sales_team_name}</b>
        </p>
      )
    }

    return ''
  }

  return (
    <>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <EaAlertDanger
            onClick={() => isAlertSHow('failed')}
            show={isAlertFailed}
            message={
              errorMessage === ''
                ? FormatMessage('IMPERSONATE_FAILED', props.fullname)
                : errorMessage
            }
          />
          <EaAlertSuccess
            newProps={true}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            message='TEAM.MEMBER.UPDATE'
            name={alertName}
          />
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-3 me-md-5 mb-3'>
              <div className='symbol symbol-40px symbol-lg-60px symbol-fixed'>
                {props.profileImage === null ? (
                  <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='User' />
                ) : (
                  // <img src={props.profile_img} alt='User' />}
                  <img src={props.profileImage} alt='User' />
                )}
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>{props.fullname}</span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
                      {props.roleName ? props.roleName : '-'}
                    </span>
                    <span className='d-flex align-items-center me-5 mb-2 '>
                      {/* <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='me-1'
                    /> */}
                      <KTSVG path='/media/icons/duotune/ecommerce/ecm009.svg' className='me-1' />
                      {props.getParent === null ? (
                        <b className='me-1'>HQ | </b>
                      ) : (
                        <b className='me-1'>DC | </b>
                      )}
                      {props.getWhName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {permission.write && (
                <a
                  href='#'
                  className='btn btn-secondary me-2'
                  data-bs-toggle='modal'
                  data-bs-target='#ea_modal__user'
                  onClick={teamMemberDetail}
                >
                  Edit
                </a>
              )}
              {props.isHideButton || ['sr', 'ss', 'sg', 'mo'].includes(props.userType) ? (
                ''
              ) : (
                <>
                  {isImpersonationLogin === 'true' && ['WM'].includes(props.userType) ? (
                    ''
                  ) : (
                    <>
                      {impersonate && (
                        <EaButton
                          isModal={false}
                          isDisable={isButtonLoader}
                          btnName={
                            isButtonLoader ? 'Please Wait...' : `Login As ${props.firstName}`
                          }
                          onClick={handleImpersonation}
                          className='btn btn-primary btn-sm font-13 my-1'
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/team-members/member-detail/overview' && 'active')
                  }
                  to='/team-members/member-detail/overview'
                >
                  Overview
                </Link>
              </li>
              {/* because this page is still static, hidden by Kriti */}
              {/* {['SR', 'SS'].includes(props.userType) ? (
              <>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/team-members/member-detail/customers' && 'active')
                    }
                    to='/team-members/member-detail/customers'
                  >
                    Customers
                  </Link>
                </li>
              </>
            ) : (
              ''
            )} */}

              {/* {['SS'].includes(props.userType) ? (
              <>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/team-members/member-detail/sales-reps' && 'active')
                    }
                    to='/team-members/member-detail/sales-reps'
                  >
                    Sales Reps.
                  </Link>
                </li>
              </>
            ) : (
              ''
            )} */}

              {/* Sales Reps */}
              {/* because this page is still static, hidden by Kriti */}
              {/* {['SR'].includes(props.userType) ? (
              <>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/team-members/member-detail/attendance' && 'active')
                    }
                    to='/team-members/member-detail/attendance'
                  >
                    Attendance
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/team-members/member-detail/past-orders' && 'active')
                    }
                    to='/team-members/member-detail/past-orders'
                  >
                    Past Orders
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/team-members/member-detail/targets' && 'active')
                    }
                    to='/team-members/member-detail/targets'
                  >
                    Targets
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/team-members/member-detail/payment-issue' &&
                        'active')
                    }
                    to='/team-members/member-detail/payment-issue'
                  >
                    Payment Issues
                  </Link>
                </li>
              </>
            ) : (
              ''
            )} */}
            </ul>
          </div>
        </div>
      </div>

      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={isModalLoading !== 'Submit' ? true : false}
        cancel={cancleFunction}
        onSubmit={submitTeamMemberModal}
        id='ea_modal__user'
        modalTitle='Edit Team Member'
        loader={modalLoader}
      >
        <>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='mb-4'>
                <label className='form-label'>
                  First Name<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className={clsx(
                    'form-control',
                    isValidation && teamMember.first_name === '' ? 'is-invalid' : ''
                  )}
                  onChange={(e) => {
                    handleState(e, 'text')
                  }}
                  value={teamMember.first_name}
                  name='first_name'
                  placeholder='Type here...'
                  id='first_name'
                />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='mb-4'>
                <label className='form-label'>
                  Last Name<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className={clsx(
                    'form-control',
                    isValidation && teamMember.last_name === '' ? 'is-invalid' : ''
                  )}
                  onChange={(e) => {
                    handleState(e, 'text')
                  }}
                  value={teamMember.last_name}
                  name='last_name'
                  id='last_name'
                  placeholder='Type here...'
                />
              </div>
            </div>
          </div>
          <div className='mb-4'>
            <label className='form-label'>
              Email<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              disabled
              className={clsx(
                'form-control',
                isValidation && teamMember.email === '' ? 'is-invalid' : ''
              )}
              onChange={(e) => {
                handleState(e, 'text')
              }}
              value={teamMember.email}
              name='email'
              id='email'
              placeholder='Type here...'
              onBlur={() => {
                if (teamMember.email !== '') {
                  if (!validateEmail(teamMember.email)) {
                    setIsCorrectEmail(emailValidationMessage)
                  } else {
                    setIsCorrectEmail('')
                  }
                }
              }}
            />
            <div className='mt-4'>
              <ErrorHandler errorMessage={isCorrectEmail} />
            </div>
          </div>
          <div className='mb-4' id='phone_no'>
            <MobileNumberInput
              onChange={(e: any) => {
                const re = /^[0-9\b]+$/
                if (e.target.value === '' || re.test(e.target.value)) {
                  handleState(e, 'text')
                }
              }}
              validation={isValidation && teamMember.phone_no === '' ? 'is-invalid' : ''}
              name='phone_no'
              numberValue={teamMember.phone_no}
            />
          </div>
          <div className='mb-4'>
            <label className='form-label' id='userType'>
              User Type<span className='text-danger'>*</span>
            </label>
            <Select
              options={warehouseManagerUserType}
              isSearchable
              placeholder='Select'
              className={clsx(
                'react-select-container mb-4',
                isValidation && teamMember.type === '' ? 'is-invalid' : ''
              )}
              onChange={(e) => {
                setUserType(e)
                userTypeOnChangeHandler(e)
                handleState(e, 'type')
              }}
              value={userType}
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>
          <div className='mb-4'>
            <label className='form-label' id='roleModal'>
              Role<span className='text-danger'>*</span>
            </label>
            <AsyncPaginate
              loadOptions={fetchRolesModal}
              onChange={(e: any) => {
                setRoleModal(e)
                handleState(e, 'role')
                setSupervisor('')
              }}
              isSearchable
              isDisabled={userType === ''}
              cacheUniqs={[userType]}
              placeholder='Select'
              value={roleModal}
              className={`react-select-container  ${
                isValidation && teamMember.role === '' ? 'is-invalid' : ''
              }`}
              classNamePrefix='react-select'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
          </div>

          {
            // hide and show warehouse based on usertype
            teamMember.type !== '' && !teamMember.type.includes('wm') && (
              <div className='mb-4' id='warehouseModal'>
                <label className='form-label'>Warehouse</label>
                <AsyncPaginate
                  loadOptions={fetchWarehouseModal}
                  onChange={(e: any) => {
                    setWarehouseModal(e)
                    setWardReset(moment())
                    handleState(e, 'warehouse')
                    setSupervisor('')
                  }}
                  isSearchable
                  placeholder='Select'
                  value={warehouseModal}
                  className={`react-select-container ${
                    isValidation && teamMember.warehouse === '' ? 'is-invalid' : ''
                  }`}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            )
          }

          {
            // show hide supervisor based on Logistics Officer and usertype
            (teamMember.type.toLowerCase().includes('sr') ||
              roleModal.label.includes('Logistics Officer')) && (
              <div className='mb-4'>
                <label className='form-label' id='supervisor'>
                  Supervisor<span className='text-danger'>*</span>
                </label>
                <AsyncPaginate
                  loadOptions={fetchSupervisor}
                  cacheUniqs={[warehouseModal, teamMember, roleModal]}
                  onChange={(e: any) => {
                    setSupervisor(e)
                    handleState(e, 'supervisor')
                  }}
                  isSearchable
                  isDisabled={warehouseModal === ''}
                  placeholder='Select'
                  value={supervisor}
                  className={`react-select-container  ${
                    isValidation && teamMember.supervisor === '' ? 'is-invalid' : ''
                  }`}
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            )
          }

          {teamMember.type.toLowerCase().includes('sr') && (
            <div className='mb-4'>
              <label className='form-label' id='supervisor'>
                Sales Team<span className='text-danger'>*</span>
              </label>
              <AsyncPaginate
                loadOptions={fetchSalesTeam}
                isSearchable
                key={resetSalesTeam}
                placeholder='All Sales Team'
                className={`react-select-container  ${
                  isValidation && teamMember.sales_team === '' ? 'is-invalid' : ''
                }`}
                classNamePrefix='react-select'
                name='sales_team'
                onChange={(e) => {
                  setSalesTeam(e)
                  handleState(e, 'sales_team')
                }}
                isDisabled={supervisor === ''}
                value={salesTeam}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          )}

          {teamMember.type.toLowerCase().includes('sr') && (
            <div className='mb-4'>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input widget-13-check'
                  name='allowPayLaterToday'
                  checked={allowPayLaterToday}
                  onChange={(e: any) => setAllowPayLaterToday(!allowPayLaterToday)}
                  type='checkbox'
                  value=''
                  id='allowPayLaterToday'
                />
                <label className='form-check-label' htmlFor='allowPayLaterToday'>
                  Enable Pay Later Today
                </label>
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col-lg-6 fv-row mb-4'>
              <label className='form-label' id='gender'>
                Gender<span className='text-danger'>*</span>
              </label>
              <Select
                options={initialGender}
                isSearchable
                placeholder='Select'
                className={clsx(
                  'react-select-container',
                  isValidation && teamMember.gender === '' ? 'is-invalid' : ''
                )}
                onChange={(e) => {
                  setGender(e)
                  handleState(e, 'gender')
                }}
                value={gender}
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div className='col-lg-6 fv-row mb-4'>
              <label className='form-label'>
                Date of Birth<span className='text-danger'>*</span>
              </label>
              <CustomDatePicker />
            </div>
          </div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label me-3 ms-0'>Access Status</label>
            <input
              checked={checked}
              id='accessStatus'
              onChange={(e) => {
                let checked = $('#accessStatus').is(':checked')
                handleState(checked, 'status')
                if (checked == true) {
                  setChecked(true)
                } else {
                  setChecked(false)
                }
              }}
              value={checked}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              defaultChecked={true}
            />
          </div>
        </>
        {warehouseModal &&
          teamMember.type.includes('ss') &&
          teamMember.warehouse !== '' &&
          teamMember.role !== '' && (
            <>
              {salesTeamWards.map((item: any, index: number) => {
                return (
                  <div className='mt-2'>
                    <div className='mb-4'>
                      <label className='form-label'>
                        Sales Team<span className='text-danger'>*</span>
                      </label>
                      <AsyncPaginate
                        key={salesTeamReset}
                        loadOptions={(e: any) => fetchSalesTeamOfWards(e)}
                        isSearchable
                        onBlur={() => {
                          setSalesTeamReset(moment())
                          setWardsSalesTeamOffset('')
                          setWardsSalesTeamSearch('')
                        }}
                        isOptionDisabled={(option: any) => option.isDisable}
                        className={`react-select-container ${
                          isValidation && salesTeamWards[index].salesTeam === '' ? 'is-invalid' : ''
                        }`}
                        classNamePrefix='react-select'
                        placeholder='Select'
                        menuPosition='fixed'
                        onChange={(e) => {
                          let totalSalesTeamWards = [...salesTeamWards]

                          totalSalesTeamWards[index].salesTeam = e
                          totalSalesTeamWards[index].wards = []

                          setSalesTeamWards(totalSalesTeamWards)
                        }}
                        value={salesTeamWards[index].salesTeam}
                        name='salesTeam'
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='form-label'>
                        Wards<span className='text-danger'>*</span>
                      </label>
                      <AsyncPaginate
                        key={salesTeamWardsReset}
                        loadOptions={(e) => fetchSalesTeamWards(e, salesTeamWards[index].salesTeam)}
                        isSearchable
                        isMulti
                        onBlur={() => {
                          setSalesTeamWardsReset(moment())
                          setSalesTeamOffset('')
                          setSalesTeamList([])
                          setSalesTeamSearch('')
                        }}
                        isDisabled={isEmpty(salesTeamWards[index].salesTeam)}
                        className={`react-select-container ${
                          isValidation &&
                          salesTeamWards[index] &&
                          salesTeamWards[index].wards !== null &&
                          salesTeamWards[index].wards.length === 0
                            ? 'is-invalid'
                            : ''
                        }`}
                        classNamePrefix='react-select'
                        placeholder='Select'
                        menuPosition='fixed'
                        onChange={(e) => {
                          let totalSalesTeamWards = [...salesTeamWards]

                          totalSalesTeamWards[index].wards = e

                          setSalesTeamWards(totalSalesTeamWards)
                        }}
                        value={salesTeamWards[index].wards}
                        name='wards'
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#0c78a4',
                          },
                        })}
                      />
                    </div>

                    {findSalesTeamWithId(
                      isEmpty(salesTeamWards[index].salesTeam)
                        ? ''
                        : salesTeamWards[index].salesTeam?.value ?? ''
                    )}

                    {salesTeamWards.length !== 1 && (
                      <>
                        <button
                          className='text-danger btn btn-link p-0'
                          onClick={(e: any) => {
                            removeSalesTeamWards(e, index)
                          }}
                        >
                          Remove
                        </button>
                        <hr className='bg-light my-3' />
                      </>
                    )}
                  </div>
                )
              })}

              <div className='my-3'>
                <ErrorHandler errorMessage={isWard} />
              </div>

              <button className='text-primary btn btn-link p-0' onClick={addSalesTeamWardsRow}>
                Add More Sales Team
              </button>
              <hr className='bg-light my-3' />
            </>
          )}

        {teamMember.type.toLocaleLowerCase().includes('sr') && (
          <>
            <div className='mt-5 mb-3 border-bottom'>
              <h6 className='text-bold'>TRA Configuration</h6>
            </div>
            <div className='mb-4'>
              <label className='form-label'>Device Id</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  isTraValid &&
                    (teamMember.tra_device_id === '' || teamMember.tra_device_id === null)
                    ? 'is-invalid'
                    : ''
                )}
                onChange={(e) => {
                  handleState(e, 'text')
                }}
                value={teamMember.tra_device_id || ''}
                name='tra_device_id'
                placeholder='Type here...'
                id='tra_device_id'
                autoComplete='off'
              />
            </div>
            <div className='mb-4'>
              <label className='form-label'>Device Name</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  isTraValid &&
                    (teamMember.tra_device_name === '' || teamMember.tra_device_name === null)
                    ? 'is-invalid'
                    : ''
                )}
                onChange={(e) => {
                  handleState(e, 'text')
                }}
                value={teamMember.tra_device_name || ''}
                name='tra_device_name'
                placeholder='Type here...'
                id='tra_device_name'
                autoComplete='off'
              />
            </div>
            <div className='mb-4'>
              <label className='form-label'>Serial Number</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  isTraValid &&
                    (teamMember.tra_device_serial_number === '' ||
                      teamMember.tra_device_serial_number === null)
                    ? 'is-invalid'
                    : ''
                )}
                onChange={(e) => {
                  handleState(e, 'text')
                  setSerialNumberValid('')
                }}
                value={teamMember.tra_device_serial_number || ''}
                name='tra_device_serial_number'
                placeholder='Type here...'
                id='tra_device_serial_number'
                autoComplete='off'
              />
            </div>
            <div className='mb-4'>
              <ErrorHandler errorMessage={serialNumberValid} />
            </div>
            <div className='mb-4'>
              <label className='form-label'>Password</label>
              <div className='d-flex position-relative'>
                <input
                  placeholder='Password'
                  type={'text'}
                  autoComplete='off'
                  className={clsx(
                    'form-control',
                    // psw-input
                    isTraValid &&
                      (teamMember.tra_device_password === '' ||
                        teamMember.tra_device_password === null)
                      ? 'is-invalid'
                      : ''
                  )}
                  name='tra_device_password'
                  id='tra_device_password'
                  onChange={(e) => {
                    handleState(e, 'text')
                  }}
                  value={teamMember.tra_device_password || ''}
                />
                {/* <a
                onClick={handleTogglePassword}
                className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm cursor-pointer'
              >
                {showPassword ? (
                  <>
                    <KTSVG
                      path='/media/icons/ic_eye_off.svg'
                      className='ic ms-n4 position-absolute psw-toggle'
                    />
                  </>
                ) : (
                  <>
                    <KTSVG
                      path='/media/icons/ic_eye_on.svg'
                      className='ic ms-n4 position-absolute psw-toggle'
                    />
                  </>
                )}
              </a> */}
              </div>
            </div>
            <div className='mb-4'>
              <MobileNumberInput
                label='Connected Phone'
                isOptional={true}
                validation={
                  isTraValid &&
                  (teamMember.tra_device_phone === '' || teamMember.tra_device_phone === null)
                    ? 'is-invalid'
                    : ''
                }
                onChange={(e: any) => {
                  const re = /^[0-9\b]+$/
                  if (e.target.value === '' || re.test(e.target.value)) {
                    if (e.target.value !== '0') {
                      handleState(e, 'text')
                    }
                  }
                }}
                numberValue={teamMember.tra_device_phone || ''}
                name='tra_device_phone'
              />
            </div>
          </>
        )}

        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessageEdit} />
        </div>
      </EaModal>

      <>
        <Modal
          show={showSrConfirm}
          onHide={() => {
            setShowSrConfirm(false)
          }}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>TRA Configuration</h2>
            </Modal.Title>
            <div
              className='btn p-1 mh-24 btn-active-light-primary ms-2'
              onClick={() => {
                setShowSrConfirm(false)
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='p-5'>
              <p className='text-bold'>
                Serial number and password already assigned to other SR. are you sure you want to
                continue?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={() => {
                  setShowSrConfirm(false)
                }}
                disabled={isModalLoading !== 'Submit'}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={submitTeamMemberModal}
                disabled={isModalLoading !== 'Submit'}
              >
                {isModalLoading === 'Submit' ? 'Continue' : isModalLoading}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export {TeamMemberHeader}
