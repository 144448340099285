import React, {useEffect, useState} from 'react'
import {StylesConfig} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {apiBaseURL, categoryOptions, KTSVG, retrieveFilter, saveFilter} from '../../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import SkuThumbnail from '../../../../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import {EaStatisticsWidget} from '../../../../../../_eaFruitsDms/partials/widgets'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'
import CustomPaginate from '../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ResetButton from '../../../../../../_eaFruitsDms/layout/components/button/resetButton'
import { filters } from '../../../../../../_eaFruitsDms/utils/FilterConstants'
import tenantConfiguration from '../../../../../../TenantVariables'

const Stock: React.FC = () => {
  const warehouseId = localStorage.getItem('wId')

  const categoryStyle: StylesConfig<any> = {
    option: (styles, {data}) => {
      return {
        ...styles,
        color: data.color,
        background: data.bg,
        fontWeight: data.weight,
        textTransform: data.transform,
        cursor: data.cursor,
        fontSize: data.size,
        ':hover': {
          ...styles[':hover'],
          backgroundColor: '#DEEBFF',
        },
        ':focus': {
          ...styles[':focus'],
          backgroundColor: '#DEEBFF',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: '#DEEBFF',
          color: '#fff',
        },
      }
    },
  }

  const [widgetData, setWidgetData] = useState({
    brands: 0,
    category: 0,
    low_sku: 0,
    no_sku: 0,
  })

  let stockFiltered : any = retrieveFilter(filters.sa_warehouse_dc_stock);

  const initialState = {
      filterBrand : stockFiltered?.filterBrand || {label : 'All Brands' , value : ''},
      filterCategory: stockFiltered?.filterCategory || {label : 'All Categories' , value : ''},
      filterGroup: stockFiltered?.filterGroup || {label : 'All Groups' , value : ''},
      selected: stockFiltered?.selected || '',
      search: stockFiltered?.search || '',
      currentOffSet: stockFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [searchByName, setSearchByName] = useState<string>(stockFiltered?.search || '')
  const [selected, setSelected] = useState<number>(stockFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>(stockFiltered?.currentOffSet || '0')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [stocks, setStocks] = useState<any>([])
  const [timer, setTimer] = useState<any>(null)
  const [searchSelect, setSearchSelect] = useState('')
  const [categoryOffSet, setCategoryOffSet] = useState<any>('')
  const [brandOffSet, setBrandOffSet] = useState<any>('')
  const [brands, setBrands] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const [filterBrand, setFilterBrand] = useState<any>(stockFiltered?.filterBrand || {
    label: 'All Brands',
    value: ''
  });
  const [filterCategory, setFilterCategory] = useState<any>(stockFiltered?.filterCategory || {
    label: 'All Categories',
    value: ''
  }); 
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [groups, setGroups] = useState<any>([])
  const [filterGroup, setFilterGroup] = useState<any>(stockFiltered?.filterGroup || {
    label: 'All Groups',
    value: ''
  });
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(stockFiltered?.buttonDisable || false) 
 
  useEffect(() => {
    fetchWidgetData().then()
  }, [])

  useEffect(() => {
    fetchStockItems().then()
  }, [currentOffset, filterBrand, filterCategory, filterGroup, searchByName])

  useEffect(() => {
    setBrandOffSet('')
    setCategoryOffSet('')
    setGroupOffSet('')
  }, [searchSelect])

  const fetchWidgetData = async () => {
    const widgetDataResponse = await getRequest(
      `${apiBaseURL()}warehouse/warehouse_widget/?warehouse_id=${warehouseId}&trade_only=True`,
      true
    )

    if (widgetDataResponse.length > 0) {
      setWidgetData({...widgetData, ...widgetDataResponse[0]})
    }
  }

  const fetchStockItems = async () => {
    setTimeout(async () => {
      setDisplayLoader(true)
      const stockItemResponse = await getRequest(
        `${apiBaseURL()}inventory/product-SKUs/?warehouse_id=${warehouseId}&limit=${limit}&offset=${currentOffset}&brand_id__id=${filterBrand.value}&category_id__id=${filterCategory.value}&search=${searchByName}&variants__group_name__id=${filterGroup.value}&trade_only=true`,
        true
      )
      setStocks(stockItemResponse.results)
      setCount(stockItemResponse.count)
      setNext(stockItemResponse.next)
      setPrev(stockItemResponse.previous)
      setDisplayLoader(false)
    }, timer)

      let updatedState: any = { ...filterStates }
      updatedState['selected'] = selected
      updatedState['currentOffSet'] = currentOffset
      updatedState['buttonDisable'] = buttonDisable

      setFilterStates(updatedState)
      saveFilter(filters.sa_warehouse_dc_stock , filterStates)
      if(filterBrand.value == '' && filterGroup.value == '' && filterCategory.value == '' && searchByName == ''){
          setButtonDisable(false)
      }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * limit
      setCurrentOffset(newOffset)
    }
  }

  const fetchBrands = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const brandResponse = await getRequest(
      `${apiBaseURL()}master/product/brand?ordering=name&search=${search}&limit=${limit}&offset=${brandOffSet}`,
      true
    )

    let hasMore: boolean = false
    if(brandResponse.results){
        if (brandResponse.next !== null) {
          const queryParams = new URLSearchParams(brandResponse.next)
          let newOffset: any = queryParams.get('offset')
          setBrandOffSet(newOffset)
          hasMore = true
        }
    
        if (brands.length == 0) {
          options.push({
            label: 'All Brands',
            value: '',
          })
        }
    
        if (brandResponse.results.length > 0) {
          brandResponse.results.map((option: any) => {
            options.push({
              label: option.name,
              value: option.id,
            })
          })
        }
    
        if (brands.length > 0) {
          setBrands(brands.concat(options))
        } else {
          setBrands(options)
        }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategories = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffSet}`,
      true
    )

    let hasMore: boolean = false
    if(categoriesResponse.results){
        if (categoriesResponse.next !== null) {
          const queryParams = new URLSearchParams(categoriesResponse.next)
          let newOffset: any = queryParams.get('offset')
          setCategoryOffSet(newOffset)
          hasMore = true
        }
    
        if (categories.length == 0) {
          options.push({
            label: 'All Categories',
            value: '',
          })
        }
    
        if (categoriesResponse.results.length > 0) {
          categoriesResponse.results.map((option: any) => {
            options.push({
              label: option.name,
              value: option.id,
              ...categoryOptions,
            })
            if (option.child_category.length > 0) {
              option.child_category.map((child: any) => {
                options.push({
                  label: child.name,
                  value: child.id,
                })
              })
            }
          })
      }
  
        if (categories.length > 0) {
          setCategories(categories.concat(options))
        } else {
          setCategories(options)
        }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const response = await getRequest(
      `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffSet}`,
      true
    )

    let hasMore: boolean = false
    if(response.results){
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setGroupOffSet(newOffset)
        hasMore = true
      }
      if (groups.length == 0) {
        options.push({
          label: 'All Groups',
          value: '',
        })
      }
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.name,
            value: option.id,
          })
        })
      }
  
      if (groups.length > 0) {
        setGroups(groups.concat(options))
      } else {
        setGroups(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleFilterChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setTimer(0)
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'brand': 
        setFilterBrand(e)
        setFilterStates({ ...filterStates, ['filterBrand']: e })
        break
      case 'category':
        setFilterCategory(e)
        setFilterStates({ ...filterStates, ['filterCategory']: e })
        break
      case 'group':
        setFilterGroup(e)
        setFilterStates({ ...filterStates, ['filterGroup']: e })
        break
      default:
        setTimer(1000)
        setSearchByName(inputValue)
        setFilterStates({ ...filterStates, ['search']: inputValue })
    }
  }

  
  const handleChangeReset = () =>{
    setSearchByName('')
    setFilterBrand({label : 'All Brands' , value : ''})
    setFilterCategory({label : 'All Categories' , value : ''})
    setFilterGroup({label : 'All Groups' , value : ''})
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['filterBrand'] = ''
    updatedState['filterCategory'] = ''
    updatedState['filterGroup'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}
  return (
    <>
      <EATitle title='Stock' />
      {/* begin::Row */}
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-xl-5 rounded-2'
            color='success'
            title={widgetData.brands + ' Brands'}
            path='/'
            description='Total Brands'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-xl-5 rounded-2'
            color='info'
            title={widgetData.category + ' Categories'}
            description='Total Categories'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-xl-5 rounded-2'
            color='warning'
            title={widgetData.low_sku + ' SKUs'}
            description='Low Stock SKUs'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-xl-5 rounded-2'
            color='danger'
            title={widgetData.no_sku + ' SKUs'}
            description='No Stock SKUs'
            path='/'
          />
        </div>
      </div>
      {/* end::Row */}
      <div className='card'>
        {displayLoader && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} value={searchByName} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchBrands}
                isSearchable
                className='react-select-container w-120px me-3'
                classNamePrefix='react-select'
                placeholder='All Brands'
                name='brand'
                value={filterBrand}
                // value={brands.filter((option: any) => option.value === filterBrand)}
                onChange={(e: any) => handleFilterChange(e, 'brand')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                loadOptions={fetchCategories}
                isSearchable
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                placeholder='All Categories'
                styles={categoryStyle}
                name='categories'
                value={filterCategory}
                // value={categories.filter((option: any) => option.value === filterCategory)}
                onChange={(e: any) => handleFilterChange(e, 'category')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchGroups}
                isSearchable
                className='react-select-container my-1 me-3 w-120px'
                classNamePrefix='react-select'
                value={filterGroup}
                // value={groups.filter((option: any) => option.value == filterGroup)}
                onChange={(e: any) => handleFilterChange(e, 'group')}
                placeholder='All Group'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {stocks.length === 0 ? (
              <NoRecords />
            ) : (
              <>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-110px'>Product SKU</th>
                      <th className='min-w-110px'>Brand</th>
                      <th className='min-w-120px'>Category</th>
                      <th className='min-w-110px'>Product Group</th>
                      <th className='min-w-110px'>Variant</th>
                      <th className='min-w-100px'>Size</th>
                      <th className='min-w-100px'>Packaging</th>
                      <th className='min-w-120px'>Stock</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {stocks.map((stock: any) => {
                      return (
                        <tr>
                          <SkuThumbnail src={stock.product_img} skuName={stock.name} />
                          <td>{stock.brand_id}</td>
                          <td>
                            <div>
                              <div className='text-muted font-13 mb-1 font-medium'>
                                {stock.parent_category}
                              </div>
                              {stock.category_id}
                            </div>
                          </td>
                          <td>{stock.group_name}</td>
                          <td>{stock.variants.name}</td>
                          <td>
                            {stock.fix_size
                              ? stock.measurement_unit.toLowerCase() === 'gram'
                                ? stock.size / 1000
                                : stock.size
                              : stock.variants.approx_weight > 0
                              ? stock.variants.approx_weight / 1000
                              : 1}{' '}
                            Kgs
                          </td>
                          <td>{stock.material.name}</td>
                          <td>
                            {stock.total_qty}
                            {stock.low_stock && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr057.svg'
                                className='ic ms-3'
                              />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
       <CustomPaginate
        data={stocks}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.sa_warehouse_dc_stock}
        filterStates={filterStates}
        isDisabled={displayLoader}
      />
    </>
  )
}

export default Stock
