import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import {
  apiBaseURL,
  checkPermissions,
  dateFormat,
  dateIsToday,
  isEmpty,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import {blobRequest, getRequest} from '../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../TenantVariables'
import {decryptData} from '../../../_eaFruitsDms/helpers/storageHelper'

const PreOrderDetail: React.FC<Props> = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const preOrders: Array<PageLink> = [
    {
      title: 'Pre-Orders',
      path: '/orders',
      isSeparator: false,
      isActive: false,
    },
  ]
  const orderId = localStorage.getItem('oId')
  const userType = decryptData('user_type') || ''

  const location = useLocation()

  const [isDisplayLoader, setIsDisplayLoader] = useState<boolean>(false)
  const [order, setOrder] = useState<any>(null)
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState<boolean>(false)
  const [showAddOrderButton, setAddOrderButton] = useState<boolean>(false)

  useEffect(() => {
    fetchOrderDetails().then()
    checkPermission().then()
  }, [])

  const checkPermission = async () => {
    const res = await checkPermissions('orders')

    if ((res && res.write) || userType.toLowerCase() === 'ts') {
      setAddOrderButton(true)
    }
  }

  const fetchOrderDetails = async () => {
    setIsDisplayLoader(true)

    const orderDetails = await getRequest(
      `${tenantConfiguration.apiBaseUrl}preorder/pre_order/${orderId}/`,
      true
    )

    setIsDisplayLoader(false)

    if (orderDetails) {
      orderDetails.status = isEmpty(orderDetails.order_status)
        ? orderDetails.status
        : orderDetails.order_status
      orderDetails.pre_order_sku &&
        orderDetails.pre_order_sku.map((sku: any, index: number) => {
          const product: any =
            orderDetails.delivery_product_order &&
            orderDetails.delivery_product_order.find(
              (product_sku: any) => product_sku.product_sku.id === sku.product_sku_id.id
            )

          orderDetails.pre_order_sku[index].discounted_price = 0

          if (!isEmpty(product)) {
            if (
              !['0', 0].includes(product.discount_price_per_sku) &&
              !isEmpty(product.delivery_date_discount_type)
            ) {
              if (product.delivery_date_discount_price < product.order_date_price) {
                orderDetails.pre_order_sku[index].product_price = product.order_date_price
                orderDetails.pre_order_sku[index].discounted_price =
                  product.delivery_date_discount_price
              } else if (product.delivery_date_discount_price < product.delivery_date_price) {
                orderDetails.pre_order_sku[index].product_price = product.delivery_date_price
                orderDetails.pre_order_sku[index].discounted_price =
                  product.delivery_date_discount_price
              }
            }

            orderDetails.pre_order_sku[index].total_price = product.final_total_price
            orderDetails.pre_order_sku[index].qty = product.qty
            orderDetails.pre_order_sku[index].sokopoint_rewards =
              product?.sokopoint_rewards?.length > 0 ? product?.sokopoint_rewards[0] : null
          }

          return sku
        })

      setOrder(orderDetails)
    }
  }

  const downloadPdf = async () => {
    setDownloadButtonDisabled(true)
    await blobRequest(
      `${apiBaseURL()}payment/sales-invoices-pdf/${order.id}`,
      true,
      `Sales Invoice #${order.pre_order_brand_group_id}`,
      setDownloadButtonDisabled
    )
  }

  const backNavigation = () => {
    let back_url = localStorage.getItem('redirection_back') || ''

    if (isEmpty(back_url)) {
      back_url = '/orders'
    }

    return back_url
  }

  return (
    <>
      <EATitle title='Pre-Orders' />
      <PageTitle breadcrumbs={preOrders} backNavigate={backNavigation()}>
        {intl.formatMessage({id: `Order #${order ? order.pre_order_brand_group_id : '-'}`})}
      </PageTitle>
      {isDisplayLoader && <IsLoader />}
      <div className='card mb-4 mb-xl-5'>
        <div className='card-header border-0 px-7 pt-5 pb-0'>
          <div className='d-flex flex-column mb-2 mb-sm-0'>
            <div className='d-flex align-items-center mb-1'>
              <span className='text-dark font-20 fw-bold me-1'>
                Order #{order ? order.pre_order_brand_group_id : '-'}
              </span>
              {order && order.status === 'Scheduled' && (
                <span className='py-1 ms-4 btn btn-sm bg-light-primary'>{order.status}</span>
              )}
              {order && order.status === 'Delivered' && (
                <span className='py-1 ms-4 btn btn-sm bg-light'>{order.status}</span>
              )}
              {order && order.status === 'Pending' && (
                <span className='py-1 ms-4 btn btn-sm bg-light-success'>{order.status}</span>
              )}
              {order && order.status === 'unfulfilled' && (
                <span className='py-1 ms-4 btn btn-sm bg-light-danger'>Unfulfilled</span>
              )}
              {order && order.status === 'rejected' && (
                <span className='py-1 ms-4 btn btn-sm bg-light-danger'>Rejected</span>
              )}
            </div>
          </div>
          <div className='ms-auto'>
            {order && !isEmpty(order.status) && order.status.toLowerCase() === 'delivered' && (
              <>
                <button
                  className='btn btn-primary'
                  onClick={downloadPdf}
                  disabled={downloadButtonDisabled}
                >
                  {downloadButtonDisabled ? 'Downloading...' : 'Download Invoice'}
                </button>
              </>
            )}
            {showAddOrderButton &&
              ['ts', 'wm'].includes(userType.toLowerCase()) &&
              order &&
              ['scheduled', 'pending'].includes(order.status.toLowerCase()) &&
              !dateIsToday(order.pre_order_date) &&
              location.pathname !== '/reports/order-report/order-detail' && (
                <>
                  {/*TODO: can order is the part of part-2*/}
                  {/*<button*/}
                  {/*    className='btn btn-light me-2'*/}
                  {/*>*/}
                  {/*    Cancel Pre-Order*/}
                  {/*</button>*/}

                  {/*<button*/}
                  {/*  className='btn btn-primary'*/}
                  {/*  onClick={() => navigate('/orders/manage-order')}*/}
                  {/*>*/}
                  {/*  Edit Pre-Order*/}
                  {/*</button>*/}
                </>
              )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col-lg-9 col-xl-9 col-xxl-8'>
              <div className='row'>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Order Created Date</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {order ? dateFormat(order.created_date).displayDate : '-'}
                    </span>
                  </div>
                </div>
                {order && (
                  <div className='col-sm-6 col-xl-4 mb-6'>
                    <label className='font-14 text-muted mb-2'>
                      {order && order.status === 'rejected' ? 'Rejection Date' : 'Delivery Date'}
                    </label>
                    <div>
                      <span className='font-14 text-dark'>
                        {order
                          ? order.status === 'rejected'
                            ? isEmpty(order.rejection_date)
                              ? '-'
                              : dateFormat(order.rejection_date).displayDate
                            : isEmpty(order.pre_order_date)
                            ? '-'
                            : dateFormat(order.pre_order_date).displayDate
                          : '-'}
                      </span>
                    </div>
                  </div>
                )}
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Collected Date</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {order && !isEmpty(order?.pre_order_collected_date)
                        ? dateFormat(order.pre_order_collected_date).displayDate
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Created By</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {order
                        ? order.created_by.type.toUpperCase() +
                          ' - ' +
                          order.created_by.first_name +
                          ' ' +
                          order.created_by.last_name
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {order
                        ? order.customer.business_name + ', ' + order.customer.street.name
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-4 mb-6'>
                  <label className='font-14 text-muted mb-2'>Warehouse Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {order && order.warehouse && order.warehouse.length > 0
                        ? order.warehouse[0].name
                        : '-'}
                    </span>
                  </div>
                </div>
                {order && order?.is_rejected && (
                  <>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Rejection Reason</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order
                            ? !isEmpty(order?.rejection_option)
                              ? order?.rejection_option
                              : 'Other'
                            : '-'}
                        </span>
                      </div>
                    </div>

                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Rejection Note</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order
                            ? !isEmpty(order?.rejection_note)
                              ? order?.rejection_note
                              : '-'
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-header border-0 px-7 pt-5 pb-0'>
          <div className='d-flex flex-column mb-2 mb-sm-0'>
            <div className='d-flex align-items-center mb-1'>
              <span className='text-dark font-20 fw-bold me-1'>Ordered Items</span>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          {order == null || order.pre_order_sku.length === 0 ? (
            <NoRecords />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 mb-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500 '>
                    <th className='min-w-150px py-2'>Product SKU</th>
                    <th className='min-w-120px py-2'>Price/Unit</th>
                    <th className='min-w-200px py-2'>Discounted Price/Unit</th>
                    <th className='min-w-80px w-80px py-2'>Qty.</th>
                    <th className='min-w-300px w-300px py-2'>Reward Offer</th>
                    <th className='min-w-150px w-150px py-2 text-end'>Total Amt.</th>
                    <th className='min-w-150px w-150px py-2'></th>
                  </tr>
                </thead>
                <tbody>
                  {order.pre_order_sku.map((preOrder: any) => {
                    return (
                      <>
                        <tr>
                          <td>{preOrder.product_sku_id.name}</td>
                          <td
                            className={clsx(
                              `${preOrder.discounted_price > 0 && 'text-decoration-line-through'}`
                            )}
                          >
                            {tenantConfiguration.currencyCode}{' '}
                            {numberFormat(preOrder.product_price).displayFormat}
                          </td>
                          <td>
                            {tenantConfiguration.currencyCode}{' '}
                            {
                              numberFormat(
                                preOrder.discounted_price > 0 ? preOrder.discounted_price : 0
                              ).displayFormat
                            }
                          </td>
                          <td>{numberFormat(preOrder.qty).displayFormat}</td>
                          <td>
                            {isEmpty(preOrder?.sokopoint_rewards) ? (
                              '-'
                            ) : (
                              <>
                                <div>
                                  Reward offer <b>{preOrder?.sokopoint_rewards?.title ?? '-'}</b>{' '}
                                  applied. You used{' '}
                                  {isEmpty(preOrder?.sokopoint_rewards?.applied_sokopoints) ? (
                                    '-'
                                  ) : (
                                    <b>
                                      {
                                        numberFormat(
                                          preOrder?.sokopoint_rewards?.applied_sokopoints
                                        ).displayFormat
                                      }
                                    </b>
                                  )}{' '}
                                  Sokopoints. saved
                                  {isEmpty(preOrder?.sokopoint_rewards?.soko_sku_discount) ? (
                                    '-'
                                  ) : (
                                    <b>
                                      {' '}
                                      {tenantConfiguration?.currencyCode}{' '}
                                      {
                                        numberFormat(preOrder?.sokopoint_rewards?.soko_sku_discount)
                                          .displayFormat
                                      }
                                    </b>
                                  )}
                                  .
                                </div>
                              </>
                            )}
                          </td>
                          <td className='text-end'>
                            {tenantConfiguration.currencyCode}{' '}
                            {numberFormat(preOrder.total_price).displayFormat}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    )
                  })}
                  <tr className='bg-light2 text-end'>
                    <td colSpan={6}></td>
                    <td>Total Items</td>
                    <td>
                      {order
                        ? order.pre_order_sku.length > 1
                          ? order.pre_order_sku.length + ' Items'
                          : order.pre_order_sku.length + ' Item'
                        : '0'}{' '}
                    </td>
                  </tr>
                  <tr className='bg-light2 text-end'>
                    <td colSpan={4}></td>
                    <td colSpan={3}>Total Amount (Without Discount)</td>
                    <td>
                      {tenantConfiguration.currencyCode}{' '}
                      {order
                        ? numberFormat(order.total_amount).displayFormat
                        : parseFloat('0').toFixed(2)}
                    </td>
                  </tr>

                  {order && order?.soko_point_sku_discount > 0 && (
                    <tr className='bg-light2 text-end'>
                      <td colSpan={6}></td>
                      <td className='w-170px'>SokoPoint Discount</td>
                      <td>
                        -{tenantConfiguration.currencyCode}{' '}
                        {order ? numberFormat(order.soko_point_sku_discount).displayFormat : 0}
                      </td>
                    </tr>
                  )}

                  <tr className='bg-light2 text-end'>
                    <td colSpan={6}></td>
                    <td>Total Discount</td>
                    <td>
                      -{tenantConfiguration.currencyCode}{' '}
                      {order ? numberFormat(order.discount).displayFormat : 0}
                    </td>
                  </tr>
                  {/*<tr className='bg-light2 text-end'>*/}
                  {/*    <td colSpan={3}></td>*/}
                  {/*    <td>Returned Items</td>*/}
                  {/*    <td>- {tenantConfiguration.currencyCode} 0.00</td>*/}
                  {/*</tr>*/}
                  {/*<tr className='bg-light2 text-end'>*/}
                  {/*    <td colSpan={4}></td>*/}
                  {/*    <td>Credit Note</td>*/}
                  {/*    <td>- {tenantConfiguration.currencyCode + " " + 0}</td>*/}
                  {/*</tr>*/}
                  {order && order.taxes > 0 && (
                    <tr className='bg-light2 text-end'>
                      <td colSpan={6}></td>
                      <td>Taxes</td>
                      <td>
                        {tenantConfiguration.currencyCode} {order ? order.taxes : 0}
                      </td>
                    </tr>
                  )}

                  <tr className='bg-light2 text-end'>
                    <td colSpan={6}></td>
                    <td>
                      <span className='p-0 fw-500'>Total Payable</span>{' '}
                    </td>
                    <td>
                      <span className='p-0 fw-500'>
                        {tenantConfiguration.currencyCode}{' '}
                        {order ? numberFormat(order.total_payable).displayFormat : 0}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* end::Table */}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PreOrderDetail
