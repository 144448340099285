import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  KTSVG,
  retrieveFilter,
  saveFilter
} from "../../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core";
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce";
import ResetButton from "../../../../../_eaFruitsDms/layout/components/button/resetButton";
import CustomPaginate from "../../../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import { filters } from "../../../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}
type modalType = {
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}
const JobApplications: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  let applicationFiltered : any = retrieveFilter(filters.cms_job_application);

  const initialState = {
    currentOpeningsId : applicationFiltered?.currentOpeningsId || {label : 'All Current Opening' , value : ''},
    startDate: applicationFiltered?.startDate || '',
    endDate: applicationFiltered?.endDate || '',
    search: applicationFiltered?.search || '',
    selected: applicationFiltered?.selected || '',
    currentOffSet: applicationFiltered?.currentOffSet || '',
    buttonDisable: true
  }

  const [jobApplicationsList, setJobApplicationsList] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(applicationFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(applicationFiltered?.currentOffSet || '0')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(applicationFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const [openingOffSet, setOpeningOffSet] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState('')
  const [currentOpenings, setCurrentOpenings] = useState<any>([])
  const [currentOpeningsId, setCurrentOpeningsId] = useState<any>(applicationFiltered?.currentOpeningsId || {label : 'All Current Opening' , value : ''})

const initialDateFilter : any = {
  startDateView : 'Select',
  startDate: applicationFiltered?.startDate || { displayDate: "", apiDate: null },
  endDate: applicationFiltered?.endDate || { displayDate: "", apiDate: null }
};

  const debounceSearch = useDebounce(search, 500);
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(applicationFiltered?.buttonDisable || false) 
  const [applyDate, setApplyDate] = useState<any>(initialDateFilter);
  const cmsApplications: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Career',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    setTimeout(() => fetchJobApplication().then(), timer)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)
    saveFilter(filters.cms_job_application , filterStates)

    if(search == '' && currentOpeningsId.value == '' && applyDate.startDate.apiDate == '' &&  applyDate.endDate.apiDate == '' ){
      setButtonDisable(false)
  }
  }, [
    currentOffset,
    debounceSearch,
    currentOpeningsId,  
    applyDate
  ])

  const fetchJobApplication = async () => {
    setListLoader(true)
    let dateUrl = `&created_at_after=${applyDate.startDate.apiDate == null ? '' : applyDate.startDate.apiDate}&created_at_before=${applyDate.endDate.apiDate == null ? '' : applyDate.endDate.apiDate }`

    const API = `${apiBaseURL()}career/Application/get?&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}&currentopening_id=${currentOpeningsId.value}` + dateUrl
    const response = await getRequest(API, true)
    setJobApplicationsList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
    setFilterStates({ ...filterStates, ['search']: inputValue })
    setCurrentOffset(0)
    setSelected(0)
  }

  const fetchCurrentOpenings = async (search?: any) => {
    const response = await fetchAsyncDropdown(
      openingOffSet,
      setOpeningOffSet,
      currentOpenings,
      setCurrentOpenings,
      `career/CurrentOpenings/get?limit=${limit}&search=${search}&offset=${openingOffSet}`,
      search,
      setSearchSelect,
      'title',
      true,
      'All Current Opening'
    )
    response.options.push({
      label: 'General Application',
      value: 0,
    })
    return response
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    switch (inputName) {
      case 'currentOpenings':
        setCurrentOpeningsId(e)
        setFilterStates({ ...filterStates, ['currentOpeningsId']: e })
        break
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const CustomDatePickerComponent = () => {
      return <>
        <DateRangePicker
            onCallback={(start, end, label) => {
            setButtonDisable(true)
            const callBackDate : any = {
            startDate: start ? dateFormat(start._d) : null,
            endDate: end ? dateFormat(end._d) : null,
            startDateView : ''
            }
            let updatedState: any = { ...filterStates }
            updatedState['startDate'] = callBackDate.startDate
            updatedState['endDate'] = callBackDate.endDate
            setFilterStates(updatedState)
            setApplyDate(callBackDate)
            }}
            initialSettings={
                applyDate.startDate.apiDate && applyDate.endDate.apiDate
                  ? {
                    startDate: moment(applyDate.startDate.apiDate).toDate(),
                    endDate: moment(applyDate.endDate.apiDate).toDate(),
                    autoUpdateInput: true,
                    alwaysShowCalendars: true,
                    cancelClass: 'btn-secondary',
                    drops: "auto",
                    locale: {
                    format: 'DD MMM YYYY'
                    },
                    ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    },
                      }
                  : {
                    autoUpdateInput: true,
                    alwaysShowCalendars: true,
                    cancelClass: 'btn-secondary',
                    drops: "auto",
                    locale: {
                    format: 'DD MMM YYYY'
                    },
                    ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    },
                   }
                  }
                >
                <input type="text" className="form-control calendar my-1 me-3 w-auto" placeholder="Select" disabled={listLoadder} value={applyDate}/>
        </DateRangePicker>
      </>
  }

  const redirectPage = (item: any) => {
    saveFilter(filters.cms_job_application , filterStates)

    navigate('application-details')
    localStorage.setItem('applicationId', item.id)
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOpeningsId({label : 'All Current Opening' , value : ''})
    setCurrentOffset(0)
    setSelected(0)
    let updatedDateState : any = {...applyDate}
    updatedDateState['startDate'] =   {displayDate: "", apiDate: ""}
    updatedDateState['endDate'] =   {displayDate: "", apiDate: ""}
    setApplyDate(updatedDateState)

    let updatedState: any = { ...filterStates }
    updatedState['currentOpeningsId'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Job Applications - ' />
      <div>
        <PageTitle breadcrumbs={cmsApplications}>
          {intl.formatMessage({id: 'Job Applications'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_BLOG_AUTHORS.ADD')
              : messageHandler === 'successEdit'
              ? FormatMessage('CMS_BLOG_AUTHORS.UPDATE')
              : messageHandler === 'successDelete' && FormatMessage('CMS_BLOG_AUTHORS.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} value={search} isDisabled={listLoadder}/>
              <div className='col-auto ms-auto'>
                {/* <div className='card-toolbar d-flex my-0'> */}
                <form
                  className='card-toolbar d-flex my-0'
                  onSubmit={(e) => e.preventDefault()}
                  autoComplete='off'
                >
                  <AsyncPaginate
                    
                    loadOptions={fetchCurrentOpenings}
                    isSearchable
                    onChange={(e) => handleChange(e, 'currentOpenings')}
                    isDisabled={listLoadder}
                    className='react-select-container my-1 me-3 w-180px'
                    classNamePrefix='react-select'
                    placeholder='Current Opening'
                    value={currentOpeningsId}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                  <div className='col-12 col-md-auto ms-auto mt-3 me-3 mt-md-0 d-flex '>
                    <CustomDatePickerComponent />
                  </div>
                  <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                </form>
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {jobApplicationsList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Name</th>
                          <th className='min-w-100px py-2'>Email Address</th>
                          <th className='min-w-100px py-2'>Mobile No</th>
                          <th className='min-w-100px py-2'>Current Opening</th>
                          <th className='min-w-100px py-2'>Received Date</th>
                          <th className='min-w-80px action-th py-2'>Actions</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {jobApplicationsList.map((item: any) => {
                          return (
                            <tr>
                              <td onClick={() => redirectPage(item)}>
                                {item.firstname} {item.lastname}
                              </td>
                              <td onClick={() => redirectPage(item)}>{item.email}</td>
                              <td onClick={() => redirectPage(item)}>{item.phone_no}</td>
                              <td onClick={() => redirectPage(item)}>
                                {item.currentopenings_id !== null
                                  ? item.currentopenings_id.title
                                  : 'General Application'}
                              </td>
                              <td onClick={() => redirectPage(item)}>
                                {dateFormat(item.created_at).displayDate}{' '}
                                {dateFormat(item.created_at, 'LT').displayDate}
                              </td>
                              <td className=' text-end' onClick={() => redirectPage(item)}>
                                <span className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                                  <KTSVG path='/media/icons/duotune/arrows/arr007.svg' />
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
          <CustomPaginate
          data={jobApplicationsList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={listLoadder}
          saveFilterName={filters.cms_job_application}
          filterStates={filterStates}
        />
      </div>
    </>
  )
}

export default JobApplications
