import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  retrieveFilter,
  saveFilter
} from "../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { getRequest, patchRequest, postRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core";
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce";
import tenantConfiguration from "../../../../TenantVariables";
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton";
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}
const DiscountRequestRejection: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const DISCOUNT_REQUEST_REJECTION_TYPE = "discount_request_rejection"

  const initialRow: any = {
    option: '',
    type: DISCOUNT_REQUEST_REJECTION_TYPE
  }
  
  const initialReasonValidation: any = {
    option: '',
  }

  const reasonMaster: Array<PageLink> = [
    {
      title: 'Masters',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]

  let categoryFiltered : any = retrieveFilter(filters.reason_master_discount_request_rejection);

  const initialFiltersState = {
      selected: categoryFiltered?.selected || '',
      search: categoryFiltered?.search || '',
      currentOffSet: categoryFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [discountRequestRejections, setDiscountRejections] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState<number>(categoryFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(categoryFiltered?.currentOffSet || '0');
  const [search, setSearch] = useState<string>(categoryFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [reason, setReason] = useState<any>(initialRow)
  const [reasonValidation, setReasonValidation] = useState<any>(initialReasonValidation)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState('Submit')
  const [disableBtn, setDisable] = useState(false)
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(categoryFiltered?.buttonDisable || false) 
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    setCurrentOffset('')
  }, [debouncedSearch])

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    discountRequestRejectionsList().then()
  }, [currentOffset, debouncedSearch, count])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_rejection_options")
    setPermission(res)
   }

  const discountRequestRejectionsList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}master/options-master/?type=${DISCOUNT_REQUEST_REJECTION_TYPE}&limit=${limit}&ordering=-updated_date&search=${search}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    if (response && response.next === null) {
      response.results.push({
        id: "",
        option: "Other"
      })
    }
    setDiscountRejections(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.reason_master_discount_request_rejection , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  const singledata = async (item: any) => {
    setModalLoader(true)
    setId(item.id)
    const API = `${apiBaseURL()}master/options-master/${item.id}/`
    const response = await getRequest(API, true)
    setReason({...reason, ['option']: response.option})
    setModalLoader(false)
  }

  const clearFunctionAdd = () => {
    setReason(initialRow)
    setReasonValidation('')
    setErrorMessage('')
    setIsLoading('Submit')
    setDisable(false)
    setModalLoader(false)
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value
    setReasonValidation({...reasonValidation, [inputName]: ''})
    setReason({...reason, ['option']: inputValue})
  }

  const validateForm = () => {
    let navigateField = {
      option: reason.option,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    let validations = {...reasonValidation}
    let isValid = true
       if (reason.option == '') {
          validations.option = 'is-invalid'
          isValid = false
    }
    setReasonValidation(validations)
    return isValid
  }

  const submitFunction = async () => {
    const isValid = validateForm()
      if (isValid) {
        const reasonInfo = {...reason}
        setDisable(true)
        setIsLoading('Please Wait...')   
        if (requestHandler === 'add') {
          postRequest(`${apiBaseURL()}master/options-master/`, reasonInfo, true).then((response: any) => {
            handleResponse(response)
          })
        } else {
          patchRequest(`${apiBaseURL()}master/options-master/${id}/`, reasonInfo, true).then(
            (response: any) => {
              handleResponse(response)
            }
          )
        }
      }
  }

  const handleResponse = (response: any) =>{
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    if (response.status === 200 || response.status === 201) {
      notify.success = true
    }
    if (response.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = response.data.error.field
    }
    if (notify.success) {
      setIsLoading('Submit')
      setDisable(false)
      discountRequestRejectionsList()
      $('#closeEaModalLORRejection').trigger('click')
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 2000)
      setIsAlertSuccess(true)
    }
    if (notify.failed) {
      setIsLoading('Submit')
      setDisable(false)
      if (notify.errorField == 'non_field_errors') {
        setErrorMessage(
          'Your added data is already exist in this system! Please try again with unique data.'
        )
      }
    }
  } 

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <EATitle title='Discount Request Rejection' />
      <div>
        <PageTitle breadcrumbs={reasonMaster}>{intl.formatMessage({id: 'Discount Request Rejection'})}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'? FormatMessage('DISCOUNT_REQUEST_REJECT.ADD') : messageHandler === 'successEdit'
            ? FormatMessage('DISCOUNT_REQUEST_REJECT.UPDATE') : messageHandler === 'successDelete' && FormatMessage('DISCOUNT_REQUEST_REJECT.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} value={search} disabled={listLoadder}/>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_reasons'
                    btnName='Add Discount Request Rejection'
                    className="btn-primary me-3"
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
          {discountRequestRejections.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Discount Request Rejection</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {discountRequestRejections.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='mb-4'>
                                    <span>{item.option}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='text-end'>
                                {permission.write && item.id !== "" && (
                                  <a
                                    href='javascript:void(0)'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_create_reasons'
                                    onClick={(event) => {
                                      singledata(item)
                                      setRequest('edit')
                                      setMessageHandler('successEdit')
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )} 
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
          </div>
          {/* begin::Body */}
        </div>
        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${discountRequestRejections.length == 0 ? 0 : selected * 10 + 1} to ${
                discountRequestRejections.length == 0 ? 0 : discountRequestRejections.length < 10 ? count : (selected + 1) * 10
              } of ${discountRequestRejections.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={discountRequestRejections.length == 0 ? 0 : count} selected={selected} numberClick={numberClick} prev={prevPage} next={nextPage}
              />
            </div>
          </div>
        </div> */}
        <CustomPaginate
              data={discountRequestRejections}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.reason_master_discount_request_rejection}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      </div>
      <EaModal
        id='ea_modal_create_reasons' modalTitle={requestHandler === 'add' ? 'Add Discount Request Rejection' : 'Update Discount Request Rejection'}  btnDisabled={disableBtn} closeAuto='closeEaModalLORRejection' onSubmit={submitFunction} cancel={clearFunctionAdd} actionBtnName={isLoading} loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>Discount Request Rejection<span style={{color: '#FF0000'}}>*</span></label>
          <input
            type='text'
            id='option'
            className={clsx('form-control', reasonValidation.option)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'option')}
            name='option'
            value={reason && reason.option}
          />
        </div>
        <div className='mb-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  )
}

export default DiscountRequestRejection
