import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  addVehicleModel,
  vehicleModelList,
  vehicleModelUpdate
} from "../../../../_eaFruitsDms/apiFunctions/master/master";
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  fetchAsyncDropdown,
  KTSVG,
  retrieveFilter,
  saveFilter
} from "../../../../_eaFruitsDms/helpers";
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core";
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton";
import tenantConfiguration from "../../../../TenantVariables";
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce";
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}
const VehicleModel: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterBreadCrumbs: Array<PageLink> = [
    {
      title: 'Masters',
      path: '/masters/vehiclemaster',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Vehicle Master',
      path: '/masters/vehiclemaster',
      isSeparator: false,
      isActive: false,
    },
  ]

  let teleSalesFiltered : any = retrieveFilter(filters.sa_master_vehicle_modal);
  
  const initialFiltersState = {
      selected: teleSalesFiltered?.selected || '',
      search: teleSalesFiltered?.search || '',
      currentOffSet: teleSalesFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(teleSalesFiltered?.buttonDisable || false) 

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState<number>(teleSalesFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(teleSalesFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(teleSalesFiltered?.search || '')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [vehicleModel, setVehicleModel] = useState<any>('')
  const [vehicleModelEdit, setVehicleModelEdit] = useState<any>('')
  const [validationEdit, setValidationEdit] = useState<any>('')
  const [validation, setValidation] = useState<any>('')
  const [validationVehicleMake, setValidationVehicleMake] = useState<any>('')
  const [validationVehicleMakeEdit, setValidationVehicleMakeEdit] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [searchSelect, setSearchSelect] = useState('')
  const [vehicalMakeOffset, setVehicaMakeOffset] = useState<any>('')
  const [vehicalMakeOption, setVehicalMakeOption] = useState<any>([])
  const [limit, setLimit] = useState<number>(10)
  const [selectedMakeId, setSelectedMakeId] = useState('')
  const [selectedMakeEditId, setSelectedMakeEditId] = useState('')
  const [vehicleMakeList, setVehicleMakeList] = useState<any>([])
  const [typeOffset, setTypeOffset] = useState<any>('')
  const [typeList, setTypeList] = useState<any>('')
  const [typeSearch, setTypeSearch] = useState<any>('')
  const [type, setType] = useState<any>('')
  const [typeValidation, setTypeValidation] = useState<any>('')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const debounceSearch = useDebounce(search, 500);
  // const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  
  useEffect(() => {
    fetchPermission()
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_vehicle_breakdown_options")
    setPermission(res)
   }

  useEffect(() => {
    VehicalList()
  }, [currentOffset, debounceSearch])
  useEffect(() => {
    setVehicaMakeOffset('')
  }, [searchSelect])

  const VehicalList = () => {
    setListLoader(true)
    vehicleModelList(limit, currentOffset, debounceSearch, '').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })
    getRequest(`${apiBaseURL()}master/vechile-make/?limit=1000`, true).then((res) => {
      let vehicleMakeName: any = []
      res.results.forEach((element: any) => {
        vehicleMakeName.push({ value: element.id, label: element.vechile_make })
      })
      setVehicleMakeList(vehicleMakeName)
      console.log(vehicleMakeName)
    })

    
    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_master_vehicle_modal , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
     
      vehicleModelList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      vehicleModelList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      vehicleModelList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }
  
  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}
  // AddFunction
  const clearFunction = () => {
    setVehicleModel('')
    setValidation('')
    setSelectedMakeId('')
    setTypeValidation('')
    setType('')
    setTypeOffset('')
    setErrorMessage('')
    setValidationVehicleMake('')
  }

  // Develop BY: Kriti
  // Description: Add Vehicle Model function which is called on Eamodel's submit event

  const AddFunction = () => {
    if (vehicleModel === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
    }

    if (type === '') {
      setTypeValidation('is-invalid')
    } else {
      setTypeValidation('')
    }

    if (selectedMakeId === '') {
      setValidationVehicleMake('is-invalid')
    } else {
      setValidationVehicleMake('')
    }
    if (vehicleModel !== '') {
      if (selectedMakeId !== '') {
        if (type !== '') {
          AddFunctionApi()
        }
      }
    }
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    console.log('type.value == ', type.value)

    addVehicleModel(vehicleModel, selectedMakeId, type.value).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunction()
      setListLoader(true)
        vehicleModelList(limit, '0', '').then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'vechile_model') {
        setErrorMessage(
          'Your added Vehicle Model name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // edit function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setVehicleModelEdit(item.vechile_model)
    setSelectedMakeEditId(item.vechile_make)
    setType({ label: item.vechile_typename, value: item.vehicle_type })
    setEditId(item.id)
    setModalLoader(false)
  }

  // Develop BY: Kriti
  // Description: Edit Vehicle Model function which is called on Eamodel's submit event

  const EditFunction = () => {
    if (vehicleModelEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
    }
    if (selectedMakeEditId === '') {
      setValidationVehicleMakeEdit('is-invalid')
    } else {
      setValidationVehicleMakeEdit('')
    }
    if (type === '') {
      setTypeValidation('is-invalid')
    } else {
      setTypeValidation('')
    }
    if (vehicleModelEdit !== '') {
      if (selectedMakeEditId !== '') {
        if (type !== '') {
          EditFunctionApi()
        }
      }
    }
  }

  const clearFunctionEdit = () => {
    setValidationEdit('')
    setVehicleModelEdit('')
    setErrorMessage('')
    setSelectedMakeEditId('')
    setVehicaMakeOffset('')
    setValidationVehicleMakeEdit('')
    setTypeValidation('')
    setType('')
    setTypeOffset('')
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    vehicleModelUpdate(vehicleModelEdit, selectedMakeEditId, EditId, type.value).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
      setListLoader(true)
        vehicleModelList(limit, '0', search).then((res) => {
          setGetList(res.data)

          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'vechile_model') {
        setErrorMessage(
          'Your added  Vehicle Model name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const fetchVehicaleMake = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const Make = `${apiBaseURL()}master/vechile-make/?&limit=${limit}&offset=${vehicalMakeOffset}&search=${search}`
    const response = await getRequest(Make, true)
    let hasMore: boolean = false

    if(response.results){
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setVehicaMakeOffset(newOffset)
        hasMore = true
      }
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.vechile_make,
            value: option.id,
          })
        })
      }
      if (vehicalMakeOption.length > 0) {
        setVehicalMakeOption(vehicalMakeOption.concat(options))
      } else {
        setVehicalMakeOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handlechangeVehicleMakeAdd = (e: any) => {
    setSelectedMakeId(e.value)
  }
  const handlechangeVehicleMakeEdit = (e: any) => {
    setSelectedMakeEditId(e.value)
  }

  const fetchVehicleType = (search: any) => {
    return fetchAsyncDropdown(
      typeOffset,
      setTypeOffset,
      typeList,
      setTypeList,
      `master/vechile-type/?ordering=name`,
      search,
      setTypeSearch,
      'vechile_type'
    )
  }

  const fetchVehicleTypeEdit = (search: any) => {
    return fetchAsyncDropdown(
      typeOffset,
      setTypeOffset,
      typeList,
      setTypeList,
      `master/vechile-type/?ordering=name`,
      search,
      setTypeSearch,
      'vechile_type'
    )
  }

  useEffect(() => {
    setTypeOffset('')
  }, [typeSearch])

  return (
    <>
      <div>
        <EATitle title='Vehicle Model Master - ' />
        <PageTitle breadcrumbs={masterBreadCrumbs}>
          {intl.formatMessage({ id: 'Vehicle Model Master' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your Vehicle Model has been added successfully.'
              : 'Your Vehicle Model has been edited  successfully.'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              {/* <CustomSearchBar /> */}
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                  value={search}
                  disabled={listLoadder}
                />
              </div>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_vehicle_model'
                    btnName='Add Vehicle Model'
                    className="btn-primary me-3"
                    onClick={() => {
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {getList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2' style={{ width: '25%' }}>
                            Vehicle Model
                          </th>
                          <th className='min-w-100px py-2'>Vehicle Make</th>
                          <th className='min-w-100px py-2'>Vehicle Type</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {getList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.vechile_model}</td>
                              <td>{item.vechile_makename}</td>
                              <td>{item.vechile_typename}</td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_edit_vehicle_model'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    onClick={(event) => {
                                      onChangeAdd(item)
                                      setMessageHandler('successEdit')
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                                {/* <a
                        href='javascript:void(0)'
                        className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                      >
                        <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                      </a> */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
          <CustomPaginate
              data={getList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.sa_master_vehicle_modal}
              filterStates={filterStates}
              isDisabled={listLoadder}
          />
      </div>
      <EaModal
        id='ea_modal_create_vehicle_model'
        modalTitle='Add Vehicle Model'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunction}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Make<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchVehicaleMake}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container w-100', validationVehicleMake)}
            classNamePrefix='react-select'
            menuPosition='fixed'
            value={vehicalMakeOption.filter((option: any) => option.value === selectedMakeId)}
            onChange={handlechangeVehicleMakeAdd}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Type<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchVehicleType}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container', typeValidation)}
            classNamePrefix='react-select'
            value={type}
            onChange={(e) => {
              setType(e)
              if (e.value !== '') {
                setTypeValidation('')
              }
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Model<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validation)}
            placeholder='Type here...'
            onChange={(e) => setVehicleModel(capitalizeFirstLetter(e.target.value))}
            value={vehicleModel}
          />
        </div>

        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      <EaModal
        id='ea_modal_edit_vehicle_model'
        modalTitle='Edit Vehicle Model'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Make<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchVehicaleMake}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container w-100', validationVehicleMakeEdit)}
            classNamePrefix='react-select'
            name='VehicalMakeEdit'
            value={vehicleMakeList.filter((option: any) => option.value === selectedMakeEditId)}
            onChange={handlechangeVehicleMakeEdit}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Type<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchVehicleTypeEdit}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container', typeValidation)}
            classNamePrefix='react-select'
            value={type}
            onChange={(e) => {
              setType(e)
              if (e.value !== '') {
                setTypeValidation('')
              }
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Vehicle Model<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationEdit)}
            placeholder='Type here...'
            onChange={(e) => setVehicleModelEdit(capitalizeFirstLetter(e.target.value))}
            value={vehicleModelEdit}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default VehicleModel
