import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  BrandCreate,
  BrandDelete,
  BrandSingle,
  BrandsList,
  BrandUpdate,
} from "../../../_eaFruitsDms/apiFunctions/brands/brands"
import {
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  retrieveFilter,
  saveFilter,
  toAbsoluteUrl,
} from "../../../_eaFruitsDms/helpers"
import { Helmet } from 'react-helmet'
import capitalizeFirstLetter from "../../../_eaFruitsDms/helpers/components/CapitalLetter"
import FormatMessage from "../../../_eaFruitsDms/helpers/components/FormateMessage"
import ValidationHandler from "../../../_eaFruitsDms/helpers/components/validationHandler"
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../_eaFruitsDms/layout/components/modal/eaModal"
import CustomPagination from "../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import CustomSunEditor from "../../../_eaFruitsDms/layout/components/sunEditor/CustomSunEditor"
import EATitle from "../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../_eaFruitsDms/layout/core"
import Imagecropper from "./components/imageCroper"
import TableBrand from "./components/tableBrand"
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import tenantConfiguration from "../../../TenantVariables"
import { filters } from "../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}

const Brands: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Brands Management',
  }

  let discountCappingFiltered : any = retrieveFilter(filters.sa_brand);

  const initialFiltersState = {
      selected: discountCappingFiltered?.selected || '',
      search: discountCappingFiltered?.search || '',
      currentOffSet: discountCappingFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  // all states
  
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [getList, setGetList] = useState<any>([])
  const [id, setId] = useState('')
  const [selected, setSelected] = useState<number>(discountCappingFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(discountCappingFiltered?.currentOffSet || '0');
  const [count, setCount] = useState<any>('')
  const [search, setSearch] = useState<string>(discountCappingFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [BrandName, setBrandName] = useState('')
  const [tinNumber, setTinNumber] = useState('')
  const [vrnNumber, setvrnNumber] = useState('')
  const [BrandNameEdit, setBrandNameEdit] = useState('')
  const [tinNumberEdit, setTinNumberEdit] = useState('')
  const [vrnNumberEdit, setvrnNumberEdit] = useState('')
  const [BrandNamevalid, setBrandNamevalid] = useState('')
  const [tinNumbervalid, setTinNumbervalid] = useState('')
  const [vrnNumbervalid, setvrnNumbervalid] = useState('')
  const [BrandNamevalidEdit, setBrandNamevalidEdit] = useState('')
  const [tinNumbervalidEdit, setTinNumbervalidEdit] = useState('')
  const [vrnNumbervalidEdit, setvrnNumbervalidEdit] = useState('')
  const [imageRequire, setImageRequire] = useState('')
  const [img, setImg] = useState<any>('')
  const [BrandImage, setBrandImage] = useState('')
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [imageRequireEdit, setImageRequireEdit] = useState('')
  const [imgEdit, setImgEdit] = useState<any>('Browse')
  const [BrandImageEdit, setBrandImageEdit] = useState('')
  const [imgSizeInvalidEdit, setimgSizeInvalidEdit] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(false)
  const [imageValidationSizeEdit, setImageValidationSizeEdit] = useState<boolean>(true)
  const [timer, setTimer] = useState<any>(null)
  const [validationImageEdit, setValidationImageEdit] = useState('')
  const [imageSizeEdit, setImageSizeEdit] = useState('')
  const [imageSize, setImageSize] = useState('')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)
  const [cropDataEdit, setCropDataEdit] = useState(defaultSrc)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [orderingBrandName, setOrderingBrandName] = useState(true)
  const [ordering, setOrdering] = useState('')
  const [isOrdering, setIsOrdering] = useState(false)
  const [description, setDescription] = useState('')
  const [descriptionValidation, setDescriptionValidation] = useState('')
  const [descriptionEdit, setDescriptionEdit] = useState('')
  const [descriptionValidationEdit, setDescriptionValidationEdit] = useState('')
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(discountCappingFiltered?.buttonDisable || false) 

  const debounceSearch = useDebounce(search, 500); 

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
    BrandsList(limit, currentOffset, debounceSearch, ordering).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_brand , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }, [debounceSearch , currentOffset])

  const fetchPermission = async () =>{
    let res = await checkPermissions("brands")
    setPermission(res)
   }
 
  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setBrandImage(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          console.log('width ==>>', width, 'height ==>>', height)
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageValidation(false)
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const onImageChangeEdit = (e: any) => {
    setImageSizeEdit('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImgEdit(URL.createObjectURL(file))
        setBrandImageEdit(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 1.0) {
            setImageSizeEdit('text-danger')
            setImageValidationSizeEdit(false)
          } else {
            setImageValidationSizeEdit(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }
  const getProps = (val: any) => {
    setMessageHandler('successEdit')
    setModalLoader(true)
    setId(val.id)
    BrandSingle(val.id).then((res: any) => {
      setBrandNameEdit(res.name)
      setTinNumberEdit(res.brand_tin_number)
      setvrnNumberEdit(res.brand_vrn_number)
      setDescriptionEdit(res.brand_story)
      setImgEdit(res.brand_image)
      setCropDataEdit(res.brand_image)
      setModalLoader(false)
    })
  }

  const getDeleteProps = (val: any) => {
    setMessageHandler('successDelete')
    setId(val.id)
    setDeleteName(val.name)
  }

  const deleteFuntion = () => {
    BrandDelete(id).then((notify) => {
      if (notify.success) {
        BrandsList('10', currentOffset, search, ordering).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
        })
        setIsAlertSuccess(true)
      }
      if (notify.failed) {
        setIsAlertFailed(true)
      }
      $('#closeDeleteEaModalBrand').trigger('click') // modal close
      setTimeout(() => {
        setIsAlertSuccess(false)
        setIsAlertFailed(false)
      }, 2000)
    })
  }

  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }



  

  
  const addFunction = () => {
    let navigateField = {
      BrandName: BrandName,
      description: description,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    // ui validation [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (BrandImage == '') {
      setImageRequire('file-icon-inValid')
    } else {
      setImageRequire('')
    }
    if (BrandName.trim() == '') {
      setBrandNamevalid('is-invalid')
    } else {
      setBrandNamevalid('')
    }
    if (tinNumber.trim() == '') {
      setTinNumbervalid('is-invalid')
    } else {
      setTinNumbervalid('')
    }
    if (vrnNumber.trim() == '') {
      setvrnNumbervalid('is-invalid')
    } else {
      setvrnNumbervalid('')
    }
    if (description === '' || description === '<p><br></p>') {
      setDescriptionValidation('is-invalid')
    } else {
      setDescriptionValidation('')
    }
    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (BrandImage !== '') {
      if (BrandName.trim() !== '') {
        // if (tinNumber.trim() !== '') {
        //   if (vrnNumber.trim() !== '') {
        if (description !== '' && description !== '<p><br></p>') {
          if (imageValidation) {
            setDisableBtn(true)
            setIsLoading('Please Wait..')
            BrandCreate(BrandName, tinNumber, vrnNumber, description, BrandImage).then((notify) => {
              setDisableBtn(false)
              setIsLoading('Submit')
              if (notify.success) {
                setListLoader(true)
                BrandsList(limit, currentOffset, search, ordering).then((res) => {
                  setGetList(res.data)
                  setCount(res.count)
                  setNext(res.next)
                  setPrev(res.prev)
                  setListLoader(false)
                  if (res.data.length == 0) {
                    setLength('2')
                  } else {
                    setLength('1')
                  }
                })
                $('#closeCreateEaModalBrand').trigger('click') // modal close
                clearFunction()
                setTimeout(() => {
                  setIsAlertSuccess(false)
                  setIsAlertFailed(false)
                }, 2000)

                setIsAlertSuccess(true)
              }
              if (notify.failed) {
                // setIsAlertFailed(true)
              }
              if (notify.errorField == 'name') {
                setErrorMessage(
                  'Your added brand name already exists in this system! Please try again with a unique name.'
                )
              }
            })
          }
        }
      }
    }
    //   }
    // }
  }

  const updateFunction = () => {
    let navigateField = {
      BrandNameEdit: BrandNameEdit,
      descriptionEdit: descriptionEdit,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (BrandNameEdit.trim() == '') {
      setBrandNamevalidEdit('is-invalid')
    } else {
      setBrandNamevalidEdit('')
    }
    if (tinNumberEdit.trim() == '') {
      setTinNumbervalidEdit('is-invalid')
    } else {
      setTinNumbervalidEdit('')
    }
    if (vrnNumberEdit.trim() == '') {
      setvrnNumbervalidEdit('is-invalid')
    } else {
      setvrnNumbervalidEdit('')
    }
    if (descriptionEdit === '' || descriptionEdit === '<p><br></p>') {
      setDescriptionValidationEdit('is-invalid')
    } else {
      setDescriptionValidationEdit('')
    }

    if (BrandNameEdit.trim() !== '') {
      // if (tinNumberEdit.trim() !== '') {
      //   if (vrnNumberEdit.trim() !== '') {
      if (descriptionEdit !== '' && descriptionEdit !== '<p><br></p>') {
        if (imageValidationSizeEdit) {
          setDisableBtn(true)
          setIsLoading('Please Wait..')
          BrandUpdate(
            BrandNameEdit,
            tinNumberEdit,
            vrnNumberEdit,
            BrandImageEdit,
            descriptionEdit,
            id
          ).then((notify) => {
            setDisableBtn(false)
            setIsLoading('Submit')
            if (notify.success) {
              clearFunctionEdit()
            setListLoader(true)
              BrandsList(limit, currentOffset, search, ordering).then((res) => {
                setGetList(res.data)
                setCount(res.count)
                setNext(res.next)
                setPrev(res.prev)
                setListLoader(false)
                if (res.data.length == 0) {
                  setLength('2')
                } else {
                  setLength('1')
                }
              })

              setIsAlertSuccess(true)
              $('#closeupdateEaModalBrand').trigger('click') // modal close
              setTimeout(() => {
                setIsAlertSuccess(false)
                setIsAlertFailed(false)
              }, 2000)
            }
            if (notify.failed) {
              // setIsAlertFailed(true)
            }
            if (notify.errorField == 'name') {
              setErrorMessage(
                'Your added brand name already exists in this system! Please try again with a unique name.'
              )
            }
          })
        }
      }
    }
    //   }
    // }
  }

  const clearFunction = () => {
    // clear add modal state
    setErrorMessage('')
    setCropData(defaultSrc)
    setBrandImage('')
    setImg('')
    setBrandName('')
    setTinNumber('')
    setvrnNumber('')
    setImageRequire('')
    setvrnNumbervalid('')
    setimgSizeInvalid('')
    setTinNumbervalid('')
    setBrandNamevalid('')
    setOrdering('')
    setDescription('')
    setDescriptionValidation('')
  }

  const clearFunctionEdit = () => {
    // clear edit modal state
    setErrorMessage('')
    setImgEdit('')
    setOrdering('')
    setImageSizeEdit('')
    setBrandImageEdit('')
    setBrandNameEdit('')
    setimgSizeInvalidEdit('')
    setTinNumberEdit('')
    setvrnNumberEdit('')
    setImageRequireEdit('')
    setvrnNumbervalidEdit('')
    setTinNumbervalidEdit('')
    setBrandNamevalidEdit('')
    setDescriptionEdit('')
    setDescriptionValidationEdit('')
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const getImage = (val: any) => {
    setBrandImage(val)
  }

  const getImageEdit = (val: any) => {
    setBrandImageEdit(val)
  }

  const handleOrdering = () => {
    setOrderingBrandName(!orderingBrandName)
    setIsOrdering(true)
  }

  useEffect(() => {
    if (isOrdering) {
      if (orderingBrandName) {
        setOrdering('-name')
        setListLoader(true)
        BrandsList('10', '0', search, '-name').then((res: any) => {
          setGetList(res.data)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
      } else {
        setOrdering('name')
        setListLoader(true)
        BrandsList('10', '0', search, 'name').then((res: any) => {
          setGetList(res.data)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
      }
    }
  }, [orderingBrandName, isOrdering])

  const handleDescription = (e: any) => {
    setDescriptionValidation('')
    setDescription(e)
    console.log('des', e)
  }

  const handleDescriptionEdit = (e: any) => {
    setDescriptionValidationEdit('')
    setDescriptionEdit(e)
    console.log('desedt', e)
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Brands' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({ id: 'Brands Management' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('BRAND.ADD', messageTitle)
            : messageHandler === 'successEdit'
              ? FormatMessage('BRAND.UPDATE', messageTitle)
              : messageHandler === 'successDelete' && FormatMessage('BRAND.DELETE', messageTitle)
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7 align-items-center'>
          {/* <div className='card-toolbar row g-0 w-100'> */}
            <CustomSearchBar id='searcListBrands' onChange={(e: any) => searchList(e)} value={search} disabled={listLoadder}  />
             <div className='ms-auto'>
                <div className='card-toolbar d-flex my-0'>
                  <div className="me-3">
                    {permission.write && (
                      <EaButton
                        isModal={true}
                        dataTarget='#ea_modal_create_brand'
                        btnName='Add Brand'
                        onClick={() => setMessageHandler('successAdd')}
                      />
                    )} 
                  </div>
              <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                </div>
              </div>
          {/* </div> */}
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
              {getList.length === 0 ? 
                <NoRecords/> : 
                <TableBrand
                showDelete={permission.delete}
                showWrite={permission.write}
                getLength={getLength}
                tableList={getList}
                getPropsEdit={getProps}
                getPropsDelete={getDeleteProps}
                orderingBrandName={orderingBrandName}
                handleOrdering={handleOrdering}
              />  
            }
          </div>
        </div>
      </div>
       <CustomPaginate
              data={getList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.sa_brand}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      {/* add brand */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunction}
        onSubmit={addFunction}
        closeAuto='closeCreateEaModalBrand'
        id='ea_modal_create_brand'
        modalTitle='Add Brand'
      >
        <div className='mb-4'>
          <label className='form-label' id='BrandName'>
            Brand Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', BrandNamevalid)}
            value={BrandName}
            onChange={(e) => {
              setBrandName(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setBrandNamevalid(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>TIN Number</label>
          <input
            type='text'
            className={clsx('form-control')}
            value={tinNumber}
            maxLength={20}
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setTinNumber(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setTinNumbervalid(validation)
              }
            }}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>VRN Number</label>
          <input
            type='text'
            className={clsx('form-control')}
            value={capitalizeFirstLetter(vrnNumber)}
            maxLength={7}
            onChange={(e) => {
              setvrnNumber(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setvrnNumbervalid(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4 mt-4'>
          <label className='form-label' id='description'>
            Brand Story<span className="text-danger">*</span>
          </label>
          <CustomSunEditor
            data={description}
            handleChange={handleDescription}
            fieldName='brand_story_edit'
            validationTriggered={descriptionValidation}
            message='Brand Story Field is required.'
          />
        </div>
        <Imagecropper
          title='Brand Image'
          getImage={getImage}
          onImageChange={onImageChange}
          imageRequire={imageRequire}
          imageSize={imgSizeInvalid}
          cropData={cropData}
          setCrop={setCropData}
        />
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* update brands */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        cancel={clearFunctionEdit}
        onSubmit={updateFunction}
        closeAuto='closeupdateEaModalBrand'
        id='ea_modal_update_brand'
        modalTitle='Edit Brand'
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label' id='BrandNameEdit'>
            Brand Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', BrandNamevalidEdit)}
            value={BrandNameEdit}
            onChange={(e) => {
              setBrandNameEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setBrandNamevalidEdit(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>TIN Number</label>
          <input
            type='text'
            className={clsx('form-control')}
            value={tinNumberEdit}
            maxLength={20}
            onChange={(e) => {
              const re = /^[0-9\b]+$/
              if (e.target.value === '' || re.test(e.target.value)) {
                setTinNumberEdit(e.target.value)
                const validation = ValidationHandler(e.target.value, 'is-invalid')
                setTinNumbervalidEdit(validation)
              }
            }}
            placeholder='Type here...'
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>VRN Number</label>
          <input
            type='text'
            className={clsx('form-control')}
            maxLength={7}
            value={capitalizeFirstLetter(vrnNumberEdit)}
            onChange={(e) => {
              setvrnNumberEdit(e.target.value)
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setvrnNumbervalidEdit(validation)
            }}
            placeholder='Type here...'
          />
        </div>
        <div>
          <div className='mb-4 mt-4'>
            <label className='form-label' id='descriptionEdit'>
              Brand Story<span className="text-danger">*</span>
            </label>
            <CustomSunEditor
              data={descriptionEdit}
              handleChange={handleDescriptionEdit}
              fieldName='brand_story'
              validationTriggered={descriptionValidationEdit}
              message='Brand Story Field is required.'
            />
          </div>
          <Imagecropper
            title='Brand Image'
            getImage={getImageEdit}
            onImageChange={onImageChangeEdit}
            imageRequire={validationImageEdit}
            imageSize={imageSizeEdit}
            cropData={cropDataEdit}
            setCrop={setCropDataEdit}
          />
        </div>
        <div className='mt-3'>
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>

      {/* delete Brand */}
      <EaModal
        onSubmit={deleteFuntion}
        closeAuto='closeDeleteEaModalBrand'
        modalTitle='Delete Brand'
        children={
          <p className='mb-0'>
            Are you sure you want to delete <span className='fw-bold'>{deleteName}?</span>
          </p>
        }
        id={'delete_brand'}
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default Brands
