import {apiBaseURL} from '../../helpers'
import {deleteRequest, getRequest, patchRequest, postRequest, putRequest} from '../../https/apiCall'
import {getToken} from '../../localStorage/getItem/getLocalStorage'
import EaFruitsLogout from '../../redux/action/logout/logoutAction'

const header = {
  headers: {
    Authorization: `Bearer ${getToken}`,
  },
}

// === user profile start ===

//request method for api implementation :- last change by kriti
export const getMyProfile = async (id: any) => {
  let data = ''
  await getRequest(`${apiBaseURL()}auth/users/${id}/`, true)
    .then((res) => {
      data = res
    })
    .catch((err) => {
      if (err.response.status == 500 || 401) {
        EaFruitsLogout()
      }
    })
  return data
}

export const myProfileEdit = async (
  id: any,
  name: any,
  phone: any,
  gender: any,
  date: string,
  img: any,
  lname: any
) => {
  const notify = {
    success: false,
    failed: false,
  }

  let form_data = new FormData()

  form_data.append('first_name', name)
  form_data.append('last_name', lname)
  form_data.append('gender', gender)
  form_data.append('date_of_birth', date)
  form_data.append('phone_no', phone)
  if (img !== '') {
    form_data.append('profile_img', img, img.name)
  }

  await patchRequest(`${apiBaseURL()}auth/users/update/${id}/`, form_data, true).then((res) => {
    notify.success = true // res
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}
// === user profile end ===

// === Distribution Boundries start ===
// Region
export const RegionCreate = async (name: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let form_data = new FormData()
  form_data.append('name', name)

  if (kml_file) {
    form_data.append('kml_file', kml_file, kml_file.name)
  }

  await postRequest(`${apiBaseURL()}master/region/create/`, form_data, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const RegionList = async (limit: any, offset: any, val?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }

  await getRequest(
    `${apiBaseURL()}master/region/list?limit=${limit}&offset=${offset}${
      val ? `&search=${val}` : ''
    }`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const RegionSingle = async (id: string) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master/region/${id}/`, true).then((res) => {
    data = res
  })
  return data
}

export const RegionUpdate = async (id: string, name: string, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let form_data = new FormData()
  form_data.append('id', id)
  form_data.append('name', name)

  if (kml_file.name) {
    form_data.append('kml_file', kml_file, kml_file.name)
  }

  await putRequest(`${apiBaseURL()}master/region/${id}/`, form_data, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      // notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const RegionDelete = async (id: string) => {
  await deleteRequest(`${apiBaseURL()}master/region/${id}`, true).then((res) => {})
}
// region end ====

// district
export const DistrictCreate = async (name: any, region: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()

  form_data.append('name', name)
  form_data.append('region', region)
  if (kml_file) {
    form_data.append('kml_file', kml_file, kml_file.name)
  }
  await postRequest(`${apiBaseURL()}master/district/create/`, form_data, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const DistrictUpdate = async (id: any, name: any, region: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()

  form_data.append('name', name)
  form_data.append('region', region)
  if (kml_file.name) {
    form_data.append('kml_file', kml_file, kml_file.name)
  }
  await putRequest(`${apiBaseURL()}master/district/${id}/`, form_data, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const DistictList = async (limit: any, offset: any, val?: any, region?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    `${apiBaseURL()}master/district/list?limit=${limit}&offset=${offset}${
      val ? `&search=${val}` : ''
    }&region__id=${region}`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const DistrictSingle = async (id: any) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master/district/${id}/`, true).then((res) => {
    data = res
  })
  return data
}
// district end

// ward start
export const WardCreate = async (name: any, district: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('district', district)
  if (kml_file) {
    form_data.append('kml_file', kml_file, kml_file.name)
  }

  await postRequest(`${apiBaseURL()}master/wards/create/`, form_data, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const WardUpdate = async (id: any, name: any, district: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()

  form_data.append('name', name)
  form_data.append('district', district)

  if (kml_file !== '') {
    form_data.append('kml_file', kml_file, kml_file.name)
  }
  await patchRequest(`${apiBaseURL()}master/wards/${id}/`, form_data, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const WardList = async (
  limit: any,
  offset: any,
  district?: any,
  region?: any,
  search?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }

  await getRequest(
    `${apiBaseURL()}master/wards/list/?limit=${limit}&offset=${offset}&district__name=${district}&district__region__name=${region}&search=${search}`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

// ward end

// street start

export const streetCreate = async (name: any, ward: any, land_mark: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()

  form_data.append('name', name)
  form_data.append('ward', ward)
  form_data.append('land_mark', land_mark)

  if (kml_file) {
    form_data.append('kml_file', kml_file, kml_file.name)
  }

  await postRequest(`${apiBaseURL()}master/street/create/`, form_data, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const StreetList = async (
  limit: any,
  offset: any,
  wards?: any,
  district?: any,
  region?: any,
  search?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }

  await getRequest(
    `${apiBaseURL()}master/street/list/?limit=${limit}&offset=${offset}&ward__id=${wards}&ward__district__id=${district}&ward__district__region__id=${region}&search=${search}`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const StreetSingle = async (id: any) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master/street/${id}/`, true).then((res) => {
    data = res
  })
  return data
}

export const StreetUpdate = async (id: any, name: any, ward: any, kml_file: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let form_data = new FormData()

  form_data.append('name', name)
  form_data.append('ward', ward)

  if (kml_file !== '') {
    form_data.append('kml_file', kml_file, kml_file.name)
  }
  await patchRequest(`${apiBaseURL()}master/street/${id}/`, form_data, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

// === Distribution Boundries end ===

// === product master start ===
// categories
export const CategoriesCreate = async (name: string, parent_category: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    name: name,
    parent_category: parent_category,
  }
  await postRequest(`${apiBaseURL()}master/product/category-create`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const CategoriesList = async (limit: any, offset: any, val?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    `${apiBaseURL()}master/product/category?limit=${limit}&offset=${offset}${
      val ? `&search=${val}` : ''
    }`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const CategoriesSingle = async (id: string) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master/product/category/${id}/`, true).then((res) => {
    data = res
  })
  return data
}

export const CategoriesUpdate = async (name: string, parent_category: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    name: name,
    parent_category: parent_category == 'null' ? null : parent_category,
  }
  await putRequest(`${apiBaseURL()}master/product/category/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const CategoriesDelete = async (id: string) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(`${apiBaseURL()}master/product/category/${id}/`, true).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

// groups
export const GroupsCreate = async (
  name: string,
  measurementUnit: string,
  group_image: any,
  description: any,
  product_vat: any,
  category_id: any,
  isSalesQuantityControl: boolean,
  allowedGrams: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('measurement_unit', measurementUnit)
  form_data.append('short_description', description)
  form_data.append('product_vat', product_vat !== '' ? product_vat : '0.00')
  form_data.append('category_id', category_id)

  form_data.append('is_max_qty_limit_active', isSalesQuantityControl ? 'true' : 'false')
  if (isSalesQuantityControl) {
    form_data.append('sales_max_size_limit', allowedGrams)
  }

  if (group_image.name) {
    form_data.append('group_image', group_image, group_image.name)
  } else {
    form_data.append('group_image', group_image)
  }
  await postRequest(`${apiBaseURL()}master/product/group`, form_data, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const GroupsList = async (limit: any, offset: any, val?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    `${apiBaseURL()}master/product/group?limit=${limit}&offset=${offset}${
      val ? `&search=${val}` : ''
    }`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const GroupsSingle = async (id: string) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master/product/group/${id}/`, true).then((res) => {
    data = res
  })
  return data
}

export const GroupsUpdate = async (
  name: string,
  measurementUnit: string,
  group_image: any,
  description: any,
  product_vat: any,
  category_id: any,
  isSalesQuantityControl: boolean,
  allowedGrams: any,
  id: string
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let form_data = new FormData()
  form_data.append('name', name)
  form_data.append('measurement_unit', measurementUnit)
  form_data.append('short_description', description)
  form_data.append('product_vat', product_vat !== '' ? product_vat : '0.00')
  form_data.append('category_id', category_id)
  form_data.append('is_max_qty_limit_active', isSalesQuantityControl ? 'true' : 'false')
  if (isSalesQuantityControl) {
    form_data.append('sales_max_size_limit', allowedGrams)
  }
  if (group_image !== '') {
    form_data.append('group_image', group_image, group_image.name)
  }
  await putRequest(`${apiBaseURL()}master/product/group/${id}/`, form_data, true)
    .then((res) => {
      notify.success = true // res
      console.log(res)
    })
    .catch((err) => {
      notify.failed = true // catch
      notify.errorField = err.response.data.error.field // catch
      console.log(err)
    })
  return notify
}

export const GroupsDelete = async (id: string) => {
  const notify = {
    success: false,
    failed: false,
  }

  await deleteRequest(`${apiBaseURL()}master/product/group/${id}/`, true).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

// product variant
export const VariantsCreate = async (
  name: string,
  group_name: string,
  apporxWeight: string,
  checkValue: any,
  material_id: any,
  shelf_life: any,
  near_expiry: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    name: name,
    group_name: group_name,
    approx_weight: apporxWeight === '' ? 0 : apporxWeight,
    display_on_website: checkValue,
    material_id: material_id,
    shelf_life: shelf_life,
    near_expiry: near_expiry,
  }
  await postRequest(`${apiBaseURL()}master/product/variant/add`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const VariantsList = async (limit: any, offset: any, val: any, group_id: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    `${apiBaseURL()}master/product/variant?limit=${limit}&offset=${offset}&search=${val}&group_name__id=${group_id}`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const VariantsSingle = async (id: string) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master/product/variant/${id}/`, true).then((res) => {
    data = res
  })
  return data
}

export const VariantsUpdate = async (
  name: string,
  group_name: string,
  apporxWeight: string,
  checkValueEdit: any,
  material_id: any,
  shelf_life: any,
  near_expiry: any,
  id: string
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    name: name,
    group_name: group_name,
    approx_weight: apporxWeight === '' ? 0 : apporxWeight,
    display_on_website: checkValueEdit,
    material_id: material_id,
    shelf_life: shelf_life,
    near_expiry: near_expiry,
  }
  await putRequest(`${apiBaseURL()}master/product/variant/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const VariantsDelete = async (id: string) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(`${apiBaseURL()}master/product/variant/${id}/`, true).then((res) => {
    if (res.status === 204) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
    }
  })
  return notify
}

// package Material
export const PackageCreate = async (name: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    name: name,
  }
  await postRequest(`${apiBaseURL()}master/product/material`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

export const PackageList = async (limit: any, offset: any, val?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }

  await getRequest(
    `${apiBaseURL()}master/product/material?limit=${limit}&offset=${offset}${
      val ? `&search=${val}` : ''
    }`,
    true
  ).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

export const PackageUpdate = async (id: string, name: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    name: name,
  }
  await putRequest(`${apiBaseURL()}master/product/material/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

export const PackageDelete = async (id: string) => {
  const notify = {
    success: false,
    failed: false,
  }

  await deleteRequest(`${apiBaseURL()}master/product/material/${id}/`, true)
    .then((res) => {
      notify.success = true // res
    })
    .catch((err) => {
      notify.failed = true // catch
    })
  return notify
}

//Customer Categories List

export const customerCategoriesList = async (
  limit: any,
  offset: any,
  val?: any,
  ordering?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${val}&ordering=name`
  await getRequest(`${apiBaseURL()}master/customer-category/list/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

//Customer Categories Add
export const addCustomerCategories = async (
  category: string,
  master_nature_of_business: string,
  creditDays: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    category: category,
    master_nature_of_business: master_nature_of_business,
    max_credit_days: creditDays,
    is_max_credit_days_active: creditDays != 0 ? true : false,
  }

  await postRequest(`${apiBaseURL()}master/customer-category/create/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}
//Customer Categories Edit

export const customerCategoriesUpdate = async (
  category: string,
  master_nature_of_business: string,
  creditDaysEdit: string,
  id: string,
  items?: any,
  isCreditDays?: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let credit = ''

  if (isCreditDays === true) {
    credit = creditDaysEdit === '' ? '0' : creditDaysEdit
  } else {
    credit = items.max_credit_days
  }

  let body = {
    category: category,
    master_nature_of_business: master_nature_of_business,
    max_credit_days: credit,
    is_max_credit_days_active: isCreditDays,
  }

  await putRequest(`${apiBaseURL()}master/customer-category/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

//Categories delete
export const customerCategoriesDelete = async (id: string) => {
  console.log(id)

  await deleteRequest(`${apiBaseURL()}master/customer-category-delete/${id}/`, true).then(
    (res) => {}
  )
}

// Customer  TagsList
export const customerTagsList = async (limit: any, offset: any, val?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  // await getRequest(`${apiBaseURL()}master/product/category?limit=${limit}&offset=${offset}${val ? `&search=${val}` : ''}`, true)
  let url = `?limit=${limit}&offset=${offset}&search=${val}`
  await getRequest(`${apiBaseURL()}master/customer-tags/list` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

//Add Customer Tag

export const addCustomerTags = async (tag_name: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    tag_name: tag_name,
  }
  await postRequest(`${apiBaseURL()}master/customer-tags/create/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
      console.log('msg', res.data.error.field)
    }
  })
  return notify
}

//Customer Tag Edit
export const customerTagsUpdate = async (tag_name: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    tag_name: tag_name,
  }
  await putRequest(`${apiBaseURL()}master/customer-tags/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

//Customer Tag delete
export const customerTagsDelete = async (id: string) => {
  await deleteRequest(`${apiBaseURL()}master/customer-tags-delete/${id}/`, true).then((res) => {})
}

// Customer Credit Score
export const customerCreditScoreList = async (limit: any, offset: any, ordering: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  // await getRequest(`${apiBaseURL()}master/product/category?limit=${limit}&offset=${offset}${val ? `&search=${val}` : ''}`, true)
  let url = `?limit=${limit}&offset=${offset}&ordering=name&${ordering}&ordering=id`
  await getRequest(`${apiBaseURL()}master/credit-score/list` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

// Bulk edit in  Customer Credit Score
export const bulkcreditScore = async (param: any) => {
  // console.table(param)
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  await postRequest(`${apiBaseURL()}master/credit-score/`, param, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.status === 500) {
      notify.failed = true
    }
  })

  return notify
}

//Rejection Master
// CustomerRejection List
export const customerRejectionList = async (
  limit: any,
  offset: any,
  val?: any,
  type?: any,
  ordering?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  // await getRequest(`${apiBaseURL()}master/product/category?limit=${limit}&offset=${offset}${val ? `&search=${val}` : ''}`, true)

  let url = `?type=${type}&search=${val}&ordering=${ordering}&limit=${limit}&offset=${offset}`
  await getRequest(`${apiBaseURL()}master/options-master` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

// CustomerRejection Add
export const addCustomerRejection = async (option: string, type: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    option: option,
    type: type,
  }
  await postRequest(`${apiBaseURL()}master/options-master/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

//CustomerRejection Edit

export const customerRejectionUpdate = async (option: string, type: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    option: option,
    type: type,
  }
  await putRequest(`${apiBaseURL()}master/options-master/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

//customer Rejection delete
export const customerRejectionDelete = async (id: string) => {
  await deleteRequest(`${apiBaseURL()}master/options-master/${id}/`, true).then((res) => {})
}

// Credit Rejection List
export const creditRejectionList = async (
  limit: any,
  offset: any,
  val?: any,
  type?: any,
  ordering?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?type=${type}&search=${val}&ordering=${ordering}&limit=${limit}&offset=${offset}`
  await getRequest(`${apiBaseURL()}master/options-master` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

// Credit  Rejection  Add
export const addCreditRejection = async (option: string, type: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    option: option,
    type: type,
  }
  await postRequest(`${apiBaseURL()}master/options-master/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

//Credit  Rejection  Edit

export const creditRejectionUpdate = async (option: string, type: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    option: option,
    type: type,
  }
  await putRequest(`${apiBaseURL()}master/options-master/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}
//credit Rejection  delete
export const creditRejectionDelete = async (id: string) => {
  console.log(id)

  await deleteRequest(`${apiBaseURL()}master/options-master/${id}/`, true).then((res) => {})
}

// Discount  Rejection List
export const discountRejectionList = async (
  limit: any,
  offset: any,
  val?: any,
  type?: any,
  ordering?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?type=${type}&search=${val}&ordering=${ordering}&limit=${limit}&offset=${offset}`
  await getRequest(`${apiBaseURL()}master/options-master` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

//  Discount  Rejection  Add
export const addDiscountRejection = async (option: string, type: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    option: option,
    type: type,
  }
  await postRequest(`${apiBaseURL()}master/options-master/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

//Discount  Rejection  Edit

export const discountRejectionUpdate = async (option: string, type: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    option: option,
    type: type,
  }
  await putRequest(`${apiBaseURL()}master/options-master/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

//Discount  Rejection  delete
export const discountRejectionDelete = async (id: string) => {
  console.log(id)

  await deleteRequest(`${apiBaseURL()}master/options-master/${id}/`, true).then((res) => {})
}

// Vehicle Master

// Vehicle Type Master List
export const vehicleTypeList = async (limit: any, offset: any, search?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=name`
  await getRequest(`${apiBaseURL()}master/vechile-type/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}
// Vehicle Type Master Create
export const addVehicleType = async (vechile_type: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    vechile_type: vechile_type,
  }
  await postRequest(`${apiBaseURL()}master/vechile-type/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

// Vehicle Type Master Edit
export const vehicleTypeUpdate = async (vechile_type: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    vechile_type: vechile_type,
  }
  await putRequest(`${apiBaseURL()}master/vechile-type/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

// =========================================
// Vehicle Make Master List
export const vehicleMakeList = async (limit: any, offset: any, search?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=name`
  await getRequest(`${apiBaseURL()}master/vechile-make/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

// Vehicle Make Master Create
export const addVehicleMake = async (vechile_make: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    vechile_make: vechile_make,
  }
  await postRequest(`${apiBaseURL()}master/vechile-make/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}
// Vehicle Make Master Edit
export const vehicleMakeUpdate = async (vechile_make: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    vechile_make: vechile_make,
  }
  await putRequest(`${apiBaseURL()}master/vechile-make/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

// =========================================
// Vehicle Model Master List
export const vehicleModelList = async (limit: any, offset: any, search?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=name`
  await getRequest(`${apiBaseURL()}master/vechile-model/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}

// Vehicle Model Master Create
export const addVehicleModel = async (
  vechile_model: string,
  vechile_make: string,
  vechile_type: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    vechile_model: vechile_model,
    vechile_make: vechile_make,
    vehicle_type: vechile_type,
  }
  await postRequest(`${apiBaseURL()}master/vechile-model/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}

// Vehicle Model Master Edit

export const vehicleModelUpdate = async (
  vechile_model: string,
  vechile_make: string,
  id: string,
  vechile_type: any
) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    vechile_model: vechile_model,
    vechile_make: vechile_make,
    vehicle_type: vechile_type,
  }
  await putRequest(`${apiBaseURL()}master/vechile-model/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

// =========================================

// Vehicle Breakdown Options List
export const vehicleBreakdownList = async (
  limit: any,
  offset: any,
  search?: any,
  type?: any,
  ordering?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&type=${type}&ordering=name`
  await getRequest(`${apiBaseURL()}master/options-master/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}
// Vehicle Breakdown Options Create
export const addVehicleBreakdown = async (option: string, type: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    option: option,
    type: type,
  }
  await postRequest(`${apiBaseURL()}master/options-master/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}
// Vehicle Breakdown Options Edit

export const vehicleBreakdownUpdate = async (option: string, type: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    option: option,
    type: type,
  }
  await putRequest(`${apiBaseURL()}master/options-master/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

// =========================================

//
// Payment Master Banks List
export const paymentBanksList = async (limit: any, offset: any, search?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=-id`
  await getRequest(`${apiBaseURL()}master/payment-bank/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}
//
// Payment Master Banks Create
export const addPaymentBanksList = async (bank_name: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    bank_name: bank_name,
  }
  await postRequest(`${apiBaseURL()}master/payment-bank/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}
//
// Payment Master Banks Edit

export const paymentBanksListUpdate = async (bank_name: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    bank_name: bank_name,
  }
  await putRequest(`${apiBaseURL()}master/payment-bank/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}

// =========================================

// Payment Master Wallets List
export const paymentWalletsList = async (limit: any, offset: any, search?: any, ordering?: any) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  let url = `?limit=${limit}&offset=${offset}&search=${search}&ordering=-id`
  await getRequest(`${apiBaseURL()}master/wallet-provider/` + url, true).then((res) => {
    data.count = res.count
    data.next = res.next
    data.prev = res.previous
    data.data = res.results
  })
  return data
}
// Payment Master Wallets Create
export const addPaymentWallets = async (provider_name: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }

  let body = {
    provider_name: provider_name,
  }
  await postRequest(`${apiBaseURL()}master/wallet-provider/`, body, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field // catch
    }
  })
  return notify
}
// Payment Master Wallets Edit

export const paymentWalletsUpdate = async (provider_name: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  let body = {
    provider_name: provider_name,
  }
  await putRequest(`${apiBaseURL()}master/wallet-provider/${id}/`, body, true).then((res) => {
    if (res.status === 200) {
      notify.success = true
    }
    if (res.statusText === 'Bad Request') {
      notify.failed = true
      notify.errorField = res.data.error.field
    }
  })
  return notify
}
