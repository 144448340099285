import clsx from "clsx";
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getUsers,
  getUserSingle,
  UserCreate,
  UserDelete,
  UserUpdate
} from "../../../../_eaFruitsDms/apiFunctions/userMaster/user";
import { apiBaseURL } from "../../../../_eaFruitsDms/helpers";
import {
  checkPermissions,
  dateFormat,
  defaultPermission,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isModalValidation,
  retrieveFilter,
  saveFilter,
  staticSearch
} from "../../../../_eaFruitsDms/helpers/CommonHelpers";
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../../_eaFruitsDms/helpers/components/validationHandler";
import DatePickerCustom from "../../../../_eaFruitsDms/helpers/datePicker";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import MobileNumberInput from "../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { validateEmail } from "../../../../_eaFruitsDms/validations/eaValidation";
import TableUserAccounts from "./components/tableUserAccounts";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton";
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce";
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import tenantConfiguration from "../../../../TenantVariables";
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}

const UserAccounts: React.FC<Props> = ({ className }) => {
  const intl = useIntl();
  const adminHeaderTitle = {
    title: 'Super Admin Users',
    subTitle: 'User Accounts',
  }
  let userAccountsFiltered: any = retrieveFilter(filters.sa_user_account);

  const initialFiltersState = {
    selected: userAccountsFiltered?.selected || '',
    search: userAccountsFiltered?.search || '',
    currentOffSet: userAccountsFiltered?.currentOffSet || '',
    buttonDisable: true
  }
  // all states
  const [getLength, setLength] = useState('1')
  const [getList, setGetList] = useState<any>([])
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(userAccountsFiltered?.search || '')
  const [getRoles, setGetRoles] = useState<any>([])
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [Email, setEmail] = useState('')
  const [Phone, setPhone] = useState('')
  const [Role, setRole] = useState<any>('')
  const [Gender, setGender] = useState('')
  const [Date, setDate] = useState('Select')
  const [dateShow, setDateShow] = useState('Select')
  const [dateShowEdit, setDateShowEdit] = useState('Select')
  const [Status, setStatus] = useState<any>(false)
  const [userName, setUserName] = useState('')
  const [validationname, setValidationName] = useState('')
  const [validationLastName, setvalidationLastName] = useState('')
  const [validationEmail, setValidationEmail] = useState('')
  const [validationPhone, setValidationPhone] = useState('')
  const [validationRole, setValidationRole] = useState('')
  const [validationGender, setValidationGender] = useState('')
  const [validationDate, setValidationDate] = useState('')
  const [validationEmail_Format, setValidationEmail_Format] = useState(false)
  const [nameEdit, setNameEdit] = useState('')
  const [lastNameEdit, setLastNameEdit] = useState('')
  const [EmailEdit, setEmailEdit] = useState('')
  const [PhoneEdit, setPhoneEdit] = useState('')
  const [RoleEdit, setRoleEdit] = useState<any>('')
  const [GenderEdit, setGenderEdit] = useState('')
  const [DateEdit, setDateEdit] = useState('')
  const [StatusEdit, setStatusEdit] = useState<any>(false)
  const [validationnameEdit, setValidationNameEdit] = useState('')
  const [validationLastNameEdit, setvalidationLastNameEdit] = useState('')
  const [validationEmailEdit, setValidationEmailEdit] = useState('')
  const [validationPhoneEdit, setValidationPhoneEdit] = useState('')
  const [validationRoleEdit, setValidationRoleEdit] = useState('')
  const [validationGenderEdit, setValidationGenderEdit] = useState('')
  const [validationDateEdit, setValidationDateEdit] = useState('')
  const [validationEmail_FormatEdit, setValidationEmail_FormatEdit] = useState(false)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoadingEdit, setIsLoadingEdit] = useState('Submit')
  const [isLoadingDelete, setIsLoadingDelete] = useState('Yes, Proceed')
  const [selected, setSelected] = useState<number>(userAccountsFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<any>(userAccountsFiltered?.currentOffSet || '0');
  const [count, setCount] = useState('1')
  const [id, setId] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')

  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [messageTitle, setMessageTitle] = useState('')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [roles, setRoles] = useState<any>([])
  const [accountOffset, setAccountOffset] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [emailTitle, setEmailTitle] = useState('')
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(userAccountsFiltered?.buttonDisable || false)

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchPermission()
    setListLoader(true)
    getUsers('10', currentOffset, debounceSearch).then((res) => {
      setGetList(res.data)
      if (res.data.length == 0) {
        setLength("2");
      } else {
        setLength("1");
      }
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_user_account, filterStates)
    if (search == '') {
      setButtonDisable(false)
    }

  }, [debounceSearch, currentOffset])

  useEffect(() => {
    setAccountOffset("");
  }, [searchSelect]);

  const fetchPermission = async () => {
    let res = await checkPermissions("super_admin_user_accounts")
    setPermission(res)
  }

  const handleEmailData = (email: any) => {
    setMessageHandler("emailValidate");
    setIsAlertSuccess(true);
    setEmailTitle(email);
    setTimeout(() => {
      setIsAlertSuccess(false);
    }, 1500);
  };

  let defaultOptions: any = [
    {
      value: "Male",
      label: "Male"
    },
    {
      value: "Female",
      label: "Female"
    }
  ];
  const fetchGender = async (search: any) => {
    let options: any = [];
    if (search) {
      options = staticSearch(defaultOptions, search);
    } else {
      options = defaultOptions;
    }
    return {
      options: options,
      hasMore: false
    };
  };
  const fetchRoles = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }
    let options: any = []
    const ROLE_API = `${apiBaseURL()}auth/application/role/list/?user_type=sa&ordering=name&search=${search}&limit=${limit}&offset=${accountOffset}`
    const roleResponse = await getRequest(ROLE_API, true )

    let hasMore: boolean = false
    if(roleResponse.results){
        if (roleResponse.next !== null) {
          const queryParams = new URLSearchParams(roleResponse.next)
          let newOffset: any = queryParams.get('offset')
          setAccountOffset(newOffset)
          hasMore = true
        }
        if (roleResponse.results.length > 0) {
          roleResponse.results.map((option: any) => {
            options.push({
              label: option.name,
              value: option.id,
            })
          })
        }

        if (roles.length > 0) {
          setRoles(roles.concat(options))
        } else {
          setRoles(options)
        }
    }

    return {
      options: options,
      hasMore: hasMore
    };
  };

  const getProps = (val: any) => {
    setModalLoader(true)
    setMessageHandler('successEdit')
    setId(val.id)
    getUserSingle(val.id).then((res: any) => {
      let formateDate = dateFormat(res.date_of_birth);

      setNameEdit(res.first_name);
      setLastNameEdit(res.last_name);
      setEmailEdit(res.email);
      setPhoneEdit(res.phone_no);
      setRoleEdit(res.role);
      setGenderEdit(res.gender);
      setDateEdit(formateDate.apiDate);
      setStatusEdit(res.is_active);
      // formate date
      setInitialSettingsAdd((pre: any) => ({
        ...pre,
        startDate: formateDate.displayDate
      }));
      setDateShowEdit(formateDate.displayDate);
      if (res.role !== null) {
        let roleId = res.role.id;
        let roleName = res.role.name;
        let seletedRole: any = [];
        seletedRole.push({ value: roleId, label: roleName });
        setRoleEdit(seletedRole);
      }
      setModalLoader(false)
    })
  }

  const getPropsDelete = (val: any) => {
    setMessageHandler("successDelete");
    setId(val.id);
    setUserName(val.first_name);
  };

  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const createUser = () => {
    let navigateField = {
      datePickerClose: name,
      lastName: lastName,
      Email: Email,
      Phone: Phone,
      Role: Role,
      Gender: Gender,
      Date: Date
    };

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0]);

    // ui validation [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (name.trim() == "") {
      setValidationName("is-invalid");
    } else {
      setValidationName("");
    }
    if (lastName.trim() == "") {
      setvalidationLastName("is-invalid");
    } else {
      setvalidationLastName("");
    }
    if (Email == "") {
      setValidationEmail("is-invalid");
    } else {
      setValidationEmail("");
    }
    if (Phone == "") {
      setValidationPhone("is-invalid");
    } else {
      setValidationPhone("");
    }
    if (Role == "") {
      setValidationRole("is-invalid");
    } else {
      setValidationRole("");
    }
    if (Gender == "") {
      setValidationGender("is-invalid");
    } else {
      setValidationGender("");
    }
    if (Date == "Select") {
      setValidationDate("is-invalid");
    } else {
      setValidationDate("");
    }

    // api validation front-end side. [ SAME IN ALL INSERT,UPDATE,DELETE FUNCTIONS ]
    if (name.trim() !== "") {
      if (lastName.trim() !== "") {
        if (Email.trim() !== "") {
          if (validateEmail(Email)) {
            if (Phone.trim() !== "") {
              if (Role !== "") {
                if (Gender.trim() !== "") {
                  if (Date.trim() !== "") {
                    setDisableBtn(true);
                    setIsLoading("Please Wait..");
                    UserCreate(name, lastName, Gender, Email, Date, Status, Phone, Role.value).then(
                      (res) => {
                        setDisableBtn(false);
                        setIsLoading("Submit");
                        if (res.success) {
                          clearFunction()
                          setIsAlertSuccess(true)
                          setListLoader(true)
                          getUsers('10', '0', search).then((res) => {
                            setGetList(res.data)
                            setSelected(0)
                            setCount(res.count)
                            setNext(res.next)
                            setPrev(res.prev)
                            setListLoader(false)
                            if (res.data.length == 0) {
                              setLength("2");
                            } else {
                              setLength("1");
                            }
                          });
                          $("#closeCreateUser").trigger("click");
                          setTimeout(() => {
                            setIsAlertFailed(false);
                            setIsAlertSuccess(false);
                          }, 1500);
                        }

                        if (res.errorField == "email") {
                          setErrorMessage(
                            "Your added email address already exists in this system! Please try again with a unique email address."
                          );
                        }

                        if (res.failed) {
                          // setIsAlertFailed(true)
                        }
                      }
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const deleteFuntion = () => {
    setIsLoadingDelete("Please Wait...");
    UserDelete(id).then((res) => {
      setIsLoadingDelete("Yes, Proceed");
      clearFunctionEdit();
      if (res.success) {
        setIsAlertSuccess(true)
        setListLoader(true)
        getUsers('10', currentOffset, search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
          if (res.data.length == 0) {
            setLength("2");
          } else {
            setLength("1");
          }
        });
      }
      if (res.failed) {
        setIsAlertFailed(true);
      }
      $("#delete_admin_user").trigger("click");
      setTimeout(() => {
        setIsAlertFailed(false);
        setIsAlertSuccess(false);
      }, 1500);
    });
  };

  const createUserEdit = () => {
    let navigateField = {
      datePickerCloseEdit: nameEdit,
      lastNameEdit: lastNameEdit,
      EmailEdit: EmailEdit,
      PhoneEdit: PhoneEdit,
      RoleEdit: RoleEdit,
      GenderEdit: GenderEdit,
      DateEdit: DateEdit
    };

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0]);

    if (nameEdit === "") {
      setValidationNameEdit("is-invalid");
    } else {
      setValidationNameEdit("");
    }
    if (lastNameEdit === "") {
      setvalidationLastNameEdit("is-invalid");
    } else {
      setvalidationLastNameEdit("");
    }
    if (EmailEdit == "") {
      setValidationEmailEdit("is-invalid");
    } else {
      setValidationEmailEdit("");
    }
    if (PhoneEdit == "") {
      setValidationPhoneEdit("is-invalid");
    } else {
      setValidationPhoneEdit("");
    }
    if (RoleEdit == "") {
      setValidationRoleEdit("is-invalid");
    } else {
      setValidationRoleEdit("");
    }
    if (GenderEdit == "") {
      setValidationGenderEdit("is-invalid");
    } else {
      setValidationGenderEdit("");
    }
    if (DateEdit == "") {
      setValidationDateEdit("is-invalid");
    } else {
      setValidationDateEdit("");
    }

    if (nameEdit !== "") {
      if (lastNameEdit !== "") {
        if (EmailEdit !== "") {
          if (validateEmail(EmailEdit)) {
            if (PhoneEdit !== "") {
              if (RoleEdit !== "") {
                if (GenderEdit !== "") {
                  if (DateEdit !== "") {
                    setIsLoadingEdit("Please Wait...");
                    setDisableBtn(true);
                    setIsLoading("Please Wait..");
                    UserUpdate(
                      nameEdit,
                      lastNameEdit,
                      GenderEdit,
                      EmailEdit,
                      DateEdit,
                      StatusEdit,
                      PhoneEdit,
                      RoleEdit.value,
                      id
                    ).then((res) => {
                      setDisableBtn(false);
                      setIsLoading("Submit");
                      setIsLoadingEdit("Submit");
                      if (res.success) {
                        clearFunctionEdit()
                        setIsAlertSuccess(true)
                        setListLoader(true)
                        getUsers('10', currentOffset, search).then((res) => {
                          setGetList(res.data)
                          setCount(res.count)
                          setNext(res.next)
                          setPrev(res.prev)
                          setListLoader(false)
                          if (res.data.length == 0) {
                            setLength("2");
                          } else {
                            setLength("1");
                          }
                        });

                        $("#closeEditUser").trigger("click");
                        setTimeout(() => {
                          setIsAlertFailed(false);
                          setIsAlertSuccess(false);
                        }, 1500);
                      }
                      if (res.failed) {
                        // setIsAlertFailed(true)
                      }
                      if (res.errorField == "email") {
                        setErrorMessage(
                          "Your added email address already exists in this system! Please try again with a unique email address."
                        );
                      }
                    });
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const clearFunction = () => {
    // clear add modal state
    setErrorMessage("");
    setName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setRole("");
    setGender("");
    setDate("Select");
    setDateShow("Select");
    setInitialSettingsAdd((preValue: any) => ({ ...preValue, startDate: moment() }));
    setStatus(false);
    setValidationName("");
    setvalidationLastName("");
    setValidationEmail("");
    setValidationEmail_Format(false);
    setValidationPhone("");
    setValidationRole("");
    setValidationGender("");
    setValidationDate("");
    setAccountOffset("");
  };

  const clearFunctionEdit = () => {
    // clear edit modal state
    setNameEdit("");
    setLastNameEdit("");
    setEmailEdit("");
    setPhoneEdit("");
    setRoleEdit("");
    setDateEdit("Select");
    setDateShowEdit("Select");
    setInitialSettingsAdd((preValue: any) => ({ ...preValue, startDate: moment() }));
    setGenderEdit("");
    setErrorMessage("");
    setStatusEdit(false);
    setValidationNameEdit("");
    setvalidationLastNameEdit("");
    setValidationEmailEdit("");
    setValidationEmail_FormatEdit(false);
    setValidationPhoneEdit("");
    setValidationRoleEdit("");
    setValidationGenderEdit("");
    setValidationDateEdit("");
    setAccountOffset("");
  };

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      getUsers('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  };

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      getUsers('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  };

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      getUsers('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  };

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == "success") {
      setIsAlertSuccess(false);
    }
    if (val == "failed") {
      setIsAlertFailed(false);
    }
  };

  const onApply = () => {
    setValidationDate("");
    $(`#datePickerClose`).blur();
  };

  const onCancel = () => {
    $(`#datePickerClose`).blur();
  };

  const onApplyEdit = () => {
    setValidationDateEdit("");
    $(`#datePickerCloseEdit`).blur();
  };
  const onCancelEdit = () => {
    $(`#datePickerCloseEdit`).blur();
  };

  const [initialSettingsAdd, setInitialSettingsAdd] = useState({
    startDate: moment(),
    alwaysShowCalendars: true,
    autoUpdateInput: false,
    cancelClass: "btn-secondary",
    singleDatePicker: true,
    maxDate: moment(),
    locale: {
      format: GLOBAL_DATE_FORMAT
    },
    drops: "up",
    showDropdowns: true
  });

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let date = start._d;
          let newDate = dateFormat(start._d);
          setDate(newDate.apiDate);
          setDateShow(newDate.displayDate);
          setInitialSettingsAdd({
            startDate: start._d,
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            cancelClass: "btn-secondary",
            singleDatePicker: true,
            maxDate: moment(),
            locale: {
              format: GLOBAL_DATE_FORMAT
            },
            drops: "up",
            showDropdowns: true
          });
          onApply();
        }}
        initialSettings={initialSettingsAdd}
        dateValidation={validationDate}
        dateValue={dateShow}
      />
    );
  };

  const CustomDatePickerEdit = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let date = start._d;
          let newDate = dateFormat(start._d);
          setDateEdit(newDate.apiDate);
          setDateShowEdit(newDate.displayDate);
          setInitialSettingsAdd({
            startDate: start._d,
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            cancelClass: "btn-secondary",
            singleDatePicker: true,
            maxDate: moment(),
            locale: {
              format: GLOBAL_DATE_FORMAT
            },
            drops: "up",
            showDropdowns: true
          });
          onApplyEdit();
        }}
        initialSettings={initialSettingsAdd}
        dateValidation={validationDateEdit}
        dateValue={dateShowEdit}
      />
    );
  };

  const handleChangeReset = () => {
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title="User Accounts" />
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]}>
          {intl.formatMessage({ id: "Super Admin Users" })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow("success")}
          show={IsAlertSuccess}
          message={
            messageHandler === "successAdd"
              ? FormatMessage("SUPER.ADMIN.ADD", messageTitle)
              : messageHandler === "successEdit"
                ? FormatMessage("SUPER.ADMIN.UPDATE", messageTitle)
                : messageHandler === "successDelete"
                  ? FormatMessage("SUPER.ADMIN.DELETE", messageTitle)
                  : messageHandler === "emailValidate"
                    // ?  FormatMessage('RESEND_EMAIL', messageTitle)
                    ? `Verification email has been resent to ${messageTitle}.`
                    : null
          }
        // name={resendEmail}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow("failed")}
          show={isAlertFailed}
          message="failed"
        />
        {listLoadder && <IsLoader />}
        <div className='card'>

          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={searchList} value={search} disabled={listLoadder} />
            <div className='ms-md-auto'>
              <div className='card-toolbar d-flex my-0'>
                {/* 
                                <select
                                    className='form-select form-select-white font-13 my-1 me-3 w-auto'
                                >
                                    <option value='all'>All Users</option>
                                    <option value='active'>Users1</option>
                                    <option value='inactive'>Users2</option>
                                </select> */}

                {/* <Select
                                    options={optionUsers}
                                    isSearchable
                                    placeholder='Select User'
                                    className='react-select-container my-1 me-3 w-140px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                /> */}
                {permission.write && (
                  <div className="me-3">
                    <EaButton
                      isModal={true}
                      className="btn-sm btn-primary my-1"
                      dataTarget="#ea_modal_create_adminuser"
                      btnName="Add Super Admin User"
                      onClick={() => {
                        setMessageHandler("successAdd");
                      }}
                    />
                  </div>
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {getList.length === 0 ?
                <NoRecords /> :
                <>
                  <TableUserAccounts
                    showDelete={permission.delete}
                    showWrite={permission.write}
                    getLength={getLength}
                    tableList={getList}
                    getPropsEdit={getProps}
                    getPropsDelete={getPropsDelete}
                    emailData={handleEmailData}
                  />
                </>}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={getList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.sa_user_account}
          filterStates={filterStates}
          isDisabled={listLoadder}
        />
      </div>

      {/* add SA*/}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={createUser}
        cancel={clearFunction}
        closeAuto="closeCreateUser"
        modalTitle="Add Super Admin User"
        children={
          <>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-4">
                  <label className="form-label">
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Type here..."
                    id="datePickerClose"
                    className={clsx("form-control", validationname)}
                    value={name}
                    onChange={(e) => {
                      setName(capitalizeFirstLetter(e.target.value));
                      const validation = ValidationHandler(e.target.value, "is-invalid");
                      setValidationName(validation);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-4">
                  <label className="form-label">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Type here..."
                    className={clsx("form-control", validationLastName)}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(capitalizeFirstLetter(e.target.value));
                      const validation = ValidationHandler(e.target.value, "is-invalid");
                      setvalidationLastName(validation);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">
                Email<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="Email"
                placeholder="Type here..."
                className={clsx("form-control", validationEmail)}
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  const validation = ValidationHandler(e.target.value, "is-invalid");
                  setValidationEmail(validation);
                }}
                onBlur={() => {
                  if (Email !== "") {
                    if (validateEmail(Email)) {
                      setValidationEmail_Format(false);
                    } else {
                      setValidationEmail_Format(true);
                    }
                  }
                }}
              />
              {validationEmail_Format && <p className="text-danger">Wrong email format</p>}
            </div>
            <div className="mb-4" id="Phone">
              <MobileNumberInput
                validation={validationPhone}
                setValidation={setValidationPhone}
                numberValue={Phone}
                setNumberValue={setPhone}
              />
              {/* <label className='form-label'>Phone Number</label>
              <input
                type='text'
                placeholder='Type here...'
                maxLength={9}
                className={clsx('form-control', validationPhone)}
                value={Phone}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setPhone(e.target.value)
                    const validation = ValidationHandler(e.target.value, 'is-invalid')
                    setValidationPhone(validation)
                  }
                }}
              /> */}
            </div>
            <div className="mb-4">
              <label className="form-label" id="Role">
                Role<span className="text-danger">*</span>
              </label>
              <AsyncPaginate

                loadOptions={fetchRoles}
                isSearchable
                className={clsx("react-select-container w-100", validationRole)}
                classNamePrefix="react-select"
                placeholder="Select Role"
                value={Role}
                onChange={(e) => {
                  setRole(e);
                  let event = e;
                  if (event.length === 0) {
                    event = "";
                  }
                  const validation = ValidationHandler(event, "is-invalid");
                  setValidationRole(validation);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4"
                  }
                })}
              />
            </div>
            <div className="row">
              <div className="col mb-4">
                <label className="form-label" id="Gender">
                  Gender<span className="text-danger">*</span>
                </label>
                <AsyncPaginate

                  loadOptions={fetchGender}
                  isSearchable
                  placeholder="Select"
                  className={clsx("react-select-container mb-4", validationGender)}
                  classNamePrefix="react-select"
                  onChange={(e: any) => {
                    setGender(e.value);
                    const validation = ValidationHandler(e.value, "is-invalid");
                    setValidationGender(validation);
                  }}
                  // value={Gender}
                  value={defaultOptions.find((gender: any) => gender.value === Gender)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#0c78a4"
                    }
                  })}
                />
              </div>
              {/* <div className="col mb-4">
                                <label className="form-label">Date of Birth</label>
                                <input type="date" name="" placeholder='Select' id="form-label"
                                    className={clsx(
                                        'form-control', validationDate
                                    )}
                                    value={Date}
                                    onChange={(e) => {
                                        setDate(e.target.value)
                                    }}
                                />
                            </div> */}

              <div className="col mb-4">
                <label className="form-label" id="Date">
                  Date of Birth<span className="text-danger">*</span>
                </label>
                <CustomDatePicker />
                {/* <DatePicker
                  onCancel={onCancel}
                  onCallback={(dateView: any, dateApi: any) => {
                    setDate(dateApi)
                    setDateShow(dateView)
                  }}
                  id='datePicker'
                  onApply={onApply}
                  dateValue={dateShow}
                  validation={validationDate}
                /> */}
              </div>
            </div>
            <div className="form-check form-switch form-check-custom form-check-solid">
              <label className="form-check-label me-3">Access Status</label>
              <input
                className={clsx("form-check-input h-30px w-50px")}
                checked={Status}
                onChange={(e) => {
                  let checkedEdit = $("#checkBox-user").is(":checked");
                  if (checkedEdit == true) {
                    setStatus(true);
                  } else {
                    setStatus(false);
                  }
                }}
                value={Status}
                type="checkbox"
                name="layout-builder[layout][header][fixed][desktop]"
                id="checkBox-user"
              />
            </div>
            <div className="mt-3">
              <ErrorHandler errorMessage={errorMessage} />
            </div>
          </>
        }
        id={"ea_modal_create_adminuser"}
      />

      {/* edit SA */}
      <EaModal
        actionBtnName={isModalLoading}
        btnDisabled={disableBtn}
        onSubmit={createUserEdit}
        cancel={clearFunctionEdit}
        closeAuto='closeEditUser'
        modalTitle='Edit Super Admin User'
        loader={modalLoader}
        children={
          <>
            <div className="mb-4">
              <label className="form-label">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={clsx("form-control", validationnameEdit)}
                value={nameEdit}
                onChange={(e) => {
                  setNameEdit(capitalizeFirstLetter(e.target.value));
                  const validation = ValidationHandler(e.target.value, "is-invalid");
                  setValidationNameEdit(validation);
                }}
                id="datePickerCloseEdit"
                placeholder="Type here..."
              />
            </div>
            <div className="mb-4">
              <label className="form-label">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="lastNameEdit"
                className={clsx("form-control", validationLastNameEdit)}
                value={lastNameEdit}
                onChange={(e) => {
                  setLastNameEdit(capitalizeFirstLetter(e.target.value));
                  const validation = ValidationHandler(e.target.value, "is-invalid");
                  setvalidationLastNameEdit(validation);
                }}
                placeholder="Type here..."
              />
            </div>
            <div className="mb-4">
              <label className="form-label">
                Email<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="EmailEdit"
                disabled
                placeholder="Type here..."
                className={clsx("form-control", validationEmailEdit)}
                onBlur={() => {
                  if (validateEmail(EmailEdit)) {
                    setValidationEmail_FormatEdit(false);
                  } else {
                    setValidationEmail_FormatEdit(true);
                  }
                }}
                value={EmailEdit}
                onChange={(e) => {
                  setEmailEdit(e.target.value);
                  const validation = ValidationHandler(e.target.value, "is-invalid");
                  setValidationEmailEdit(validation);
                }}
              />
              {validationEmail_FormatEdit && <p className="text-danger">Wrong email format</p>}
            </div>
            <div className="mb-4" id="PhoneEdit">
              <MobileNumberInput
                validation={validationPhoneEdit}
                setValidation={setValidationPhoneEdit}
                numberValue={PhoneEdit}
                setNumberValue={setPhoneEdit}
              />
            </div>
            <div className="mb-4">
              <label className="form-label" id="RoleEdit">
                Role
              </label>
              <AsyncPaginate

                loadOptions={fetchRoles}
                isSearchable
                className={clsx("react-select-container mb-4", validationRoleEdit)}
                classNamePrefix="react-select"
                placeholder="Select Role"
                value={RoleEdit}
                onChange={(e) => {
                  setRoleEdit(e);
                  let event = e;
                  if (event.length === 0) {
                    event = "";
                  }
                  const validation = ValidationHandler(event, "is-invalid");
                  setValidationRoleEdit(validation);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#0c78a4"
                  }
                })}
              />
            </div>
            <div className="row">
              <div className="col mb-4">
                <label className="form-label" id="Gender">
                  Gender<span className="text-danger">*</span>
                </label>
                <AsyncPaginate

                  loadOptions={fetchGender}
                  isSearchable
                  className={clsx("react-select-container mb-4", validationGenderEdit)}
                  classNamePrefix="react-select"
                  placeholder="Select"
                  onChange={(e) => {
                    setGenderEdit(e.value);
                    const validation = ValidationHandler(e.value, "is-invalid");
                    setValidationGenderEdit(validation);
                  }}
                  // value={Gender}
                  value={defaultOptions.find((gender: any) => gender.value === GenderEdit)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#0c78a4"
                    }
                  })}
                />
              </div>
              {/* <div className="col mb-4">
                                <label className="form-label">Date of Birth</label>
                                <input type="date" name="" placeholder='Select' id="form-label"
                                    className={clsx(
                                        'form-control', validationDateEdit
                                    )}
                                    value={DateEdit}
                                    onChange={(e) => setDateEdit(e.target.value)}
                                />
                            </div> */}
              <div className="col mb-4">
                <label className="form-label" id="DateEdit">
                  Date of Birth<span className="text-danger">*</span>
                </label>
                <CustomDatePickerEdit />
                {/* <DatePicker
                  onCancel={onCancelEdit}
                  onCallback={(dateView: any, dateApi: any) => {
                    setDateEdit(dateApi)
                    setDateShowEdit(dateView)
                  }}
                  id='datePickerEdit'
                  onApply={onApplyEdit}
                  dateValue={dateShowEdit}
                  validation={validationDateEdit}
                /> */}
              </div>
            </div>
            <div className="form-check form-switch form-check-custom form-check-solid">
              <label className="form-check-label me-3">Access Status</label>
              <input
                className={clsx("form-check-input h-30px w-50px")}
                checked={StatusEdit}
                onChange={(e) => {
                  let checkedEdit = $("#checkBox-userEdit").is(":checked");
                  if (checkedEdit == true) {
                    setStatusEdit(true);
                  } else {
                    setStatusEdit(false);
                  }
                }}
                value={StatusEdit}
                type="checkbox"
                name="layout-builder[layout][header][fixed][desktop]"
                id="checkBox-userEdit"
              />
            </div>
            <div className="mt-3">
              <ErrorHandler errorMessage={errorMessage} />
            </div>
          </>
        }
        id={"ea_modal_edit_adminuser"}
      />

      {/* delete SA*/}
      <EaModal
        modalTitle="Delete Super Admin User"
        children={
          <p className="mb-0">
            Are you sure you want to delete <span className="fw-bold">{userName}?</span>
          </p>
        }
        closeAuto="delete_admin_user"
        id={"deleteAdminSku"}
        actionBtnName={isLoadingDelete}
        onSubmit={deleteFuntion}
      />
    </>
  );
};

export default UserAccounts;
