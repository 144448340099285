import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, dateFormat, fetchAsyncDropdown, KTSVG, measurementLabel } from "../../../_eaFruitsDms/helpers";
import { getRequest, postRequest } from "../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import tenantConfiguration from "../../../TenantVariables";

type Props = {
  className: string
}
const WTransferStockIn: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const headerTitle = {
    title: 'Stock-In',
    subTitle: 'Warehouse Transfer Stock-In',
  }

  const navigate = useNavigate()

  const [inwardIds, setInwardIds] = useState<any>([])
  const [inwardIdResponse, setInwardIdResponse] = useState<any>([])
  const [stockEntries, setStockEntries] = useState<any>([])
  const [warehouseId, setWarehouseId] = useState<any>(0)
  const [stockId, setStockId] = useState<any>(0)
  const [warehouseName, setWarehouseName] = useState<string>('')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [productSkus, setProductSkus] = useState<any>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [stockOffSet, setStockOffSet] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [searchSelect, setSearchSelect] = useState('')
  const [reasonOffSet, setReasonOffSet] = useState<any>('')
  const [reasons, setReasons] = useState<any>([])

  useEffect(() => {
    const w_id = localStorage.getItem('w_id')
    setWarehouseId(w_id)
    setDisplayLoader(true)
    apiCall(w_id).then(() => {
      setDisplayLoader(false)
    })
  }, [])

  useEffect(() => {
    setStockOffSet('')
  }, [searchSelect])

  const apiCall = async (wId: any) => {
    const response = await getRequest(
      `${apiBaseURL()}inventory/product-SKUs?limit=1000&warehouse_id=${wId}`,
      true
    )
    let productSkus: any = []

    if (response && response.results.length > 0) {
      response.results.map((item: any) => {
        productSkus.push({
          label: item.name,
          value: item.id,
        })
      })
    }
    setProductSkus(productSkus)
  }

  const fetchWarehouseStock = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const viewStockAPI = `${apiBaseURL()}inventory/view_stock_in_out/?receiver_warehouse_id=${warehouseId}&transfer_status=false&stock_type=WarehouseTransfer&limit=${limit}&offset=${stockOffSet}&search=${search}`

    let warehouseStockResponse = await getRequest(viewStockAPI, true)

    let hasMore: boolean = false

    let options: any = []
    let optionData: any = []

    if(warehouseStockResponse.results){
      if (warehouseStockResponse.next !== null) {
        const queryParams = new URLSearchParams(warehouseStockResponse.next)
        let newOffset: any = queryParams.get('offset')
        setStockOffSet(newOffset)
        hasMore = true
      }
  
      if (warehouseStockResponse.results.length > 0) {
        warehouseStockResponse.results.map((option: any) => {
          options.push({
            label: `#${option.id}`,
            value: option.id,
          })
          optionData.push(option)
        })
      }
  
      if (inwardIds.length > 0) {
        setInwardIds(inwardIds.concat(options))
        setInwardIdResponse(inwardIdResponse.concat(optionData))
      } else {
        setInwardIds(options)
        setInwardIdResponse(optionData)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchReason = async (search?: any) => {
    let response = await fetchAsyncDropdown(
      reasonOffSet,
      setReasonOffSet,
      reasonOffSet,
      setReasons,
      `master/stockin_reason?warehouse_transfer=true&ordering=stockin_reason`,
      search,
      setSearchSelect,
      'stockin_reason'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    if (inputName === 'inward') {
      setStockId(inputValue)
      const inwardObj = inwardIdResponse.find((option: any) => option.id == inputValue)
      let updatedOption: any = []

      inwardObj.stock_entries.map((option: any) => {
        let innerUpdatedOption: any = { ...option }
        innerUpdatedOption['receivable_qty'] = innerUpdatedOption.qty
        innerUpdatedOption['is_reason_disabled'] = true
        innerUpdatedOption['is_validation'] = false

        updatedOption.push(innerUpdatedOption)
      })

      setStockEntries(updatedOption)
      setWarehouseName(inwardObj.sender_warehouse_id.name)
    }
  }

  const handleChangeRow = (e: any, index: number, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    const updatedStockEntries = [...stockEntries]

    if (inputName === 'qty') {
      updatedStockEntries[index].is_reason_disabled = true

      if (inputValue < updatedStockEntries[index].receivable_qty) {
        updatedStockEntries[index].is_reason_disabled = false
      } else {
        updatedStockEntries[index].is_reason_disabled = true
        delete updatedStockEntries[index].reason
      }

      updatedStockEntries[index].is_validation = false

      if (inputValue > updatedStockEntries[index].receivable_qty) {
        updatedStockEntries[index].is_validation = true
        updatedStockEntries[index].is_reason_disabled = false
        setStockEntries(updatedStockEntries)
        return false
      }
    }

    if (inputName === 'reason') {
      updatedStockEntries[index].reason = inputValue
      updatedStockEntries[index].validation_for_reason = ''
      updatedStockEntries[index].note = ''
    }

    if (inputValue === 'note') {
      updatedStockEntries[index].validation_for_note = ''
    }

    updatedStockEntries[index][inputName] = inputValue
    setStockEntries(updatedStockEntries)
  }

  const validateStockEntries = () => {
    const updatedStockEntries = [...stockEntries]

    let isInvalid: boolean = true

    updatedStockEntries.map((stockEntries: any, index: number) => {
      if (['', null, 'null'].includes(stockEntries.qty)) {
        updatedStockEntries[index].validation_for_qty = 'is-invalid'
        isInvalid = false
      }

      if (stockEntries.qty < stockEntries.receivable_qty) {
        if (['', null, 'null'].includes(stockEntries.reason)) {
          if (['', null, 'null'].includes(stockEntries.note)) {
            if (stockEntries.reason === '') {
              updatedStockEntries[index].validation_for_note = 'is-invalid'
              isInvalid = false
            }
          } else {
            if (stockEntries.note === '') {
              updatedStockEntries[index].validation_for_reason = 'is-invalid'
              isInvalid = false
            }
          }
        }
      }
    })

    if (!isInvalid) {
      setStockEntries(updatedStockEntries)
    }

    return isInvalid
  }

  const submitHandler = async () => {
    const isValid: boolean = validateStockEntries()

    if (!isValid) {
      return false
    }

    const updatedStockEntries = [...stockEntries]
    updatedStockEntries.map((stockEntries: any) => {
      stockEntries.product_id = stockEntries.product_id.id
    })

    const warehouseStockInSubmitData: any = {
      stock_entries: stockEntries,
      stock_type: 'WarehouseTransfer',
      stock_entry_type: 'In',
      transfer_status: true,
      receiver_warehouse_id: warehouseId,
      action_by: localStorage.getItem('user_id'),
    }

    setIsSubmitting(true)
    const bulkStockInAPIUrl = `${tenantConfiguration.apiBaseUrl
      }inventory/bulk_stock_in_out/?stock_in_out_id=${parseInt(stockId)}`
    setDisplayLoader(true)
    await postRequest(bulkStockInAPIUrl, warehouseStockInSubmitData, true)
    setDisplayLoader(false)
    setIsSubmitting(false)
    setStockEntries([])

    navigate('/reports/daily-stock-in-report')
  }

  const cancel = () => {
    setStockEntries([])
    setWarehouseName('')
    setStockId(0)
  }

  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: 'Warehouse Transfer Stock-In' })}
      </PageTitle>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        {displayLoader && <IsLoader />}
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Warehouse Transfer Stock-In</span>
          </h3>
          {/* end::Title */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          <div className='row mb-4'>
            <div className='col-md-3'>
              <label className='form-label'>
                Inward ID<span className="text-danger">*</span>
              </label>
              <AsyncPaginate
                    
                loadOptions={fetchWarehouseStock}
                placeholder='Select'
                isSearchable
                className='react-select-container w-100'
                classNamePrefix='react-select'
                menuPosition='fixed'
                name='role'
                value={inwardIds.filter((option: any) => option.value === stockId)}
                onChange={(e) => handleChange(e, 'inward')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div className='col-md-4 col-xl-3'>
              <label className='form-label'>Warehouse/Distribution Center</label>
              <input type='text' className='form-control' disabled value={warehouseName} />
            </div>
          </div>
          {/* begin::Table container */}
          {stockEntries.length == 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='table-responsive border-top'>
                {/* begin::Table */}
                <table className='table table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-200px'>Product SKU</th>
                      <th className='min-w-120px w-120px'>Batch Number</th>
                      <th className='min-w-150px w-180px'>Expiry Date</th>
                      <th className='min-w-120px w-120px'>Receivable Qty</th>
                      <th className='min-w-190px w-190px'>
                        Actual Qty<span className="text-danger">*</span>
                      </th>
                      <th className='min-w-300px w-300px'>Reason</th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {stockEntries.map((stockEntry: any, index: number) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <Select
                                options={productSkus}
                                isDisabled={true}
                                isSearchable
                                className='react-select-container w-100'
                                classNamePrefix='react-select'
                                menuPosition='fixed'
                                styles={{
                                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                value={productSkus.find(
                                  (option: any) => option.value === stockEntry.product_id.id
                                )}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                  },
                                })}
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='#43246'
                                value={stockEntry.batch_number}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control calendar'
                                placeholder='Select'
                                value={dateFormat(stockEntry.expiry_date).displayDate}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control w-100px'
                                placeholder='45 Crate'
                                value={
                                  stockEntry.receivable_qty +
                                  ' ' +
                                  measurementLabel(
                                    stockEntry.product_id.fix_size ||
                                      (stockEntry.product_id.variants &&
                                        stockEntry.product_id.variants.approx_weight > 0)
                                      ? stockEntry.product_id.material
                                      : stockEntry.product_id.measurement_unit
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <div
                                className='input-group'
                                style={{
                                  borderColor:
                                    stockEntry.is_validation ||
                                      stockEntry.validation_for_qty === 'is-invalid'
                                      ? '#d01f45'
                                      : '',
                                }}
                              >
                                <input
                                  type='number'
                                  placeholder='45'
                                  name='qty'
                                  value={stockEntry.qty}
                                  onChange={(e: any) => handleChangeRow(e, index)}
                                  className={clsx(
                                    'form-control min-w-70px',
                                    stockEntry.is_validation ||
                                      stockEntry.validation_for_qty === 'is-invalid'
                                      ? 'is-invalid'
                                      : ''
                                  )}
                                />
                                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                  {measurementLabel(
                                    stockEntry.product_id.fix_size ||
                                      (stockEntry.product_id.variants &&
                                        stockEntry.product_id.variants.approx_weight > 0)
                                      ? stockEntry.product_id.material
                                      : stockEntry.product_id.measurement_unit
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <AsyncPaginate
                    
                                loadOptions={fetchReason}
                                placeholder='Select'
                                isSearchable
                                isDisabled={stockEntry.is_reason_disabled}
                                className={clsx(
                                  'react-select-container w-100',
                                  stockEntry.validation_for_reason
                                )}
                                classNamePrefix='react-select'
                                menuPosition='fixed'
                                name='reason'
                                value={reasons.filter(
                                  (option: any) => option.value === stockEntry.reason
                                )}
                                onChange={(e) => handleChangeRow(e, index, 'reason')}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                  },
                                })}
                              />
                              {stockEntry.reason == '' && (
                                <input
                                  type='text'
                                  className={clsx(
                                    'form-control mt-2',
                                    stockEntry.validation_for_note
                                  )}
                                  placeholder='Enter Reason'
                                  name='note'
                                  value={stockEntries.other_reason && stockEntries.other_reason}
                                  onChange={(e: any) => handleChangeRow(e, index)}
                                  disabled={stockEntry.is_reason_disabled}
                                />
                              )}
                            </td>
                            <td>
                              <button type='button' className='position-relative btn ms-auto p-0'>
                                <span className='svg-icon-dark'>
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className='ic me-0'
                                  />
                                </span>
                              </button>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              <div className='pt-4 border-top text-center'>
                <button
                  type='button'
                  className='btn btn-secondary me-3'
                  disabled={isSubmitting}
                  onClick={cancel}
                >
                  Cancel
                </button>
                <button className='btn btn-primary' onClick={submitHandler} disabled={isSubmitting}>
                  {isSubmitting ? 'Please wail...' : 'Submit'}
                </button>
              </div>
            </>
          )}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default WTransferStockIn
