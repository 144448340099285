import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import Slider from 'react-slick'
import {
  apiBaseURL,
  checkPermissions,
  dateFormat,
  defaultPermission,
  fetchAsyncDropdown,
  fetchStaticAsyncDropdown,
  retrieveFilter,
  saveFilter,
} from '../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ShowingEntries from '../../../../_eaFruitsDms/helpers/components/ShowingEntries'
import {deleteRequest, getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import {EaChartPie} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import tenantConfiguration from '../../../../TenantVariables'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'

const warehouseoptions = [
  {value: 'all', label: 'All Warehouses'},
  {value: 'Warehouse1', label: 'Warehouse 1'},
  {value: 'Warehouse2', label: 'Warehouse 2'},
  {value: 'Warehouse3', label: 'Warehouse 3'},
]

const surveytelesaleoptions = [
  {value: '', label: 'All Users'},
  {value: 'ts', label: 'TS'},
  {value: 'sr', label: 'SR'},
  {value: 'ss', label: 'SS'},
  {value: 'mo', label: 'MO'},
]

const surveyflagoptions = [
  {value: 'flagsonly', label: 'Flags Only'},
  {value: 'Flag1', label: 'Flag1'},
  {value: 'Flag2', label: 'Flag2'},
  {value: 'Flag3', label: 'Flag3'},
]

type Props = {
  className: string
}

const SurveyName: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()

  let surveyDetails: any = ''

  if (localStorage.getItem('surveyFormSingle')) {
    surveyDetails = JSON.parse(localStorage.getItem('surveyFormSingle') || '')
  } else {
    navigate('/survey-forms')
  }

  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Survey Forms',
    subTitle: 'Survey Forms',
  }
  const settings = {
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }

  const initialValue = [
    {
      id: '',
      surveyform_id: {
        id: '',
        survey_form_name: '',
      },
      customer_id: {
        id: '',
        ward: '',
        business_name: '',
        contact_no: '',
        warehouse: [
          {
            id: '',
            name: '',
          },
        ],
      },
      submited_at: '0',
      created_at: '',
      updated_at: '',
      answers: [
        {
          id: '',
          answer: '',
          questions_id: '',
        },
      ],
      feedback_by: {
        id: '',
        first_name: '',
        last_name: '',
        profile_img: null,
        street: [],
        type: '',
      },
    },
  ]

  let surveyFormFiltered: any = retrieveFilter(filters.sa_survey_detail)

  const initialPageState = {
    search: surveyFormFiltered?.search || '',
    limit: tenantConfiguration.limit,
    offset: '',
    list: initialValue,
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: surveyFormFiltered?.currentOffSet || '',
    selected: surveyFormFiltered?.selected || 0,
  }

  const initialState = {
    warehouseId: surveyFormFiltered?.warehouseId || {label: 'All Warehouses', value: ''},
    type: surveyFormFiltered?.type || '',
    selected: surveyFormFiltered?.selected || '',
    search: surveyFormFiltered?.search || '',
    currentOffSet: surveyFormFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  // state
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseId, setWarehouseId] = useState(
    surveyFormFiltered?.warehouseId || {label: 'All Warehouses', value: ''}
  )
  const [type, setType] = useState(surveyFormFiltered?.type || '')
  const [isLoadingDelete, setIsLoadingDelete] = useState<string>('Yes, Proceed')
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [messageTitle, setMessageTitle] = useState<string>('')
  const [chatData, setChatData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pageState, setPageState] = useState(initialPageState)
  const [permission, setPermission] = useState(defaultPermission)
  const [permissionfeedback, setPermissionFeedBack] = useState(defaultPermission)
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    surveyFormFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(pageState.search, 500)

  useEffect(() => {
    checkPermissions('survey_forms').then((res: any) => {
      setPermission(res)
    })
    checkPermissions('survey_forms_feedback').then((res: any) => {
      setPermissionFeedBack(res)
    })
  }, [])

  const getSurveyFeedBack = async () => {
    const url =
      apiBaseURL() +
      'surveyForms/answer/list?surveyform_id=' +
      surveyDetails[0] +
      `&oredering=name&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${debounceSearch}`
    const warehouseUrl = '&warehouse_id=' + warehouseId.value
    const feedbackType = '&feedback_by__type=' + type
    const response = await getRequest(url + warehouseUrl + feedbackType, true)
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
      isLoading: response ? false : true,
    })

    let updatedState: any = {...filterStates}
    updatedState['selected'] = pageState.selected
    updatedState['currentOffSet'] = pageState.currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_survey_detail, filterStates)
    // if(warehouseId.value == '' && categoryId.value == '' && searchByName == '' && typeId == '' && (userType == undefined || userType == '')){
    //     setButtonDisable(false)
    // }
  }

  const getSurveyReport = async () => {
    setIsLoading(true)
    const reportUrl = apiBaseURL() + 'surveyForms/survay_form_analytics/' + surveyDetails[0]
    const reportResponse = await getRequest(reportUrl, true)
    if (reportResponse) {
      let reportData: any = []
      reportResponse.length !== 0 &&
        reportResponse.forEach((item: any) => {
          // reportData.push({ value: item.team_size, name: item.name })
          let title = item.question_title
          let data: any = []
          item.question_options.length !== 0 &&
            item.question_options.forEach((que: any) => {
              que.survey_answer_count > 0 &&
                data.push({value: que.survey_answer_count, name: que.option_text})
            })

          if (data.length > 0) {
            reportData.push({
              title: title,
              data: data,
            })
          }
        })

      setChatData(reportData)
    }
    setIsLoading(false)
  }

  const fetchWarehouse = async (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      warehouseList,
      setWarehouseList,
      'warehouse/list?ordering=name',
      search,
      setDropDownSearch,
      false,
      true,
      'All Warehouse'
    )
  }

  const fetchUserType = (search?: string) => {
    return fetchStaticAsyncDropdown(surveytelesaleoptions, search)
  }

  const onChangeWarehouse = (e: any) => {
    setWarehouseId(e)
    setButtonDisable(true)
    let updatedPageState: any = {...pageState}
    updatedPageState['selected'] = 0
    updatedPageState['currentOffset'] = 0
    setPageState(updatedPageState)
    setFilterStates({...filterStates, ['warehouseId']: e})
  }

  const removeSurveyForm = async () => {
    setIsLoadingDelete('Please Wait...')
    const deleteSurveyQuestionAPI = `${apiBaseURL()}surveyForms/form/${
      surveyDetails && surveyDetails[0]
    }`

    const res = await deleteRequest(deleteSurveyQuestionAPI, true)

    if (res && res.status == 204) {
      setIsLoadingDelete('Yes, Proceed')
      $('#delete_survey_form').trigger('click')

      setMessageTitle(surveyDetails[1])
      setIsAlertSuccess(true)

      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
        navigate('/survey-forms')
      }, 1500)
    }
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  useEffect(() => {
    getSurveyFeedBack()
  }, [pageState.currentOffset, debounceSearch, warehouseId.value, type])

  useEffect(() => {
    setOffset(0)
  }, [dropDownSearch])

  useEffect(() => {
    getSurveyReport()
  }, [])

  const fakeJsonChatData = [
    {
      title: 'question 1',
      formName: surveyDetails ? surveyDetails[1] : '',
      data: [
        {value: 300, name: 'check Box'},
        {value: 200, name: 'textarea'},
        {value: 700, name: 'test'},
      ],
    },
    {
      title: 'question 2',
      formName: surveyDetails ? surveyDetails[1] : '',
      data: [
        {value: 500, name: 'Union Ads'},
        {value: 120, name: 'Video Ads'},
        {value: 1048, name: 'tes ads'},
        {value: 580, name: 'camera1'},
        {value: 800, name: 'camera2'},
        {value: 456, name: 'camer3a'},
        {value: 100, name: 'came3ra'},
      ],
    },
    {
      title: 'question 3',
      formName: surveyDetails ? surveyDetails[1] : '',
      data: [
        {value: 900, name: 'check Box'},
        {value: 240, name: 'textarea'},
        {value: 584, name: 'test'},
      ],
    },
    {
      title: 'question 4',
      formName: surveyDetails ? surveyDetails[1] : '',
      data: [
        {value: 1048, name: 'check Box'},
        {value: 200, name: 'textarea'},
        {value: 580, name: 'test'},
      ],
    },
  ]

  const handleChange = (e: any) => {
    const inputValue = e.target ? e.target.value : e.value
    setButtonDisable(true)
    setPageState({
      ...pageState,
      search: inputValue,
      currentOffset: '',
      selected: 0,
    })
    setFilterStates({...filterStates, ['search']: inputValue})
  }

  const handleChangeReset = () => {
    let updatePageState: any = {...pageState}
    updatePageState['currentOffset'] = 0
    updatePageState['selected'] = 0
    updatePageState['search'] = ''
    setPageState(updatePageState)

    setWarehouseId({label: 'All Warehouse', value: ''})
    setType('')

    let updatedState: any = {...filterStates}
    updatedState['roleId'] = ''
    updatedState['warehouseId'] = ''
    updatedState['search'] = ''
    updatedState['selected'] = ''
    updatedState['currentOffSet'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={[reportHeaderTitle]} backNavigate='/survey-forms'>
        {intl.formatMessage({id: 'Survey Forms'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={FormatMessage('SURVEY_FORM.DELETE', messageTitle)}
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card ${className} mb-5`}>
        <div className='card-header border-0 py-3 py-md-4 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>{surveyDetails && surveyDetails[1]}</span>
            <p className='font-13 text-muted mb-0'>
              {surveyDetails && dateFormat(surveyDetails[2]).displayDate}
            </p>
          </h3>
          <div className='card-toolbar d-flex g-3 my-0'>
            {permissionfeedback.write && (
              <>
                {permission.write && (
                  <>
                    {surveyDetails && surveyDetails[3] === 0 && (
                      <Button
                        onClick={() => {
                          surveyDetails &&
                            navigate('/survey-forms/survey-feedback', {
                              state: {id: surveyDetails[0]},
                            })
                        }}
                        variant='light'
                        size='sm'
                        className='me-2'
                      >
                        Edit
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
            {/* {permissionfeedback.delete && <>
                            {permission.delete &&
                                <Button
                                    data-bs-toggle='modal'
                                    data-bs-target='#deleteSurveyForm'
                                    variant='light' size='sm' className=''>
                                    Delete
                                </Button>}
                        </>} */}
          </div>
        </div>
      </div>
      {chatData.length > 0 && (
        <div className='row mt-3 mt-md-0'>
          <div className='col-12 mt-md-0'>
            <div className='card card-xl-stretch mb-5'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                <div>
                  <h3 className='card-title align-items-start flex-column my-0'>
                    <span className='card-label fw-bold font-17'>Survey Analytics</span>
                  </h3>
                </div>
              </div>
              <div className='card-body'>
                {isLoading ? (
                  <IsLoader />
                ) : (
                  <div className='px-4'>
                    <Slider {...settings}>
                      {chatData.map((item: any) => (
                        <EaChartPie item={{data: item.data, bottom: '30%', title: item.title}} />
                      ))}
                    </Slider>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div className='card'>
          {pageState.isLoading && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            {/* <CustomSearchBar newSearch={true} setResponse={setPageState} /> */}
            <CustomSearchBar
              onChange={handleChange}
              isDisable={pageState.isLoading}
              value={pageState.search}
            />
            <div className='ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <AsyncPaginate
                  loadOptions={fetchWarehouse}
                  isSearchable
                  placeholder='All Warehouses'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  onChange={onChangeWarehouse}
                  value={warehouseId}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchUserType}
                  isSearchable
                  placeholder='All Warehouses'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  onChange={(e: any) => {
                    setFilterStates({...filterStates, ['type']: e.value})
                    setType(e.value)
                    setButtonDisable(true)
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    setPageState(updatedPageState)
                  }}
                  value={surveytelesaleoptions.find((option: any) => option.value == type)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {/* <Select
                  options={surveytelesaleoptions}
                  isSearchable
                  placeholder='All Users'
                  onChange={(e: any) => {
                      setFilterStates({ ...filterStates, ['type']: e.value})
                      setType(e.value)
                      setButtonDisable(true)
                      let updatedPageState: any = { ...pageState }
                      updatedPageState['selected'] = 0
                      updatedPageState['currentOffset'] = 0
                      setPageState(updatedPageState)
                }}
                  className='react-select-container my-1 me-3 w-190px'
                  classNamePrefix='react-select'
                  value={type}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                /> */}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                {/* <Select
                                    options={surveyflagoptions}
                                    isSearchable
                                    placeholder="Flags only"
                                    className='react-select-container my-1 me-3 w-140px'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                /> */}
                {/* <label className="form-label mb-0"><button type="button" className="btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                    Export</button>
                                </label> */}
                {/* </div> */}
              </form>
            </div>
          </div>

          <div className='card-body py-3'>
            {/* {surveyFormDetail.length === 0 ? <NoRecords /> : */}
            {pageState.list.length === 0 ? (
              <NoRecords />
            ) : (
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2 table-hover'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-150px'>Date</th>
                      <th className='min-w-140px'>Conducted By</th>
                      <th className='min-w-120px'>Customer Name</th>
                      <th className='min-w-120px'>Mapped Warehouse</th>
                      <th className='min-w-80px py-2'>Flag</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      // surveyFormDetail.length !== 0 && surveyFormDetail.map((item) => (
                      pageState.list.length !== 0 &&
                        pageState.list.map((item) => (
                          <tr
                            onClick={() => {
                              {
                                permissionfeedback.read &&
                                  navigate('/survey-forms/survey-detail', {
                                    state: {
                                      answerId: item.id,
                                    },
                                  })
                              }
                            }}
                            className='cursor-pointer'
                          >
                            <td className=''>
                              {item.submited_at && dateFormat(item.submited_at).displayDate}
                            </td>
                            <td className=''>
                              {item.feedback_by
                                ? item.feedback_by.first_name +
                                  ' ' +
                                  item.feedback_by.last_name +
                                  ' ' +
                                  `(${item.feedback_by.type.toUpperCase()})`
                                : '-'}
                            </td>
                            <td className=''>
                              {item.customer_id ? item.customer_id.business_name : '-'}
                            </td>
                            <td className=''>
                              {item.customer_id && item.customer_id.warehouse.length !== 0
                                ? item.customer_id.warehouse[0].name
                                : '-'}
                            </td>
                            <td className=''>-</td>
                          </tr>
                        ))
                    }
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <CustomPaginate
          data={pageState.list}
          selected={pageState.selected}
          pageState={pageState}
          setPageState={setPageState}
          totalRecords={pageState.count}
          limit={pageState.limit}
          isDisable={pageState.isLoading}
          saveFilterName={filters.sa_survey_detail}
          filterStates={filterStates}
        />
      </div>

      <EaModal
        modalTitle='Delete Survey Form'
        children={
          <p className='mb-0'>
            Are you sure you want to delete{' '}
            <span className='fw-bold'>{surveyDetails && surveyDetails[1]}?</span>
          </p>
        }
        closeAuto='delete_survey_form'
        id={'deleteSurveyForm'}
        actionBtnName={isLoadingDelete}
        onSubmit={removeSurveyForm}
      />
    </>
  )
}

export default SurveyName
