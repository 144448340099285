import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  addPaymentBanksList,
  paymentBanksList,
  paymentBanksListUpdate,
} from "../../../../_eaFruitsDms/apiFunctions/master/master"
import { checkPermissions, defaultPermission, KTSVG, retrieveFilter, saveFilter } from "../../../../_eaFruitsDms/helpers"
import capitalizeFirstLetter from "../../../../_eaFruitsDms/helpers/components/CapitalLetter"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../_eaFruitsDms/layout/core"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import ResetButton from "../../../../_eaFruitsDms/layout/components/button/resetButton"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import tenantConfiguration from "../../../../TenantVariables"
import { filters } from "../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}
const Banks: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const masterBreadCrumbs: Array<PageLink> = [
    {
      title: 'Masters',
      path: '/masters/paymentmaster',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Payment Master',
      path: '/masters/paymentmaster',
      isSeparator: false,
      isActive: false,
    },
  ]

  let teleSalesFiltered : any = retrieveFilter(filters.sa_master_payment_bank);
  
  const initialFiltersState = {
      selected: teleSalesFiltered?.selected || '',
      search: teleSalesFiltered?.search || '',
      currentOffSet: teleSalesFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState<number>(teleSalesFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(teleSalesFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(teleSalesFiltered?.search || '')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [paymentBank, setPaymentBank] = useState<any>('')
  const [paymentBankEdit, setPaymentBankEdit] = useState<any>('')
  const [validationEdit, setValidationEdit] = useState<any>('')
  const [validation, setValidation] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)

  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(teleSalesFiltered?.buttonDisable || false) 
  const debounceSearch = useDebounce(search, 500);
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  
  useEffect(() => {
    fetchPermission()
    bankList()
  }, [currentOffset, debounceSearch])

  const fetchPermission = async () =>{
    let res = await checkPermissions("masters_payment")
    setPermission(res)
   }

  const bankList = () => {
    setListLoader(true)
    paymentBanksList(limit, currentOffset, debounceSearch, '').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })

    
    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_master_payment_bank , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }


 


  const searchList = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }
  
  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}
  // AddFunction
  const clearFunction = () => {
    setPaymentBank('')
    setValidation('')
    setErrorMessage('')
  }

  // Develop BY: Kriti
  // Description: Add Bank Option function which is called on Eamodel's submit event

  const AddFunction = () => {
    if (paymentBank === '') {
      setValidation('is-invalid')
    } else {
      setValidation('')
      AddFunctionApi()
    }
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addPaymentBanksList(paymentBank).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunction()
        setListLoader(true)
        paymentBanksList(limit, '0', '').then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'bank_name') {
        setErrorMessage(
          'Your added Bank name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  // edit function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setPaymentBankEdit(item.bank_name)
    setEditId(item.id)
    setModalLoader(false)
  }

  // Develop BY: Kriti
  // Description: Edit Bank Option function which is called on Eamodel's submit event

  const EditFunction = () => {
    if (paymentBankEdit === '') {
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
      EditFunctionApi()
    }
  }

  const clearFunctionEdit = () => {
    setValidationEdit('')
    setPaymentBankEdit('')
    setErrorMessage('')
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    paymentBanksListUpdate(paymentBankEdit, EditId).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        paymentBanksList(limit, '0', search).then((res) => {
          setGetList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'bank_name') {
        setErrorMessage(
          'Your added  Bank name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <div>
        <EATitle title='Banks - ' />
        <PageTitle breadcrumbs={masterBreadCrumbs}>{intl.formatMessage({ id: 'Banks' })}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your Bank Name has been added successfully.'
              : 'Your Bank Name has been edited  successfully.'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              {/* <CustomSearchBar /> */}
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                  value={search}
                  disabled={listLoadder}
                />
              </div>
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_bank'
                    btnName='Add Bank'
                    className="btn-primary me-3"
                    onClick={() => {
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {getList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px py-2'>Bank Name</th>
                          <th className='min-w-80px action-th py-2'>
                            {permission.write && 'Actions'}
                          </th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {getList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.bank_name}</td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ea_modal_edit_bank'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    onClick={(event) => {
                                      onChangeAdd(item)
                                      setMessageHandler('successEdit')
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                                {/* <a
                            href='javascript:void(0)'
                            className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr011.svg'
                              className='ic mr-0'
                            />
                          </a> */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
                <CustomPaginate
                  data={getList}
                  selected={selected}
                  setSelected={setSelected}
                  setCurrentOffset={setCurrentOffset}
                  totalRecords={count}
                  limit={limit}
                  saveFilterName={filters.sa_master_payment_bank}
                  filterStates={filterStates}
                  isDisabled={listLoadder}
              />
      </div>
      <EaModal
        id='ea_modal_create_bank'
        modalTitle='Add Bank'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunction}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Bank Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validation)}
            placeholder='Type here...'
            onChange={(e) => setPaymentBank(capitalizeFirstLetter(e.target.value))}
            value={paymentBank}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      <EaModal
        id='ea_modal_edit_bank'
        modalTitle='Edit Bank'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Bank Name<span className="text-danger">*</span>
          </label>
          <input
            type='text'
            className={clsx('form-control', validationEdit)}
            placeholder='Type here...'
            onChange={(e) => setPaymentBankEdit(capitalizeFirstLetter(e.target.value))}
            value={paymentBankEdit}
          />
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}
export default Banks
