import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, dateFormat, fetchAsyncDropdown, focusVatidateField, isModalValidation } from "../../../helpers";
import FormatMessage from "../../../helpers/components/FormateMessage";
import { getRequest, patchRequest } from "../../../https/apiCall";
import EaModal from "../modal/eaModal";
import Toasts from "../toast/toast";
import moment from "moment/moment";

const UpdateStockModal = () => {
  const currentWarehouseId = localStorage.getItem('w_id')

  const initialRow = {
    warehouse_id: currentWarehouseId,
    product_sku_id: '',
    batch_number: '',
    expiryDate: '',
    actual_qty: '',
    units: '',
    reason: 'parent',
    reason_note: '',
  }
  const initialValidation = {
    product_sku_id: '',
    batch_number: '',
    expiryDate: '',
    actual_qty: '',
    units: '',
    reason: '',
    reason_note: '',
  }
  const [searchSelect, setSearchSelect] = useState('')
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [productSkus, setProductSkus] = useState<any>([])
  const [productSkuResponse, setProductSkuResponse] = useState<any>([])
  const [limit, setLimit] = useState<number>(10)
  const [reasonOffset, setReasonOffset] = useState<any>('')
  const [reasonOption, setReasonOption] = useState<any>([])
  const [batchOffSet, setBatchOffSet] = useState<any>('')
  const [productId, setProductId] = useState<any>('')
  const [material, setMaterial] = useState<any>('')
  const [productSkuBatches, setProductSkuBatches] = useState<any>([])
  const [productSkuBatchesOption, setProductSkuBatchesOption] = useState<any>([])
  const [manualStock, setManualStock] = useState<any>(initialRow)
  const [stockValidation, setStockValidation] = useState<any>(initialValidation)
  const [isButtonLoadder, setIsButtonLoader] = useState<any>('Submit')
  const [isLoading, setIsLoading] = useState('Submit')
  const [batchId, setBatchId] = useState('Submit')
  const [productSkuValue, setProductSkuValue] = useState<any>('')
  const [manualStockReason, setManualStockReason] = useState<any>('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [batchReset, setBatchReset] = useState<any>(moment());

  const navigate = useNavigate()
  const selectStyles = {
    menuList: (base: any) => ({
      ...base,
      maxHeight: 200,
    }),
  }

  useEffect(() => {
    setProductOffSet('')
    setBatchReset(moment())
    setBatchOffSet('')
  }, [searchSelect])

  const fetchProductsku = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const productResponse = await getRequest(
      `${apiBaseURL()}inventory/product-SKUs/?ordering=name&trade_only=True&limit=${limit}&search=${search}&offset=${productOffSet}&warehouse_id=${currentWarehouseId}&variants__group_name__id=&exclude_pre=&include_zero=True`,
      true
    )
    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if(productResponse.results){
      if (productResponse.next !== null) {
        const queryParams = new URLSearchParams(productResponse.next)
        let newOffset: any = queryParams.get('offset')
        setBatchReset(moment())
        setProductOffSet(newOffset)
        hasMore = true
      }

      if (productResponse.results.length > 0) {
        productResponse.results.map((option: any) => {
          optionData.push(option)
          options.push({
            label: option.name,
            value: option.id,
          })
        })
      }

      if (productSkus.length > 0) {
        setProductSkus(productSkus.concat(options))
        setProductSkuResponse(productSkuResponse.concat(optionData))
      } else {
        setProductSkus(options)
        setProductSkuResponse(optionData)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchReason = async (search?: any) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonOption,
      setReasonOption,
      `master/options-master/?type=Manual_Stock_Update`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: null,
    })
    return response
  }

  const fetchBatches = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const batchResponse = await getRequest(
      `${apiBaseURL()}inventory/product-SKUs/?id=${manualStock.product_sku_id
      }&limit=${limit}&offset=${batchOffSet}&search=${search}&warehouse_id=${currentWarehouseId}&include_zero=True`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if(batchResponse.results){
      if (batchResponse.next !== null) {
        const queryParams = new URLSearchParams(batchResponse.next)
        let newOffset: any = queryParams.get('offset')
        setBatchOffSet(newOffset)
        hasMore = true
      }

      if (batchResponse.results.length > 0) {
        batchResponse.results.map((option: any) => {
          if (option.product_stocking.length > 0) {
            option.product_stocking.map((stock: any) => {
              optionData.push(stock)
              options.push({
                label: stock.batch_number,
                value: stock.batch_number,
              })
            })
          }
        })
      }

      setProductSkuBatches(optionData)
      setProductSkuBatchesOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleChangeRow = (e: any, name: any) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value
    setStockValidation({ ...stockValidation, [inputName]: '' })
    let rows: any = { ...manualStock }

    switch (inputName) {
      case 'product_sku_id':
        rows['product_sku_id'] = inputValue
        setProductId(inputValue)
        setBatchReset(moment())
        let productRes = productSkuResponse.filter(
          (productSku: any) => productSku.id === inputValue
        )
        setMaterial(productRes[0].material.name)
        setProductSkuBatchesOption('')

        rows['expiryDate'] = ''
        rows['actual_qty'] = ''
        break

      case 'batch_number':
        rows['batch_number'] = inputValue
        const productStock = productSkuBatches.filter(
          (option: any) => option.batch_number == inputValue
        )
        setBatchId(productStock[0].id)
        let dateView = dateFormat(productStock[0].expiry_date)
        rows['id'] = productStock[0].id
        rows['expiryDate'] = dateView.displayDate
        rows['actual_qty'] = productStock[0].units
        break

      case 'units':
        rows['units'] = parseInt(inputValue)
        break
      case 'reason':
        rows['reason'] = inputValue
        break
      case 'reason_note':
        rows['reason_note'] = inputValue
    }
    setManualStock(rows)
  }

  const validateForm = () => {
    let navigateField = {
      product_sku_id: manualStock.product_sku_id,
      batch_number: manualStock.batch_number,
      units: manualStock.units,
      reason: manualStock.reason,
      reason_note: manualStock.reason_note,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    let validations = { ...stockValidation }
    let isValid = true

    if (manualStock.product_sku_id === '') {
      validations.product_sku_id = 'is-invalid'
      isValid = false
    }

    if (manualStock.batch_number === '') {
      validations.batch_number = 'is-invalid'
      isValid = false
    }

    if (manualStock.units === '') {
      validations.units = 'is-invalid'
      isValid = false
    }

    if ((manualStock.reason && manualStock.reason === '') || manualStock.reason == 'parent') {
      validations.reason = 'is-invalid'
      isValid = false
    }
    if (!manualStock.reason && manualStock.reason_note === '') {
      validations.reason_note = 'is-invalid'
      isValid = false
    }
    setStockValidation(validations)
    return isValid
  }

  const apiCall = () => {
    const isValid = validateForm()
    if (isValid) {
      let stockInfo: any = { ...manualStock }

      delete stockInfo.expiryDate
      const notify = {
        success: false,
        failed: false,
        errorField: '',
      }
      setIsLoading('Please Wait...')
      patchRequest(`${apiBaseURL()}inventory/update_stock/${batchId}/`, stockInfo, true).then(
        (response: any) => {
          setIsButtonLoader('Submit')
          if (response.status === 200) {
            notify.success = true
          }
          if (response.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = response.data.error.field
          }
          if (notify.success) {
            setIsLoading('Submit')
            $('#closeEaModalBlogsCategory').trigger('click')
            setTimeout(() => {
              // setIsAlertFailed(false)
              setIsAlertSuccess(false)
            }, 2000)
            setIsAlertSuccess(true)
            // setTimeout(() => {
            //   navigate('/reports/manual-stock-change-report')
            // }, 300)
          }
          if (notify.failed) {
            // if (notify.errorField == 'title') {
            //   setErrorMessage(
            //     'Your added category is already exist in this system! Please try again with unique data.'
            //   )
            // }
            // setIsAlertFailed(true)
          }
        }
      )
    }
  }

  const clearFunction = () => {
    setManualStock(initialRow)
    setStockValidation(initialValidation)
    setProductId('')
    setBatchReset(moment())
    setProductSkuBatches('')
    setMaterial('')
    setIsLoading('')
    setProductSkus([])
    setProductSkuValue('')
    setManualStockReason('')
  }

  return (
    <>
      <EaModal
        id='ea_modal_update_stock'
        modalTitle='Update Stock'
        cancel={clearFunction}
        onSubmit={apiCall}
        btnDisabled={isButtonLoadder === 'Submit' ? false : true}
        closeAuto='closeEaModalBlogsCategory'
        actionBtnName={isLoading}
      >
        <div className='mb-4'>
          <label className='form-label' id='product_sku_id'>
            Product SKU<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchProductsku}
            placeholder='Select'
            isSearchable
            className={clsx('react-select-container w-100', stockValidation.product_sku_id)}
            classNamePrefix='react-select'
            menuPosition='fixed'
            onChange={(e) => {
              handleChangeRow(e, 'product_sku_id')
              setProductSkuValue(e)
            }}
            name='product_sku_id'
            value={productSkuValue}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label' id='batch_number'>
                Batch Number<span className="text-danger">*</span>
              </label>
              <AsyncPaginate
                key={batchReset}
                loadOptions={fetchBatches}
                placeholder='Select'
                isSearchable
                isDisabled={productId == ''}
                className={clsx('react-select-container w-100', stockValidation.batch_number)}
                classNamePrefix='react-select'
                value={
                  (productSkuBatchesOption.length > 0 &&
                    productSkuBatchesOption.find(
                      (option: any) => option.value == manualStock.batch_number
                    )) ||
                  ''
                }
                onChange={(e) => handleChangeRow(e, 'batch_number')}
                menuPosition='fixed'
                name='batch_number'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>Expiry Date</label>
              <input
                type='text'
                className='form-control'
                placeholder='Select'
                value={manualStock.expiryDate}
                // value= {dateFormat(manualStock.expiryDate)}
                disabled
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>System Stock</label>
              <div className='input-group mb-1'>
                <input
                  type='number'
                  placeholder='0'
                  className='form-control min-w-70px'
                  value={manualStock.actual_qty}
                  disabled
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                  {material}
                </span>
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>
                Actual Stock<span className="text-danger">*</span>
              </label>
              <div
                className='input-group mb-1'
                style={{ borderColor: stockValidation.units !== '' ? '#d01f45' : '' }}
              >
                <input
                  type='number'
                  id='units'
                  placeholder='0'
                  name='units'
                  onChange={(e: any) => handleChangeRow(e, 'units')}
                  className={clsx('form-control min-w-70px', stockValidation.units)}
                  value={manualStock.units}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                  {material}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <label className='form-label' id='manualStockReason'>
            Reason<span className="text-danger">*</span>
          </label>
          <AsyncPaginate
                    
            loadOptions={fetchReason}
            styles={selectStyles}
            placeholder='Select'
            isSearchable
            className={clsx('react-select-container w-100', stockValidation.reason)}
            classNamePrefix='react-select'
            menuPosition='fixed'
            value={manualStockReason}
            onChange={(e: any) => {
              handleChangeRow(e, 'reason')
              setManualStockReason(e)
            }}
            name='reason'
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        {manualStock.reason == null && manualStock.reason !== 'parent' && (
          <div>
            <label className='form-label'>
              Notes<span className="text-danger">*</span>
            </label>
            <textarea
              id='reason_note'
              className={clsx('form-control', stockValidation.reason_note)}
              rows={4}
              placeholder='Type here…'
              name='reason_note'
              value={manualStock.reason_note}
              onChange={(e: any) => handleChangeRow(e, 'reason_note')}
            ></textarea>
          </div>
        )}
      </EaModal>
      <Toasts
        message={FormatMessage('MANUAL_STOCK_CHANGE')}
        showToast={IsAlertSuccess}
        bgClass='light-success'
      />
    </>
  )
}

export default UpdateStockModal
