import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  numberFormatWithCode,
  retrieveFilter,
  saveFilter,
} from '../../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../../TenantVariables'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {WarehouseFilter} from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {CustomDatePicker} from '../../../../../_eaFruitsDms/helpers/components/CustomDatePicker'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import { filters } from '../../../../../_eaFruitsDms/utils/FilterConstants'
import ResetButton from '../../../../../_eaFruitsDms/layout/components/button/resetButton'
import DateRangePicker from 'react-bootstrap-daterangepicker'

type Props = {
  className: string
}
const CreditRequest: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Reports",
        subTitle: "Credit Request"
    }

    let todayDate = dateFormat(moment());

    let creditRequestFiltered = retrieveFilter(filters.sa_credit_request_rejection_report)
   
    const initialState = {
      warehouse: creditRequestFiltered?.warehouse || {label: 'All Warehouses', value: ''},
      startDate: creditRequestFiltered?.startDate || todayDate,
      endDate: creditRequestFiltered?.endDate || todayDate,
      search: creditRequestFiltered?.search || '',
      selected: creditRequestFiltered?.selected || '',
      currentOffset: creditRequestFiltered?.currentOffset || '',
      buttonDisable: true,
    }

    const [fetchingData, setFetchingData] = useState<boolean>(false);
    const [creditRequestReport, setCreditRequestReport] = useState([]);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState(creditRequestFiltered?.selected || 0);
    const [currentOffSet, setCurrentOffSet] = useState(creditRequestFiltered?.currentOffSet || 0);
    const [search, setSearch] = useState(creditRequestFiltered?.search || "");
    const [warehouse, setWarehouse] = useState(creditRequestFiltered?.warehouse || {label: 'All Warehouses', value: ''});
    const [limit, setLimit] = useState(tenantConfiguration.limit);
    const [date, setDate] = useState<any>({
        startDate: creditRequestFiltered?.startDate || todayDate,
        endDate: creditRequestFiltered?.endDate || todayDate
    })
    const [filterStates, setFilterStates] = useState<any>(initialState)
    const [buttonDisable, setButtonDisable] = useState<boolean>(
      creditRequestFiltered?.buttonDisable || false
    );

    const debounceSearch = useDebounce(search, 500);

    useEffect(() => {
        fetchReportData().then()
    }, [debounceSearch, currentOffSet, warehouse, date]);

    const fetchReportData = async () => {
        setFetchingData(true)
        const response = await getRequest(
          `${apiBaseURL()}reports/rejection/credit-request?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${checkEmpty(warehouse.value, "")}&rejection_date_duration_after=${date.startDate.apiDate}&rejection_date_duration_before=${date.endDate.apiDate}`,
          true
        )

        if (response.results) {
            const reportData: any = []
            response.results.map((report: any) => {
                return reportData.push({
                    id: report.id,
                    order_no: isEmpty(report.order_no) ? "-" : `#${report.order_no}`,
                    created_date: isEmpty(report.created_at) ? "-" : dateFormat(report.created_at).displayDate,
                    created_time: isEmpty(report.created_at) ? "-" : dateFormat(report.created_at, "LT").displayDate,
                    request_by: isEmpty(report.requested_by_name) ? "-" : `${report.requested_by_name} (${isEmpty(report.requested_by_type) ? "-" : report.requested_by_type.toUpperCase()})`,
                    response_by: isEmpty(report.action_by_name) ? "-" : `${report.action_by_name} (${isEmpty(report.action_by_type) ? "-" : report.action_by_type.toUpperCase()})`,
                    action_date: isEmpty(report.action_at) ? "-" : dateFormat(report.action_at).displayDate,
                    action_time: isEmpty(report.action_at) ? "-" : dateFormat(report.action_at, "LT").displayDate,
                    customer_name: isEmpty(report.customer_name) ? "-" : report.customer_name,
                    customer_street: isEmpty(report.customer_street) ? "-" : report.customer_street,
                    credit_requested: isEmpty(report.requested_amount)? "-" : numberFormatWithCode(report.requested_amount).displayFormat,
                    reason: isEmpty(report.rejection_reason_option) ? isEmpty(report.rejection_note) ? "-" : report.rejection_note : report.rejection_reason_option
                })
            })
            setCount(response.count)
            setCreditRequestReport(reportData)
        }
        setFetchingData(false)
        
        let updatedState: any = { ...filterStates }
        updatedState['selected'] = selected
        updatedState['currentOffset'] = currentOffSet
        updatedState['buttonDisable'] = buttonDisable
        setFilterStates(updatedState)

        saveFilter(filters.sa_credit_request_rejection_report, filterStates)

        if (
            // warehouse.value == warehouseCheck &&
            // search == '' && 
            date.startDate.apiDate === todayDate &&
            date.endDate.apiDate === todayDate
        ) {
          setButtonDisable(false)
        }
    };

    const handleFilterChange = (e: any, name?: string) => {
        const inputName:string = e.target ? e.target.name : name
                setButtonDisable(true)
        switch (inputName) {
            case 'warehouse':
                setWarehouse(e)
                setFilterStates({ ...filterStates, ['warehouse']: e })
                break
            case 'search':
                setSearch(e.target.value)
                setFilterStates({ ...filterStates, ['search']: e.target.value })
                break
        }
    }

    
    const handleChangeReset = () => {
        setSearch('')
        setWarehouse({label: 'All Warehouses', value: ''})
        setCurrentOffSet(0)
        setSelected(0)
    
        let updatedDateState: any = { ...date }
        updatedDateState['startDate'] = todayDate
        updatedDateState['endDate'] = todayDate
        setDate(updatedDateState)
    
        let updatedState: any = { ...filterStates }
        updatedState['warehouse'] = ''
        updatedState['search'] = ''
        updatedState['currentOffset'] = ''
        updatedState['selected'] = ''
        updatedState['startDate'] = ''
        updatedState['endDate'] = ''
        setFilterStates(updatedState)
        setButtonDisable(false)
      }

      const CustomDatePickerComponent = () => {
        return (
          <>
            <DateRangePicker
              onCallback={(start, end, label) => {
                setButtonDisable(true)
                const callBakeDate = {
                  startDate: dateFormat(start._d),
                  endDate: dateFormat(end._d),
                }
                let updatedState: any = { ...filterStates }
                updatedState['startDate'] = callBakeDate.startDate
                updatedState['endDate'] = callBakeDate.endDate
                setFilterStates(updatedState)
                setDate(callBakeDate)
              }}
              initialSettings={{
                startDate: moment(date.startDate.apiDate).toDate(),
                endDate: moment(date.endDate.apiDate).toDate(),
                autoUpdateInput: true,
                alwaysShowCalendars: true,
                cancelClass: 'btn-secondary',
                drops: 'auto',
                locale: {
                  format: 'DD MMM YYYY',
                },
                ranges: {
                  Today: [moment(), moment()],
                  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                },
              }}
            >
              <input
                type='text'
                className='form-control calendar my-1 me-3 w-auto'
                // defaultValue={invoiceDate}
                disabled={fetchingData}
              />
            </DateRangePicker>
          </>
        )
      }

    return <>
        <EATitle title='Credit Request' />
        <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Credit Request' })}</PageTitle>
        <div>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar onChange={handleFilterChange} value={search}/>
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>
                            <WarehouseFilter
                              warehouse={warehouse}
                              handleFilterChange={handleFilterChange}
                              isDisabled={fetchingData}
                            />
                            <CustomDatePickerComponent/>
                            <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                            {/*<label className="form-label mb-0"><button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 fw-600 d-flex align-items-center">*/}
                            {/*    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />*/}
                            {/*    Export</button>*/}
                            {/*</label>*/}
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {
                            fetchingData ? <FetchingRecords /> :
                              creditRequestReport.length === 0 ? <NoRecords />
                                :
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='w-100px py-2'>Date & Time</th>
                                        <th className='min-w-110px py-2'>Order No.</th>
                                        <th className='w-150px py-2'>Request by</th>
                                        <th className='w-150px py-2'>Response by</th>
                                        <th className='w-150px py-2'>Date & Time</th>
                                        <th className='w-150px py-2'>Request for</th>
                                        <th className='w-150px py-2'>Credit Requested</th>
                                        <th className='w-250px py-2'>Rejection Comments</th>
                                    </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                    {
                                        creditRequestReport.map((requestReport: any) => {
                                            return <tr>
                                                <td>
                                                    {requestReport.created_date}
                                                    <p className='font-13 text-muted mb-0'>{requestReport.created_time}</p>
                                                </td>
                                                <td>
                                                    {requestReport.order_no}
                                                </td>
                                                <td>
                                                    {requestReport.request_by}
                                                </td>
                                                <td>
                                                    {requestReport.response_by}
                                                </td>
                                                <td>
                                                    {requestReport.action_date}
                                                    <p className='font-13 text-muted mb-0'>{requestReport.action_time}</p>
                                                </td>
                                                <td>
                                                    {requestReport.customer_name}{isEmpty(requestReport.customer_street) ? "" : `, ${requestReport.customer_street.toUpperCase()}`}
                                                </td>
                                                <td>
                                                    {requestReport.credit_requested}
                                                </td>
                                                <td>
                                                    {requestReport.reason}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                        }
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <CustomPaginate
              data={creditRequestReport}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffSet}
              totalRecords={count}
              limit={limit}
              isDisabled={fetchingData}
              saveFilterName={filters.sa_credit_request_rejection_report}
              filterStates={filterStates}
            />
        </div>
    </>
}

export default CreditRequest
