import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {connect} from 'react-redux'
import tenantConfiguration from '../../../../TenantVariables'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  numberFormat,
  numberFormatRatio,
} from '../../../../_eaFruitsDms/helpers'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaChartPie} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {EaStatisticsWidget} from '../../../../_eaFruitsDms/partials/widgets'
import {useNavigate} from 'react-router-dom'

const SupportRequestReport = (props: any) => {
  const {warehouseId, date} = props
  const intl = useIntl()
  const navigate = useNavigate()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Reports',
  }

  const [fetchingRequest, setFetchingRequest] = useState(true)
  const [fetchingWarehouse, setFetchingWarehouse] = useState(true)
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [metaCount, setMetaCount] = useState({
    total_count: 0,
    open_count: 0,
    in_progress_count: 0,
    closed_count: 0,
    average_turn_around_time: 0,
    resolved_count: 0,
  })
  const [limit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [request, setRequest] = useState({
    data: [],
    title: 'Complaints Attended',
    bottom: '5%',
    selectedMode: false,
  })
  const [warehouseChart, setWarehouseChart] = useState({
    data: [],
    title: 'Complaints Count',
    bottom: '5%',
    selectedMode: true,
  })
  const [warehouseReportId, setWarehouseReportId] = useState('')
  const [supportRequest, setSupportRequest] = useState([])
  const [fetchingSupportRequest, setFetchingSupportRequest] = useState(true)
  const [search, setSearch] = useState('')

  const debounceSearch = useDebounce(search, 500)

  const supportRequestAttendedPieChart = async () => {
    setFetchingRequest(true)

    const url = `${apiBaseURL()}reports/complaint-requests/attended/${
      isEmpty(warehouseReportId) ? '' : warehouseReportId
    }?start_date=${date.startDate}&end_date=${date.endDate}`

    const response = await getRequest(url, true)

    let chart: any = {...request}
    let mergeChart: Object[] = []

    if (response && response.result) {
      response.result.forEach((item: any) => {
        mergeChart.push({
          name: item.request_status.toUpperCase(),
          value: parseFloat(item.requests_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          total_request_count: parseFloat(item.total_request_count)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setRequest(chart)
    setFetchingRequest(false)
    setWarehouseReportId('')
  }

  const warehousePieChart = async () => {
    setFetchingWarehouse(true)

    const url = `${apiBaseURL()}reports/complaint-requests/warehouse-raised?start_date=${
      date.startDate
    }&end_date=${date.endDate}`

    const response = await getRequest(url, true)

    let chart: any = {...warehouseChart}
    let mergeChart: Object[] = []

    if (response.result) {
      if (response.result.length >= 1) {
        setWarehouseReportId(response.result[0].warehouse_id)
      }

      if (response && response.result) {
        response.result.forEach((item: any, index: number) => {
          mergeChart.push({
            name: item.warehouse_name,
            value: item.raised_count,
            warehouse_id: item.warehouse_id,
            selected: index === 0 ? true : false,
          })
        })
      }
    }

    chart.data = mergeChart
    setWarehouseChart(chart)
    setFetchingWarehouse(false)
  }

  const fetchSupportRequest = async () => {
    setFetchingSupportRequest(true)
    const url = `${apiBaseURL()}reports/complaint-requests?date_duration_after=${
      date.startDate
    }&date_duration_before=${date.endDate}&warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&status=&search=${debounceSearch}&limit=${limit}&offset=${currentOffset}`
    const response = await getRequest(url, true)

    if (response && response.results) {
      setSupportRequest(response.results)
      setCount(response.count)
      setMetaCount(response.meta)
    }

    setFetchingSupportRequest(false)
  }

  const toolTip = (item: any) => {
    return `<div>
        <p>
          <b>Complaint Status - ${item.data.name}</b>
        </p>
        <div>
          <b>Total Complaints Counts - ${
            numberFormat(item.data.total_request_count).displayFormat
          }</b>
        </div>
      </div>`
  }

  const toolTipWarehouse = (item: any) => {
    return `<div>
        <p>
          <b>${item.data.name}</b>
        </p>
        <div>
          <b>Total Complaints Raised Counts - ${numberFormat(item.data.value).displayFormat}</b>
        </div>
      </div>`
  }

  const updateSelectedWarehouse = (item: any) => {
    const id = item.data.warehouse_id
    let chart: any = {...warehouseChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        warehouse_id: item.warehouse_id,
        selected: item.warehouse_id === id ? true : false,
      })
    })

    chart.data = mergeChart
    setWarehouseChart(chart)
  }

  useEffect(() => {
    warehousePieChart()
  }, [date])

  useEffect(() => {
    if (!isEmpty(warehouseReportId)) {
      supportRequestAttendedPieChart()
    }
  }, [warehouseReportId, date])

  useEffect(() => {
    fetchSupportRequest()
  }, [debounceSearch, date, warehouseId, currentOffset])

  const navigateTo = (id: string) => {
    localStorage.setItem('complaints-detail-id', id)
    navigate('/all-complaints-details/overview')
  }

  return (
    <>
      <EATitle title='Complaints Report - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Complaints Report'})}
      </PageTitle>

      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={numberFormat(metaCount.total_count).displayFormat}
            description='Complaints Raised'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={`${numberFormat(metaCount.open_count).displayFormat} / ${
              numberFormat(metaCount.in_progress_count).displayFormat
            } / ${numberFormat(metaCount.resolved_count).displayFormat} / ${
              numberFormat(metaCount.closed_count).displayFormat
            }`}
            description={'Complaints Open / In Progress / Resolved / Closed'}
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='danger'
            title={`${numberFormatRatio(metaCount.average_turn_around_time)}`}
            description={'Days Average Turnaround Time'}
          />
        </div>
      </div>

      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Complaints Analytics</span>
              </h3>
            </div>

            {warehouseChart.data.length > 0 ? (
              <div className='card-body'>
                <div className='row'>
                  {fetchingWarehouse ? (
                    <div
                      style={{
                        marginTop: '30%',
                      }}
                    >
                      <FetchingRecords />
                    </div>
                  ) : (
                    <div className='col-md-6'>
                      <EaChartPie
                        item={warehouseChart}
                        tooltip={toolTipWarehouse}
                        onClick={(item: any) => {
                          setWarehouseReportId(item.data.warehouse_id)
                          updateSelectedWarehouse(item)
                        }}
                        isAnimationDisable={true}
                      />
                    </div>
                  )}
                  <div className='col-md-6'>
                    {request.data.length > 0 && (
                      <>
                        {fetchingRequest ? (
                          <div
                            style={{
                              marginTop: '30%',
                            }}
                          >
                            <FetchingRecords />
                          </div>
                        ) : (
                          <EaChartPie tooltip={toolTip} item={request} isAnimationDisable={true} />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <NoRecords />
            )}
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar
            onChange={(e: any) => {
              setSearch(e.target.value)
              setCurrentOffset(0)
              setSelected(0)
            }}
            isDisable={fetchingRequest || fetchingSupportRequest}
          />
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingSupportRequest ? (
              <FetchingRecords />
            ) : supportRequest.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px py-2'>Complaint ID</th>
                    <th className='min-w-150px py-2'>Complaint Title</th>
                    <th className='min-w-170px py-2'>Customer Name</th>
                    <th className='min-w-170px py-2'>SR Name</th>
                    <th className='min-w-130px py-2'>Created By</th>
                    <th className='min-w-170px py-2'>Handled By</th>
                    {/* <th className='min-w-80px py-2'>Rating</th> */}
                    <th className='min-w-150px py-2'>Date and Time</th>
                    <th className='min-w-100px py-2'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {supportRequest.length > 0 &&
                    supportRequest.map((item: any) => {
                      return (
                        <tr className='cursor-pointer' onClick={() => navigateTo(item.id)}>
                          <td>{isEmpty(item.ref_no) ? '-' : `#${item.ref_no}`}</td>
                          <td>{checkEmpty(item.title)}</td>
                          <td>{checkEmpty(item.customer_name)}</td>
                          <td>{checkEmpty(item.sr_name)}</td>
                          <td>
                            {!isEmpty(item.created_by_name) && !isEmpty(item.created_by_type) ? (
                              <>
                                {item.created_by_name} ({item.created_by_type.toUpperCase()})
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            {' '}
                            {!isEmpty(item.assign_to_name) && !isEmpty(item.assign_to_type) ? (
                              <>
                                {item.assign_to_name} ({item.assign_to_type.toUpperCase()})
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          {/* <td>
                            {isEmpty(item.rating) ? (
                              '-'
                            ) : (
                              <>
                                <KTSVG
                                  path='/media/icons/duotune/general/gen029.svg'
                                  className='ic text-warning me-1'
                                />
                                {item.rating}
                              </>
                            )}
                          </td> */}
                          <td>
                            {isEmpty(item.created_at) ? (
                              '-'
                            ) : (
                              <>
                                <div>{dateFormat(item.created_at).displayDate}</div>
                                {dateFormat(item.created_at, 'LT').displayDate}
                              </>
                            )}
                          </td>

                          <td>
                            {isEmpty(item.status) ? (
                              '-'
                            ) : (
                              <>
                                {(() => {
                                  switch (item.status) {
                                    case 'assigned':
                                      return (
                                        <>
                                          <span className='badge badge-light-primary text-dark'>
                                            Assigned{' '}
                                            {!isEmpty(item.assigned_since) &&
                                              `(${
                                                item.assigned_since === 0
                                                  ? 'Today'
                                                  : `Since ${item.assigned_since} day(s)`
                                              })`}
                                          </span>
                                        </>
                                      )

                                    case 'open':
                                      return (
                                        <>
                                          <span className='badge badge-light-warning text-dark'>
                                            Open{' '}
                                            {!isEmpty(item.open_since) &&
                                              `(${
                                                item.open_since === 0
                                                  ? 'Today'
                                                  : `Since ${item.open_since} day(s)`
                                              })`}
                                          </span>
                                        </>
                                      )

                                    case 'closed':
                                      return (
                                        <>
                                          <span className='badge badge-light-success text-dark'>
                                            Closed{' '}
                                            {!isEmpty(item.closed_since) &&
                                              `(${
                                                item.closed_since === 0
                                                  ? 'Same Day'
                                                  : `Took ${item.closed_since} day(s)`
                                              })`}
                                          </span>
                                        </>
                                      )

                                    case 'resolved':
                                      return (
                                        <>
                                          <span className='badge badge-light-info text-dark'>
                                            Resolved{' '}
                                            {!isEmpty(item.resolved_since) &&
                                              `(${
                                                item.resolved_since === 0
                                                  ? 'Same Day'
                                                  : `Took ${item.resolved_since} day(s)`
                                              })`}
                                          </span>
                                        </>
                                      )

                                    case 'in_process':
                                      return (
                                        <>
                                          <span className='badge badge-light-danger text-dark'>
                                            In Progress{' '}
                                            {!isEmpty(item.in_progress_since) &&
                                              `(${
                                                item.in_progress_since === 0
                                                  ? 'Same Day'
                                                  : `Took ${item.in_progress_since} day(s)`
                                              })`}
                                          </span>
                                        </>
                                      )
                                  }
                                })()}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={supportRequest}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={fetchingSupportRequest || fetchingRequest}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouseId: state.supportRequestReport.warehouseId,
    startDate: state.supportRequestReport.startDate,
    endDate: state.supportRequestReport.endDate,
    date: state.supportRequestReport.date,
  }
}

export default connect(mapStateToProps, null)(SupportRequestReport)
