import axios from 'axios'
import moment from 'moment'
import EaFruitsLogout from '../redux/action/logout/logoutAction'
import {requestHeaders, show500Error} from '../helpers'
import toast from 'react-hot-toast'

export const postRequest = async (URL: any, formData: any, token?: any, multipart?: boolean) => {
  let error: any = null
  const response: any = await axios
    .post(URL, formData, requestHeaders(token, multipart))
    .catch((e) => {
      error = e.response

      if (error.status == 500) {
        show500Error()
      }
    })

  if (error !== null) {
    if (error.data.error) {
      if (error.data.error.code == 417 || error.data.error.code == 419) {
        EaFruitsLogout()
      }
    }
    return error
  }

  return response
}

export const getRequest = async (URL: any, token?: any) => {
  let error: any = null
  const response: any = await axios.get(URL, requestHeaders(token)).catch((e) => {
    error = e.response
  })

  if (error !== null) {
    if (error.data.error) {
      if (error.data.error.code == 417 || error.data.error.code == 419) {
        EaFruitsLogout()
      }
    }
    return error
  }

  return response.data
}

export const blobRequest = async (URL: any, token?: any, fileName?: string, setState?: any) => {
  const req = new XMLHttpRequest()
  req.open('GET', URL, true)
  const getToken: any = localStorage.getItem('token')

  req.responseType = 'blob'
  req.setRequestHeader('DEVICE-TYPE', 'Web')

  if (token) {
    req.setRequestHeader('Authorization', `Bearer ${getToken}`)
  }
  req.onload = () => {
    //Convert the Byte Data to BLOB object.
    const blob: any = new Blob([req.response], {type: 'application/octetstream'})

    const url = window.URL || window.webkitURL
    const link = url.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('download', (fileName && fileName !== '' ? fileName : moment()) + '.pdf')
    a.setAttribute('href', link)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    if (setState) {
      setState(false)
    }
  }
  req.send()
}

export const blobXlsRequest = async (URL: any, fileName?: string, token?: any, setState?: any) => {
  const req = new XMLHttpRequest()

  req.open('GET', URL, true)
  const getToken: any = localStorage.getItem('token')

  req.responseType = 'blob'

  req.setRequestHeader('DEVICE-TYPE', 'Web')

  if (token) {
    req.setRequestHeader('Authorization', `Bearer ${getToken}`)
  }

  req.onload = () => {
    if (req.status === 200) {
      const contentType: any = req.getResponseHeader('Content-Type')
      const type = 'application/json'

      if (contentType.toLowerCase().includes(type)) {
        // Handle JSON response
        const reader: any = new FileReader()

        reader.onload = function () {
          const jsonResponse = JSON.parse(reader.result)

          if (jsonResponse && jsonResponse.results) {
            toast.success(
              <div>
                <h4>{jsonResponse?.results}</h4>
              </div>,
              {
                position: 'top-center',
                duration: 3000,
                style: {
                  background: '#fdf3d3',
                  maxWidth: 700,
                  marginTop: 10,
                },
              }
            )
          }
        }

        reader.readAsText(req.response)

        if (setState) {
          setState(false)
        }
      } else {
        // Convert the Byte Data to BLOB object.
        const blob: any = new Blob([req.response], {type: 'application/vnd.ms-excel'})

        const url = window.URL || window.webkitURL
        const link = url.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('download', fileName + '.xlsx')
        a.setAttribute('href', link)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        if (setState) {
          setState(false)
        }
      }
    } else {
      console.error('Failed to fetch data')
    }
  }
  req.send()
}

export const putRequest = async (URL: any, formData: any, token?: any, multipart?: boolean) => {
  let error: any = null
  const response: any = await axios
    .put(URL, formData, requestHeaders(token, multipart))
    .catch((e) => {
      error = e.response
    })

  if (error !== null) {
    if (error.data.error) {
      if (error.data.error.code == 417 || error.data.error.code == 419) {
        EaFruitsLogout()
      }
    }
    return error
  }
  return response
}

export const patchRequest = async (URL: any, formData: any, token?: any) => {
  let error: any = null
  const response: any = await axios.patch(URL, formData, requestHeaders(token)).catch((e) => {
    error = e.response
  })

  if (error !== null) {
    if (error.data.error) {
      if (error.data.error.code == 417 || error.data.error.code == 419) {
        EaFruitsLogout()
      }
    }
    return error
  }
  return response
}

export const deleteRequest = async (URL: any, token?: any) => {
  let error: any = null
  const response: any = await axios.delete(URL, requestHeaders(token)).catch((e) => {
    error = e.response
  })

  if (error !== null) {
    if (error.data.error) {
      if (error.data.error.code == 417 || error.data.error.code == 419) {
        EaFruitsLogout()
      }
    }
    return error
  }
  return response
}

export const getDropdownRequest = async (URL: any, token?: any) => {
	let error: any = null
	const response: any = await axios.get(URL, requestHeaders(token))
	  .catch((e) => {
		error = e.response
	  })
  
	if (error !== null) {
	  if (error.data.error) {
		if (error.data.error.code == 417 || error.data.error.code == 419) {
		  EaFruitsLogout()
		}
	  }
	  return error
	}
  
	return response
  }