import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  addMobileWalletAccounts,
  mobileWalletAccountsList,
  mobileWalletAccountsUpdate,
} from '../../../_eaFruitsDms/apiFunctions/paymentAccounts/paymentAccounts'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../_eaFruitsDms/helpers/components/CapitalLetter'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../TenantVariables'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'

type Props = {
  className: string
}
const MobileWalletAccounts: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const paymentBreadCrumbs: Array<PageLink> = [
    {
      title: 'Payment Accounts',
      path: 'payment-accounts/mobile-wallet-accounts',
      isSeparator: false,
      isActive: false,
    },
  ]
  let walletFiltered : any = retrieveFilter(filters.wm_wallet);

    const initialState = {
      selected: walletFiltered?.selected || '',
      search: walletFiltered?.search || '',
      currentOffSet: walletFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [mobileWalletList, setMobileWalletList] = useState<any>([])
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(walletFiltered?.search || '')
  const [currentOffset, setCurrentOffset] = useState<any>(walletFiltered?.currentOffSet || '0');
  const [selected, setSelected] = useState<number>(walletFiltered?.selected || 0)
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [EditId, setEditId] = useState<any>('')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [deleteName, setDeleteName] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')

  const [validationWalletName, setValidationWalletName] = useState<any>('')
  const [validationWalletNumber, setValidationWalletNumber] = useState<any>('')

  const [validationWalletNameEdit, setValidationWalletNameEdit] = useState<any>('')
  const [validationWalletNumberEdit, setValidationWalletNumberEdit] = useState<any>('')

  const [walletOption, setWalletOption] = useState<any>([])
  const [singleWalletName, setSingleWalletName] = useState<any>('')
  const [singleWalletNameEdit, setSingleWalletNameEdit] = useState<any>('')
  const [walletNumber, setWalletNumber] = useState<any>('')
  const [walletNumberEdit, setWalletNumberEdit] = useState<any>('')
  const [accessSts, setAccessSts] = useState<any>(true)
  const [accessStsEdit, setAccessStsEdit] = useState<any>(true)
  const [mobileOffset, setMobileOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limmit)
  const [mobileWalletOption, setMobileWalletOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [deleteWalletName, setDeleteWalletName] = useState<any>('')
  const [walletList, setWalletList] = useState<any>([])
  const [permission, setPermission] = useState(defaultPermission)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(walletFiltered?.buttonDisable || false)
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchPermission()
    MobileWallet()
  }, [currentOffset , debounceSearch])

  useEffect(() => {
    setMobileOffset('')
  }, [searchSelect])

  useEffect(() =>{
    getRequest(`${apiBaseURL()}master/wallet-provider/?ordering=name&limit=1000`, true).then(
      (res) => {
        let data = res.results
        let wallet: any = []
        data.forEach((item: any) => {
          wallet.push({ label: item.provider_name, value: item.id })
        })
        setWalletList(wallet)
      }
    )
  },[])

  const MobileWallet = () => {
      setListLoader(true)
    mobileWalletAccountsList(limit, currentOffset, debounceSearch, '').then((res) => {
      setMobileWalletList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_wallet , filterStates)

    if(search == ''){
      setButtonDisable(false)
  }
  }

  // const WalletNameDropDown = () => {
  //   let walletName: any = []
  //   axios.get(`${apiBaseURL()}master/wallet-provider/`, header).then((res) => {
  //     let data = res.data
  //     data.forEach((element: any) => {
  //       walletName.push({value: element.id, label: element.provider_name})
  //     })

  const fetchPermission = async () =>{
    let res = await checkPermissions("payment_account")
    setPermission(res)
   }

  const fetchBankName = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const MOBILE_WALLET_API = `${apiBaseURL()}master/wallet-provider/?limit=${limit}&offset=${mobileOffset}&search=${search}&ordering=provider_name`
    const mobileWalletResponse = await getRequest(MOBILE_WALLET_API, true)
    let hasMore: boolean = false

    if(mobileWalletResponse.results){
      if (mobileWalletResponse.next !== null) {
        const queryParams = new URLSearchParams(mobileWalletResponse.next)
        let newOffset: any = queryParams.get('offset')
        setMobileOffset(newOffset)
        hasMore = true
      }
      if (mobileWalletResponse.results.length > 0) {
        mobileWalletResponse.results.map((option: any) => {
          options.push({
            label: option.provider_name,
            value: option.id,
          })
        })
      }
      if (mobileWalletOption.length > 0) {
        setMobileWalletOption(mobileWalletOption.concat(options))
      } else {
        setMobileWalletOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      mobileWalletAccountsList('10', newOffset, search).then((res) => {
        setMobileWalletList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      // setCurrentOffset(newOffset)
      setSelected(selected - 1)
      mobileWalletAccountsList('10', newOffset, search).then((res) => {
        setMobileWalletList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      // setCurrentOffset(newOffset)
      setSelected(selected + 1)
      mobileWalletAccountsList('10', newOffset, search).then((res) => {
        setMobileWalletList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
      })
    }
  }

  const searchList = (e: any) => {
    setSearch(e.target.value)
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handlechangeWalletName = (e: any) => {
    setSingleWalletName(e.value)
    setValidationWalletName('')
  }
  const handlechangeEditWalletName = (e: any) => {
    setSingleWalletNameEdit(e.value)
    setValidationWalletNameEdit('')
  }

  // Develop BY: Kriti
  // Description: Add Mobile Wallet Account function which is called on Eamodel's submit event

  const AddFunction = () => {
    let navigateField = {
      singleWalletName: singleWalletName,
      walletNumber: walletNumber,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (singleWalletName === '') {
      setValidationWalletName('is-invalid')
    } else {
      setValidationWalletName('')
    }
    if (walletNumber === '') {
      setValidationWalletNumber('is-invalid')
    } else {
      setValidationWalletNumber('')
    }
    if (singleWalletName !== '') {
      if (walletNumber !== '') {
        AddFunctionApi()
      }
    }
  }

  const clearFunction = () => {
    setSingleWalletName('')
    setWalletNumber('')
    setValidationWalletName('')
    setValidationWalletNumber('')
    setErrorMessage('')
    setMobileOffset('')
    setAccessSts('true')
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    addMobileWalletAccounts(singleWalletName, walletNumber, accessSts).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunction()
        setListLoader(true)
        mobileWalletAccountsList(limit, '0', '').then((res) => {
          setMobileWalletList(res.data)

          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)

        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'wallet_number') {
        setErrorMessage(
          'Your added data is already exist in this system! Please try again with unique data.'
        )
      }
      setIsloading('Submit')
    })
  }

  // edit function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setEditId(item.id)
    setSingleWalletNameEdit(item.walletProvide.id)
    setWalletNumberEdit(item.wallet_number)
    setAccessStsEdit(item.is_active)
    setEditId(item.id)
    setModalLoader(false)
  }

  // Develop BY: Kriti
  // Description: Edit Mobile Wallet Account function which is called on Eamodel's submit event

  const EditFunction = () => {
    let navigateField = {
      singleWalletNameEdit: singleWalletNameEdit,
      walletNumberEdit: walletNumberEdit,
    }

    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    if (singleWalletNameEdit === '') {
      setValidationWalletNameEdit('is-invalid')
    } else {
      setValidationWalletNameEdit('')

      if (walletNumberEdit === '') {
        setValidationWalletNumberEdit('is-invalid')
      } else {
        setValidationWalletNumberEdit('')
        // EditFunctionApi()
      }

      if (singleWalletNameEdit !== '') {
        if (walletNumberEdit !== '') {
          EditFunctionApi()
        }
      }
    }
  }

  const clearFunctionEdit = () => {
    setSingleWalletNameEdit('')
    setWalletNumberEdit('')
    setAccessStsEdit('')
    setValidationWalletNameEdit('')
    setValidationWalletNumberEdit('')
    setErrorMessage('')
    setMobileOffset('')
    setModalLoader(false)
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    mobileWalletAccountsUpdate(singleWalletNameEdit, walletNumberEdit, accessStsEdit, EditId).then(
      (notify) => {
        setIsloadingEdit('Submit')
        if (notify.success) {
          clearFunctionEdit()
          setListLoader(true)
          mobileWalletAccountsList(limit, '0', debounceSearch).then((res) => {
            setMobileWalletList(res.data)
            setListLoader(false)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setSelected(0)
          })
          $('#closeEditEaModalBrand').trigger('click')
          setTimeout(() => {
            setIsAlertSuccess(false)
            setIsAlertFailed(false)
          }, 2000)
          setIsAlertSuccess(true)
        }
        if (notify.errorField == 'wallet_number') {
          setErrorMessage(
            'Your added data is already exist in this system! Please try again with unique data.'
          )
        }
        setIsloading('Submit')
      }
    )
  }

  const onDelete = (item: any) => {
    setDeleteId(item.id)
    setDeleteName(item.wallet_number)
    setDeleteWalletName(item.walletProvide.provider_name)
  }

  // const deleteApi = () => {
  //   mobileWalletAccountsDelete(DeleteId).then((notify) => {
  //     if (notify.success) {
  //       mobileWalletAccountsList('10', currentOffset, debounceSearch).then((res) => {
  //         setMobileWalletList(res.data)
  //         setCount(res.count)
  //         setNext(res.next)
  //         setPrev(res.prev)
  //       })
  //       setIsAlertSuccess(true)
  //     }
  //     if (notify.failed) {
  //       setIsAlertFailed(true)
  //     }
  //     $('#closeDeleteEaModalBrand').trigger('click') // modal close
  //     setTimeout(() => {
  //       setIsAlertSuccess(false)
  //       setIsAlertFailed(false)
  //     }, 2000)
  //   })
  // }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Mobile Wallet Accounts' />
      <div>
        <PageTitle breadcrumbs={paymentBreadCrumbs}>
          {intl.formatMessage({ id: 'Mobile Wallet Accounts' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? 'Your Mobile wallet account has been added succeefully.'
              : messageHandler === 'successEdit'
                ? 'Your Mobile wallet account has been updated succeefully.'
                : messageHandler === 'successDelete' &&
                'Your Mobile wallet account has been deleted succeefully..'
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />
        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              {/* <CustomSearchBar /> */}
              <div className='col-12 col-md-auto mt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control form-search'
                  onChange={searchList}
                  value={search}
                  disabled={listLoadder}
                />
              </div>
              <div className='col-auto ms-md-auto'>
                <form className="card-toolbar d-flex my-0" onSubmit={(e: any) => e.preventDefault()}>
                {permission.write &&
                <div className="me-3">
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_create_mobile_wallet'
                    btnName='Add Mobile Wallet'
                    onClick={() => {
                      setMessageHandler('successAdd')
                    }}
              />
                </div>
                }
              <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                </form>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            <>
                  {mobileWalletList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-1'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='w-150px py-2'>Wallet Name</th>
                          <th className='w-170px py-2'>Wallet Number</th>
                          <th className='min-w-100px py-2'>Status</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && "Actions"}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {mobileWalletList.map((item: any) => {
                          return (
                            <tr>
                              <td>{item.walletProvide.provider_name}</td>
                              <td>{item.wallet_number}</td>
                              <td>
                                {item.is_active ? (
                                  <span className='badge badge-light-success text-dark'>
                                    Active
                                  </span>
                                ) : (
                                  <span className='badge badge-secondary text-dark'>Inactive</span>
                                )}
                              </td>
                              <td className='text-end'>
                                {permission.write &&
                                <span
                                data-bs-target='#ea_modal_edit_mobile_wallet'
                                data-bs-toggle='modal'
                                onClick={(event) => {
                                  onChangeAdd(item)
                                  setMessageHandler('successEdit')
                                }}
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </span>}
                                {/* {permission.delete &&
                                <span
                                data-bs-target='#ea_modal_delete_mobile_wallet'
                                data-bs-toggle='modal'
                                onClick={(event) => {
                                  onDelete(item)
                                  setMessageHandler('successDelete')
                                }}
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </span>} */}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </>
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={mobileWalletList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_wallet}
          filterStates={filterStates}
          isDisabled={listLoadder}
        />
      </div>
      <EaModal
        id='ea_modal_create_mobile_wallet'
        modalTitle='Add Mobile Wallet'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunction}
        actionBtnName={isLoadingEdit}
      >
        <div className='mb-4'>
          <label className='form-label' id='singleWalletName'>
            Wallet Name<span className="text-danger">*</span>
          </label>
          <AsyncPaginate

            loadOptions={fetchBankName}
            placeholder='Select'
            isSearchable
            className={clsx('react-select-container w-100', validationWalletName)}
            classNamePrefix='react-select'
            name='walletEdit'
            value={mobileWalletOption.filter((option: any) => option.value === singleWalletName)}
            onChange={handlechangeWalletName}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Wallet Number<span className="text-danger">*</span>
          </label>
          <input
            type='number'
            id='walletNumber'
            maxLength={10}
            className={clsx('form-control', validationWalletNumber)}
            placeholder='Type here...'
            onChange={(e) => {
              setWalletNumber(capitalizeFirstLetter(e.target.value))
              setValidationWalletNumber('')
            }}
            value={walletNumber}
          />
        </div>
        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label me-3'>Access Status</label>
            <input
              checked={accessSts}
              id='accessStatus'
              onChange={(e) => {
                let checkedEdit = $('#accessStatus').is(':checked')
                if (checkedEdit == true) {
                  setAccessSts(true)
                } else {
                  setAccessSts(false)
                }
              }}
              value={accessSts}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              defaultChecked={true}
            />
            {/* <input className='form-check-input' type='checkbox' value='' checked /> */}
          </div>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      <EaModal
        id='ea_modal_edit_mobile_wallet'
        modalTitle='Edit Mobile Wallet'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label' id='singleWalletNameEdit'>
            Wallet Name<span className="text-danger">*</span>
          </label>
          <AsyncPaginate

            loadOptions={fetchBankName}
            placeholder='Select'
            isSearchable
            className={clsx('react-select-container w-100', validationWalletNameEdit)}
            classNamePrefix='react-select'
            name='walletEdit'
            value={walletList.filter((option: any) => option.value == singleWalletNameEdit)}
            onChange={handlechangeEditWalletName}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Wallet Number<span className="text-danger">*</span>
          </label>
          <input
            type='number'
            id='walletNumberEdit'
            className={clsx('form-control', validationWalletNumberEdit)}
            placeholder='Type here...'
            onChange={(e) => {
              setWalletNumberEdit(capitalizeFirstLetter(e.target.value))
              setValidationWalletNumberEdit('')
            }}
            value={walletNumberEdit}
          />
        </div>
        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label me-3'>Access Status</label>
            <input
              checked={accessStsEdit}
              id='accessStatusEdit'
              onChange={(e) => {
                let checkedEdit = $('#accessStatusEdit').is(':checked')
                if (checkedEdit == true) {
                  setAccessStsEdit(true)
                } else {
                  setAccessStsEdit(false)
                }
              }}
              value={accessStsEdit}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='layout-builder[layout][header][fixed][desktop]'
              defaultChecked={true}
            />
            {/* <input className='form-check-input' type='checkbox' value='' checked /> */}
          </div>
        </div>
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
      {/* delete Mobile Account*/}
      <EaModal
        modalTitle='Delete Mobile Wallet Account'
        closeAuto='closeDeleteEaModalBrand'
        children={
          <p className='mb-0'>
            Are you sure you want to delete this {deleteWalletName} mobile wallet number{' '}
            <span className='fw-bold'>{deleteName}?</span>
          </p>
        }
        id={'ea_modal_delete_mobile_wallet'}
        actionBtnName='Yes, Proceed'
        // onSubmit={deleteApi}
      />
    </>
  )
}

export default MobileWalletAccounts
