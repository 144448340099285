import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkPermissions,
  dateFormat,
  defaultPermission,
  downloadFile,
  eaCloseModal,
  fetchAsyncDropdown,
  KTSVG,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest, putRequest} from '../../../../_eaFruitsDms/https/apiCall'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {decryptData} from '../../../../_eaFruitsDms/helpers/storageHelper'

const DocumentSection = ({customerId, documentType, documentTitle}: any) => {
  const rejection = {
    option: '1',
    comments: '',
  }
  const rejectionValidation: any = {
    option: '',
    comments: '',
  }
  const initialBusinessDocument = {
    business_license: null,
    reg_certificate: null,
    national_id: null,
  }
  const userType: any = decryptData('user_type') || ''

  const [buttonText, setButtonText] = useState('Upload')
  const [searchSelect, setSearchSelect] = useState('')
  const [rejectReasonOffSet, setRejectReasonOffSet] = useState<any>('')
  const [rejectReasonOption, setRejectReasonOption] = useState<any>([])
  const [documentRejection, setDocumentRejection] = useState(rejection)
  const [docRejectionValidation, setDocRejectionValidation] = useState<any>(rejectionValidation)
  const [documentNumber, setDocumentNumber] = useState<any>('')
  const [documentFile, setDocumentFile] = useState<any>('')
  const [documentNumberValidation, setDocumentNumberValidation] = useState<any>('')
  const [documentFileValidation, setDocumentFileValidation] = useState<any>('')
  const [documentNumberRequired, setDocumentNumberRequired] = useState<any>(false)
  const [documentFileRequired, setDocumentFileRequired] = useState<any>(false)
  const [businessDetailData, setBusinessDetailData] = useState<any>(initialBusinessDocument)
  const [permission, setPermission] = useState(defaultPermission)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)

  useEffect(() => {
    const customerPermission = localStorage.getItem('customerPermission')
    if (customerPermission == 'allCustomer') {
      checkPermissions('customer').then((res: any) => {
        setPermission(res)
      })
    }
    if (customerPermission == 'verification-requests') {
      checkPermissions('customer_verification_request').then((res: any) => {
        setPermission(res)
      })
    }
    if (customerPermission == 'suspendedCustomer') {
      checkPermissions('suspended_customer').then((res: any) => {
        setPermission(res)
      })
    }
    fetchDocumentDetails().then()
  }, [])

  const fetchDocumentDetails = async () => {
    if (customerId != null) {
      setDisplayLoader(true)
      const response: any = await getRequest(
        `${apiBaseURL()}customers/${parseInt(customerId)}/business-documents`,
        true
      )

      if (response.data && response.data.error) {
        //TODO: need to handle error
      }

      setBusinessDetailData(response)
      setDisplayLoader(false)
    }
  }

  const validateForm = () => {
    let isValid = true

    if (documentNumberRequired && documentNumber === '') {
      setDocumentNumberValidation('is-invalid')
      isValid = false
    }

    if (documentFileRequired && documentFile === '') {
      setDocumentFileValidation('is-invalid')
      isValid = false
    }

    return isValid
  }

  const uploadBusinessDocument = async () => {
    if (validateForm()) {
      setButtonText('Please Wait...')
      const documentData = new FormData()

      documentData.append(`${documentType}_file`, documentFile)
      documentData.append(`${documentType}`, documentNumber)

      setDocumentNumber('')
      setDocumentFile('')

      await putRequest(
        `${apiBaseURL()}customers/${customerId}/business-documents/upload`,
        documentData,
        true
      )
      setButtonText('Upload')
      await fetchDocumentDetails()
    }
  }

  const fetchRejectionReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const response = await fetchAsyncDropdown(
      rejectReasonOffSet,
      setRejectReasonOffSet,
      rejectReasonOption,
      setRejectReasonOption,
      `master/options-master/?type=Business_Details_Rejection`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const clearFunction = () => {
    setDocumentRejection(rejection)
    setDocRejectionValidation(rejectionValidation)
  }

  const handleChangeReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setDocRejectionValidation({...docRejectionValidation, [inputName]: ''})

    switch (inputName) {
      case 'option':
        setDocumentRejection({...documentRejection, ['option']: inputValue})
        break
      case 'comments':
        setDocumentRejection({...documentRejection, ['comments']: inputValue})
        break
    }
  }
  const validateFormReject = () => {
    let validations = {...docRejectionValidation}
    let isValid = true

    if (
      (documentRejection.option && documentRejection.option === '') ||
      documentRejection.option == '1'
    ) {
      validations.option = 'is-invalid'
      isValid = false
    }
    if (documentRejection.option === '' && documentRejection.comments.trim() === '') {
      validations.comments = 'is-invalid'
      isValid = false
    }

    setDocRejectionValidation(validations)

    return isValid
  }

  const rejectDocument = async (customId: string) => {
    const isValid = validateFormReject()

    if (isValid) {
      let documentData: any = {
        status: 'rejected',
        rejection_option: documentRejection.option,
        rejection_note: documentRejection.comments,
      }

      await patchRequest(
        `${apiBaseURL()}customers/${customerId}/business-documents/${
          businessDetailData[documentType].id
        }`,
        documentData,
        true
      )

      setTimeout(() => {
        clearFunction()
      }, 200)

      eaCloseModal(customId)
      await fetchDocumentDetails()
    }
  }

  const handleChange = (e: any, type?: string) => {
    if (type === 'file') {
      const file = handleFile(e)
      setDocumentNumberRequired(true)
      setDocumentFile(file)
    } else {
      setDocumentFileRequired(true)
      setDocumentNumber(e.target.value)
    }
  }

  const handleFile = (e: any) => {
    const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif|\.jfif)$/i
    // To check file extension
    if (allowedExtensions.exec(e.target.value)) {
      if (e.dataTransfer) {
        return e.dataTransfer.files[0]
      } else if (e.target) {
        return e.target.files[0]
      }
    }
    return ''
  }

  const approvedDocument = async (documentId: number) => {
    let documentData: any = {
      status: 'verified',
    }

    await patchRequest(
      `${apiBaseURL()}customers/${customerId}/business-documents/${documentId}`,
      documentData,
      true
    )
    await fetchDocumentDetails()
  }

  return (
    <>
      {displayLoader && <IsLoader />}
      {businessDetailData &&
        businessDetailData[documentType] != null &&
        businessDetailData[documentType].status === 'pending' && (
          <>
            {/* begin::Item */}
            <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
              <div className='symbol symbol-60px symbol-1by1 me-4'>
                {businessDetailData &&
                businessDetailData[documentType] != null &&
                businessDetailData[documentType].file_name !== '' &&
                businessDetailData &&
                businessDetailData[documentType] != null &&
                businessDetailData[documentType].file_name != null ? (
                  ['application/pdf', 'application/msword'].includes(
                    businessDetailData[documentType] && businessDetailData[documentType].mime_type
                  ) ? (
                    <div
                      className='symbol-label symbol-hover'
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/ic_pdf.png')})`}}
                      onClick={() => {
                        downloadFile(
                          businessDetailData &&
                            businessDetailData[documentType] != null &&
                            businessDetailData[documentType].file_name
                        )
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr091.svg'
                        className='me-0 ic mr-0 h-24px w-24px position-absolute text-white download-icon'
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className='symbol-label symbol-hover img-zoom overflow-hidden'
                        data-bs-toggle='modal'
                        data-bs-target={`#${documentType}_imgZoom_Modal`}
                      >
                        <img
                          alt='business liecense file'
                          src={`${
                            businessDetailData &&
                            businessDetailData[documentType] != null &&
                            businessDetailData[documentType].file_name
                          }`}
                          className='img-fluid'
                        />
                        <KTSVG
                          path='/media/icons/duotune/general/gen004.svg'
                          className='me-0 ic mr-0 h-24px w-24px position-absolute text-white download-icon'
                        />
                      </div>
                    </>
                  )
                ) : (
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icons/placeholder_img_crop.jpg'
                      )})`,
                    }}
                  ></div>
                )}
              </div>

              <div className='d-flex flex-row-fluid flex-wrap align-items-center row g-3'>
                <div className='col-md-5 col-lg-4'>
                  <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{documentTitle}</p>
                  {!['', null, 'null'].includes(
                    businessDetailData[documentType] && businessDetailData[documentType].document_no
                  ) && (
                    <p className='mb-0 pt-1'>
                      No.{' '}
                      {businessDetailData[documentType] &&
                        businessDetailData[documentType].document_no}
                    </p>
                  )}
                </div>
              </div>
              {['ts', 'wm'].includes(userType.toLowerCase()) ? (
                <>
                  {/* <div className="col me-2"></div> */}
                  <div className='col-auto ms-auto'>
                    <div className='row'>
                      <div className='col-md'>
                        <button
                          className='btn btn-light me-2'
                          onClick={() => {
                            downloadFile(
                              businessDetailData &&
                                businessDetailData[documentType] != null &&
                                businessDetailData[documentType].file_name
                            )
                          }}
                        >
                          Download
                        </button>
                        <button
                          className='btn btn-primary me-2'
                          onClick={() => approvedDocument(businessDetailData[documentType].id)}
                        >
                          Approve
                        </button>
                        <EaButton
                          isModal={true}
                          className='btn btn-danger'
                          btnName='Reject'
                          dataTarget={`#${documentType}_ea_modal_reject_reason`}
                        ></EaButton>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='col-auto ms-auto'>
                  <span className='badge badge-light-success my-2 text-dark'>Verify Pending</span>
                </div>
              )}
            </div>
            {/* action doc div end */}
          </>
        )}

      {businessDetailData &&
        businessDetailData[documentType] != null &&
        businessDetailData[documentType].status === 'rejected' && (
          <>
            <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
              <div className='symbol symbol-60px symbol-1by1 me-4'>
                <div
                  className='symbol-label'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/icons/placeholder_img_crop.jpg'
                    )})`,
                  }}
                ></div>
              </div>
              <div className='d-flex flex-row-fluid flex-wrap align-items-center row g-3'>
                <div className='col-md-5 col-lg-4'>
                  <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{documentTitle}</p>
                  <p className='text-muted mb-1'>
                    Rejected on{' '}
                    {dateFormat(
                      businessDetailData[documentType] && businessDetailData[documentType].action_at
                    ).displayDate +
                      ' ' +
                      dateFormat(
                        businessDetailData[documentType] &&
                          businessDetailData[documentType].action_at,
                        'LT'
                      ).displayDate}
                  </p>
                  <p className='text-muted mb-0'>
                    By{' '}
                    {!['', null, 'null'].includes(
                      businessDetailData[documentType] && businessDetailData[documentType].action_by
                    )
                      ? businessDetailData[documentType] &&
                        businessDetailData[documentType].action_by
                      : '-'}
                  </p>
                  {businessDetailData[documentType] &&
                  businessDetailData[documentType].rejection_reason ? (
                    <>
                      <p className='text-muted mb-0'>
                        For{' '}
                        {businessDetailData[documentType] &&
                          businessDetailData[documentType].rejection_reason}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className='text-muted mb-0'>
                        For{' '}
                        {businessDetailData[documentType] &&
                          businessDetailData[documentType].rejection_reason}
                      </p>
                    </>
                  )}
                </div>
                {['wm', 'ts'].includes(userType.toLowerCase()) ? (
                  <>
                    <div className='col'>
                      <div className='row g-3'>
                        <div className='col-md'>
                          <div className='row g-2'>
                            <div className='col-md-6'>
                              <div className='mb-4 position-relative'>
                                <label className='form-label me-2'>{documentTitle}</label>
                                <label
                                  htmlFor={documentType}
                                  className={clsx(
                                    'form-control file-input text-truncate pe-6',
                                    documentFileValidation
                                  )}
                                >
                                  <span className='text-muted'>
                                    {documentFile ? documentFile.name : 'Browse'}
                                  </span>
                                </label>
                                <input
                                  type='file'
                                  id={documentType}
                                  accept='application/pdf, image/*'
                                  name={`${documentType}_file`}
                                  onChange={(e) => handleChange(e, 'file')}
                                  className='form-control'
                                />
                              </div>
                            </div>

                            <div className='col-md-6'>
                              <label className='form-label'>{documentTitle} Number</label>
                              <input
                                type='text'
                                name={documentType}
                                className={clsx('form-control', documentNumberValidation)}
                                placeholder='XXXXXXXXXX'
                                value={documentNumber}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-md-auto mt-md-auto'>
                          {(permission.write || userType.toLowerCase() === 'ts') && (
                            <button
                              disabled={buttonText !== 'Upload'}
                              className='btn btn-primary'
                              onClick={() => uploadBusinessDocument()}
                            >
                              {buttonText}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='col-auto ms-auto'>
                    <span className='badge badge-light-danger my-2 text-dark'>Rejected</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

      {businessDetailData &&
        businessDetailData[documentType] != null &&
        businessDetailData[documentType].status === 'verified' && (
          <>
            <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
              <div className='symbol symbol-60px symbol-1by1 me-4'>
                {businessDetailData &&
                businessDetailData[documentType] != null &&
                businessDetailData[documentType].file_name !== '' &&
                businessDetailData &&
                businessDetailData[documentType] != null &&
                businessDetailData[documentType].file_name != null ? (
                  ['application/pdf', 'application/msword'].includes(
                    businessDetailData[documentType] && businessDetailData[documentType].mime_type
                  ) ? (
                    <div
                      className='symbol-label symbol-hover'
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/ic_pdf.png')})`}}
                      onClick={() => {
                        downloadFile(
                          businessDetailData &&
                            businessDetailData[documentType] != null &&
                            businessDetailData[documentType].file_name
                        )
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr091.svg'
                        className='me-0 ic mr-0 h-24px w-24px position-absolute text-white download-icon'
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className='symbol-label symbol-hover img-zoom overflow-hidden'
                        data-bs-toggle='modal'
                        data-bs-target={`#${documentType}_imgZoom_Modal`}
                      >
                        <img
                          alt='business liecense file'
                          src={`${
                            businessDetailData &&
                            businessDetailData[documentType] != null &&
                            businessDetailData[documentType].file_name
                          }`}
                          className='img-fluid'
                        />
                        <KTSVG
                          path='/media/icons/duotune/general/gen004.svg'
                          className='me-0 ic mr-0 h-24px w-24px position-absolute text-white download-icon'
                        />
                      </div>
                    </>
                  )
                ) : (
                  <div
                    className='symbol-label'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        '/media/icons/placeholder_img_crop.jpg'
                      )})`,
                    }}
                  ></div>
                )}
              </div>
              <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                <div className='col-md-5 col-lg-4 me-2'>
                  <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{documentTitle}</p>
                  <p className='mb-0 pt-1'>
                    No.{' '}
                    {businessDetailData[documentType] &&
                      businessDetailData[documentType].document_no}
                  </p>
                </div>
                <div className='col me-2'>
                  <p className='text-muted mb-1'>
                    Verified on{' '}
                    {dateFormat(
                      businessDetailData[documentType] && businessDetailData[documentType].action_at
                    ).displayDate +
                      ' ' +
                      dateFormat(
                        businessDetailData[documentType] &&
                          businessDetailData[documentType].action_at,
                        'LT'
                      ).displayDate}
                  </p>
                  <p className='text-muted mb-0'>
                    By{' '}
                    {businessDetailData[documentType] && businessDetailData[documentType].action_by
                      ? businessDetailData[documentType] &&
                        businessDetailData[documentType].action_by
                      : '-'}
                  </p>
                </div>
                <div className='col-auto ms-auto'>
                  <span className='badge badge-light-success my-2 text-dark'>Verified</span>
                </div>
              </div>
            </div>
          </>
        )}

      {businessDetailData && businessDetailData[documentType] == null && (
        <>
          <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
            <div className='symbol symbol-60px symbol-1by1 me-4'>
              <div
                className='symbol-label'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')})`,
                }}
              ></div>
            </div>
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='col-md-5 col-lg-4 me-2'>
                <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{documentTitle}</p>
              </div>
              {['WM', 'TS'].includes(userType) ? (
                <>
                  <div className='col'>
                    <div className='row g-3'>
                      <div className='col-md'>
                        <div className='row g-2'>
                          <div className='col-md-6'>
                            <label className='form-label me-2'>{documentTitle}</label>
                            <div className='position-relative'>
                              <label
                                htmlFor={documentType}
                                className={clsx(
                                  'form-control file-input text-truncate pe-6',
                                  documentFileValidation
                                )}
                              >
                                <span className='text-muted'>
                                  {documentFile ? documentFile.name : 'Browse'}
                                </span>
                              </label>
                              <input
                                type='file'
                                id={documentType}
                                accept='application/pdf, image/*'
                                name={documentType}
                                onChange={(e) => handleChange(e, 'file')}
                                className='form-control'
                              />
                            </div>
                          </div>

                          <div className='col-md-6'>
                            <label className='form-label'>{documentTitle} Number</label>
                            <input
                              type='text'
                              name={documentType}
                              className={clsx('form-control', documentNumberValidation)}
                              placeholder='XXXXXXXXXX'
                              value={documentNumber}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='col-md-auto mt-md-auto'>
                        {(permission.write || userType.toLowerCase() === 'ts') && (
                          <button
                            disabled={buttonText !== 'Upload'}
                            className='btn btn-primary'
                            onClick={() => uploadBusinessDocument()}
                          >
                            {buttonText}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='col-auto ms-auto'>
                  <span className='badge badge-light my-2 text-dark'>Not Uploaded</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <EaModal
        modalTitle='Verification Document Rejection Reason'
        onSubmit={() => rejectDocument(`${documentType}`)}
        cancel={clearFunction}
        id={`${documentType}_ea_modal_reject_reason`}
        actionBtnName='Yes, Proceed'
        closeAuto={`${documentType}`}
        children={
          <>
            <p>Are you sure, you want to reject this document?</p>
            <div className='mb-4'>
              <label className='form-label'>Verification Document Rejection Reason</label>
              <AsyncPaginate
                loadOptions={fetchRejectionReason}
                isSearchable
                className={clsx('react-select-container w-100', docRejectionValidation.option)}
                classNamePrefix='react-select'
                name='option'
                onChange={(e: any) => handleChangeReason(e, 'option')}
                value={rejectReasonOption.filter(
                  (option: any) => option.value === documentRejection.option
                )}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            {documentRejection.option == '' && (
              <div>
                <label className='form-label'>Comments</label>
                <textarea
                  className={clsx('form-control', docRejectionValidation.comments)}
                  rows={4}
                  name='comments'
                  value={documentRejection.comments}
                  onChange={(e: any) => handleChangeReason(e, 'comments')}
                ></textarea>
              </div>
            )}
          </>
        }
      />

      {/* img zoom */}
      <EaModal
        modalTitle=''
        modalClass='md-effect-1 zoom-modal'
        children={
          <>
            <img
              alt={documentTitle}
              src={`${
                businessDetailData &&
                businessDetailData[documentType] != null &&
                businessDetailData[documentType].file_name
              }`}
              width='500'
              className='w-100 img-fluid'
            />
            <div className='text-center mt-3'>
              <button
                type='button'
                onClick={() => {
                  downloadFile(
                    businessDetailData[documentType] != null &&
                      businessDetailData[documentType].file_name
                  )
                }}
                className='mx-auto btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center justify-content-center'
              >
                <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
                Download
              </button>
            </div>
          </>
        }
        id={'imgZoom_Modal'}
        actionBtnName='Yes, Proceed'
      />

      <EaModal
        modalTitle=''
        modalClass='md-effect-1 zoom-modal'
        children={
          <>
            <img
              alt={documentTitle}
              src={`${
                businessDetailData &&
                businessDetailData[documentType] != null &&
                businessDetailData[documentType].file_name
              }`}
              width='500'
              className='w-100 img-fluid'
            />
            <div className='text-center mt-3'>
              <button
                type='button'
                onClick={() => {
                  downloadFile(
                    businessDetailData &&
                      businessDetailData[documentType] != null &&
                      businessDetailData[documentType].file_name
                  )
                }}
                className='mx-auto btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center justify-content-center'
              >
                <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
                Download
              </button>
            </div>
          </>
        }
        id={`${documentType}_imgZoom_Modal`}
        actionBtnName='Yes, Proceed'
      />
    </>
  )
}

export default DocumentSection
