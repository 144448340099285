import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { apiBaseURL, checkPermissions, defaultPermission, retrieveFilter, saveFilter } from "../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest } from "../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert"
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader"
import CustomPagination from "../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../_eaFruitsDms/layout/core"
import TableSurveyForms from "./components/tableSurveyForms"
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import tenantConfiguration from "../../../TenantVariables"
import { filters } from "../../../_eaFruitsDms/utils/FilterConstants"

const SurveyForms = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const adminHeaderTitle = {
    title: 'Survey Forms',
    subTitle: 'Survey Forms',
  }

  
  let surveyListFiltered : any = retrieveFilter(filters.sa_survey_form);

  const initialFiltersState = {
      selected: surveyListFiltered?.selected || '',
      search: surveyListFiltered?.search || '',
      currentOffSet: surveyListFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [surveyForms, setSurveyForms] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [searchByName, setSearchByName] = useState<string>(surveyListFiltered?.search || '')
  const [selected, setSelected] = useState<number>(surveyListFiltered?.selected || 0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>(surveyListFiltered?.currentOffSet || '0');
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [messageHandler, setMessageHandler] = useState<string>('')
  const [messageTitle, setMessageTitle] = useState<string>('')
  const [timer, setTimer] = useState<any>(null)

  const [SurveyFormName, setSurveyFormName] = useState(true)
  const [FormPermissions, setFormPermission] = useState(true)
  const [orderColumnKey, setOrderColumnKey] = useState('')
  const initialOrdering = "-id"
  const [ordering, setOrdering] = useState(initialOrdering)
  const [permission, setPermission] = useState(defaultPermission)
  const [permissionfeedback, setPermissionFeedBack] = useState(defaultPermission)

  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(surveyListFiltered?.buttonDisable || false) 
  
  const debounceSearch = useDebounce(searchByName, 500); 

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() =>{
    if (location.state) {
      let state: any = location.state

      if (state.status) {
        setIsAlertSuccess(true)
      } else {
        setIsAlertFailed(true)
      }

      setMessageTitle(state.formName)
      setMessageHandler(state.type)

      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)

      navigate(location.pathname, {})
    }
  },[])

  useEffect(() => {
    fetchSurveyForms(limit, currentOffset, debounceSearch, ordering).then((r) => { })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.sa_survey_form , filterStates)
    if(searchByName == ''){
        setButtonDisable(false)
    }

  }, [debounceSearch , currentOffset])

  const fetchPermission = async () =>{
    let res : any
    res = await checkPermissions("survey_forms")
   setPermission(res)

    res = await checkPermissions("survey_forms_feedback")
    setPermissionFeedBack(res)
   }

  const fetchSurveyForms = async (limit: number, offset: any, search: string, ordering: any) => {
    let surveyFormsAPI = `${apiBaseURL()}surveyForms/form/list?ordering=${ordering}&limit=${limit}&offset=${offset}`

    if (searchByName != '') {
      surveyFormsAPI += `&search=${search}`
    }

    setDisplayLoader(true)

    const res = await getRequest(surveyFormsAPI, true)

    setSurveyForms(res.results)
    setCount(res.count)
    setNext(res.next)
    setPrev(res.previous)
    setDisplayLoader(false)
  }

  const filterChangeHandler = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearchByName(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      fetchSurveyForms(limit, newOffset, searchByName, ordering).then((r) => { })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      fetchSurveyForms(limit, newOffset, searchByName, ordering).then((r) => { })
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      fetchSurveyForms(limit, newOffset, searchByName, ordering)
    }
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const handleOrdering = (key: any) => {
    setOrderColumnKey(key)
    if (key === "SurveyFormName") {
      setSurveyFormName(!SurveyFormName)
      setFormPermission(true)
    }

    if (key === "FormPermissions") {
      setFormPermission(!FormPermissions)
      setSurveyFormName(true)
    }
  }

  useEffect(() => {
    if (orderColumnKey === "SurveyFormName") {
      if (SurveyFormName) {
        setOrdering("survey_form_name")
      } else {
        setOrdering("-survey_form_name")
      }
    }

    if (orderColumnKey === "FormPermissions") {
      if (FormPermissions) {
        setOrdering("access_to")
      } else {
        setOrdering("-access_to")
      }
    }
  }, [SurveyFormName, FormPermissions, orderColumnKey])

  useEffect(() => {
    fetchSurveyForms(limit, currentOffset, searchByName, ordering).then((r) => { })
  }, [ordering])

  const handleChangeReset = () =>{
    setSearchByName('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Survey Form' />
      <div>
        <PageTitle breadcrumbs={[adminHeaderTitle]}>
          {intl.formatMessage({ id: 'Survey Forms' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={isAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('SURVEY_FORM.ADD', messageTitle)
              : FormatMessage('SURVEY_FORM.UPDATE', messageTitle)
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message={
            messageHandler === 'errorInUpdate'
              ? FormatMessage('SURVEY_FORM.UPDATE.ERROR', messageTitle)
              : messageHandler === 'duplicateAdd'
                ? FormatMessage('SURVEY_FORM.DUPLICATE', messageTitle)
                : messageHandler === 'errorInAdd'
                  ? FormatMessage('SURVEY_FORM.ADD.ERROR', messageTitle)
                  : 'failed'
          }
        />

        <div className='card'>
          {displayLoader && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={filterChangeHandler}  value={searchByName} disabled={displayLoader}/>
            <div className='ms-md-auto'>
              <div className='card-toolbar d-flex my-0'>
                <div className="me-3">
                  {permission.write && <button
                    className='btn btn-sm btn-primary my-1'
                    onClick={() => navigate('survey-feedback')}
                  >
                    Add Survey Form
                  </button>}</div>
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <TableSurveyForms
                surveyForms={surveyForms}
                fetchSurveyForms={fetchSurveyForms}
                limit={limit}
                currentOffset={currentOffset}
                searchByName={searchByName}
                SurveyFormName={SurveyFormName}
                FormPermissions={FormPermissions}
                handleOrdering={handleOrdering}
                showWrite={permission.write}
                showDelete={permission.delete}
                showWriteSurvey={permissionfeedback.write}
                showDeleteSurvey={permissionfeedback.delete}
              />
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        {/* <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${surveyForms.length == 0 ? 0 : selected * limit + 1} to ${surveyForms.length == 0 ? 0 : surveyForms.length < 10 ? count : (selected + 1) * 10
                } of ${surveyForms.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                prev={prevPage}
                next={nextPage}
                count={count}
                selected={selected}
                numberClick={numberClick}
              />
            </div>
          </div>
        </div> */}
        
        <CustomPaginate
              data={surveyForms}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.sa_survey_form}
              filterStates={filterStates}
              isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default SurveyForms
