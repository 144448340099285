import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkPermissions,
  checkUserType,
  dateFormat,
  defaultPermission,
  fetchAsyncDropdown,
  isEmpty,
  KTSVG,
  retrieveFilter,
  saveFilter,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import tenantConfiguration from '../../../../TenantVariables'
import {fetchUserTypes, userTypes} from './CustomerConst'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import moment from 'moment/moment'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'

type Props = {
  className: string
}
const WMOnBoardingRequest: React.FC<Props> = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const customerHeaderTitle = {
    title: 'Customers',
  }

  let customersFiltered = retrieveFilter(filters.ts_verification_customer)

  const initialVerificationPending: any = {
    all: customersFiltered?.all || true,
    on_boarding: customersFiltered?.on_boarding || true,
    mobile: customersFiltered?.mobile || true,
    document: customersFiltered?.document || true,
    address: customersFiltered?.address || true,
  }

  const initialState = {
    warehouse: customersFiltered?.warehouse || {label: 'All Warehouses', value: ''},
    userId: customersFiltered?.userId || {label: 'All Users', value: ''},
    userType: customersFiltered?.userType || '',
    all: customersFiltered?.all || true,
    on_boarding: customersFiltered?.on_boarding || true,
    mobile: customersFiltered?.mobile || true,
    document: customersFiltered?.document || true,
    address: customersFiltered?.address || true,
    selected: customersFiltered?.selected || '',
    search: customersFiltered?.search || '',
    currentOffSet: customersFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const [customers, setCustomers] = useState<any>([])
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [next, setNext] = useState<string>('')
  const [prev, setPrev] = useState<string>('')
  const [currentOffset, setCurrentOffset] = useState<any>(customersFiltered?.currentOffSet || '')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(customersFiltered?.selected || 0)
  const [searchBy, setSearchBy] = useState<string>(customersFiltered?.search || '')
  const [verificationPendingFilter, setVerificationPendingFilter] = useState<any>(
    initialVerificationPending
  )
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouse, setWarehouse] = useState<any>(
    customersFiltered?.warehouse || {label: 'All Warehouses', value: ''}
  )
  const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [permission, setPermission] = useState(defaultPermission)
  const [userOffset, setUserOffset] = useState<any>('')
  const [userType, setUserType] = useState<any>(customersFiltered?.userType || '')
  // const [userId, setUserId] = useState<any>('')
  const [userId, setUserId] = useState<any>(
    customersFiltered?.userId || {
      label: 'All Users',
      value: '',
    }
  )
  const [userReset, setUserReset] = useState<any>(moment())
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    customersFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(searchBy, 500)

  useEffect(() => {
    localStorage.removeItem('contact_no')
    localStorage.removeItem('contact_is_verified')
    fetchPermission()
  }, [])

  useEffect(() => {
    fetchCustomers().then()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)

    setTimeout(() => {
      saveFilter(filters.ts_verification_customer, filterStates)
    }, 200)

    if (warehouse.value == '' && searchBy == '' && (userType == undefined || userType == '')) {
      setButtonDisable(false)
    }
  }, [currentOffset, verificationPendingFilter, debounceSearch, warehouse, userType, userId])

  useEffect(() => {
    setWarehouseOffSet('')
    setUserOffset('')
  }, [searchSelect])

  const fetchPermission = async () => {
    let res = await checkPermissions('customer_verification_request')
    setPermission(res)
  }

  const fetchCustomers = async () => {
    setDisplayLoader(true)

    let fetchCustomersAPIUrl = `${apiBaseURL()}customers/verification-requests?limit=${limit}&offset=${currentOffset}&on_boarding_verify=${
      verificationPendingFilter.on_boarding
    }&contact_verify=${verificationPendingFilter.mobile}&document_verify=${
      verificationPendingFilter.document
    }&address_verify=${verificationPendingFilter.address}&warehouse_id=${
      warehouse.value
    }&reg_by_type=${userType}&reg_by_id=${userId !== '' ? userId.value : ''}`

    if (debounceSearch != '') {
      fetchCustomersAPIUrl += `&search=${debounceSearch}`
    }

    const customerResponse = await getRequest(fetchCustomersAPIUrl, true)

    setCustomers(customerResponse.results)
    setCount(customerResponse.count)
    setNext(customerResponse.next)
    setPrev(customerResponse.previous)
    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    return await fetchAsyncDropdown(
      warehouseOffSet,
      setWarehouseOffSet,
      warehouses,
      setWarehouses,
      `warehouse/list?is_parent=true&parent_warehouse__id=${
        !isEmpty(getW_Id) ? getW_Id : ''
      }&ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All Warehouses'
    )
  }

  const handleSearchFilter = (e: any) => {
    setButtonDisable(true)
    setSearchBy(e.target.value)
    setFilterStates({...filterStates, ['search']: e.target.value})
  }

  const filterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    let checkBoxFilters: any = {...verificationPendingFilter}
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    let updatedState: any = {...filterStates}

    switch (inputName) {
      case 'all':
        if (e.target.checked) {
          checkBoxFilters.all = true
          checkBoxFilters.mobile = true
          checkBoxFilters.on_boarding = true
          checkBoxFilters.address = true
          checkBoxFilters.document = true
          updatedState['all'] = true
          updatedState['mobile'] = true
          updatedState['on_boarding'] = true
          updatedState['address'] = true
          updatedState['document'] = true
        } else {
          checkBoxFilters = {...initialVerificationPending}
        }
        break
      case 'on_boarding':
        checkBoxFilters.on_boarding = !!e.target.checked
        updatedState['on_boarding'] = e.target.checked
        updatedState['all'] = false
        break
      case 'mobile':
        checkBoxFilters.mobile = !!e.target.checked
        updatedState['mobile'] = e.target.checked
        updatedState['all'] = false
        break
      case 'document':
        checkBoxFilters.document = !!e.target.checked
        updatedState['document'] = e.target.checked
        updatedState['all'] = false
        break
      case 'address':
        checkBoxFilters.address = !!e.target.checked
        updatedState['address'] = e.target.checked
        updatedState['all'] = false
        break
      case 'warehouse':
        setWarehouse(e)
        setUserReset(moment())
        setUserOffset('')
        updatedState['warehouse'] = e
        break
      case 'user-type':
        setUserType(e.value)
        setUserOffset('')
        setUserReset(moment())
        setUserId('')
        setSearchSelect('')
        updatedState['userType'] = e.value
        updatedState['userId'] = ''
        break
      case 'users':
        setUserId(e)
        updatedState['userId'] = e
        break
    }
    setFilterStates(updatedState)

    if (
      !checkBoxFilters.on_boarding &&
      !checkBoxFilters.mobile &&
      !checkBoxFilters.document &&
      !checkBoxFilters.address
    ) {
      setVerificationPendingFilter(initialVerificationPending)
    } else {
      if (
        checkBoxFilters.on_boarding &&
        checkBoxFilters.mobile &&
        checkBoxFilters.document &&
        checkBoxFilters.address
      ) {
        setVerificationPendingFilter({
          ...checkBoxFilters,
          ['all']: true,
          ['mobile']: true,
          ['document']: true,
          ['address']: true,
        })
      } else {
        checkBoxFilters.all = false
        setVerificationPendingFilter(checkBoxFilters)
      }
    }
  }

  const goToOverview = (customer: any) => {
    localStorage.setItem('customerPermission', 'verification-requests')
    localStorage.setItem('cId', customer.id.toString())
    localStorage.setItem('contact_is_verified', customer.verification_statuses.contact_verified)
    localStorage.setItem('contact_no', customer.contact_no)
    saveFilter(filters.ts_verification_customer, filterStates)
    navigate('overview')
  }

  const approveCustomer = async (customer: any) => {
    if (!customer.verification_statuses.contact_verified) {
      localStorage.setItem('cId', customer.id.toString())
      localStorage.setItem('contact_is_verified', customer.verification_statuses.contact_verified)
      localStorage.setItem('contact_no', customer.contact_no)
      navigate('mobile-number')
    } else {
      goToOverview(customer)
    }
  }

  const selectedFilter = () => {
    let totalSelectedFilter = 0

    if (verificationPendingFilter.on_boarding) {
      totalSelectedFilter += 1
    }

    if (verificationPendingFilter.mobile) {
      totalSelectedFilter += 1
    }

    if (verificationPendingFilter.address) {
      totalSelectedFilter += 1
    }

    if (verificationPendingFilter.document) {
      totalSelectedFilter += 1
    }

    return totalSelectedFilter
  }

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []
    let hasMore: boolean = false

    if (userType !== '') {
      if (search !== '') {
        setSearchSelect(search)
      }

      let wId = ''

      if (userType !== 'ts') {
        wId = warehouse.value
      }

      const response: any = await getRequest(
        `${apiBaseURL()}auth/users/team/list?search=${searchSelect}&type=${userType}&limit=${limit}&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`,
        true
      )

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (options.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label:
                option.first_name + ' ' + option.last_name + ' (' + option.type.toUpperCase() + ')',
              value: option.id,
            })
          })
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleChangeReset = () => {
    setSearchBy('')
    setWarehouse({label: 'All Warehouses', value: ''})
    setUserId({label: 'All Users', value: ''})
    setUserType('')
    setUserId({label: 'All Users', value: ''})
    setCurrentOffset(0)
    setSelected(0)
    let updateVerificationPending: any = {...verificationPendingFilter}
    updateVerificationPending['all'] = true
    updateVerificationPending['on_boarding'] = true
    updateVerificationPending['mobile'] = true
    updateVerificationPending['document'] = true
    updateVerificationPending['address'] = true
    setFilterStates(updateVerificationPending)

    let updatedState: any = {...filterStates}
    updatedState['warehouseId'] = ''
    updatedState['categoryId'] = ''
    updatedState['typeId'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <div>
        <EATitle title='Verification Request' />
        <PageTitle breadcrumbs={[customerHeaderTitle]}>
          {intl.formatMessage({id: 'Verification Requests'})}
        </PageTitle>
        <div className='card'>
          {displayLoader && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={handleSearchFilter}
              value={searchBy}
              isDisable={displayLoader}
            />
            <div className='ms-md-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}

              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <AsyncPaginate
                  loadOptions={fetchWarehouses}
                  isSearchable
                  className={clsx(
                    'react-select-container my-1 me-3 w-150px'
                    // customerValidation.customer_type
                  )}
                  classNamePrefix='react-select'
                  value={warehouse}
                  isDisabled={displayLoader}
                  onChange={(e: any) => filterChange(e, 'warehouse')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <div className='my-1 me-3 w-170px'>
                  <button
                    className='btn form-select border pe-7 text-start'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    disabled={displayLoader}
                  >
                    {verificationPendingFilter.all
                      ? 'All Request Types'
                      : selectedFilter() + ' Selected'}
                  </button>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-state-bg w-250px w-md-300px'
                    data-kt-menu='true'
                  >
                    <ul className='list-unstyled menu-with-checkbox mb-0'>
                      <li>
                        <label
                          className='form-check form-check-sm form-check-custom form-check-solid'
                          htmlFor='customer'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            onChange={filterChange}
                            name='all'
                            id='customer'
                            value='2'
                            checked={verificationPendingFilter.all}
                          />
                          <span className='form-check-label'>All Verification Requests</span>
                        </label>
                      </li>
                      <li>
                        <label
                          className='form-check form-check-sm form-check-custom form-check-solid'
                          htmlFor='customer2'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            onChange={filterChange}
                            name='on_boarding'
                            id='customer2'
                            value='2'
                            checked={verificationPendingFilter.on_boarding}
                          />
                          <span className='form-check-label'>Onboarding Requests</span>
                        </label>
                      </li>
                      <li>
                        <label
                          className='form-check form-check-sm form-check-custom form-check-solid'
                          htmlFor='customer3'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            onChange={filterChange}
                            name='mobile'
                            id='customer3'
                            value='2'
                            checked={verificationPendingFilter.mobile}
                          />
                          <span className='form-check-label'>Mobile Verification</span>
                        </label>
                      </li>
                      <li>
                        <label
                          className='form-check form-check-sm form-check-custom form-check-solid'
                          htmlFor='customer4'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='customer4'
                            value='2'
                            onChange={filterChange}
                            name='document'
                            checked={verificationPendingFilter.document}
                          />
                          <span className='form-check-label'>Business Details Verification</span>
                        </label>
                      </li>
                      <li>
                        <label
                          className='form-check form-check-sm form-check-custom form-check-solid'
                          htmlFor='customer5'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='customer5'
                            onChange={filterChange}
                            name='address'
                            value='2'
                            checked={verificationPendingFilter.address}
                          />
                          <span className='form-check-label'>Address Verification</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

                <AsyncPaginate
                  loadOptions={fetchUserTypes}
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='Added By ...'
                  isDisabled={displayLoader}
                  onChange={(e: any) => filterChange(e, 'user-type')}
                  value={userTypes.find((option: any) => option.value === userType)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {userType !== '' && (
                  <AsyncPaginate
                    key={userReset}
                    loadOptions={fetchUsers}
                    className='react-select-container my-1 me-3 w-200px'
                    classNamePrefix='react-select'
                    placeholder='All Users'
                    isDisabled={displayLoader}
                    onChange={(e: any) => filterChange(e, 'users')}
                    value={userId || ''}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                {/* <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-4'>
                                    <input className='form-check-input widget-13-check me-2' type='checkbox'
                                        value={verificationPendingFilter.all} id='allrequest' checked={verificationPendingFilter.all} name="all" onChange={filterChange} />
                                    <label className='fw-500' htmlFor='allrequest'>All</label>
                                </div>
                                <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-4'>
                                    <input className='form-check-input widget-13-check me-2' type='checkbox'
                                        value={verificationPendingFilter.mobile} id='allrequestmob' checked={verificationPendingFilter.mobile} name="mobile" onChange={filterChange} />
                                    <label className='fw-500' htmlFor='allrequestmob'>Mobile</label>
                                </div>
                                <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-4'>
                                    <input className='form-check-input widget-13-check me-2' type='checkbox'
                                        value={verificationPendingFilter.document} id='business' checked={verificationPendingFilter.document} name="document" onChange={filterChange} />
                                    <label className='fw-500' htmlFor='business'>Business Details</label>
                                </div>
                                <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-5'>
                                    <input className='form-check-input widget-13-check me-2' type='checkbox'
                                        value={verificationPendingFilter.address} id='address' checked={verificationPendingFilter.address} name="address" onChange={filterChange} />
                                    <label className='fw-500' htmlFor='address'>Address</label>
                                </div> */}

                {/*<label className="form-label mb-0"><button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">*/}
                {/*    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />*/}
                {/*    Export</button>*/}
                {/*</label>*/}
                {/* </div> */}
              </form>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {customers.length === 0 ? (
              <NoRecords />
            ) : (
              <>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-60px'>ID</th>
                        <th className='min-w-150px'>Customer Name</th>
                        <th className='min-w-140px'>Category</th>
                        <th className='min-w-150px'>Warehouse Name</th>
                        <th className='min-w-150px'>Added By</th>
                        <th className='min-w-80px text-center'>Mobile</th>
                        <th className='min-w-130px text-center'>Business Details</th>
                        <th className='min-w-135px text-center'>Location</th>
                        <th className='min-w-110px text-center'>Reg. Date</th>
                        {checkUserType(['wm', 'ts']) && permission.write && (
                          <th className='min-w-100px action-th py-2 text-start'>Action</th>
                        )}
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {customers.map((customer: any) => {
                        return (
                          <tr>
                            <td
                              className='cursor-pointer'
                              onClick={() => goToOverview(customer.id)}
                            >
                              <div className='media-body'>
                                <p className='m-0 xs-font'>#{customer.id}</p>
                              </div>
                            </td>
                            <td onClick={() => goToOverview(customer)} style={{cursor: 'pointer'}}>
                              <div className='d-flex align-items-center'>
                                <span
                                  className='rounded-circle pro-icon me-2'
                                  data-bs-toggle='tooltip'
                                  title={customer.business_name}
                                >
                                  {customer.logo === null ? (
                                    <img
                                      src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                      alt=''
                                      className='rounded-circle pro-icon me-1 d-inline-block text-dark font-10'
                                    />
                                  ) : (
                                    <img
                                      src={customer.logo}
                                      alt=''
                                      className='rounded-circle pro-icon me-1 d-inline-block text-dark font-10'
                                    />
                                  )}
                                </span>
                                <div className='media-body'>
                                  <p className='m-0 xs-font'>{customer.business_name}</p>
                                </div>
                              </div>
                            </td>
                            <td onClick={() => goToOverview(customer)} style={{cursor: 'pointer'}}>
                              <div>
                                <div className='text-muted font-13 mb-1 font-medium'>
                                  {customer.nature_of_business}
                                </div>
                                {customer.category_name}
                              </div>
                            </td>
                            <td onClick={() => goToOverview(customer)} style={{cursor: 'pointer'}}>
                              {checkEmpty(customer.warehouse_name)}
                            </td>
                            <td onClick={() => goToOverview(customer)} style={{cursor: 'pointer'}}>
                              {isEmpty(customer.reg_by_name)
                                ? 'Self Registered'
                                : checkEmpty(customer.reg_by_name)}{' '}
                              {!isEmpty(customer.reg_by_type) &&
                                `(${customer.reg_by_type.toUpperCase()})`}
                            </td>
                            <td className='text-center'>
                              <button
                                onClick={() => {
                                  localStorage.setItem('cId', customer.id)
                                  localStorage.setItem(
                                    'contact_is_verified',
                                    customer.verification_statuses.contact_verified
                                  )
                                  localStorage.setItem('contact_no', customer.contact_no)
                                  navigate('mobile-number')
                                }}
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3 cursor-pointer'
                              >
                                {customer.verification_statuses &&
                                customer.verification_statuses.contact_verified ? (
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='ic mr-0'
                                  />
                                ) : (
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='ic mr-0'
                                  />
                                )}
                              </button>
                            </td>
                            <td className='text-center'>
                              <button
                                onClick={() => {
                                  localStorage.setItem('cId', customer.id)
                                  localStorage.setItem(
                                    'contact_is_verified',
                                    customer.contact_is_verified
                                  )
                                  localStorage.setItem('contact_no', customer.contact_no)
                                  navigate('business-details')
                                }}
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                              >
                                {customer.verification_statuses &&
                                customer.verification_statuses.business_doc_verified ? (
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='ic mr-0'
                                  />
                                ) : (
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='ic mr-0'
                                  />
                                )}
                              </button>
                            </td>
                            <td className='text-center'>
                              <button
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={() => {
                                  localStorage.setItem(
                                    'contact_is_verified',
                                    customer.contact_is_verified
                                  )
                                  localStorage.setItem('contact_no', customer.contact_no)
                                  localStorage.setItem('cId', customer.id)
                                  navigate('business-address')
                                }}
                              >
                                {customer.verification_statuses &&
                                customer.verification_statuses.business_address_verified ? (
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='ic mr-0'
                                  />
                                ) : (
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='ic mr-0'
                                  />
                                )}
                              </button>
                            </td>
                            <td onClick={() => goToOverview(customer)} style={{cursor: 'pointer'}}>
                              {dateFormat(customer.reg_date).displayDate}
                            </td>
                            {checkUserType(['wm', 'ts']) && permission.write && (
                              <td>
                                {customer.status !== 'approved' ? (
                                  <button
                                    className='btn btn-primary'
                                    onClick={() => approveCustomer(customer)}
                                  >
                                    Approve
                                  </button>
                                ) : (
                                  '-'
                                )}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </div>
                {/* end::Table container */}
              </>
            )}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={customers}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.ts_verification_customer}
          filterStates={filterStates}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default WMOnBoardingRequest
