import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  KTSVG,
  retrieveFilter,
  saveFilter,
  toAbsoluteUrl
} from "../../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertDanger, EaAlertSuccess, } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../../_eaFruitsDms/layout/components/button/eaButton"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"
import ResetButton from "../../../../../_eaFruitsDms/layout/components/button/resetButton"
import CustomPaginate from "../../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import { filters } from "../../../../../_eaFruitsDms/utils/FilterConstants"

type Props = {
  className: string
}

const VendorsSuccessStories = ({ className }: any) => {
  const intl = useIntl()
  const navigate = useNavigate()

  let vendorStoryFiltered : any = retrieveFilter(filters.cms_vendor_story);

  const initialFiltersState = {
      selected: vendorStoryFiltered?.selected || '',
      search: vendorStoryFiltered?.search || '',
      currentOffSet: vendorStoryFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [vendorsStoryList, setVendorsStoryList] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState<number>(vendorStoryFiltered?.selected || 0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>(vendorStoryFiltered?.currentOffSet || '0');
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<string>(vendorStoryFiltered?.search || '')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit, setLimit] = useState<number>(10)
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);
  const [filterStates, setFilterStates] = useState<any>(initialFiltersState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(vendorStoryFiltered?.buttonDisable || false) 

  const cmsAuthor: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Success Stories',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    setTimeout(() => fetchVendorsStory().then(), timer)
  }, [currentOffset, debounceSearch, count, vendorsStoryList.length])

  useEffect(() => {
    localStorage.removeItem('storyId')
    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
  }

  const fetchVendorsStory = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}successstories/get?usertype=Customer&limit=${limit}&ordering=-updated_at&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)
    setVendorsStoryList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.cms_vendor_story , filterStates)
    if(search == ''){
        setButtonDisable(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setSearch(e.target.value)
    setFilterStates({ ...filterStates, ['search']: e.target.value })
  }

  const handleChangeReset = () =>{
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['search'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Customer Success Stories - ' />
      <div>
        <PageTitle breadcrumbs={cmsAuthor}>{intl.formatMessage({ id: 'Customer' })}</PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successAdd'
              ? FormatMessage('CMS_VENDOR_STORY.ADD')
              : messageHandler === 'successEdit'
                ? FormatMessage('CMS_VENDOR_STORY.UPDATE')
                : messageHandler === 'successDelete' && FormatMessage('CMS_VENDOR_STORY.DELETE')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch}  value={search} disabled={listLoadder} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {permission.write && (
                  <EaButton
                    btnName='Add Customer Sucess Story'
                    className="btn-primary me-3"
                    onClick={() => {
                      navigate('add-stories')
                      localStorage.setItem('StoryType', 'Customer')
                    }}
                  />
                )}
            <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>

              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {vendorsStoryList.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-180px py-2'>Name</th>
                          <th className='min-w-120px py-2'>Story Title</th>
                          <th className='min-w-120px py-2'>Sub Title</th>
                          <th className='min-w-120px py-2'>Status</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {vendorsStoryList.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-28px me-2'>
                                    <span className='symbol-label'>
                                      {item.profile_thumbnail === null ? (
                                        <img
                                          alt='group image'
                                          className='h-35px rounded-1'
                                          src={defaultSrc}
                                        />
                                      ) : (
                                        <img
                                          src={item.profile_thumbnail}
                                          className='h-40 rounded-1'
                                          alt=''
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                      {item.firstname} {item.lastname}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>{item.story_title}</td>
                              <td>{item.subtitle}</td>
                              <td>
                                {item.is_active == true ? (
                                  <>
                                    <span className='badge badge-light-success my-2 text-dark'>
                                      Active
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <span className='badge badge-light-danger my-2 text-dark'>
                                      Inactive
                                    </span>
                                  </>
                                )}
                              </td>
                              <td className='text-end'>
                                {permission.write && (
                                  <a
                                    href='javascript:void(0)'
                                    className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                    onClick={(event) => {
                                      navigate('add-stories')
                                      localStorage.setItem('StoryType', 'Customer')
                                      localStorage.setItem('storyId', item.id)
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='ic mr-0'
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
         <CustomPaginate
              data={vendorsStoryList}
              selected={selected}
              setSelected={setSelected}
              setCurrentOffset={setCurrentOffset}
              totalRecords={count}
              limit={limit}
              saveFilterName={filters.cms_vendor_story}
              filterStates={filterStates}
              isDisabled={listLoadder}
        />
      </div>
    </>
  )
}

export default VendorsSuccessStories