import clsx from 'clsx'
import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router'
import {AsyncPaginate} from 'react-select-async-paginate'
import {apiBaseURL, dateFormat, KTSVG, measurementLabel} from '../../../_eaFruitsDms/helpers'
import {getRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import DatePicker from '../../../_eaFruitsDms/layout/components/datePicker/datePicker'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {StockInTabs} from '../../../_eaFruitsDms/helpers/components/stockInTabs'

type Props = {
  className: string
}

let tableRowIndex: number = 0

const FreshStockIn: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const headerTitle = {
    title: 'Stock-In',
    subTitle: 'Fresh Stock-In',
  }

  const currentDate = moment().format('YYYY-MM-DD')
  const currentDateObj = moment().toDate()

  const initialRow: any = {
    index: 0,
    product_id: '',
    batch_number: '',
    product_sku: null,
    validation_for_product: '',
    validation_for_batch: '',
    validation_for_qty: '',
    quantity_label: '',
    inward_quantity: '',
    expiry_date: currentDate,
    quantity: '',
    qty: '',
  }

  const [freshStockInRows, setFreshStockInRows] = useState<any>([initialRow])
  const [productSkus, setProductSkus] = useState<any>([])
  const [productSkuData, setProductSkuData] = useState<any>([])
  const [warehouseId, setWarehouseId] = useState<any>(0)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isDataUpdate, setIsDataUpdate] = useState<boolean>(false)
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [limit, setLimit] = useState<number>(10)
  const [searchSelect, setSearchSelect] = useState('')

  useEffect(() => {
    setProductOffSet('')
  }, [searchSelect])

  useEffect(() => {
    setWarehouseId(localStorage.getItem('w_id'))
  }, [])

  const fetchProductSkus = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const wId: any = localStorage.getItem('w_id')

    const productSKUResponse = await getRequest(
      `${apiBaseURL()}inventory/product-SKUs/?warehouse_id=${wId}&ordering=name&search=${search}&limit=${limit}&offset=${productOffSet}&remove_product_stocking=true&&only_ripening_product=false`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false
    
    if(productSKUResponse.results){
      if (productSKUResponse.next !== null) {
        const queryParams = new URLSearchParams(productSKUResponse.next)
        let newOffset: any = queryParams.get('offset')
        setProductOffSet(newOffset)
        hasMore = true
      }
  
      if (productSKUResponse.results.length > 0) {
        productSKUResponse.results.map((option: any) => {
          optionData.push(option)
          options.push({
            label: option.name,
            value: option.id,
          })
        })
      }
  
      if (productSkus.length > 0) {
        setProductSkus(productSkus.concat(options))
        setProductSkuData(productSkuData.concat(optionData))
      } else {
        setProductSkus(options)
        setProductSkuData(optionData)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  // Receive data from FreshStockTableRow
  const handleChange = (e: any, index: any) => {
    let rows = [...freshStockInRows]
    const inputName = e.target ? e.target.name : 'product'
    let inputValue = e.target ? e.target.value : e.value
    let product: any = null

    switch (inputName) {
      case 'product':
        rows[index]['product_id'] = inputValue
        rows[index]['batch_number'] = ''
        rows[index]['expiry_date'] = currentDate
        rows[index]['qty'] = ''

        product = productSkuData.filter((productSku: any) => productSku.id === inputValue)
        rows[index]['validation_for_product'] = ''
        if (product.length > 0) {
          product = product[0]
          rows[index]['inward_quantity'] = 0
          rows[index]['product_sku'] = product

          rows[index]['quantity_label'] = measurementLabel(
            product.fix_size
              ? product.material.name
              : product.variants && product.variants.approx_weight > 0
                ? product.material.name
                : product.measurement_unit
          )
        }
        break
      case 'batch_number':
        if (inputValue.length > 1 && !inputValue.includes('#')) {
          return false
        }

        if (inputValue.length == 1 && !inputValue.includes('#')) {
          inputValue = '#' + inputValue
        }

        rows[index]['batch_number'] = inputValue
        if (inputValue.trim() != '') {
          rows[index]['validation_for_batch'] = ''
        } else {
          rows[index]['validation_for_batch'] = 'is-invalid'
        }
        break
      case 'expiry_date':
        rows[index]['expiry_date'] = moment(inputValue)
        break
      case 'quantity':
        //from 7th-Dec-2022 quantity known as inward_quantity and inward_quantity as total kgs : by Pinak Mehta
        inputValue = inputValue.replace(/\D/g, '')
        rows[index]['qty'] = inputValue
        product = rows[index]['product_sku']
        if (inputValue > 0) {
          if (product.fix_size) {
            if (product.measurement_unit.toLowerCase() === 'gram') {
              rows[index]['inward_quantity'] = inputValue * (product.size / 1000)
            } else {
              rows[index]['inward_quantity'] = inputValue * product.size
            }
          } else {
            if (product.variants && product.variants.approx_weight > 0) {
              rows[index]['inward_quantity'] = (inputValue * product.variants.approx_weight) / 1000
            } else {
              rows[index]['inward_quantity'] = inputValue
            }
          }
        } else {
          rows[index]['inward_quantity'] = 0
        }

        if (inputValue.trim() != '' && inputValue != 0) {
          rows[index]['validation_for_qty'] = ''
        } else {
          rows[index]['validation_for_qty'] = 'is-invalid'
        }

        break
    }
    setIsDataUpdate(true)
    setFreshStockInRows(rows)
  }

  // Add New Table Row
  const addNewRow = () => {
    tableRowIndex++

    const updatedRows = [...freshStockInRows]
    initialRow.index = tableRowIndex
    updatedRows[tableRowIndex] = initialRow
    setFreshStockInRows(updatedRows)
    setProductOffSet('')
  }

  // Remove Table row if rows are count is more than 1
  const deleteRow = (index: number): any => {
    if (freshStockInRows.length > 1) {
      setProductOffSet('')
      let indexToRemove = freshStockInRows.findIndex((x: any) => x.index == index)

      if (indexToRemove > -1) {
        let updatedRows: any = []

        freshStockInRows.forEach((row: any, index: any) => {
          if (index != indexToRemove) {
            if (index > indexToRemove) {
              row.index = row.index - 1
            }
            updatedRows.push(row)
          }
        })

        setFreshStockInRows(updatedRows)
        tableRowIndex--
      }
    } else {
      alert('You can not remove this row because you need to add at least one stock item.!')
    }
  }

  const freshStockInSubmitHandler = async () => {
    let isValid: boolean = true
    const updatedFreshStockInRows: any = [...freshStockInRows]

    updatedFreshStockInRows.map((row: any, index: number) => {
      if (row['product_id'] === '') {
        isValid = false
        updatedFreshStockInRows[index]['validation_for_product'] = 'is-invalid'
      }

      if (row['batch_number'].trim() === '') {
        isValid = false
        updatedFreshStockInRows[index]['validation_for_batch'] = 'is-invalid'
      }

      if (row['qty'] === 0 || row['qty'] === '') {
        isValid = false
        updatedFreshStockInRows[index]['validation_for_qty'] = 'is-invalid'
      }
    })

    if (isValid) {
      const freshStockInSubmitData: any = {
        stock_entries: freshStockInRows,
        stock_type: 'FreshProduct',
        stock_entry_type: 'In',
        receiver_warehouse_id: warehouseId,
        action_by: localStorage.getItem('user_id'),
      }

      const bulkStockInAPIUrl = `${apiBaseURL()}inventory/bulk_stock_in_out/`
      setDisplayLoader(true)
      setIsSubmitting(true)
      await postRequest(bulkStockInAPIUrl, freshStockInSubmitData, true)
      setIsSubmitting(false)
      setDisplayLoader(false)
      setFreshStockInRows([initialRow])
      navigate('/reports/daily-stock-in-report')
    } else {
      setFreshStockInRows(updatedFreshStockInRows)
    }
  }

  const onCancel = () => {
    if (!isDataUpdate) {
      navigate('/')
    }
    setFreshStockInRows([initialRow])
    setIsDataUpdate(false)
  }

  return (
    <>
      <StockInTabs />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: 'Fresh Stock-In' })}
      </PageTitle>

      <div className={`card mb-6 ${className}`}>
        {displayLoader && <IsLoader />}

        {/* begin::Header */}
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Fresh Stock-In</span>
          </h3>
          {/* end::Title */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-gray-100 align-middle gs-0 gy-2'>
              {/* begin::Table head */}
              <thead>
                <tr className='text-muted fw-500'>
                  <th className='min-w-200px'>
                    Product SKU<span className="text-danger">*</span>
                  </th>
                  <th className='min-w-120px w-200px'>
                    Batch Number<span className="text-danger">*</span>
                  </th>
                  <th className='min-w-150px w-180px'>
                    Expiry Date<span className="text-danger">*</span>
                  </th>
                  <th className='min-w-190px w-190px'>
                    Inward Qty.<span className="text-danger">*</span>
                  </th>
                  <th className='w-120px min-w-130px'>Total</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {freshStockInRows.map((row: any, index: number): any => {
                  let dateView = dateFormat(freshStockInRows[index].expiry_date)
                  if (row)
                    return (
                      <>
                        <tr key={index.toString()}>
                          <td>
                            <AsyncPaginate

                              loadOptions={fetchProductSkus}
                              placeholder='Select'
                              isSearchable
                              className={clsx(
                                'react-select-container w-100',
                                freshStockInRows[index].validation_for_product
                              )}
                              classNamePrefix='react-select'
                              menuPosition='fixed'
                              name='product'
                              value={
                                freshStockInRows[index].product_id == ''
                                  ? ''
                                  : productSkus.find(
                                    (data: any) =>
                                      data.value == freshStockInRows[index].product_id
                                  )
                              }
                              onChange={(e) => handleChange(e, index)}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#0c78a4',
                                },
                              })}
                            />
                          </td>
                          <td>
                            <input
                              type='text'
                              name='batch_number'
                              className={clsx(
                                'form-control',
                                freshStockInRows[index].validation_for_batch
                              )}
                              placeholder='Type here...'
                              value={freshStockInRows[index].batch_number}
                              onChange={(e) => handleChange(e, index)}
                            />
                          </td>
                          <td>
                            <DatePicker
                              onCallback={(dateView: any, dateApi: any) => {
                                let rows = [...freshStockInRows]
                                rows[index].expiry_date = dateApi
                                setFreshStockInRows(rows)
                              }}
                              onCancel={() => { }}
                              id='datePicker'
                              name='expiry_date'
                              onApply={() => { }}
                              direction='down'
                              startDate={currentDateObj}
                              minDate={currentDateObj}
                              dateValue={dateView.displayDate}
                            />
                          </td>
                          <td>
                            <div
                              className='input-group'
                              style={{
                                borderColor:
                                  freshStockInRows[index].validation_for_qty != '' ? 'Red' : '',
                              }}
                            >
                              <input
                                type='text'
                                className={clsx(
                                  'form-control',
                                  freshStockInRows[index].validation_for_qty
                                )}
                                name='quantity'
                                value={freshStockInRows[index].qty}
                                onChange={(e) => handleChange(e, index)}
                                placeholder='Type here...'
                              />
                              <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                {freshStockInRows[index].quantity_label}
                              </span>
                            </div>
                          </td>
                          <td>
                            <input
                              type='text'
                              className='form-control min-w-70px'
                              value={freshStockInRows[index].inward_quantity + ' Kg'}
                              disabled
                            />
                          </td>
                          <td>
                            <button
                              type='button'
                              className='position-relative btn ms-auto p-0'
                              id={index.toString()}
                              onClick={() => {
                                deleteRow(index)
                              }}
                            >
                              <span className='svg-icon-dark'>
                                <KTSVG
                                  path='/media/icons/duotune/abstract/abs012.svg'
                                  className='ic me-0'
                                />
                              </span>
                            </button>
                          </td>
                        </tr>
                      </>
                    )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            <button type='button' className='position-relative btn ms-auto p-0' onClick={addNewRow}>
              <span className='svg-icon-dark'>
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='ic me-0' />
              </span>
            </button>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          <div className='pt-4 border-top text-center'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={freshStockInSubmitHandler}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Please wail...' : 'Submit'}
            </button>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default FreshStockIn
