import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkPermissions,
  dateFormat,
  defaultPermission,
  eaCloseModal,
  fetchAsyncDropdown,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest} from '../../../../_eaFruitsDms/https/apiCall'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import LightBoxDynamic from './LightBoxDynamic'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {decryptData} from '../../../../_eaFruitsDms/helpers/storageHelper'

const AddressSection = ({addressType, addressTitle, statusKey, type, customer}: any) => {
  const customerId = localStorage.getItem('cId')

  const rejection = {
    option: '-1',
    comments: '',
  }
  const rejectionValidation: any = {
    option: '',
    comments: '',
  }
  const userType: any = decryptData('user_type') || ''

  const [lightboxImages, setLightBoxImages] = useState<any>([])
  const [images, setImages] = useState<any>([])
  const [rejectReasonOffSet, setRejectReasonOffSet] = useState<any>('')
  const [rejectReasonOption, setRejectReasonOption] = useState<any>([])
  const [addressRejection, setAddressRejection] = useState(rejection)
  const [addressRejectionValidation, setAddressRejectionValidation] =
    useState<any>(rejectionValidation)
  const [searchSelect, setSearchSelect] = useState('')
  const [businessAddress, setBusinessAddress] = useState<any>(null)
  const [permission, setPermission] = useState(defaultPermission)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)

  useEffect(() => {
    const customerPermission = localStorage.getItem('customerPermission')
    if (customerPermission == 'allCustomer') {
      checkPermissions('customer').then((res: any) => {
        setPermission(res)
      })
    } else {
      checkPermissions('suspended_customer').then((res: any) => {
        setPermission(res)
      })
    }
  }, [])

  useEffect(() => {
    fetchAddressDetails().then()
  }, [addressType])

  const fetchAddressDetails = async () => {
    setDisplayLoader(true)
    const response = await getRequest(
      `${apiBaseURL()}customers/${customerId}/business-address-documents`,
      true
    )

    if (response.data) {
      if (response.data.error) {
        //TODO: Need to handle error response
      }
    }

    const lightBoxData: any = []
    const imageList: any = []
    setLightBoxImages([])

    response[addressType].length > 0 &&
      response[addressType].map((photo: any) => {
        if (photo['file_name']) {
          lightBoxData.push({
            id: photo.id,
            address_id: response.id,
            src: `${photo['file_name']}`,
            thumb: `${photo[`thumbnail`]}`,
            latitude: photo[`latitude`],
            longitude: photo[`longitude`],
            customer_latitude: (customer && customer.latitude) || null,
            customer_longitude: (customer && customer.longitude) || null,
            address_verify: response[statusKey] === 'verified',
            customer_info: `Location of the ${customer && customer.business_name}, ${
              customer && customer.street
            }`,
            photo_info: `Address Verification Photo(s) for the ${
              customer && customer.business_name
            }, ${customer && customer.street} are captured here!`,
          })

          imageList.push(photo['file_name'])
        }
      })

    setLightBoxImages(lightBoxData)
    setImages(imageList)

    setBusinessAddress(response)
    setDisplayLoader(false)
  }

  const approveAddressVerification = async () => {
    let approveAddressData: any = {
      status: 'verified',
      type: type,
    }

    await patchRequest(
      `${apiBaseURL()}customers/${customerId}/business-address-documents/${businessAddress.id}`,
      approveAddressData,
      true
    )
    await fetchAddressDetails()
  }

  const validateFormReject = () => {
    let validations = {...addressRejectionValidation}
    let isValid = true

    if (
      (addressRejection.option && addressRejection.option === '') ||
      addressRejection.option == '-1'
    ) {
      validations.option = 'is-invalid'
      isValid = false
    }
    if (addressRejection.option === '' && addressRejection.comments.trim() === '') {
      validations.comments = 'is-invalid'
      isValid = false
    }

    setAddressRejectionValidation(validations)

    return isValid
  }

  const rejectDocument = async () => {
    const isValid = validateFormReject()

    if (isValid) {
      let approveAddressData: any = {
        status: 'rejected',
        type: type,
        rejection_option: addressRejection.option !== '-1' ? addressRejection.option : null,
        rejection_note: addressRejection.comments,
      }

      await patchRequest(
        `${apiBaseURL()}customers/${customerId}/business-address-documents/${businessAddress.id}`,
        approveAddressData,
        true
      )

      setTimeout(() => {
        clearFunction()
      }, 200)

      eaCloseModal(addressType)
      await fetchAddressDetails()
    }
  }

  const clearFunction = () => {
    setAddressRejection(rejection)
    setAddressRejectionValidation(rejectionValidation)
  }

  const fetchRejectionReason = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const response = await fetchAsyncDropdown(
      rejectReasonOffSet,
      setRejectReasonOffSet,
      rejectReasonOption,
      setRejectReasonOption,
      `master/options-master/?type=Address_Rejection`,
      search,
      setSearchSelect,
      'option'
    )
    response.options.push({
      label: 'Other',
      value: '',
    })
    return response
  }

  const handleChangeReason = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value
    setAddressRejectionValidation({...addressRejectionValidation, [inputName]: ''})

    switch (inputName) {
      case 'option':
        setAddressRejection({...addressRejection, ['option']: inputValue})
        break
      case 'comments':
        setAddressRejection({...addressRejection, ['comments']: inputValue})
        break
    }
  }

  return (
    <>
      {displayLoader && <IsLoader />}
      {/*If Address type is pending*/}
      {businessAddress && businessAddress[`${statusKey}`] === 'pending' && (
        <>
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
            <div className='symbol symbol-60px symbol-1by1 me-4'>
              <div
                className='symbol-label'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')})`,
                }}
              ></div>
            </div>

            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='col-md-5 col-lg-4 me-2'>
                <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{addressTitle}</p>
              </div>
              {userType === 'WM' ? (
                <div className='col-auto ms-auto'>
                  <div className='row'>
                    <div className='col-md'>
                      {permission.write && (
                        <button
                          className='btn btn-primary me-2'
                          // onClick={() => actionOnDocument(`${verifyKey}`, 'Approved')}
                        >
                          Request
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='col-auto ms-auto'>
                  <span className='badge badge-light-success my-2 text-dark'>Not Uploaded</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/*If Address type verification start and photos uploaded*/}
      {businessAddress && businessAddress[`${statusKey}`] === 'verification_pending' && (
        <>
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
            {lightboxImages.length > 0 ? (
              <LightBoxDynamic
                fetchAddressDetails={fetchAddressDetails}
                addressTitle={addressTitle}
                addressType={addressType}
                lightboxImages={lightboxImages}
              />
            ) : (
              <div className='symbol symbol-60px symbol-1by1 me-4'>
                <div
                  className='symbol-label'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/icons/placeholder_img_crop.jpg'
                    )})`,
                  }}
                ></div>
              </div>
            )}
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='col-3 me-2'>
                <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{addressTitle}</p>
              </div>
              <div className='col-3 me-20'>
                <p className='text-muted mb-1'>
                  Captured on{' '}
                  {dateFormat(businessAddress[addressType][0].captured_at).displayDate +
                    ' ' +
                    dateFormat(businessAddress[addressType][0].captured_at, 'LT').displayDate}
                </p>
                <p className='text-muted mb-0'>
                  By{' '}
                  {businessAddress[addressType].length > 0
                    ? businessAddress[addressType][0].captured_by
                    : '-'}
                </p>
              </div>
              {(userType.toLowerCase() === 'ts' ||
                (permission.write && userType.toLowerCase() === 'wm')) && (
                <div className='col-auto ms-auto'>
                  <Button variant='secondary' size='sm' className='mb-2'>
                    Request New
                  </Button>
                  <Button
                    variant='primary'
                    size='sm'
                    className='ms-2 mb-2'
                    onClick={() => approveAddressVerification()}
                  >
                    Approve
                  </Button>
                  <EaButton
                    isModal={true}
                    className='btn btn-danger mb-2 ms-2'
                    btnName='Reject'
                    dataTarget={`#${addressType}_ea_modal_reject_reason`}
                  ></EaButton>
                </div>
              )}

              {['sa'].includes(userType.toLowerCase()) && (
                <div className='col-auto ms-auto'>
                  <span className='badge badge-light-success text-dark'>Verify Pending</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/*If Address type approved*/}
      {businessAddress && businessAddress[`${statusKey}`] === 'verified' && (
        <>
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
            {lightboxImages.length > 0 ? (
              <LightBoxDynamic
                fetchAddressDetails={fetchAddressDetails}
                addressTitle={addressTitle}
                addressType={addressType}
                lightboxImages={lightboxImages}
              />
            ) : (
              <div className='symbol symbol-60px symbol-1by1 me-4'>
                <div
                  className='symbol-label'
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/icons/placeholder_img_crop.jpg'
                    )})`,
                  }}
                ></div>
              </div>
            )}
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='col-3 me-2'>
                <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{addressTitle}</p>
                {/*<button className='btn btn-link p-0 fw-bold pt-1' type='button'>View Location on Map</button>*/}
              </div>
              <div className='col-3 me-20'>
                <p className='text-muted mb-1'>
                  Captured on{' '}
                  {dateFormat(businessAddress[addressType][0].captured_at).displayDate +
                    ' ' +
                    dateFormat(businessAddress[addressType][0].captured_at, 'LT').displayDate}
                </p>
                <p className='text-muted mb-0'>
                  By{' '}
                  {businessAddress[addressType].length > 0
                    ? businessAddress[addressType][0].captured_by
                    : '-'}
                </p>
              </div>
              <div className='col'>
                <p className='text-muted mb-1'>
                  Verified on{' '}
                  {dateFormat(businessAddress[`${type}_action_at`]).displayDate +
                    ' ' +
                    dateFormat(businessAddress[`${type}_action_at`], 'LT').displayDate}
                </p>
                <p className='text-muted mb-0'>
                  By{' '}
                  {businessAddress[`${type}_action_by`]
                    ? businessAddress[`${type}_action_by`]
                    : '-'}
                </p>
              </div>
              <div className='col-auto'>
                <span className='badge badge-light-success my-2 text-dark'>Verified</span>
              </div>
            </div>
          </div>
        </>
      )}

      {/*If Address type rejected*/}
      {businessAddress && businessAddress[`${statusKey}`] === 'rejected' && (
        <>
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center border-bottom px-0 py-3'>
            <div className='symbol symbol-60px symbol-1by1 me-4'>
              <div
                className='symbol-label'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')})`,
                }}
              ></div>
            </div>
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='col-3 me-2'>
                <p className='text-gray-800 fw-bold font-17 d-block mb-0'>{addressTitle}</p>
                {/*<button className='btn btn-link p-0 fw-bold pt-1' type='button'>View Location on Map</button>*/}
              </div>
              <div className='col-3 me-20'>
                <p className='text-muted mb-1'>
                  Captured on{' '}
                  {businessAddress[addressType].length > 0
                    ? dateFormat(businessAddress[addressType][0].captured_at).displayDate +
                      ' ' +
                      dateFormat(businessAddress[addressType][0].captured_at, 'LT').displayDate
                    : '-'}
                </p>
                <p className='text-muted mb-0'>
                  By{' '}
                  {businessAddress[addressType].length > 0
                    ? businessAddress[addressType][0].captured_by
                    : '-'}
                </p>
              </div>
              <div className='col me-2'>
                <p className='text-muted mb-1'>
                  Rejected on{' '}
                  {dateFormat(businessAddress[`${type}_action_at`]).displayDate +
                    ' ' +
                    dateFormat(businessAddress[`${type}_action_at`], 'LT').displayDate}
                </p>
                <p className='text-muted mb-0'>
                  For{' '}
                  {businessAddress[`${addressType}_rejection_reason`]
                    ? businessAddress[`${addressType}_rejection_reason`]
                    : '-'}
                </p>
                <p className='text-muted mb-0'>
                  By{' '}
                  {businessAddress[`${type}_action_by`]
                    ? businessAddress[`${type}_action_by`]
                    : '-'}
                </p>
              </div>
              <div className='col-auto'>
                {['WM', 'TS'].includes(userType) ? (
                  <Button variant='secondary' size='sm' className='mb-2'>
                    Request New
                  </Button>
                ) : (
                  <span className='badge badge-light-danger my-2 text-dark'>Rejected</span>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <EaModal
        modalTitle='Verification Document Rejection Reason'
        onSubmit={rejectDocument}
        cancel={clearFunction}
        id={`${addressType}_ea_modal_reject_reason`}
        actionBtnName='Yes, Proceed'
        closeAuto={`${addressType}`}
        children={
          <>
            <p>Are you sure, you want to reject this address request?</p>
            <div className='mb-4'>
              <label className='form-label'>Verification Document Rejection Reason</label>
              <AsyncPaginate
                loadOptions={fetchRejectionReason}
                isSearchable
                className={clsx('react-select-container w-100', addressRejectionValidation.option)}
                classNamePrefix='react-select'
                name='option'
                onChange={(e: any) => handleChangeReason(e, 'option')}
                value={rejectReasonOption.filter(
                  (option: any) => option.value === addressRejection.option
                )}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            {addressRejection.option == '' && (
              <div>
                <label className='form-label'>Comments</label>
                <textarea
                  className={clsx('form-control', addressRejectionValidation.comments)}
                  rows={4}
                  name='comments'
                  value={addressRejection.comments}
                  onChange={(e: any) => handleChangeReason(e, 'comments')}
                ></textarea>
              </div>
            )}
          </>
        }
      />
    </>
  )
}

export default AddressSection
